import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { NotificationManager } from "react-notifications";

import CustomDropModal from "../PopUp";
import { Timeline } from "./Timeline";

import * as categoriesActions from "../../store/categories/actions";
import * as categoriesSelectors from "../../store/categories/selectors";
import * as glossariesActions from "../../store/glossaries/actions";
import * as workflowActions from "../../store/workflows/actions";
import * as workflowSelectors from "../../store/workflows/selectors";
import * as glossariesSelectors from "../../store/glossaries/selectors";
import * as stepsActions from "../../store/steps/actions";
import * as stepsSelectors from "../../store/steps/selectors";

import { viewModals } from "../../helpers";
import strings from "../../services/strings";
import auth from "../../services/auth";

import "./WorkflowForm.scss";

class WorkflowForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      draftsByMilestone: {},
      workflowName: "",
      redirectUrl: "",
    };
  }

  componentDidMount() {
    sessionStorage.clear();
    this.loadDraftsByMilestone();
    if(auth.isAuthenticated()){
      this.props.fetchUserWorkflows();
    }
    // if (!_.size(this.props.glossaries)) {
    //   this.props.fetchAllGlossaries();
    // }
    // if (!_.size(this.props.steps)) {
    //   this.props.fetchAllSteps();
    // }
    // if (!_.size(this.props.categories)) {
    //   this.props.fetchAllCategories();
    // }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.drafts !== this.props.drafts) {
      this.loadDraftsByMilestone();
    }
    if (this.props.currentModal === viewModals.MILESTONE_DOC_ACCESS_DENY) {
      this.refs.MILESTONE_DOC_ACCESS_DENY.show();
    }
  }

  loadDraftsByMilestone = (props = this.props) => {
    if (_.size(props.drafts)) {
      let draftsByMilestone = {};
      _.map(props.drafts, (draft) => {
        let temp = [];
        if (draftsByMilestone[draft.milestone_id]) {
          temp = draftsByMilestone[draft.milestone_id];
        }
        temp.push(draft);
        draftsByMilestone[draft.milestone_id] = temp;
      });
      this.setState({
        draftsByMilestone,
      });
    }
  };

  handlecookieConsentModalHide = () => {
    this.props.setCurrentModal("");
  };

  getMilestoneDocAccessDenyModal = () => {
    return (
      <CustomDropModal
        className="boron-modal"
        ref={viewModals.MILESTONE_DOC_ACCESS_DENY}
        onHide={this.handlecookieConsentModalHide}
      >
        <h2>
          {strings.get("Client.homePage.milestoneDocAccessDenyPopUp.title")}
        </h2>
        <p>
          {strings.get("Client.homePage.milestoneDocAccessDenyPopUp.message")}
        </p>
      </CustomDropModal>
    );
  };

  changeWorkflowName = (e) => {
    this.setState({ workflowName: e.target.value });
  };

  getAddWorkflowModal = () => {
    return (
      <CustomDropModal
        className="boron-modal"
        ref="addWorkflow"
        onHide={this.handleAddWorkflowModalHide}
      >
        <div className="close-btn">
          <span
            className="ion-android-cancel clickable"
            onClick={this.hideAddWorkflowModal}
          />
        </div>
        <h2>{strings.get("Client.workflows.addNewWorkflow")}</h2>
        <form onSubmit={this.handleAddWorkflowSubmit}>
          <input
            className="form-control workflowName"
            placeholder={strings.get(
              "Client.draftsPage.worflowNamePlaceholder"
            )}
            value={this.state.workflowName}
            onChange={this.changeWorkflowName}
          />
          <button className="btn btn2" type="submit">
            {strings.get("Client.draftsPage.save")}
          </button>
          <button
            className="btn btn3"
            onClick={this.hideAddWorkflowModal}
            type="button"
          >
            {strings.get("Client.draftsPage.cancel")}
          </button>
        </form>
      </CustomDropModal>
    );
  };

  handleAddWorkflow = (url) => {
    if (_.size(this.props.userCurrentWorkflow)) {
      window.location.assign(url);
    } else if (!auth.isAuthenticated()) {
      NotificationManager.warning(
        strings.get("Client.notifications.loginRequired")
      );
    } else {
      this.setState(
        {
          workflowName: "",
          redirectUrl: url,
        },
        () => {
          this.refs.addWorkflow.show();
        }
      );
    }
  };

  handleAddWorkflowSubmit = async (event) => {
    event.preventDefault();
    if (this.state.workflowName.trim()) {
      let data = {};
      data.name = this.state.workflowName;
      data.workflow_id = this.props.match.params.workflowId;
      data.workflow_category_id = this.props.match.params.catId;
      let item = await this.props.addUserWorkflow(data);
      if (item.id) {
        this.refs.addWorkflow.hide();
        _.delay(() => {
          window.location.assign(
            `${this.state.redirectUrl}&currentWorkflowId=${item.id}`
          );
        }, 500);
      }
    } else {
      NotificationManager.info(
        strings.get("Client.notifications.fieldRequired")
      );
    }
  };

  hideAddWorkflowModal = () => {
    this.refs.addWorkflow.hide();
  };

  render() {
    return (
      <div className="WorkflowForm container-row">
        {this.getMilestoneDocAccessDenyModal()}
        {this.getAddWorkflowModal()}
        {_.size(this.props.userCurrentWorkflow) ? (
          <h3 className="saved-workflow-title">
            {this.props.userCurrentWorkflow.name}
          </h3>
        ) : null}
        <Timeline
          milestones={
            (this.props.currentItem && this.props.currentItem.milestones) || []
          }
          setCurrentModal={this.props.setCurrentModal}
          // params={this.props.match && this.props.match.params ? this.props.match.params : {}}
          userCurrentWorkflow={this.props.userCurrentWorkflow}
          drafts={this.state.draftsByMilestone || {}}
          updateDraftName={this.props.updateDraftName}
          steps={this.props.steps}
          glossaries={this.props.glossaries}
          categories={this.props.categories}
          deleteDraft={this.props.deleteDraft}
          fetchCurrentWorkflow={this.props.fetchCurrentWorkflow}
          handleAddWorkflow={this.handleAddWorkflow}
          currentUserWorkflows={
            this.props.currentUserWorkflows
              ? this.props.currentUserWorkflows
              : {}
          }
        />
      </div>
    );
  }
}

WorkflowForm.propTypes = {
  currentItem: PropTypes.object,
  setCurrentModal: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  userCurrentWorkflow: PropTypes.object,
  drafts: PropTypes.object,
  currentModal: PropTypes.string.isRequired,
  fetchCurrentWorkflow: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    categories: categoriesSelectors.getItems(state),
    steps: stepsSelectors.getItems(state),
    glossaries: glossariesSelectors.getItems(state),
    currentUserWorkflows: workflowSelectors.getUserWorkflows(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllCategories: (deleteCache) => {
      dispatch(categoriesActions.fetchAllItems(deleteCache));
    },
    fetchAllSteps: (deleteCache) => {
      dispatch(stepsActions.fetchAllItems(deleteCache));
    },
    fetchAllGlossaries: () => {
      dispatch(glossariesActions.fetchAllItems());
    },
    addUserWorkflow: (data) => {
      return dispatch(workflowActions.addUserWorkflow(data));
    },
    fetchUserWorkflows: (deleteCache) => {
      return dispatch(workflowActions.getUserForkflows(deleteCache));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(WorkflowForm);
