import React from "react";
import { Link } from "react-router-dom";

import language from "../../services/language";
import { makeSEFSlug } from "../../helpers";

function CategorySingle(props) {
  const { item } = props;
  return (
    <li className="col-sm-4">
      <Link
        to={`/${language.getIso2()}/articles/${makeSEFSlug(item.slug)}-${
          item.id
        }`}
      >
        <p>{item.name}</p>
      </Link>
    </li>
  );
}

export default CategorySingle;
