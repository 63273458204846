// export function getHeaderLogo(state) {
// 	return state.settings.headerLogo;
// }

// export function getFooterLogo(state) {
// 	return state.settings.footerLogo;
// }

// export function getSiteURL(state) {
// 	return state.settings.siteURL;
// }

// export function getSiteName(state) {
// 	return state.settings.siteName;
// }

export function getSiteSettings(state) {
  return state.settings.siteSettings;
}

export function getCurrentModal(state) {
  return state.settings.currentModal;
}

export function getCookieBannerStatus(state) {
  return state.settings.cookieBannerStatus;
}

export function getScreenLoadingStatus(state) {
  return state.settings.screenLoading;
}

export function getCurrentLanguage(state) {
  return state.settings.currentLang;
}
