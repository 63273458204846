import React, { Component } from "react";
import DocumentFormPage from "./DocumentFormPage";
export default class DocumentFormPageAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forward: true,
    };
  }

  render() {
    return this.state.forward ? <DocumentFormPage {...this.props} /> : null;
  }
}
