import api from "../../services/api";

import * as exceptionsActions from "../exceptions/actions";
import * as draftsSelectors from "./selectors";

export const types = {
  FETCH_ALL_SHARED_DRAFTS_DONE: "sharedDrafts.FETCH_ALL_SHARED_DRAFTS_DONE",
  CLEAR_SHARED_DRAFTS: "sharedDrafts.CLEAR_DRAFTS",
  SET_CATEGORY_ID: "sharedDrafts.SET_CATEGORY_ID",
  SET_SEARCH_TERM: "sharedDrafts.SET_SEARCH_TERM",
  SET_CURRENT_PAGE: "sharedDrafts.SET_CURRENT_PAGE",
  CLEAR_CACHE: "sharedDrafts.CLEAR_CACHE",
};

export function clearDrafts() {
  return async (dispatch) => {
    dispatch({
      type: types.CLEAR_SHARED_DRAFTS,
    });
  };
}

export function clearCache() {
  return {
    type: types.CLEAR_CACHE,
  };
}

export function fetchAllSharedDrafts(deleteCache = false) {
  return async (dispatch, getState) => {
    let state = getState();
    try {
      let params = new Map();
      let filters = draftsSelectors.getFilters(state);
      let pagination = draftsSelectors.getPagination(state);
      params.set("expand", "document");
      if (filters.categoryId) params.set("category_id", filters.categoryId);
      if (filters.searchTerm) params.set("name", filters.searchTerm);

      params.set("page_size", pagination.pageSize);
      params.set("page_number", deleteCache ? 1 : pagination.currentPage);

      // GET request from API
      let [response, items] = await api.get(`/shareddrafts`, params, true);

      // Clear cache if deleteCache is enabled
      if (deleteCache) {
        dispatch(clearCache());
      }

      dispatch({
        type: types.FETCH_ALL_SHARED_DRAFTS_DONE,
        payload: {
          totalPages: parseInt(response.headers.get("X-Total-Pages")),
          items,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function setCategoryId(id) {
  return {
    type: types.SET_CATEGORY_ID,
    payload: {
      id,
    },
  };
}

export function setSearchTerm(searchTerm) {
  return {
    type: types.SET_SEARCH_TERM,
    payload: {
      searchTerm,
    },
  };
}

export function setCurrentPage(page) {
  return {
    type: types.SET_CURRENT_PAGE,
    payload: {
      page,
    },
  };
}
