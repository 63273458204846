import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import _ from "lodash";

import strings from "../services/strings";
import language from "../services/language";

import "./NavigationLeftTabs.scss";

function NavigationLeftTabs(props) {
  const getMenuItems = (primaryId) => {
    let locale = language.getIso2();
    let itemsContent = [
      [
        strings.get("Client.settings.messages.shareEmail.title"),
        `/messages/shareemail`,
      ],
      [
        strings.get("Client.settings.messages.shareDraft.title"),
        `/messages/sharedraft`,
      ],
      [
        strings.get("Client.settings.messages.alerts.title"),
        `/messages/alerts`,
      ],
    ];
    let items = _.map(itemsContent, (item, i) => (
      <Link
        className={
          i === primaryId
            ? "btn btn-primary right pull-left"
            : "btn btn-default right pull-left"
        }
        to={`/${locale + item[1]}`}
        key={i}
      >
        {item[0]}
      </Link>
    ));
    return items;
  };

  return (
    <div className="navigation-left-tabs col-xs-12 col-sm-3 col-md-3">
      <div className="left-menu">{getMenuItems(props.currentItemId)}</div>
    </div>
  );
}

NavigationLeftTabs.propTypes = {
  currentItemId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationLeftTabs);
