import React from "react";
import PropTypes from "prop-types";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import Select from "react-select";
import _ from "lodash";

import AutoSuggestion from "../AutoSuggestion.js";
import { CustomFadeModal } from "../PopUp.js";

import * as documentsActions from "../../store/documents/actions";
import * as documentsSelectors from "../../store/documents/selectors";

import { makeSEFUrl } from "../../helpers";
import auth from "../../services/auth";
import strings from "../../services/strings";
import language from "../../services/language.js";

import "./LoadSavedData.scss";

class LoadSavedData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentType: "doc",
      workflowType: "non-workflow",
      userWorkflowName: "",
    };
  }

  toggleDocModel = (itemId) => {
    if (!auth.isAuthenticated()) {
      NotificationManager.warning(
        "",
        strings.get("Client.notifications.loginRequired")
      );
    } else {
      if (
        _.size(this.props.currentDocument) &&
        this.props.currentDocument.id === itemId
      ) {
        //ignore
      } else {
        this.props.setCurrentDocumentId(itemId);
        if (this.props.userCurrentWorkflowId) {
          this.props.fetchDocument(
            itemId,
            "client",
            this.props.userCurrentWorkflowId
          );
        } else {
          this.props.fetchDocument(itemId, "client");
        }
      }
      this.setState(
        {
          documentType: "doc",
        },
        () => {
          this.showDataReUseModal();
        }
      );
    }
  };

  docModalOptions = () => {
    let documentForAutofill = "";
    let propsForSend = "";
    let userWorkflowName = this.state.userWorkflowName;

    if (this.props.currentDocument) {
      if (this.state.documentType === "draft") {
        if (this.state.workflowType === "workflow") {
          if (
            _.size(this.props.currentDocument.user_workflow_drafts) &&
            this.props.currentDocument.user_workflow_drafts[userWorkflowName]
          ) {
            documentForAutofill = "user_workflow_drafts";
            propsForSend = this.props.currentDocument.user_workflow_drafts[
              userWorkflowName
            ];
          }
        } else {
          documentForAutofill = "user_drafts";
          propsForSend = this.props.currentDocument.user_drafts;
        }
      } else if (this.state.documentType === "role") {
        if (this.state.workflowType === "workflow") {
          if (
            _.size(this.props.currentDocument.user_workflow_roles) &&
            this.props.currentDocument.user_workflow_roles[userWorkflowName]
          ) {
            documentForAutofill = "user_workflow_roles";
            propsForSend = this.props.currentDocument.user_workflow_roles[
              userWorkflowName
            ];
          }
        } else {
          documentForAutofill = "roles";
          propsForSend = this.props.currentDocument.roles;
        }
      } else if (this.state.documentType === "doc") {
        if (this.state.workflowType === "workflow") {
          if (
            _.size(this.props.currentDocument.user_workflow_downloads) &&
            this.props.currentDocument.user_workflow_downloads[userWorkflowName]
          ) {
            documentForAutofill = "user_workflow_downloads";
            propsForSend = this.props.currentDocument.user_workflow_downloads[
              userWorkflowName
            ];
          }
        } else {
          documentForAutofill = "user_downloads";
          propsForSend = this.props.currentDocument.user_downloads;
        }
      }
    }
    let options = [];
    if (
      this.props.currentDocument &&
      _.has(this.props.currentDocument, documentForAutofill)
    ) {
      _.forEach(propsForSend, function (option, key) {
        let downloads = {
          value: option.id,
          name: option.file,
          percentage: option.matched_percentage,
          fields: option.matched_fields,
        };
        options.push(downloads);
      });
    }

    if (this.props.stepWiseData) {
      if (!this.props.stepFieldsCount.length) {
        return null;
      }
      options = this.getDocumentAccordingToStepsFields(options);
    }
    return options;
  };

  downloadDocMultiSelected = (selectedDownloads = []) => {
    if (this.props.stepWiseData) {
      let sharedfields = [];
      if (this.props.draftShareFields) {
        try {
          sharedfields = Object.keys(JSON.parse(this.props.draftShareFields));
        } catch (error) {
          console.trace(error);
        }
      }
      let highlightKeys = [];
      let preFilledFields = {};
      selectedDownloads.map((selectedDownload) => {
        if (_.size(selectedDownload.fields) > 0) {
          const keys = Object.keys(selectedDownload.fields);
          const currentStepId = this.props.currentStep.id;
          _.each(this.props.fields, ({ name, stepId, type }, key) => {
            if (keys.includes(name) && stepId === currentStepId) {
              //for saved draft/role
              if (!!sharedfields.includes(name)) {
                sessionStorage.setItem(name, selectedDownload.fields[name]);
                if (type === "gender_selector")
                  sessionStorage.setItem(
                    `${name}_translation`,
                    selectedDownload.fields[name]
                  );
                preFilledFields[name] = selectedDownload.fields[name];
                highlightKeys.push(name);
              }
              //for new document
              if (!this.props.draftShareFields) {
                sessionStorage.setItem(name, selectedDownload.fields[name]);
                if (type === "gender_selector")
                  sessionStorage.setItem(
                    `${name}_translation`,
                    selectedDownload.fields[name]
                  );
                preFilledFields[name] = selectedDownload.fields[name];
                highlightKeys.push(name);
              }
            }
          });
        }
      });
      this.props.handleDataReUseAccordingStep(highlightKeys, preFilledFields);
      this.hideDataReUseModal();
    } else {
      selectedDownloads.map((selectedDownload) => {
        _.each(selectedDownload.fields, (value, key) => {
          sessionStorage.setItem(key, value);
        });
      });
      let locale = language.getIso2();
      let item = this.props.currentDocument;
      _.delay(() => {
        if (this.props.userCurrentWorkflowId) {
          window.location.assign(this.props.redirectLink);
        } else {
          let url = this.processLink(item, locale);
          window.location.assign(url);
        }
        // this.props.router.push(url);
      }, 300);
    }
  };

  processLink = (item, locale) => {
    return `/${locale}/documenten/${this.props.catName}-${
      item.category_id
    }/${encodeURIComponent(makeSEFUrl(item.name))}-${item.id}`;
  };

  handleDocTypeChange = (option) => {
    this.setState({
      documentType: option.value,
      userWorkflowName: "",
    });
  };

  handleUserWorkflowSelector = (option) => {
    this.setState({
      userWorkflowName: option.value,
    });
  };

  handleWorkflowTypeChange = (option) => {
    this.setState({
      workflowType: option.value,
    });
  };

  hideDataReUseModal = () => {
    this.refs.dataReUseModal.hide();
  };

  showDataReUseModal = () => {
    this.refs.dataReUseModal.show();
  };

  getDocumentAccordingToStepsFields = (options) => {
    if (this.props.stepFieldsCount) {
      Object.keys(options).map((value, index) => {
        this.props.stepFieldsCount.forEach((element) => {
          if (
            this.props.currentStep &&
            this.props.currentStep.id &&
            element.id === this.props.currentStep.id
          ) {
            let result = _.intersection(
              Object.keys(options[index].fields),
              element.keys
            );
            let percentage = Math.round(
              (result.length / element.keys.length) * 100
            );
            options[index].percentage = percentage;
          }
        });
      });
    }
    return options;
  };

  getDataReuseModal = () => {
    const options = [
      { value: "doc", label: strings.get("Client.profilePage.downloads") },
      { value: "role", label: strings.get("Client.profilePage.roles") },
      { value: "draft", label: strings.get("Client.homePage.drafts") },
    ];

    const workflowOptions = [
      {
        value: "non-workflow",
        label: strings.get("Client.documentsList.dataReuseModal.nonWorkflows"),
      },
      {
        value: "workflow",
        label: strings.get("Client.documentsList.dataReuseModal.workflow"),
      },
    ];

    let userWorkflowOptions = [
      {
        value: "No Data",
        label: strings.get(
          "Client.documentsList.dataReuseModal.selectWorkflow"
        ),
        isDisabled: true,
      },
    ];

    let userWorkflowFilter = false;
    if (
      this.state.workflowType === "workflow" &&
      (this.state.documentType === "doc" || this.state.documentType === "draft")
    ) {
      userWorkflowFilter = true;
    }
    if (
      this.state.workflowType === "workflow" &&
      this.state.documentType === "doc" &&
      _.size(this.props.currentDocument.user_workflow_downloads)
    ) {
      let names = Object.keys(
        this.props.currentDocument.user_workflow_downloads
      );
      names.forEach((name) => {
        let data = {};
        data.value = data.label = name;
        userWorkflowOptions.push(data);
      });
    }
    if (
      this.state.workflowType === "workflow" &&
      this.state.documentType === "draft"
    ) {
      let names = Object.keys(this.props.currentDocument.user_workflow_drafts);
      names.forEach((name) => {
        let data = {};
        data.value = data.label = name;
        userWorkflowOptions.push(data);
      });
    }

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        padding: 7,
        flex: 1,
        display: "flex",
      }),
      control: (provided, state) => {
        return {
          ...provided,
          minHeight: "55px",
        };
      },
    };

    return (
      <CustomFadeModal
        className="boron-modal dataReUseModal"
        ref="dataReUseModal"
      >
        <div className="close-btn">
          <span
            className="ion-android-cancel clickable"
            onClick={this.hideDataReUseModal}
          />
        </div>
        <h5 style={{ padding: "20px" }}>
          {this.props.currentDocument && this.props.currentDocument.name
            ? this.props.currentDocument.name.toUpperCase()
            : null}
        </h5>
        <div className="select-doc">
          <div className="row" style={{ width: "100%", marginLeft: "0px" }}>
            <div
              className="col-xs-6 col-sm-6 col-md-6"
              style={{ padding: "5px" }}
            >
              <Select
                options={workflowOptions}
                defaultValue={workflowOptions[0]}
                styles={customStyles}
                onChange={this.handleWorkflowTypeChange}
              />
            </div>
            <div
              className="col-xs-6 col-sm-6 col-md-6"
              style={{ padding: "5px" }}
            >
              <Select
                options={options}
                defaultValue={options[0]}
                styles={customStyles}
                onChange={this.handleDocTypeChange}
              />
            </div>
            {userWorkflowFilter ? (
              <div
                className="col-xs-12 col-sm-12 col-md-12"
                style={{ padding: "5px" }}
              >
                <Select
                  options={userWorkflowOptions}
                  defaultValue={userWorkflowOptions[0]}
                  styles={customStyles}
                  onChange={this.handleUserWorkflowSelector}
                />
              </div>
            ) : null}
            <div
              className="col-xs-12 col-sm-12 col-md-12 listAutoSuggestion"
              style={{ padding: "5px" }}
            >
              <AutoSuggestion
                items={this.docModalOptions()}
                multiItemSelected={this.downloadDocMultiSelected}
                documentType={this.state.documentType}
              />
            </div>
          </div>
        </div>
      </CustomFadeModal>
    );
  };

  render() {
    return (
      <div className="LoadSavedData">
        {this.getDataReuseModal()}
        <button
          className={`btn btn-details-doc btn-primary ${this.props.className}`}
          onClick={() => this.toggleDocModel(this.props.documentId)}
          title={
            this.props.stepWiseData
              ? strings.get(
                  "Client.documentsList.dataReuseModal.stepWiseButtonMouseOver"
                )
              : strings.get(
                  "Client.documentsList.dataReuseModal.templateDataReUseButtonMouseOver"
                )
          }
        >
          {this.props.stepWiseData
            ? strings.get(
                "Client.documentsList.dataReuseModal.stepWiseButtonLabel"
              )
            : strings.get("Client.documentsList.loadDataSelector")}
        </button>
      </div>
    );
  }
}

//if 'stepWiseData' is true then 'handleDataReUseAccordingStep' is required
LoadSavedData.propTypes = {
  documentId: PropTypes.number.isRequired,
  catName: PropTypes.string.isRequired,
  currentDocument: PropTypes.any.isRequired,
  stepWiseData: PropTypes.bool.isRequired,
  stepFieldsCount: PropTypes.array,
  currentStep: PropTypes.object,
  draftShareFields: PropTypes.string,
  fields: PropTypes.array,
  handleDataReUseAccordingStep: PropTypes.func,
  className: PropTypes.string,
  redirectLink: PropTypes.string,
  userCurrentWorkflowId: PropTypes.number,
};

LoadSavedData.defaultProps = {
  stepWiseData: false,
  className: "",
  fields: [],
  redirectLink: "",
  userCurrentWorkflowId: 0,
};

function mapStateToProps(state) {
  return {
    currentDocument: documentsSelectors.getCurrentItem(state)
      ? documentsSelectors.getCurrentItem(state)
      : {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDocument: (id, type, userWorkflowId) => {
      dispatch(documentsActions.fetchItem(id, type, userWorkflowId));
    },
    setCurrentDocumentId: (id) => {
      dispatch(documentsActions.setCurrentItemId(id));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadSavedData);
