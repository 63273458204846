import React from "react";

export default class Ad extends React.Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return (
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-0090682890508732"
        data-ad-slot="2100549699"
        data-full-width-responsive="true"
      />
    );
  }
}
