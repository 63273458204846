import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Topbar from "../../components/Topbar";
import CategoryList from "../../../src/components/articleCategories/CategoryList";
import Footer from "../../components/footer/Footer";
import Breadcrumbs from "../../components/Breadcrumbs";

import * as articleCategoriesSelectors from "../../store/articleCategories/selectors";
import * as articleCategoriesActions from "../../store/articleCategories/actions";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import language from "../../services/language";
import strings from "../../services/strings";
class ArticleCategoriesListPage extends Component {
  componentDidMount() {
    this.props.fetchAllCategories();
  }

  render() {
    let locale = language.getIso2();
    return (
      <div className="ArticleCategoriesListPage">
        <Topbar
          title={strings.get("Client.homePage.moreCategories")}
          subtitle={""}
          searchBox={false}
          menuItem={"categories"}
        />
        <Breadcrumbs>
          <Link to={`/${locale}/`}>{strings.get("Client.homePage.home")}</Link>
          <Link to={`/${locale}/articles`}>
            {strings.get("Client.homePage.article.title")}
          </Link>
        </Breadcrumbs>
        <CategoryList items={this.props.categories} />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: articleCategoriesSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllCategories: () => {
      dispatch(articleCategoriesActions.fetchAllItems());
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleCategoriesListPage);
