import _ from 'lodash';

export function getItem(state) {
	return state.sharedDrafts;
}

export function isSaved(state) {
	return state.sharedDrafts.saved ? state.sharedDrafts.saved : false;
}

export function alreadyExist(state) {
	return state.sharedDrafts.alreadyExist ? state.sharedDrafts.alreadyExist : false;
}

export function getFields(state){
    return state.sharedDrafts.fields;
}

export function getFieldsOrder(state){
    return state.sharedDrafts.fieldsOrder;
}

export function getSelectors(state){
    return state.sharedDrafts.selectors;
}

export function getClauses(state){
    return state.sharedDrafts.clauses;
}

export function getSteps(state){
    return state.sharedDrafts.steps;
}

export function getShareFields(state){
    return state.sharedDrafts.shareFields;
}

export function getFilters(state) {
	return state.sharedDrafts.filters;
}

export function getPagination(state) {
	return state.sharedDrafts.pagination;
}

export function getItemsByPage(state, page) {
	if (!state.sharedDrafts.idsByPage['_' + page]) {
		page = (getPagination(state)).previousPage;
	};
	return _.map(state.sharedDrafts.idsByPage['_' + page], (itemId) => {
		return state.sharedDrafts.itemsById['_' + itemId]
	});
}
