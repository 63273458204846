import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ProgressBar } from "react-bootstrap";
import PropTypes from "prop-types";
import _ from "lodash";
import Select, { components } from "react-select";

import strings from "../services/strings";

import "react-picky/dist/picky.css";
import "./AutoSuggestion.scss";

const Option = (props) => (
  <components.Option disabled={props.data.disabled} {...props}>
    <div
      style={{
        flex: 1,
        width: "65%",
        // marginBottom : '5px'
      }}
    >
      <input
        type="checkbox"
        checked={props.isSelected}
        disabled={props.data.disabled}
        onChange={(e) => null}
        style={{
          flex: "auto",
        }}
      />
      <label
        style={{
          flex: 1,
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: "75%",
          textAlign: "left",
          marginBottom: "-2px",
        }}
        title={props.data.name.length > 9 ? props.data.name : null}
      >
        {props.data.name}
      </label>
    </div>
    <ProgressBar
      now={props.data.percentage}
      label={`${props.data.percentage}%`}
      style={{
        flex: 0.5,
        margin: 0,
      }}
    />
  </components.Option>
);

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.name}</span>
  </components.MultiValue>
);

function AutoSuggestion(props) {
  const [suggestions, setSuggestions] = useState([]);
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    setSuggestions(getSuggestions(props.items));
  }, []);

  useEffect(() => {
    setSuggestions(getSuggestions(props.items));
    setSelected([]);
  },[props.items]);

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = (items, disabled = false) => {
    let selectedKeys = [];
    let selectedValues = [];

    if (disabled) {
      selected.map((s) => {
        selectedKeys = [...selectedKeys, ...Object.keys(s.fields)];
        selectedValues.push(s.value);
      });
      selectedKeys = _.uniq(selectedKeys);
    }

    return items.map((download) => {
      let temp = false;
      if (disabled && !selectedValues.includes(download.value)) {
        // console.log(selectedKeys,Object.keys(download.fields), _.intersection(selectedKeys, Object.keys(download.fields)));
        temp =
          _.intersection(selectedKeys, Object.keys(download.fields)).length > 0;
      }

      return {
        ...download,
        disabled: temp,
      };
    });
  };

  const useData = () => {
    if (selected.length) {
      props.multiItemSelected(selected);
      setSelected([]);
    }
  };

  const handleFieldsChange = (value) => {
    setSelected(value);
    setSuggestions(getSuggestions(props.items, true));
  };

  const customFilter = (option, searchText) => {
    if (option.data.name.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 7,
      flex: 1,
      display: "flex",
      alignItems: "center",
      opacity: state.isDisabled ? 0.5 : 1,
      transition: "opacity 300ms",
    }),
    valueContainer: (provided, state) => {
      return {
        ...provided,
        padding: "12px 8px",
      };
    },
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: "55px",
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        minHeight: "55px",
      };
    },
    container: (provided, state) => {
      return {
        ...provided,
        flex: 1,
        fontSize: ".9rem",
      };
    },
  };

  return (
    <div className="downloaded-doc">
      <Select
        value={selected}
        key={props.documentType}
        options={suggestions}
        isClearable
        isMulti
        filterOption={customFilter}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        onChange={handleFieldsChange}
        isOptionDisabled={(option) => option.disabled}
        placeholder={strings.get(
          "App.glossaries.glossaryForm.fields.selectPlaceholder"
        )}
        styles={customStyles}
        components={{ Option, MultiValue }}
        classNamePrefix="react-select"
      />
      <button
        onClick={useData}
        className="btn btn-primary data-reuse-submit"
      >
        {strings.get("Client.documentContent.useData")}
      </button>
    </div>
  );
}

AutoSuggestion.propTypes = {
  multiItemSelected: PropTypes.func.isRequired,
  documentType: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

AutoSuggestion.defaultProps = {
  documentType: "doc",
  items: [],
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoSuggestion);
