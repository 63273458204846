import React, { Component } from "react";
import validURL from "valid-url";
import Select from "react-select";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import _ from "lodash";

import SocialShareButton from "../social/SocialShareButton";
import AutoSuggestion from "../AutoSuggestion.js";
import CustomDropModal from "../PopUp";

import img from "../../static/images/988.png";
import imgStartWorkflow from "../../static/images/98.png";

import language from "../../services/language";
import { makeSEFUrl, checkCookieConsent } from "../../helpers";
import strings from "../../services/strings";

import "./WorkflowList.scss";

class WorkflowList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoModal: false,
      modalName: null,
      modalDescription: null,
      modalReadMoreLink: null,
      openShare: null,
      shareMsg: "",
      shareMsgLoaded: false,
      workflowType: "doc",
      item: {},
    };
  }

  getTitle = () => {
    if (_.size(this.props.items)) {
      return <h2>Workflow</h2>;
    }
  };

  showInfoModal = (name, description, readMoreLink) => {
    this.setState(
      {
        modalName: name,
        modalDescription: description,
        modalReadMoreLink: readMoreLink,
      },
      this.refs.helperTextModal.show()
    );
  };

  hideInfoModal = () => {
    this.refs.helperTextModal.hide();
  };

  meerInformationIcon = (event, desc, moreLink, readMoreLink) => {
    event.preventDefault();

    this.showInfoModal(desc, moreLink, readMoreLink);
  };

  goToLink = (item, locale) => {
    if (checkCookieConsent()) {
      let link = this.processLink(item, locale);
      window.location = link;
    } else {
      this.props.setCurrentModal("cookieConsentModal");
    }
  };

  processLink = (item, locale) => {
    return `${window.location.protocol}//${
      window.location.host
    }/${locale}/workflows/${this.props.catName}-${
      this.props.catId
    }/${encodeURIComponent(makeSEFUrl(item.name))}-${item.id}`;
  };

  toggleWorkflowDetails = (itemId) => {
    if (this.refs[`doc-details-${itemId}`].style.display === "none") {
      this.refs[`doc-details-${itemId}`].style.display = `block`;
    } else this.refs[`doc-details-${itemId}`].style.display = `none`;
  };

  getPrice = (item) => {
    return parseFloat(item.price) && item.payed
      ? `${strings.get("App.workflows.price.currencySign")}${item.price}`
      : `${strings.get("App.workflows.price.free")}`;
  };

  checkFreePaid = (item) => {
    return parseFloat(item.price) && item.payed ? `Paid` : `Free`;
  };

  toggleDocModel = (item) => {
    this.props.fetchCurrentWorkflow(item.id);
    this.setState({
      workflowType: "doc",
    });
    _.delay(() => {
      this.refs.docModal.show();
    }, 250);
  };

  docModalCancel = () => {
    this.refs.docModal.hide();
  };

  downloadDocMultiSelected = (selectedDownloads = []) => {
    selectedDownloads.map((selectedDownload) => {
      //   if (_.size(selectedDownload.fields) > 0) {
      //     // if (this.state.workflowType === 'role'){
      //     //   const keys = Object.keys(selectedDownload.fields);
      //     //   const currentStepId = this.state.currentStep.id
      //     //   _.each(this.state.fields, ({ name, stepId }, key) => {
      //     //     if (keys.includes(name) && stepId === currentStepId) {
      //     //       sessionStorage.setItem(name, selectedDownload.fields[name])
      //     //     }
      //     //   })
      //     // } else {
      _.each(selectedDownload.fields, (value, key) => {
        sessionStorage.setItem(key, value);
      });
      //     // }
      //   }
    });

    // window.location.reload()
    // let locale = JSON.parse(localStorage.getItem('language')).iso2 || 'NL';
    let locale = language.getIso2();
    let item = this.props.currentWorkflow;
    _.delay(() => {
      let url = `/${locale}/workflowen/${this.props.catName}-${
        item.category_id
      }/${makeSEFUrl(item.name)}-${item.id}`;
      this.props.router.push(url);
    }, 300);
  };

  docModalOptions = () => {
    let workflowForAutofill = "";
    let propsForSend = "";

    if (this.props.currentWorkflow) {
      if (this.state.workflowType === "draft") {
        workflowForAutofill = "user_drafts";
        propsForSend = this.props.currentWorkflow.user_drafts;
      } else if (this.state.workflowType === "role") {
        workflowForAutofill = "roles";
        propsForSend = this.props.currentWorkflow.roles;
      } else if (this.state.workflowType === "doc") {
        workflowForAutofill = "user_downloads";
        propsForSend = this.props.currentWorkflow.user_downloads;
      }
    }
    let options = [];
    if (
      this.props.currentWorkflow &&
      _.has(this.props.currentWorkflow, workflowForAutofill)
    ) {
      _.forEach(propsForSend, function (option, key) {
        let downloads = {
          value: option.id,
          name: option.file,
          percentage: option.matched_percentage,
          fields: option.matched_fields,
        };
        options.push(downloads);
      });
    }
    return options;
  };

  handleInputChange = (value) => {
    this.setState({
      workflowType: value.value,
    });
  };

  getDocModal = () => {
    const options = [
      { value: "doc", label: strings.get("Client.profilePage.downloads") },
      // { value: 'role', label: strings.get('Client.profilePage.roles') },
      { value: "draft", label: strings.get("Client.homePage.drafts") },
    ];

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        padding: 7,
        flex: 1,
        display: "flex",
      }),
      control: (provided, state) => {
        return {
          ...provided,
          minHeight: "55px",
        };
      },
    };

    return (
      <CustomDropModal className="boron-modal" ref="docModal">
        {/* <Modal className="boron-modal" ref="docModal"> */}
        <div className="close-btn">
          <span
            className="ion-android-cancel clickable"
            onClick={this.docModalCancel}
          />
        </div>
        <h5 style={{ padding: "20px" }}>
          {this.props.currentWorkflow
            ? this.props.currentWorkflow.name.toUpperCase()
            : null}
        </h5>
        <div className="select-doc">
          <div className="row" style={{ width: "100%", marginLeft: "0px" }}>
            <div
              className="col-xs-12 col-sm-12 col-md-12"
              style={{ padding: "5px" }}
            >
              <Select
                options={options}
                defaultValue={options[0]}
                styles={customStyles}
                onChange={this.handleInputChange}
              />
            </div>
            <div
              className="col-xs-12 col-sm-12 col-md-12 listAutoSuggestion"
              style={{ padding: "5px" }}
            >
              <AutoSuggestion
                items={this.docModalOptions()}
                multiItemSelected={this.downloadDocMultiSelected}
                workflowType={this.state.workflowType}
              />
            </div>
          </div>
        </div>
        {/* </Modal> */}
      </CustomDropModal>
    );
  };
  getItems = () => {
    // let locale = JSON.parse(localStorage.getItem("language")).iso2 || "NL";
    let locale = language.getIso2();
    // const styleForButton = {
    //   marginTop: 2 + "rem",
    //   width: 40 + "%",
    //   marginRight: 2 + "%",
    // };
    // const styleForLink = {
    //   marginTop: 2.2 + "rem",
    //   width: 40 + "%",
    //   marginRight: 2 + "%",
    // };

    if (this.props.items) {
      //maaike: this needs to be replaced with proper pagination or something, for now as a quick fix I have changed the max length to 10000
      const length =
        this.props.items.length > 10000
          ? Math.round(this.props.items.length / 2)
          : this.props.items.length;

      return _.map(this.props.items, (item, key) =>
        key < length ? (
          <li key={item.id} className="workflow-listoption">
            <div className="doc-name-cont">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6">
                  <div
                    className="workflow-names"
                    onClick={() => this.goToLink(item, locale)}
                  >
                    <div
                      className="btn btn-make-doc"
                      // onClick={() => this.processLink(item, locale)}
                    >
                      <i className="far fa-file-alt file-icon" />
                      {/* <Link
                        to={this.processLink(item, locale)}
                        // onMouseOver={() => this.handleMouseOver(item.id)}
                        className="title"
                      > */}
                      <span className="title">{item.name}</span>
                      {/* <a className="title">{item.name}</a> */}
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
                <div
                  className="col-xs-12 col-sm-12 col-md-6"
                  style={{ float: "right" }}
                >
                  <div className="actions">
                    <CustomDropModal
                      className="boron-modal form-modal infoModal"
                      ref="helperTextModal"
                    >
                      {/* <Modal
                      className="boron-modal form-modal infoModal"
                      ref={"helperTextModal"}
                    > */}
                      <div className="close-btn">
                        <span
                          className="ion-android-cancel clickable"
                          onClick={this.hideInfoModal}
                        />
                      </div>
                      <h4>{this.state.modalName}</h4>
                      <p className="text-left">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.modalDescription,
                          }}
                        />
                      </p>
                      {this.state.modalReadMoreLink ? (
                        <button
                          className="btn btn3"
                          onClick={() =>
                            this.handleHelperClick(this.state.modalReadMoreLink)
                          }
                        >
                          {strings.get(
                            "Client.workflowsList.modalButtons.readMore"
                          )}
                        </button>
                      ) : null}
                      <button onClick={this.hideInfoModal} className="btn btn2">
                        {strings.get("Client.workflowsList.modalButtons.gotIt")}
                      </button>
                      {/* </Modal> */}
                    </CustomDropModal>
                    <div className="row">
                      <div className="col-xs-3">
                        <div className="workflow-details-actions">
                          <div>
                            <i
                              className="fas fa-info-circle"
                              onClick={(event) =>
                                this.meerInformationIcon(
                                  event,
                                  item.name,
                                  item.description,
                                  item.read_more_link
                                )
                              }
                            />
                          </div>
                          <SocialShareButton
                            style={{ width: "50px" }}
                            url={this.processLink(item, locale)}
                            message={this.state.shareMsg}
                            sendEmails={this.props.sendEmails}
                            item={{ ...item, shareable: true }}
                            callbackFromList={(trigger, itemId) =>
                              this.handleShareCallback(trigger, itemId)
                            }
                            isOpen={this.state.openShare &&
                              this.state.openShare.id === item.id}
                          />
                        </div>
                      </div>
                      {/* <div className="col-xs-4 workflow-details-name">
                        <div className='btn btn-details-doc' onClick={() => this.toggleDocModel(item)}>
                          <span className='details-text'>{strings.get('Client.workflowsList.loadDataSelector')}</span>
                        </div>
                      </div> */}
                      <div className="col-xs-5">
                        <div
                          className="btn btn-details-doc"
                          onClick={() => this.toggleWorkflowDetails(item.id)}
                        >
                          <span className="details-text">
                            {strings.get("Client.workflowsList.docdetails")}
                          </span>
                          <span className="details-icon">
                            <i className="fa fa-chevron-down" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix" />

            <div
              className="workflow-details1"
              ref={`doc-details-${item.id}`}
              style={{ display: "none" }}
            >
              <div
                className="close-btn"
                onClick={() => this.toggleWorkflowDetails(item.id)}
              >
                <span className="ion-android-cancel clickable" />
              </div>
              <div className="workflow-details-left">
                <table>
                  <tbody>
                    <tr>
                      <td className="workflow-txt-left"> Name </td>
                      <td className="spe2"> : </td>
                      <td> {item.name} </td>
                    </tr>
                    <tr>
                      <td className="workflow-txt-left"> Description </td>
                      <td className="spe2"> : </td>
                      <td>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="workflow-txt-left"> Image </td>
                      <td className="spe2"> : </td>
                      <td> {item.imageURL} </td>
                    </tr>
                    <tr>
                      <td className="workflow-txt-left"> Price </td>
                      <td className="spe2"> : </td>
                      <td> {this.getPrice(item)} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="workflow-details-right"></div>
            </div>
          </li>
        ) : null
      );
    }
  };

  getItems2 = () => {
    // let locale = JSON.parse(localStorage.getItem("language")).iso2 || "NL";
    let locale = language.getIso2();
    const styleForButton = {
      marginTop: 2 + "rem",
      width: 40 + "%",
      marginRight: 2 + "%",
    };
    const styleForLink = {
      marginTop: 2.2 + "rem",
      width: 40 + "%",
      marginRight: 2 + "%",
    };

    if (this.props.items) {
      const length = this.props.items.length; //Math.round(this.props.items.length / 2) - 1

      return _.map(this.props.items, (item, key) =>
        key > length ? (
          <li key={item.id}>
            <div className="actions">
              <Link
                to={this.processLink(item, locale)}
                onMouseOver={() => this.handleMouseOver(item.id)}
              >
                <img
                  style={{ verticalAlign: "middle" }}
                  src={imgStartWorkflow}
                  width="200"
                  alt="workflowItem"
                />
              </Link>
              &nbsp;
              <CustomDropModal
                className="boron-modal form-modal infoModal"
                ref="helperTextModal"
              >
                {/* <Modal
                className="boron-modal form-modal infoModal"
                ref={"helperTextModal"}
              > */}
                <div className="close-btn">
                  <i
                    className="ion-android-cancel clickable"
                    onClick={this.hideInfoModal}
                  />
                </div>
                <p className="text-left">{this.state.modalDescription}</p>
                {this.state.modalReadMoreLink ? (
                  <a
                    style={styleForLink}
                    target="_blank"
                    className="btn btn-default"
                    href={this.state.modalReadMoreLink}
                  >
                    {strings.get("Client.workflowsList.modalButtons.readMore")}
                  </a>
                ) : null}

                <button
                  style={styleForButton}
                  onClick={this.hideInfoModal}
                  className="btn btn-secondary"
                >
                  {strings.get("Client.workflowsList.modalButtons.gotIt")}
                </button>
                {/* </Modal> */}
              </CustomDropModal>
            </div>
            <Link
              to={this.processLink(item, locale)}
              onMouseOver={() => this.handleMouseOver(item.id)}
            >
              <img
                onClick={(event) =>
                  this.meerInformationIcon(
                    event,
                    item.description,
                    item.read_more_link
                  )
                }
                style={{ verticalAlign: "middle" }}
                src={img}
                width="100"
                alt="workflow"
              />
              <span className="title">{item.name}</span>
            </Link>
            <SocialShareButton
              url={this.processLink(item, locale)}
              message={this.state.shareMsg}
              sendEmails={this.props.sendEmails}
              item={item}
              callbackFromList={(trigger, itemId) =>
                this.handleShareCallback(trigger, itemId, true)
              }
              isOpen={this.state.openShare === item}
            />
          </li>
        ) : null
      );
    }
  };

  handleMouseOver = (id) => {
    if (this.props.onMouseOver) this.props.onMouseOver(id);
  };

  handleShareCallback = (trigger, item = this.state.item) => {
    if (trigger) {
      if (this.state.openShare && this.state.openShare.id === item.id) {
        this.setState({ openShare: {} });
      } else {
        this.setState({ openShare: item });
      }
    } else {
      this.setState(
        {
          shareMsg: "",
          shareMsgLoaded: false,
          item: item,
        },
        () => {
          this.showShareMsgModal();
        }
      );
    }
  };

  showShareMsgModal = () => {
    if (
      !(
        this.props.exceptions.template &&
        this.props.exceptions.template.notDefined
      )
    ) {
      this.refs.shareMsgModal.show();
    }
  };

  shareableMsg = () => {
    return (
      <div className="row shareable-fields">
        <div className="col-xs-12 instruction-message text-left">
          <label>
            {strings.get("Client.workflowsList.shareMsgModal.instructionMsg")}:{" "}
          </label>
          <textarea
            className="form-control"
            name="share_msg"
            placeholder={strings.get(
              "Client.workflowsList.shareMsgModal.instructionMsgPlaceholder"
            )}
            value={this.state.shareMsg}
            onChange={this.handleShareMsgChange}
          />
        </div>
      </div>
    );
  };

  handleShareMsgChange = (e) => {
    this.setState({
      shareMsg: e.target.value,
    });
  };

  shareMsgModalConfirm = () => {
    this.refs.shareMsgModal.hide();
    this.handleShareCallback(true);
  };

  shareMsgModalCancel = () => {
    this.refs.shareMsgModal.hide();
  };

  handleHelperClick = (link) => {
    if (validURL.isUri(link)) {
      window.open(link, "_blank");
    }
  };

  render() {
    let shareMsgModal = (
      <CustomDropModal className="boron-modal" ref="shareMsgModal">
        <div className="close-btn">
          <span
            className="ion-android-cancel clickable"
            onClick={this.shareMsgModalCancel}
          />
        </div>
        <div className="jd-modal">
          {this.shareableMsg()}
          <button className="btn btn2" onClick={this.shareMsgModalConfirm}>
            {strings.get("App.workflows.workflowForm.orderFieldsModal.save")}
          </button>
          <button className="btn btn3" onClick={this.shareMsgModalCancel}>
            {strings.get("App.workflows.workflowForm.orderFieldsModal.cancel")}
          </button>
        </div>
      </CustomDropModal>
    );

    return (
      <>
        {_.size(this.props.items) ? (
          <div className="WorkflowList col-md-10">
            {this.getDocModal()}
            {shareMsgModal}
            <ul>{this.getItems()}</ul>
            {this.props.items.length > 10000 ? (
              <ul>{this.getItems2()}</ul>
            ) : null}
          </div>
        ) : (
          <div></div>
        )}
      </>
    );
  }
}

WorkflowList.propTypes = {
  items: PropTypes.array,
  onMouseOver: PropTypes.func,
};

export default WorkflowList;
