import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Scrollbars } from "react-custom-scrollbars";
import { SpringSystem, MathUtil } from "rebound";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { NotificationManager } from "react-notifications";

import { buildContent } from "../../helpers";
import strings from "../../services/strings";

const dummyPreview = (
  <SkeletonTheme highlightColor="darkgrey">
    <Skeleton width={"50%"} height={20} />
    <Skeleton count={6} />
    <Skeleton width={"25%"} />
    <SkeletonTheme color="#FFFFFF" highlightColor="#FFFFFF">
      <Skeleton height={3} />
    </SkeletonTheme>
    <Skeleton count={6} />
    <Skeleton width={"25%"} />
  </SkeletonTheme>
);

class DocumentFormPreview extends Component {
  componentDidMount() {
    this.springSystem = new SpringSystem();
    this.spring = this.springSystem.createSpring();
    this.spring.addListener({ onSpringUpdate: this.handleSpringUpdate });
    this.handleNonCopyText();
  }

  handleNonCopyText = () => {
    document.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
    document.onkeydown = (e) => {
      let blockKeys = [67, 80, 83, 85];
      let macBlockKeyCode = [51, 52, 53];

      if (e.ctrlKey && blockKeys.includes(e.keyCode)) {
        NotificationManager.info("", strings.get("Exceptions.notifications.accessDenied"));
        return false;
      }
      if (
        (e.keyCode === "91" &&
          e.keyCode === "16" &&
          macBlockKeyCode.includes(e.keyCode)) ||
        (e.keyCode === "16" &&
          e.keyCode === "91" &&
          macBlockKeyCode.includes(e.keyCode))
      ) {
        return false;
      }
      if (e.keyCode === "91" && blockKeys.includes(e.keyCode)) {
        return false;
      }
      if (e.keyCode === "255" || e.keyCode === "44") {
        return false;
      }
      return true;
    };
  };

  componentWillUnmount() {
    this.springSystem.deregisterSpring(this.spring);
    this.springSystem.removeAllListeners();
    this.springSystem = undefined;
    this.spring.destroy();
    this.spring = undefined;
    document.removeEventListener("contextmenu", function (e) {
      // e.preventDefault();
    });
  }

  getContent=() =>{
    if (_.size(this.props.rules)) {
      // return this.buildContent(this.props.rules);
      return buildContent(this.props.rules, false, "", this.props);
    }
  }

  handleSpringUpdate=(spring) =>{
    const val = spring.getCurrentValue();
    this.scroll.scrollTop(val);
  }

  scrollTop=(top) =>{
    const scrollbars = this.scroll;
    const scrollTop = scrollbars.getScrollTop();
    const scrollHeight = scrollbars.getScrollHeight();
    const val = MathUtil.mapValueInRange(
      top,
      0,
      scrollHeight,
      0,
      scrollHeight - 50
    );
    this.spring.setCurrentValue(scrollTop).setAtRest();
    this.spring.setEndValue(val);
  }

  setScrollTo = (selector, scrollY = false) => {
    _.delay(() => {
      let el = document.getElementsByClassName(selector);
      if (el[0]) {
        let parentElement = el[0].parentElement;
        this.scrollTop(parentElement.offsetTop);

        if (scrollY) {
          parentElement.classList.add("fadeLeft");
        } else {
          for (var i = 0; i < el.length; i++) {
            var element = el[i];
            if (element.classList) {
              element.classList.add("highlight");
            } else {
              element.className += " highlight";
            }
          }
        }
      }
    }, 100);
  };

  render() {
    const myScrollbar = {
      height: 400,
      wordBreak: "break-word",
    };

    return (
      <div className="DocumentFormPreview" ref="wrapper">
        <div className="panel-body docpreview" ref="innerWrapperPreview">
          {this.props.headerdisplay}
          {!_.size(this.props.rules) ? (
            dummyPreview
          ) : (
            <Scrollbars
              style={myScrollbar}
              ref={(c) => (this.scroll = c)}
              // onScroll={this.handleScroll}
              // onUpdate={this.handleUpdate}
            >
              <div className="docInnerWrapper">
                <div className="col-xs-12" ref="documentBody">
                  <div className="Company-description" ref="documentcontent">
                    {this.getContent()}
                  </div>
                </div>
              </div>
            </Scrollbars>
          )}
          {this.props.footerdisplay}
        </div>
      </div>
    );
  }
}

DocumentFormPreview.propTypes = {
  rules: PropTypes.array,
  buildLabel: PropTypes.func.isRequired,
};

export default DocumentFormPreview;
