import React, { useEffect, useState, useRef } from "react";
// import TinyMCE from "react-tinymce";
import { Editor as TinyMCE } from "@tinymce/tinymce-react";
import PropTypes from "prop-types";

function Editor(props) {
  const editorRefrence = useRef("editor");

  return (
    <TinyMCE
      ref={editorRefrence}
      value={props.content || ""}
      initialValue={props.content || ""}
      init={{
        height: props.height || 400,
        plugins: "link code paste",
        toolbar:
          "undo redo | bold italic | alignleft aligncenter alignright code",
      }}
      onEditorChange={props.handleChange}
    />
  );
}

Editor.propTypes = {
  handleChange: PropTypes.func.isRequired,
  height: PropTypes.number,
};

export default Editor;
