import React, { Component } from "react";

import PropTypes from "prop-types";
import { SortableHandle } from "react-sortable-hoc";

import "./ActiveLabel.scss";

const SortableDragger = SortableHandle(() => {
  return <i className="label-drag ion-android-more-vertical"></i>;
});

class ActiveLabel extends Component {
  handleItemClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(this.props.value);
    }
  };

  handleRemoveClick = (e) => {
    e.stopPropagation();
    this.props.onRemove(this.props.value);
  };

  render() {
    let labelClass = this.props.clickable
      ? "label-content clickable"
      : "label-content";

    let dragger = this.props.draggable ? <SortableDragger /> : null;

    return (
      <div className="ActiveLabel" onClick={this.handleItemClick}>
        <div className={labelClass}>
          {dragger}
          <span></span>
          {this.props.name}
          <i
            className="label-close ion-close"
            onClick={this.handleRemoveClick}
          ></i>
        </div>
      </div>
    );
  }
}

ActiveLabel.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  clickable: PropTypes.bool,
  draggable: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

export default ActiveLabel;
