import Immutable from "seamless-immutable";
import { types } from "./actions";
import _ from "lodash";

const initialState = Immutable({
  filters: {
    searchTerm: "",
    categoryId: "",
  },
  pagination: {
    currentPage: 1,
    previousPage: 1,
    pageSize: 10,
    totalPages: 1,
  },
  sorter: {
    column: "created_at",
    descending: true,
  },
  currentItemId: null,
  latestRevisionId: null,
  draftOwnerId: null,
  stats: null,
  itemsById: {},
  idsByPage: {
    _1: [],
  },
  fields: {},
  fieldsOrder: {},
  selectors: {},
  clauses: {},
  steps: {},
});

// Clear cached info
function clearCache(state) {
  return state.merge({
    filters: state.filters,
    sorter: state.sorter,
    pagination: {
      currentPage: 1,
      previousPage: 1,
      pageSize: 10,
      totalPages: 1,
    },
    itemsById: {},
    idsByPage: {
      _1: [],
    },
  });
}

// Save all drafts to store
function fetchAllSharedDraftsDone(state, payload) {
  let newState = {
    pagination: {
      totalPages: payload.totalPages,
    },
    itemsById: {},
    idsByPage: {},
  };
  newState["idsByPage"]["_" + state.pagination.currentPage] = [];
  _.map(payload.items, (item) => {
    newState["itemsById"]["_" + item.id] = item;
    newState["idsByPage"]["_" + state.pagination.currentPage].push(item.id);
  });
  return state.merge(newState, { deep: true });
}

// Set category id filter
function setCategoryId(state, payload) {
  return state.merge(
    {
      filters: {
        categoryId: payload.id,
      },
    },
    { deep: true }
  );
}

// Set current search term
function setSearchTerm(state, payload) {
  return state.merge(
    {
      filters: {
        searchTerm: payload.searchTerm,
      },
    },
    { deep: true }
  );
}

// Set current page for items list
function setCurrentPage(state, payload) {
  return state.merge(
    {
      pagination: {
        currentPage: payload.page,
        previousPage: state.pagination.currentPage,
      },
    },
    { deep: true }
  );
}

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_ALL_SHARED_DRAFTS_DONE:
      return fetchAllSharedDraftsDone(state, action.payload);
    case types.SET_CATEGORY_ID:
      return setCategoryId(state, action.payload);
    case types.SET_SEARCH_TERM:
      return setSearchTerm(state, action.payload);
    case types.SET_CURRENT_PAGE:
      return setCurrentPage(state, action.payload);
    case types.CLEAR_CACHE:
      return clearCache(state);
    default:
      return state;
  }
}
