import React, { Component } from "react";
import _ from "lodash";

import strings from "../../services/strings";

import "./Counter.scss";

class Counter extends Component {
  getItems = () => {
    return _.map(this.props.items, (item, i) => {
      let className = "col-sm-3 col-xs-6 withDivider";
      if (i === _.size(this.props.items) - 1) {
        className = "col-sm-3 col-xs-6";
      }

      return (
        <div key={`item-${i}`} className={className}>
          <p className="count">{item.count}</p>
          <p className="title">
            {strings.get(`Client.homePage.counter.${item.title}`)}
          </p>
        </div>
      );
    });
  };

  render() {
    let bgCounterImageStyle = {};
    if (this.props.bannerBottomImg) {
      bgCounterImageStyle = {
        background: `url(${this.props.bannerBottomImg})`,
        backgroundSize: "cover",
        backgroundPositionY: "75%",
        backgroundRepeat: "no-repeat",
      };
    }
    return (
      <div className="counter" style={bgCounterImageStyle}>
        <div id="counterOpacity" />
        <div className="row">{this.getItems()}</div>
      </div>
    );
  }
}

export default Counter;
