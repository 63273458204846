import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Breadcrumbs from "../../components/Breadcrumbs";
import DraftsList from "../../components/drafts/DraftsList";
import NavigationTabs from "../../components/NavigationTabs";
import Topbar from "../../components/Topbar";
import Footer from "../../components/footer/Footer";
import DraftCategoryFilter from "../../components/drafts/DraftCategoryFilter";
import SearchBar from "../../components/drafts/SearchBar";
import Pagination from "../../components/Pagination";
import WorkflowFilter from "../../components/drafts/WorkflowFilter";
import DraftTypeFilter from "../../components/drafts/DraftTypeFilter";

import * as authSelectors from "../../store/auth/selectors";
import * as draftsActions from "../../store/drafts/actions";
import * as draftsSelectors from "../../store/drafts/selectors";
import * as categoriesActions from "../../store/categories/actions";
import * as categoriesSelectors from "../../store/categories/selectors";
import * as glossariesActions from "../../store/glossaries/actions";
import * as glossariesSelectors from "../../store/glossaries/selectors";
import * as stepsActions from "../../store/steps/actions";
import * as stepsSelectors from "../../store/steps/selectors";
import * as workflowActions from "../../store/workflows/actions";
import * as workflowSelectors from "../../store/workflows/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";
import * as settingsActions from "../../store/settings/actions";

import { browserHistory } from "../../helpers";
import language from "../../services/language";
import strings from "../../services/strings";
import auth from "../../services/auth";

import "./DraftsPage.scss";

class DraftsPage extends Component {
  componentDidMount() {
    if (!auth.isAuthenticated()) {
      browserHistory.push("/");
      window.location.reload();
    } else {
      this.fetchData();
    }
  }

  fetchData = async () => {
    this.props.setScreenLoading(true);
    await this.props.fetchAllDrafts();
    await this.props.fetchAllCategories();
    await this.props.fetchUserWorkflows();
    await this.setState({
      user: auth.getLocalUser() ? auth.getLocalUser() : null,
    });
    if (!_.size(this.props.glossaries)) {
      await this.props.fetchAllGlossaries();
    }
    if (!_.size(this.props.steps)) {
      await this.props.fetchAllSteps();
    }
    this.props.setScreenLoading(false);
  };

  handleDeleteDraft = (id) => {
    this.props.deleteDraft(id);
  };

  handleValidateDoc = (id) => {
    this.props.validateDoc(id);
  };

  handleValidateDraft = (id) => {
    this.props.validateDraft(id);
  };

  updateItemShareFields = (draftId, data) => {
    this.props.updateDraftShareFields(draftId, data);
    _.delay(() => {
      this.props.fetchAllDrafts();
    }, 500);
  };

  updateItemDraftName = (draftId, data) => {
    this.props.updateDraftName(draftId, data);
    _.delay(() => {
      this.props.fetchAllDrafts();
    }, 500);
  };

  clearReminderException = () => {
    this.props.clearExceptions();
  };

  updateItemReminder = (itemId, data) => {
    this.props.updateDraftReminder(itemId, data);
    _.delay(() => {
      this.props.fetchAllDrafts();
    }, 500);
  };

  render() {
    let locale = language.getIso2();
    return (
      <div className="UserProfilePage Profilepage DraftsPage">
        <Topbar
          title={strings.get("Client.draftsPage.title")}
          searchBox={true}
          currentView="draftsPage"
        />

        <div className="breadcrumb-cont">
          <div className="container">
            <Breadcrumbs>
              <Link to={`/${locale}/`}>
                {strings.get("Client.homePage.home")}
              </Link>
              <Link to={`/${locale}/profile`}>
                {strings.get("Client.homePage.profile")}
              </Link>
              <Link to={`/${locale}/drafts`}>
                {strings.get("Client.homePage.drafts")}
              </Link>
            </Breadcrumbs>
          </div>
        </div>
        <div className="inner-page-cont Draftpage">
          <div className="container">
            <NavigationTabs
              currentItemId={1}
              currentLang={this.props.currentLang}
            />
            <div className="row">
              <div className="col-sm-12 col-md-12 col-xs-12 cate-filter1 draft-filters">
                <DraftTypeFilter
                  filters={this.props.filters}
                  fetchItems={this.props.fetchAllDrafts}
                  setDraftsType={this.props.setDraftsType}
                />
                <DraftCategoryFilter
                  filters={this.props.filters}
                  categories={this.props.categories}
                  fetchItems={this.props.fetchAllDrafts}
                  setCategoryId={this.props.setCategoryId}
                />
                <SearchBar
                  placeholder={strings.get("Client.draftsPage.draftName")}
                  searchTerm={this.props.filters.searchTerm}
                  fetchItems={this.props.fetchAllDrafts}
                  setSearchTerm={this.props.setSearchTerm}
                />
                <WorkflowFilter
                  filters={this.props.filters}
                  categories={this.props.categories}
                  fetchItems={this.props.fetchAllDrafts}
                  setSavedWorkflowId={this.props.setSavedWorkflowId}
                  setFetchOnlyWorkflowDraftStatus={
                    this.props.setFetchOnlyWorkflowDraftStatus
                  }
                  currentUserWorkflows={
                    this.props.currentUserWorkflows
                      ? this.props.currentUserWorkflows
                      : []
                  }
                />
              </div>
            </div>
            <DraftsList
              {...this.props}
              formDeleteDraft={this.handleDeleteDraft}
              formValidateDoc={this.handleValidateDoc}
              formValidateDraft={this.handleValidateDraft}
              categories={this.props.categories}
              glossaries={this.props.glossaries}
              steps={this.props.steps}
              fields={this.props.fields}
              fieldsOrder={this.props.fieldsOrder}
              selectors={this.props.selectors}
              clauses={this.props.clauses}
              draftShareFields={this.props.draftShareFields}
              stepsFromValidation={this.props.stepsFromValidation}
              updateItemShareFields={this.updateItemShareFields}
              updateItemDraftName={this.updateItemDraftName}
              setCurrentItemId={this.props.setCurrentDraftId}
              unsetCurrentItemId={this.props.unsetCurrentDraftId}
              currentItem={this.props.currentUserDraft}
              clearReminderException={this.clearReminderException}
              updateItemReminder={this.updateItemReminder}
            />
            <div
              className="col-sm-12 text-center"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Pagination
                pagination={this.props.pagination}
                setCurrentPage={this.props.setCurrentPage}
                fetchItems={this.props.fetchAllDrafts}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: authSelectors.getProfile(state),
    drafts: draftsSelectors.getItemsByPage(
      state,
      draftsSelectors.getPagination(state).currentPage
    ),
    categories: categoriesSelectors.getItems(state),
    steps: stepsSelectors.getItems(state),
    glossaries: glossariesSelectors.getItems(state),
    fields: draftsSelectors.getFields(state),
    fieldsOrder: draftsSelectors.getFieldsOrder(state),
    selectors: draftsSelectors.getSelectors(state),
    clauses: draftsSelectors.getClauses(state),
    stepsFromValidation: draftsSelectors.getSteps(state),
    draftShareFields: draftsSelectors.getShareFields(state),
    filters: draftsSelectors.getFilters(state),
    pagination: draftsSelectors.getPagination(state),
    currentUserDraft: draftsSelectors.getCurrentItem(state),
    currentUserWorkflows: workflowSelectors.getUserWorkflows(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllDrafts: (deleteCache) => {
      return dispatch(draftsActions.fetchAllDrafts(deleteCache));
    },
    deleteDraft: (id) => {
      return dispatch(draftsActions.deleteDraft(id));
    },
    sendEmails: (data) => {
      dispatch(draftsActions.sendEmails(data));
    },
    validateDoc: (id) => {
      dispatch(draftsActions.validateItem(id));
    },
    validateDraft: (id) => {
      dispatch(draftsActions.fetchDraftShareFields(id));
    },
    fetchAllCategories: (deleteCache) => {
      dispatch(categoriesActions.fetchAllItems(deleteCache));
    },
    fetchAllSteps: (deleteCache) => {
      dispatch(stepsActions.fetchAllItems(deleteCache));
    },
    fetchAllGlossaries: () => {
      dispatch(glossariesActions.fetchAllItems());
    },
    updateDraftShareFields: (id, data) => {
      dispatch(draftsActions.updateItemShareFields(id, data));
    },
    updateDraftName: (id, data) => {
      dispatch(draftsActions.updateDraftName(id, data));
    },
    setCategoryId: (id) => {
      dispatch(draftsActions.setCategoryId(id));
    },
    setSavedWorkflowId: (id) => {
      dispatch(draftsActions.setSavedWorkflowId(id));
    },
    setFetchOnlyWorkflowDraftStatus: (status) => {
      dispatch(draftsActions.setFetchOnlyWorkflowDraftStatus(status));
    },
    setSearchTerm: (searchTerm) => {
      dispatch(draftsActions.setSearchTerm(searchTerm));
    },
    setCurrentPage: (page) => {
      dispatch(draftsActions.setCurrentPage(page));
    },
    setCurrentDraftId: (id) => {
      dispatch(draftsActions.setCurrentItemId(id));
    },
    unsetCurrentDraftId: () => {
      dispatch(draftsActions.unsetCurrentItemId());
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
    updateDraftReminder: (id, data) => {
      dispatch(draftsActions.updateItemReminder(id, data));
    },
    fetchUserWorkflows: (deleteCache) => {
      return dispatch(workflowActions.getUserForkflows(deleteCache));
    },
    setDraftsType: (type = "drafts") => {
      return dispatch(draftsActions.setDraftType(type));
    },
    setScreenLoading: (status) => {
      return dispatch(settingsActions.setScreenLoading(status));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DraftsPage);
