import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import language from "../../services/language";
import strings from "../../services/strings";

import "./ErrorBoundary.scss";
import api from "../../services/api";
import { NotificationManager } from "react-notifications";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    //log the error to admin via email
    let params = new Map();
    params.set(
      "description",
      `${error}, Error tracing -> ${JSON.stringify(errorInfo)}`
    );
    params.set("email", "");
    params.set("url", window.location.href);
  params.set("subject", "Report an Error");

    // POST request to API
    api.post("/feedback", params).then((res) => {
      NotificationManager.success(
        "",
        strings.get("Exceptions.error_boundary.adminNotified")
      );
    });
  }

  getDigits = (digit = 4, numberOfTimes = 40) => {
    let items = [];
    for (let index = 0; index < numberOfTimes; index++) {
      items.push(
        <span className="particle" key={_.uniqueId()}>
          {digit}
        </span>
      );
    }
    return items;
  };

  errorPage = () => {
    return (
      <div className="ErrorBoundary">
        <main className="container">
          {this.getDigits(4, 20)}
          {this.getDigits(0, 20)}
          <article className="content">
            <h3>
              <p>
                <strong>
                  {strings.get("Exceptions.error_boundary.title")}
                </strong>
              </p>
            </h3>
            <p>{strings.get("Exceptions.error_boundary.message")}</p>
            <h4>
              <p>{strings.get("Exceptions.error_boundary.tryAgain")}</p>
            </h4>
            <p>
              <Link className="btn btn-primary" to={`/${language.getIso2()}/`}>
                {strings.get("Client.homePage.home")}
              </Link>
            </p>
          </article>
        </main>
      </div>
    );
  };

  render() {
    if (this.state.hasError) {
      return this.errorPage();
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
