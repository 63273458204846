import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import {
  // ButtonToolbar,
  DropdownButton,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap";
import validURL from "valid-url";
import _ from "lodash";

import UploadAttachments from "./UploadAttachments";
import LoadSavedData from "../document/LoadSavedData";
import CustomDropModal from "../PopUp";
import DraftsDataReUse from "../drafts/DraftsDataReUse";

import end from "../../static/images/pyramid.svg";

import expand from "../../static/images/icons/full-screen.svg";
import collapse from "../../static/images/icons/collapse.svg";

import strings from "../../services/strings";
import auth from "../../services/auth";
import language from "../../services/language";
import { makePATHUrl, viewModals, makeSEFUrl } from "../../helpers";

import "./Timeline.scss";

const initialDraftReUseState = {
  showModal: false,
  draftId: null,
  documentId: null,
  name: null,
};

class RevisionDrafts extends Component {
  handleDraftChange = (e) => {
    window.location = e.target.value;
  };

  getRevisions = (draft) => {
    if (draft) {
      return _.map(draft.revisions, (revisionDraft) => {
        return (
          <option
            value={this.processRevisionLink(draft, revisionDraft.id)}
            key={revisionDraft.id}
          >
            {strings.get("Client.draftsPage.revisions")}{" "}
            {revisionDraft.revision}
          </option>
        );
      });
    }
  };

  processRevisionLink = (draft, revisionDraftId) => {
    let locale = language.getIso2();
    let url = `${window.location.protocol}//${
      window.location.host
    }/${locale}/documenten/${makeSEFUrl(draft.category.name)}-${
      draft.document.category_id
    }/${makeSEFUrl(draft.document.name)}-${
      draft.document.id
    }/${revisionDraftId}?workflowId=${this.props.workflow_id}&milestoneId=${
      this.props.id
    }`;

    if (_.size(this.props.userCurrentWorkflow)) {
      url = url.concat(
        `&currentWorkflowId=${this.props.userCurrentWorkflow.id}`
      );
    }
    return url;
  };

  render() {
    return (
      <select
        className="form-control cate-select1"
        name="groups"
        value=""
        onChange={this.handleDraftChange}
        key={_.uniqueId()}
      >
        <option value="">{strings.get("Client.draftsPage.select")}</option>
        {this.getRevisions(this.props.draft)}
        <option
          value={this.processRevisionLink(
            this.props.draft,
            this.props.draft.id
          )}
          key={this.props.draft.id}
        >
          {strings.get("Client.draftsPage.revisions")} 1
        </option>
      </select>
    );
  }
}

class TimelineContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpand: false,
      isShowIconExpand: false,
      draftId: undefined,
      editDraftName: "",
      showInfoModal: false,
    };
  }

  componentDidMount() {
    this.handleShowIconExpand();
  }

  handleShowIconExpand = () => {
    let elm = document.getElementById("WorkflowTimeline__Text" + this.props.id);
    let height = (elm && elm.offsetHeight) || 0;
    if (height > 150) {
      this.setState({ isShowIconExpand: true });
    }
  };

  handleRedirectAcceptance = (event, index, url) => {
    event.preventDefault();
    let show_deny_messgae = false;
    if (index !== 0) {
      if (_.size(this.props.userCurrentWorkflow)) {
        let milestones = this.props.userCurrentWorkflow.milestones;
        if (_.size(milestones[Object.keys(milestones)[0]])) {
          let milestone = milestones[Object.keys(milestones)[0]];
          let access = false;
          Object.keys(milestone).map((key) => {
            if (milestone[key]) access = true;
          });
          if (!access) {
            show_deny_messgae = true;
            this.props.setCurrentModal(viewModals.MILESTONE_DOC_ACCESS_DENY);
          }
        }
      } else {
        show_deny_messgae = true;
        this.props.setCurrentModal(viewModals.MILESTONE_DOC_ACCESS_DENY);
      }
    }

    if (!show_deny_messgae) {
      this.props.handleAddWorkflow(url);
    }
  };

  redirectLink = (document) => {
    let url = `/${this.props.locale}/documenten/${encodeURIComponent(
      makePATHUrl(document.category.name, document.category.id)
    )}/${encodeURIComponent(
      makePATHUrl(document.name, document.id)
    )}?workflowId=${this.props.workflow_id}&milestoneId=${this.props.id}`;
    if (_.size(this.props.userCurrentWorkflow)) {
      url = url.concat(
        `&currentWorkflowId=${this.props.userCurrentWorkflow.id}`
      );
    }
    return url;
  };

  redirectDraftLink = (draft) => {
    let url = `${window.location.protocol}//${window.location.host}/${
      this.props.locale
    }/documenten/${makeSEFUrl(draft.category.name)}-${
      draft.document.category_id
    }/${makeSEFUrl(draft.document.name)}-${draft.document.id}/${
      draft.latestrevision_id
    }${
      draft.selectorType !== "" && draft.selectorType !== undefined
        ? `/${draft.selectorType}`
        : ""
    }?workflowId=${this.props.workflow_id}&milestoneId=${this.props.id}`;

    if (_.size(this.props.userCurrentWorkflow)) {
      url = url.concat(
        `&currentWorkflowId=${this.props.userCurrentWorkflow.id}`
      );
    }
    window.location.assign(url);
  };

  handleDraftRenameClick = (draft) => {
    this.setState(
      {
        editDraftName: draft.name,
        draftId: draft.id,
      },
      () => {
        this.showdraftRenameModal();
      }
    );
  };

  handleDraftDeleteClick = (draft) => {
    if (this.props.userCurrentWorkflow.id) {
      this.props.deleteDraft(draft.id, this.props.userCurrentWorkflow.id);
    }
  };

  showdraftRenameModal = () => {
    this.refs.draftRenameModal.show();
  };

  draftRenameModalConfirm = () => {
    let newFields = {};
    if (this.state.editDraftName) {
      newFields["name"] = this.state.editDraftName;
    }
    if (this.state.draftId && this.props.userCurrentWorkflow.id) {
      this.props.updateDraftName(
        this.state.draftId,
        newFields,
        this.props.userCurrentWorkflow.id
      );
      this.draftRenameModalCancel();
    }
  };

  draftRenameModalCancel = () => {
    this.refs.draftRenameModal.hide();
  };

  handleDraftNameInputChange = (e) => {
    this.setState({
      editDraftName: e.target.value,
    });
  };

  hideInfoModal = () => {
    this.refs.helperTextModal.hide();
  };

  handleHelperClick = (link) => {
    if (validURL.isUri(link)) {
      window.open(link, "_blank");
    }
  };

  meerInformationIcon = (event, desc, moreLink, readMoreLink) => {
    event.preventDefault();
    this.showInfoModal(desc, moreLink, readMoreLink);
  };

  showInfoModal = (name, description, readMoreLink) => {
    this.setState(
      {
        modalName: name,
        modalDescription: description,
        modalReadMoreLink: readMoreLink,
      },
      () => {
        this.refs.helperTextModal.show();
      }
    );
  };

  render() {
    let draftRenameModal = (
      <CustomDropModal className="boron-modal" ref="draftRenameModal">
        <div className="close-btn">
          <span
            className="ion-android-cancel clickable"
            onClick={this.draftRenameModalCancel}
          />
        </div>
        <div className="jd-modal">
          <label className="pull-left">
            {strings.get("Client.editModal.title")}
          </label>
          <br />
          <div className="editable-fields-box">
            <input
              type="text"
              className="form-control"
              name="editable_draft_name"
              value={this.state.editDraftName}
              onChange={this.handleDraftNameInputChange}
            />
          </div>
          <button className="btn btn2" onClick={this.draftRenameModalConfirm}>
            {strings.get("App.documents.documentForm.orderFieldsModal.save")}
          </button>
          <button className="btn btn3" onClick={this.draftRenameModalCancel}>
            {strings.get("App.documents.documentForm.orderFieldsModal.cancel")}
          </button>
        </div>
      </CustomDropModal>
    );
    return (
      <div
        className="WorkflowTimeline__Text"
        id={"WorkflowTimeline__Text" + this.props.id}
      >
        <span className="WorkflowTimeline__Text__Info">
          <i
            className="fas fa-info-circle"
            onClick={(event) =>
              this.meerInformationIcon(
                event,
                this.props.name,
                this.props.helperText,
                this.props.helperLink
              )
            }
          ></i>
        </span>
        <CustomDropModal
          className="boron-modal form-modal infoModal"
          ref="helperTextModal"
        >
          <div className="close-btn">
            <span
              className="ion-android-cancel clickable"
              onClick={this.hideInfoModal}
            />
          </div>
          <h4>{this.state.modalName}</h4>
          <div className="text-left">
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.modalDescription,
              }}
            />
          </div>
          {this.state.modalReadMoreLink ? (
            <button
              className="btn btn3"
              onClick={() =>
                this.handleHelperClick(this.state.modalReadMoreLink)
              }
            >
              {strings.get("Client.workflowsList.modalButtons.readMore")}
            </button>
          ) : null}
          <button onClick={this.hideInfoModal} className="btn btn2">
            {strings.get("Client.workflowsList.modalButtons.gotIt")}
          </button>
        </CustomDropModal>

        {draftRenameModal}
        <div
          className={`WorkflowTimeline__Text__Content ${
            this.state.isExpand
              ? "WorkflowTimeline__Text__Content--Expand"
              : "WorkflowTimeline__Text__Content--Collapse"
          }`}
        >
          <p>
            <b>{this.props.name}</b>
          </p>
          <div dangerouslySetInnerHTML={{ __html: this.props.content }}></div>
          <div className="WorkflowTimeline__Text__Content--Documents">
            {this.props.documents.map((document, index) => {
              return (
                <div
                  className={
                    _.size(this.props.userCurrentWorkflow)
                      ? "data-reuse-container"
                      : ""
                  }
                  key={_.uniqueId()}
                >
                  <div className="mobile-expand WorkflowTimeline__Text__BtnExpand">
                    <img
                      src={this.state.isExpand ? collapse : expand}
                      onClick={() =>
                        this.setState({ isExpand: !this.state.isExpand })
                      }
                      alt="workflow"
                    />
                  </div>
                  <Link
                    className="WorkflowTimeline__Text__Content--Documents__Item"
                    key={index}
                    to={this.redirectLink(document)}
                    onClick={(e) =>
                      this.handleRedirectAcceptance(
                        e,
                        this.props.index,
                        this.redirectLink(document)
                      )
                    }
                  >
                    <span>{document.name}</span>
                  </Link>

                  {_.size(this.props.userCurrentWorkflow) ? (
                    auth.isAuthenticated() ? (
                      <LoadSavedData
                        documentId={document.id}
                        catName=""
                        stepWiseData={false}
                        className="WorkflowTimeline__Text__Content--Documents__Item reuse-data"
                        redirectLink={this.redirectLink(document)}
                        userCurrentWorkflowId={
                          this.props.userCurrentWorkflow.id
                            ? parseInt(this.props.userCurrentWorkflow.id)
                            : 0
                        }
                      />
                    ) : null
                  ) : null}
                </div>
              );
            })}
          </div>
          {_.size(this.props.userCurrentWorkflow) ? (
            <Tabs defaultActiveKey={1} id="noanim-tab-example">
              <Tab
                eventKey={1}
                title={strings.get(
                  "Client.homePage.workflowForm.tabs.draftTab"
                )}
              >
                <div className="drafts-container">
                  {_.map(this.props.drafts, (draft) => {
                    if (draft.milestone_id === this.props.id) {
                      return (
                        <div
                          className="draft-revision data-reuse-container"
                          key={_.uniqueId()}
                        >
                          <DropdownButton
                            title={draft.name}
                            drop={"up"}
                            key={draft.id}
                            id={`dropdown-basic-${draft.id}`}
                            size="sm"
                            bsPrefix="WorkflowTimeline__Text__Content--Documents__Item"
                          >
                            <ul>
                              <li>
                                {" "}
                                <Dropdown.Item
                                  eventKey="edit"
                                  onClick={() => {
                                    this.redirectDraftLink(draft);
                                  }}
                                >
                                  {strings.get(
                                    "Client.homePage.workflowForm.edit"
                                  )}
                                </Dropdown.Item>
                              </li>
                              <li>
                                <Dropdown.Item
                                  eventKey="rename"
                                  onClick={() => {
                                    this.handleDraftRenameClick(draft);
                                  }}
                                >
                                  {strings.get(
                                    "Client.homePage.workflowForm.renameDraft"
                                  )}
                                </Dropdown.Item>
                              </li>
                              <li>
                                <Dropdown.Item
                                  eventKey="reUse"
                                  onClick={() => {
                                    this.props.handleDraftReUseClick(draft);
                                  }}
                                >
                                  {strings.get("Client.draftsPage.dataReuse")}
                                </Dropdown.Item>
                              </li>
                              <li>
                                <Dropdown.Item
                                  eventKey="delete"
                                  onClick={() => {
                                    this.handleDraftDeleteClick(draft);
                                  }}
                                >
                                  {strings.get("Client.draftsPage.delete")}
                                </Dropdown.Item>
                              </li>
                            </ul>
                          </DropdownButton>
                          <RevisionDrafts
                            draft={draft}
                            id={this.props.id}
                            workflow_id={this.props.workflow_id}
                            userCurrentWorkflow={this.props.userCurrentWorkflow}
                          />
                        </div>
                      );
                    }
                  })}
                  {!_.size(this.props.drafts)
                    ? strings.get("Client.documentsList.noFiles")
                    : null}
                </div>
              </Tab>
              <Tab
                eventKey={2}
                title={strings.get(
                  "Client.homePage.workflowForm.tabs.attachmentTab"
                )}
              >
                <UploadAttachments
                  id={this.props.userCurrentWorkflow.id}
                  userCurrentWorkflow={this.props.userCurrentWorkflow}
                  mileStoneId={this.props.id}
                  workflowId={this.props.workflow_id}
                  handleShowIconExpand={this.handleShowIconExpand}
                  fetchCurrentWorkflow={this.props.fetchCurrentWorkflow}
                />
              </Tab>
            </Tabs>
          ) : null}
        </div>
        {this.state.isShowIconExpand && (
          <div className="WorkflowTimeline__Text__BtnExpand">
            <img
              src={this.state.isExpand ? collapse : expand}
              onClick={() => this.setState({ isExpand: !this.state.isExpand })}
              alt="workflow"
            />
          </div>
        )}
      </div>
    );
  }
}

// const path = [path8, path1, path2, path3, path4, path5, path6, path7];
const timeToDuration = (timeStr) => {
  let timeObj = JSON.parse(timeStr);
  const unit = ["year", "month", "week", "day", "hour"];
  const unitLang = [
    strings.get("Client.workflows.milestones.unit.year"),
    strings.get("Client.workflows.milestones.unit.month"),
    strings.get("Client.workflows.milestones.unit.week"),
    strings.get("Client.workflows.milestones.unit.day"),
    strings.get("Client.workflows.milestones.unit.hour"),
    strings.get("Client.workflows.milestones.unit.years"),
    strings.get("Client.workflows.milestones.unit.months"),
    strings.get("Client.workflows.milestones.unit.weeks"),
    strings.get("Client.workflows.milestones.unit.days"),
    strings.get("Client.workflows.milestones.unit.hours"),
  ];
  let str = unit.reduce((result, item, index) => {
    // console.log('result, item, index', result, item, index);
    if (timeObj[item])
      // return `${result} ${timeObj[item]} ${item}${
      //   (+timeObj[item] > 1 && "s") || ""
      // }`;

      return `${result} ${timeObj[item]} ${
        (+timeObj[item] > 1 && unitLang[index + 5]) || unitLang[index]
      }`;
    return result;
  }, "");
  return str === ""
    ? strings.get("Client.workflows.milestones.unit.noduration")
    : str;
};
export class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      draftReUse: {
        ...initialDraftReUseState,
      },
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 1700);
  }

  handleDraftReUseClick = (draft) => {
    let draftReUse = this.state.draftReUse;
    draftReUse.showModal = true;
    draftReUse.draftId = draft.id;
    draftReUse.documentId = draft.document_id;
    draftReUse.name = draft.name;
    this.setState({ draftReUse });
  };

  onDraftReUseModalHide = () => {
    this.setState({ draftReUse: { ...initialDraftReUseState } });
  };

  render() {
    let locale = language.getIso2();
    let { milestones } = this.props;
    // let ddd = [...milestones, ...milestones, ...milestones,...milestones, ...milestones, ...milestones];
    let ddd = milestones;
    return (
      <div className="container-row">
        <DraftsDataReUse
          draft={this.state.draftReUse}
          draftId={this.state.draftReUse.draftId}
          origin={"workflows"}
          onHide={this.onDraftReUseModalHide}
          milestones = {this.props.milestones}
        />
        {auth.isAuthenticated() && _.size(this.props.currentUserWorkflows) ? (
          <div className="my-workflow-quick-button">
            <Link
              className="btn btn-secondary"
              to={`/${language.getIso2()}/myworkflows`}
            >
              <span>{strings.get("Client.homePage.myWorkflow.title")}</span>
            </Link>
          </div>
        ) : null}
        {this.state.isLoading ? (
          <div>
            <ReactLoading
              className="react-loading"
              type="spinningBubbles"
              color="#15375c"
            />
          </div>
        ) : milestones && milestones.length > 0 ? (
          <div className="WorkflowTimelineContainer">
            {ddd.map((milestone, index) => {
              /////////////////////This block code to return index for class to set css
              let numberClass = 0;
              let arr = [0, 1, 2, 3, 3, 2, 1, 0];
              // index = ddd.length - 1 ? end : index === 0 ? 0 : index % 6;
              if (index === ddd.length - 1) numberClass = "end";
              else if (index === 0) numberClass = "start";
              else {
                numberClass = index % 8 === 0 ? arr[7] : arr[index % 8];
              }
              ///////////////////////////////////////////////////////////////////

              return (
                <div
                  className={`WorkflowTimeline WorkflowTimeline--${numberClass}`}
                  key={_.uniqueId(index)}
                >
                  <div className="WorkflowTimeline__Mobile__Nontext">
                    <span>
                      {strings.get(
                        "App.glossaries.glossaryForm.selectors.step"
                      )}{" "}
                      {index < 9 ? index + 1 : index + 1} - (
                      {timeToDuration(milestone.duration)})
                    </span>
                  </div>
                  <div className="WorkflowTimeline__Nontext">
                    <div className="WorkflowTimeline__Nontext__Block">
                      <div className="WorkflowTimeline__Nontext__Block__Square">
                        <div className="WorkflowTimeline__Nontext__Block__Square__Elip"></div>
                        <div className="WorkflowTimeline__Nontext__Block__Square__Square">
                          <span>{timeToDuration(milestone.duration)}</span>
                        </div>
                        <span className="WorkflowTimeline__Nontext__Block__Square--Order">
                          {index < 9 ? index + 1 : index + 1}
                        </span>
                      </div>
                      <div className="WorkflowTimeline__Nontext__Block__Triangle"></div>
                      <div className="WorkflowTimeline__Nontext__Block__Triangle2"></div>
                    </div>
                  </div>
                  <TimelineContent
                    {...{ ...milestone, locale, index }}
                    setCurrentModal={this.props.setCurrentModal}
                    userCurrentWorkflow={this.props.userCurrentWorkflow}
                    drafts={
                      this.props.drafts[milestone.id]
                        ? this.props.drafts[milestone.id]
                        : {}
                    }
                    updateDraftName={this.props.updateDraftName}
                    steps={this.props.steps}
                    glossaries={this.props.glossaries}
                    categories={this.props.categories}
                    deleteDraft={this.props.deleteDraft}
                    fetchCurrentWorkflow={this.props.fetchCurrentWorkflow}
                    handleAddWorkflow={this.props.handleAddWorkflow}
                    handleDraftReUseClick={this.handleDraftReUseClick}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="WorkflowTimelineContainer--Nodata">Nodata</div>
        )}
      </div>
    );
  }
}

Timeline.propTypes = {
  setCurrentModal: PropTypes.func.isRequired,
  // params: PropTypes.object.isRequired,
  userCurrentWorkflow: PropTypes.object,
  drafts: PropTypes.object,
  fetchCurrentWorkflow: PropTypes.func,
  handleAddWorkflow: PropTypes.func.isRequired,
  currentUserWorkflows: PropTypes.any.isRequired,
};
