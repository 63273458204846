import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import CustomDropModal from "./PopUp";
import Feedback from "./feedback/Feedback";
import Cookie from "./cookie/Cookie";
import Navbar from "../containers/Navbar";
import SearchBox from "../containers/SearchBox";
import SearchBoxWorkflow from "../containers/workflows/SearchBoxWorkflow";
import LanguageSelector from "../containers/LanguageSelector";

import * as settingsActions from "../store/settings/actions";
import * as settingsSelectors from "../store/settings/selectors";

import validURL from "valid-url";
import strings from "../services/strings";
import language from "../services/language";
import { browserHistory } from "../helpers";

import img from "../static/images/988.png";
// import logo from "../static/images/logo.png";

import "./Topbar.scss";

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoModal: false,
      modalName: null,
      modalDescription: null,
      modalReadMoreLink: null,
      openShare: "0",
      readMoreLInkShow: false,
      navBarIsVisible: false,
      navBarDesktopIsVisible: false,

    };
  }

  componentDidMount() {
    this.props.fetchSiteSettings();
  }

  getSearchBox = () => {
    if (this.props.searchBox) {
      let className = "";
      if (this.props.currentView === "userProfilePage") {
        className = "profilePage";
      } else if (this.props.currentView) {
        className = this.props.currentView;
      }
      return (
        <SearchBox className={className} currentLang={language.getIso2()} />
      );
    }
    if (this.props.searchBoxWorkflow) {
      let className = "";
      if (this.props.currentView === "userProfilePage") {
        className = "profilePage";
      } else if (this.props.currentView) {
        className = this.props.currentView;
      }
      return (
        <SearchBoxWorkflow
          className={className}
          currentLang={language.getIso2()}
        />
      );
    }
  };

  getDocumentInfo = () => {
    if (this.props.documentInfo && this.props.description) {
      let item = this.props.documentInfo;
      let info = this.props.description;
      return (
        <div
          className="documentInfo"
          onClick={() => this.showInfoModal(item, info)}
        >
          <img
            style={{ verticalAlign: "middle", width: "50px" }}
            src={img}
            className="icon-info"
            alt="documentInfo"
          />
        </div>
      );
    }
  };

  showInfoModal = (item, info) => {
    if (this.props.setDocDescription) {
      this.props.setDocDescription();
    }
    this.setState(
      {
        modalName: item.name,
        modalDescription: info,
        modalReadMoreLink: item.read_more_link,
      },
      this.refs.helperTextModal.show()
    );
  };

  hideInfoModal = () => {
    this.refs.helperTextModal.hide();
  };

  // getReadMoreButton() {
  //   if (this.props.readMoreLink) {
  //     return (
  //       <a
  //         target="_blank"
  //         href={this.props.readMoreLink}
  //         className="readMore"
  //         onClick={this.readMoreButtonClick}
  //       >
  //         {strings.get("Client.homePage.readMore")}
  //       </a>
  //     );
  //   }
  // }

  getDivider = () => {
    if (this.props.divider) {
      return <div className="divider" />;
    }
  };

  readMoreButtonClick = () => {
    this.setState({ descriptionShow: !this.state.descriptionShow });
  };

  handleHWTClick = (e) => {
    e.preventDefault();
  };

  // getHowItWorksButton() {
  //   return (
  //     <div id="howItWorks" onClick={this.handleHWTClick}>
  //       <a href="#">{strings.get("Client.homePage.howItWorks")}</a>
  //       <i className="ion-social-youtube" />
  //     </div>
  //   );
  // }

  handleMenuButtonClick = () => {
    let navbar = document.getElementsByClassName("Navbar")[0];
    let leftmenu = document.getElementsByClassName("leftmenu")[0];
    let menuButton = document.getElementsByClassName("menuButton")[0];

    if (!this.state.navBarIsVisible) {
      navbar.setAttribute("style", "display:block;");
      leftmenu.setAttribute("style", "display:block;");
      menuButton.style.right = "255px";
      this.setState({ navBarIsVisible: true });
    } else {
      navbar.setAttribute("style", "display:none;");
      leftmenu.setAttribute("style", "display:none;");
      menuButton.style.right = "5px";
      this.setState({ navBarIsVisible: false });
    }
  };

  handleMenuButtonDesktopClick = () => {
    let leftmenu = document.getElementsByClassName("leftmenu")[0];

    if (!this.state.navBarDesktopIsVisible) {
      leftmenu.setAttribute("style", "display:block;");
      this.setState({ navBarDesktopIsVisible: true });
    } else {
      leftmenu.setAttribute("style", "display:none;");
      this.setState({ navBarDesktopIsVisible: false });
    }
  };

  handleLogoClick = () => {
    browserHistory.push("/");
    window.location.reload();
  };

  handleHelperClick = (link) => {
    if (validURL.isUri(link)) {
      window.open(link, "_blank");
    }
  };

  // getHeaderLogo = () => <img src={this.props.headerLogo} />;
  handleLangChange = () => {
    // this.props.handleLangChange();
    this.props.fetchSiteSettings();
  };

  render() {
    let title =
      this.props.currentView === "userProfilePage" ? null : (
        <div className="title">{this.props.title}</div>
      );
    let subTitle =
      this.props.currentView === "userProfilePage" ||
      this.props.currentView === "smallHeader" ? null : (
        <div className="subtitle">{this.props.subtitle}</div>
      );

    const { siteSettings } = this.props;
    let headerLogo, bannerImg;

    if (siteSettings) {
      if (siteSettings.length) {
        siteSettings.map((image, i) => {
          if (image.key === "header_logo") {
            headerLogo = image.imageURL;
          } else if (image.key === "banner_img") {
            bannerImg = image.imageURL;
          }
        });
      }
    }

    let bgImageStyle = {};
    if (bannerImg) {
      bgImageStyle = {
        background: `url(${bannerImg})`,
        backgroundSize: "cover",
        backgroundPositionY: "25%",
        backgroundRepeat: "no-repeat",
      };
    }

    let helperTextModal = (
      <CustomDropModal
        className="boron-modal form-modal infoModal"
        ref="helperTextModal"
      >
        <div className="close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.hideInfoModal}
          />
        </div>
        <h4>{this.state.modalName}</h4>
        <p className="text-left">
          <div
            dangerouslySetInnerHTML={{ __html: this.state.modalDescription }}
          />
        </p>
        {this.state.modalReadMoreLink ? (
          <button
            className="btn btn3"
            onClick={() => this.handleHelperClick(this.state.modalReadMoreLink)}
          >
            {strings.get("Client.documentsList.modalButtons.readMore")}
          </button>
        ) : null}
        <button onClick={this.hideInfoModal} className="btn btn2">
          {strings.get("Client.documentsList.modalButtons.gotIt")}
        </button>
      </CustomDropModal>
    );

    return (
      <div className="Topbar" style={bgImageStyle}>
        {helperTextModal}
        <div className="container-row">
          <div className="logo" onClick={this.handleLogoClick}>
            {/*<span>Juri</span>
                        <span>docs</span>*/}
            {headerLogo ? <img src={headerLogo} alt="Logo" /> : null}
          </div>

          <span className="LanguageSelector" />
          {/*Remove above span if Language selector wants*/}
          {/*<LanguageSelector
            currentLang={this.props.currentLang}
            handleLangChange={this.handleLangChange}
			  />*/}

          <Navbar
            // currentLang={this.props.currentLang}
            handleNewsClick={this.props.handleNewsClick}
            menuItem={this.props.menuItem}
          />
          <div className="menuButton" onClick={this.handleMenuButtonClick}>
            <i className="ion-navicon-round" />
          </div>
          <div className="menuButtonDesktop" onClick={this.handleMenuButtonDesktopClick}>
            <i className="ion-navicon-round" />
          </div>
          {title}
          {this.getDivider()}
          {subTitle}
          {/* { this.props.description ? <div className="description">{ this.props.description }</div> : null } */}
          {/* { this.getReadMoreButton() } */}
          {this.getSearchBox()}
          {/* { this.getHowItWorksButton() } */}
          {this.getDocumentInfo()}
        </div>
        <Cookie />
        <Feedback sideTitle={strings.get("Client.feedback.sideTitle")} />
      </div>
    );
  }
}

Topbar.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  divider: PropTypes.bool,
  searchBox: PropTypes.bool,
  searchBoxWorfklow: PropTypes.bool,
  documentInfo: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    siteSettings: settingsSelectors.getSiteSettings(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSiteSettings: () => {
      dispatch(settingsActions.fetchSiteSettings());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
