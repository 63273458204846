import React, { Component } from "react";
import { connect } from "react-redux";
import { NotificationContainer } from "react-notifications";
import _ from "lodash";
import { Helmet } from "react-helmet";

import UserAlertLoader from "./components/document/UserAlertLoader";

import { browserHistory } from "./helpers";
import strings from "./services/strings";
import language from "./services/language";
import { initGA } from "./services/tracker";
import api from "./services/api";

import * as authActions from "./store/auth/actions";
import * as authSelectors from "./store/auth/selectors";
import * as exceptionsSelectors from "./store/exceptions/selectors";
import * as exceptionsActions from "./store/exceptions/actions";
import * as settingsSelectors from "./store/settings/selectors";
import * as settingsActions from "./store/settings/actions";

import "./App.scss";
import "./AppTabletLandscape.scss";
import "./AppTabletPortret.scss";
import "./AppMobile.scss";
// import "./static/styles/ionicons.min.css";
import "./containers/Page.scss";
import "./components/document/DocumentAlertLoader.scss";

import "react-notifications/lib/notifications.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Routes } from "./routes";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLoaded: false,
      load: true,
      title: "",
      lang: { id: 2, iso2: "NL" },
    };
  }

  componentDidMount() {
    initGA();
    let pathName =
      this.props.location && this.props.location.pathname
        ? this.props.location.pathname
        : "/NL";
    pathName = pathName.split("/");
    let locale = "NL";
    if (pathName.length > 1 && pathName[1] !== "") {
      locale = pathName[1];
    }
    api
      .get(`/languageFromIso/${locale}`)
      .then((data) => {
        language.set(data[0]);
        this.checkMaintenance(data[0].id);
        strings.setLanguage(data[0]).then(() => {
          this.props.setCurrentLang();
          // this.handleLangChange();
        });
      })
      .catch((error) => {
        //ignore
      });
    // } else {
    //   this.checkMaintenance(2);
    // }
    this.tryLoadUser();
  }

  componentDidUpdate(props) {
    if (
      _.size(this.props.currentLang) &&
      this.state.lang !== this.props.currentLang
    ) {
      this.setState({ lang: this.props.currentLang });
    }
    if (_.size(this.props.siteSettings)) {
      _.map(this.props.siteSettings, (item) => {
        //custom title favicon
        if (item.key === "client_title_favicon") {
          let link = document.getElementById("favicon");
          if (!link) {
            link = document.createElement("link");
            link.id = "favicon";
            link.rel = "shortcut icon";
            document.head.appendChild(link);
          }
          link.href = item.imageURL;
        }
      });
    }
  }

  checkMaintenance = async (id_language) => {
    let data = await this.props.checkMaintenance(id_language);
    let maintenanceData = _.fromPairs(_.map(data, (i) => [i.key, i.value]));

    let isMaintenance = maintenanceData["maintenance_mode"];
    let currentLocation = this.props.location.pathname;

    if (
      (isMaintenance === 1 || isMaintenance === "1") &&
      !_.endsWith(currentLocation, "maintenance") &&
      !_.endsWith(currentLocation, "contact")
    ) {
      browserHistory.push(`/maintenance/${id_language}`);
    }
    if (
      (isMaintenance === 0 || isMaintenance === "0") &&
      _.endsWith(currentLocation, "maintenance")
    ) {
      browserHistory.push(`/`);
    }
  };

  tryLoadUser = async () => {
    if (!this.state.userLoaded) {
      await this.props.getUser();
      this.setState({ userLoaded: true });
    }
  };

  // handleLangChange = () => {
  //   let lang = {};
  //   lang["id"] = language.get();
  //   lang["iso2"] = language.getIso2();
  //   this.setState({ lang });
  // };

  setSiteMetaDescription = (siteMetaDescription) => {
    let description = document.querySelector('meta[name="description"]');
    if (!description) {
      description = document.createElement("meta");
      description.name = "description";
      document.head.appendChild(description);
    }
    description.content = siteMetaDescription;
  };

  setSiteMetaKeywords = (siteMetaKeywords) => {
    let keywords = document.querySelector('meta[name="keywords"]');
    if (!keywords) {
      keywords = document.createElement("meta");
      keywords.name = "keywords";
      document.head.appendChild(keywords);
    }
    keywords.content = siteMetaKeywords;
  };

  getMetaFields = (siteName) => {
    return (
      <Helmet>
        <title>{siteName}</title>
      </Helmet>
    );
  };

  render() {
    const { siteSettings } = this.props;
    let siteName = "";
    let siteMetaDescription = "";
    let siteMetaKeywords = "";
    _.map(siteSettings, (item) => {
      if (item.key === "client_site_name") {
        siteName = item.value;
      }
      if (item.key === "site_meta_description") {
        siteMetaDescription = item.value;
      }
      if (item.key === "site_meta_keywords") {
        siteMetaKeywords = item.value;
      }
    });

    let children = React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, {
        exceptions: this.props.exceptions,
        clearExceptions: this.props.clearExceptions,
        // handleLangChange: this.handleLangChange,
        // currentLang: this.state.lang,
      });
    });

    return (
      <div className="App">
        <NotificationContainer />
        {this.props.screenLoader ? <UserAlertLoader /> : null}
        {siteName ? this.getMetaFields(siteName) : null}
        {siteMetaDescription
          ? this.setSiteMetaDescription(siteMetaDescription)
          : null}
          {siteMetaKeywords
          ? this.setSiteMetaKeywords(siteMetaKeywords)
          : null}
        {this.state.load ? children : ""}
        <Routes />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    exceptions: exceptionsSelectors.getItems(state),
    siteSettings: settingsSelectors.getSiteSettings(state),
    screenLoader: settingsSelectors.getScreenLoadingStatus(state),
    maintenanceSettings: authSelectors.getMaintenanceSettings(state),
    currentLang: settingsSelectors.getCurrentLanguage(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => {
      dispatch(authActions.logout());
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
    getUser: () => {
      return dispatch(authActions.getUser());
    },
    checkMaintenance: (id_language) => {
      return dispatch(authActions.checkMaintenance(id_language));
    },
    setCurrentLang: () => {
      dispatch(settingsActions.setCurrentLang());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
