import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import strings from "../../services/strings";

import "./DraftCategoryFilter.scss";

class DraftCategoryFilter extends Component {
  getSelectedCategory = () => {
    if (this.props.filters) {
      return this.props.filters.categoryId;
    }
    return "";
  };

  handleChange = (e) => {
    this.props.setCategoryId(e.target.value);
    this.props.fetchItems(true);
  };

  getCategoryTree = (items, item, arr, depth = 0) => {
    if (_.size(items) && _.find(items, item)) {
      if (item.parent_id && depth === 0) return;
      arr[0].push(item);
      arr[1].push(depth);
      _.map(items, (it) => {
        if (item.id === it.parent_id) {
          this.getCategoryTree(items, it, arr, depth + 1);
        }
      });
    }
  };

  render() {
    let arr = [[], []];
    _.map(this.props.categories, (item) => {
      this.getCategoryTree(this.props.categories, item, arr);
    });

    let categories = _.map(arr[0], (category, i) => {
      let sub = arr[1][i]
        ? " ".repeat(Math.round(arr[1][i] * 3 + arr[1][i] * 3)) + "|" + "_"
        : " ";
      return (
        <option
          className={`depth-${arr[1][i]}`}
          value={category.id}
          key={category.id}
        >
          {`${sub} ${category.name}`}
        </option>
      );
    });

    return (
      <div className="DraftCategoryFilter col-sm-3 col-md-3 col-xs-6 top-margin-10">
        {/* <label>{strings.get("Client.downloadsPage.from")}</label> */}
        <select
          className="form-control cate-select"
          name="groups"
          value={this.getSelectedCategory()}
          onChange={this.handleChange}
        >
          <option value="">
            {strings.get("Client.downloadsPage.allCategories")}
          </option>
          {categories}
        </select>
      </div>
    );
  }
}

DraftCategoryFilter.propTypes = {
  filters: PropTypes.object,
  categories: PropTypes.object,
  setCategoryId: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
};

export default DraftCategoryFilter;
