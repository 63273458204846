import React, { Component } from "react";
import _ from "lodash";
import "moment/locale/nl";
import ReactCollapsingTable from "react-collapsing-table";
import { NotificationManager } from "react-notifications";
import ReactLoading from "react-loading";
import PropTypes from "prop-types";

import "../drafts/DraftList.scss";

import ButtonDelete from "../drafts/ButtonDelete";

import SocialShareDraftButton from "../social/SocialShareDraftButton";

import CustomDropModal from "../PopUp";

import strings from "../../services/strings";
import { makeSEFUrl } from "../../helpers";
import language from "../../services/language";

let componentInstance = null;

const TableWorkflowName = ({ row, accessor }) => {
  return (
    <div>
      <div className="edit-icon">
        <a
          className="edit"
          onClick={() => componentInstance.handleEditFieldsClick(row)}
        >
          <i className="fas fa-pencil-alt" />
        </a>
      </div>
      <div className="edit-text">
        <a href={componentInstance.processLink(row)}>{row.name}</a>
      </div>
    </div>
  );
};

const TableTemplate = ({ row, accessor }) => {
  return (
    <span className="template-name">
      <a href={componentInstance.processTemplateLink(row)} target="_blank">
        {row.workflow.name}
      </a>
      <br />
      <small>
        <a href={componentInstance.processCategoryLink(row)} target="_blank">
          {row.category.name}
        </a>
      </small>
    </span>
  );
};

const TableDraftDelete = ({ row, accessor }) => {
  return (
    <ButtonDelete
      document={row.workflow}
      id={row.id}
      listDeleteDraft={componentInstance.handleDeleteWorkflow}
    />
  );
};

const TableWorkflowShare = ({ row, accessor }) => {
  return (
    <SocialShareDraftButton
      url={componentInstance.state.workflowZipUrl}
      message={componentInstance.state.workflowShareMessage}
      sendEmails={componentInstance.props.shareUserWorkflow}
      item={row}
      callbackFromList={(trigger, item) =>
        componentInstance.handleShareCallback(trigger, row.id, true)
      }
      isOpen={
        componentInstance.state.openSocialModal &&
        componentInstance.state.openShare === row.id
      }
      urlSuffix={"default"}
    />
  );
};

const TableWorkflowDownload = ({ row, accessor }) => {
  return (
    <span>
      <a
        className="set-reminder"
        title={strings.get("Client.workflowsList.listPage.download")}
        onClick={() => componentInstance.props.downloadUserWorkflow(row.id)}
      >
        <i className="fas fa-download" />
      </a>
    </span>
  );
};

class WorkflowsList extends Component {
  constructor(props) {
    super(props);
    componentInstance = this;
    this.state = {
      // columnsLoaded: false,
      editWorkflowName: "",
      openShare: 0,
      openSocialModal: false,
      workflowShareMessage: strings.get("Client.workflows.shareMessage"),
      workflowZipUrl: "",
      messageLoading: false,
    };
  }

  processLink = (item) => {
    let locale = language.getIso2();
    return `${window.location.protocol}//${
      window.location.host
    }/${locale}/workflows/${makeSEFUrl(item.category.name)}-${
      item.category.id
    }/${makeSEFUrl(item.workflow.name)}-${item.workflow.id}/${item.id}`;
  };

  processTemplateLink = (item) => {
    let locale = language.getIso2();
    return `${window.location.protocol}//${
      window.location.host
    }/${locale}/workflows/${makeSEFUrl(item.category.name)}-${
      item.category.id
    }/${makeSEFUrl(item.workflow.name)}-${item.workflow.id}`;
  };

  processCategoryLink = (item) => {
    let locale = language.getIso2();
    return `${window.location.protocol}//${
      window.location.host
    }/${locale}/workflows/${makeSEFUrl(item.category.name)}-${
      item.category.id
    }`;
  };

  handleDeleteWorkflow = (id) => {
    this.props.deleteUserWorkflow(id);
  };

  handleEditFieldsClick = (workflow) => {
    this.setState(
      {
        editWorkflowName: workflow.name,
        workflowId: workflow.id,
      },
      () => {
        this.showEditFieldsModal();
      }
    );
  };

  showEditFieldsModal = () => {
    this.refs.editFieldsModal.show();
  };

  handleDraftNameInputChange = (e) => {
    this.setState({
      editWorkflowName: e.target.value,
    });
  };

  handleWorkflowShareMessageChange = (e) => {
    this.setState({
      workflowShareMessage: e.target.value,
    });
  };
  editableFields = () => {
    return (
      <div className="editable-fields-box">
        <input
          type="text"
          className="form-control"
          name="editable_draft_name"
          value={this.state.editWorkflowName}
          onChange={this.handleDraftNameInputChange}
        />
      </div>
    );
  };

  workflowShareMessageField = () => {
    return (
      <div className="editable-fields-box">
        <input
          type="text"
          className="form-control"
          value={this.state.workflowShareMessage}
          onChange={this.handleWorkflowShareMessageChange}
        />
      </div>
    );
  };

  editFieldsModalConfirm = () => {
    let newFields = {};
    if (this.state.editWorkflowName) {
      newFields["name"] = this.state.editWorkflowName;
      this.props.updateUserWorkflowName(this.state.workflowId, newFields);
      this.refs.editFieldsModal.hide();
    } else {
      NotificationManager.error("Name required");
    }
  };

  editFieldsModalCancel = () => {
    this.refs.editFieldsModal.hide();
  };

  /* Collapsing Responsive Table Start*/
  getWorkflowRows = () => {
    let rows = [];
    if (this.props.currentUserWorkflows) {
      _.forEach(this.props.currentUserWorkflows, function (item, key) {
        rows.push(item);
      });
    }
    // After rows loaded, then set columnsLoaded to True.
    // if (!this.state.columnsLoaded && rows.length > 0) {
    //   this.setState({
    //     columnsLoaded: true,
    //   });
    // }
    return rows;
  };

  getWorkflowColumns = () => {
    const breakpoints = {
      desktop: 1024,
      ipad: 768,
      tablet: 640,
      mobile: 480,
    };
    let columnWidth;
    if (window.innerWidth >= breakpoints.desktop) {
      columnWidth = 200;
    } else if (window.innerWidth <= breakpoints.ipad) {
      columnWidth = 200;
    }

    let columns = [
      {
        accessor: "name",
        label: strings.get("Client.draftsPage.draftName"),
        priorityLevel: 1,
        position: 1,
        minWidth: columnWidth,
        sortable: false,
        CustomComponent: TableWorkflowName,
      },
      {
        accessor: "documentName",
        label: strings.get("Client.draftsPage.docName"),
        priorityLevel: 2,
        position: 2,
        minWidth: columnWidth,
        sortable: false,
        CustomComponent: TableTemplate,
      },
      {
        accessor: "downloadColumn",
        label: strings.get("Client.workflowsList.listPage.download"),
        priorityLevel: 3,
        position: 3,
        minWidth: columnWidth,
        sortable: false,
        CustomComponent: TableWorkflowDownload,
      },
      {
        accessor: "shareColumn",
        label: strings.get("Client.draftsPage.share"),
        priorityLevel: 4,
        position: 4,
        minWidth: columnWidth,
        sortable: false,
        CustomComponent: TableWorkflowShare,
      },
      {
        accessor: "deleteColumn",
        label: strings.get("Client.draftsPage.delete"),
        priorityLevel: 5,
        position: 5,
        minWidth: columnWidth,
        sortable: false,
        CustomComponent: TableDraftDelete,
      },
    ];
    return columns;
  };

  handleShareCallback = async (trigger, itemId, openWorkflowMessageModal) => {
    if (trigger) {
      this.setState({ openShare: 0, openSocialModal: false });
    } else {
      this.setState({ openShare: itemId, messageLoading: true });
    }
    if (!this.state.openSocialModal && openWorkflowMessageModal) {
      this.workflowShareMessageModalShow();
      try {
        await this.props.downloadUserWorkflow(itemId, this.getZipURL);
        this.setState({ messageLoading: false });
      } catch (error) {
        NotificationManager.error(strings.get("Client.notifications.error"));
      }
    }
  };

  getZipURL = (url) => {
    this.setState({
      workflowZipUrl: url,
    });
  };

  workflowShareMessageModalHide = () => {
    this.setState(
      {
        workflowShareMessage: "",
      },
      () => {
        this.refs.workflowShareMessageModal.hide();
      }
    );
  };

  workflowShareMessageModalShow = () => {
    this.refs.workflowShareMessageModal.show();
  };

  workflowShareMessageModalConfirm = () => {
    this.setState(
      {
        openSocialModal: true,
      },
      () => {
        this.refs.workflowShareMessageModal.hide();
      }
    );
  };

  render() {
    let editWorkflowNameModal = (
      <CustomDropModal className="boron-modal" ref="editFieldsModal">
        <div className="close-btn">
          <span
            className="ion-android-cancel clickable"
            onClick={this.editFieldsModalCancel}
          />
        </div>
        <div className="jd-modal">
          <label className="pull-left">
            {strings.get("Client.editModal.title")}
          </label>
          <br />
          {this.editableFields()}
          <button className="btn btn2" onClick={this.editFieldsModalConfirm}>
            {strings.get("App.documents.documentForm.orderFieldsModal.save")}
          </button>
          <button className="btn btn3" onClick={this.editFieldsModalCancel}>
            {strings.get("App.documents.documentForm.orderFieldsModal.cancel")}
          </button>
        </div>
      </CustomDropModal>
    );

    let workflowShareMessageModal = (
      <CustomDropModal className="boron-modal" ref="workflowShareMessageModal">
        <div className="close-btn">
          <span
            className="ion-android-cancel clickable"
            onClick={this.workflowShareMessageModalHide}
          />
        </div>
        {this.state.messageLoading ? (
          <div className="react-loading-container">
            <ReactLoading type="spinningBubbles" color="#36d7b7" />
            <p className="loading-message">
              {strings.get("Client.workflowsList.shareMsgModal.waitingMessage")}
            </p>
          </div>
        ) : (
          <div className="jd-modal">
            <label className="pull-left">
              {strings.get("Client.editModal.title")}
            </label>
            <br />

            {this.workflowShareMessageField()}
            <button
              className="btn btn2"
              onClick={this.workflowShareMessageModalConfirm}
            >
              {strings.get("App.documents.documentForm.orderFieldsModal.save")}
            </button>
            <button
              className="btn btn3"
              onClick={this.workflowShareMessageModalHide}
            >
              {strings.get(
                "App.documents.documentForm.orderFieldsModal.cancel"
              )}
            </button>
          </div>
        )}
      </CustomDropModal>
    );

    let rows = this.getWorkflowRows();
    let columns = this.getWorkflowColumns();

    return (
      <div className="DraftsList">
        <div className="row">
          <div className="col-sm-12">
            {editWorkflowNameModal}
            {workflowShareMessageModal}
            {/* {this.state.columnsLoaded ? ( */}
            {_.size(rows) ? (
              <ReactCollapsingTable
                theme="react-collapsible-custom-theme"
                rows={rows}
                columns={columns}
              />
            ) : (
              <div className="col-sm-12 defaultMsg">
                <div>{strings.get("Client.documentsList.noFiles")}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

WorkflowsList.propTypes = {
  items: PropTypes.array,
  onMouseOver: PropTypes.func,
  isRole: PropTypes.bool,
  downloadUserWorkflow: PropTypes.func.isRequired,
  updateUserWorkflowName: PropTypes.func.isRequired,
  deleteUserWorkflow: PropTypes.func.isRequired,
  shareUserWorkflow: PropTypes.func.isRequired,
};

export default WorkflowsList;
