import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import PropTypes from "prop-types";

import { makePATHUrl } from "../../helpers";
import language from "../../services/language";

import "./SubCategoriesList.scss";

class SubCategoriesList extends Component {
  getImage = (item) => {
    if (item.imageURL) {
      return (
        <div
          className="categoryImage"
          style={{ backgroundImage: `url(${item.imageURL})` }}
        />
      );
    }
  };

  getTitle = () => {
    if (_.size(this.props.items)) {
      return <h2></h2>;
    }
  };

  getItems = () => {
    let locale = language.getIso2();
    if (this.props.items) {
      return _.map(this.props.items, (item) => {
        return (
          <li className="col-sm-4" key={item.id}>
            <Link
              to={`/${locale}/workflows/${makePATHUrl(item.name, item.id)}`}
            >
              <span className="title">{item.name}</span>
            </Link>
          </li>
        );
      });
    }
  };

  render() {
    return (
      <div className="container-special-align">
        <div
          className="SubCategoriesList container"
          style={{ display: _.size(this.props.items) ? "block" : "none" }}
        >
          {this.getTitle()}
          <ul className="row">{this.getItems()}</ul>
        </div>
      </div>
    );
  }
}

SubCategoriesList.propTypes = {
  items: PropTypes.array,
  assignedUsers: PropTypes.array,
};

export default SubCategoriesList;
