import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";

import Topbar from "../../../components/Topbar";
import NavigationTabs from "../../../components/NavigationTabs";
import NavigationLeftTabs from "../../../components/NavigationLeftTabs";
// import CustomDropModal from "../../../components/PopUp";
import Footer from "../../../components/footer/Footer";
import ShareEmailSettingForm from "../../../components/settings/shareEmail/ShareEmailSettingForm";
import Breadcrumbs from "../../../components/Breadcrumbs";

import * as requiredOptionsActions from "../../../store/requiredOptions/actions";
import * as authActions from "../../../store/auth/actions";
import * as authSelectors from "../../../store/auth/selectors";
import * as exceptionsSelectors from "../../../store/exceptions/selectors";
import * as exceptionsActions from "../../../store/exceptions/actions";
import * as settingsActions from "../../../store/settings/actions";

import language from "../../../services/language";
import strings from "../../../services/strings";
import auth from "../../../services/auth";
import { browserHistory } from "../../../helpers";
import { NotificationManager } from "react-notifications";

// import "../../Page.scss";

class ShareEmailSettingPage extends Component {
  componentDidMount() {
    if (!auth.isAuthenticated()) {
      browserHistory.push("/");
      window.location.reload();
    }
  }

  // handleShowModal = () => {
  //   _.delay(() => {
  //     this.hideSaveModal();
  //   }, 1000);
  // };

  // showSaveModal = () => {
  //   this.refs.saveModal.show();
  // };

  // hideSaveModal = () => {
  //   this.refs.saveModal.hide();
  // };

  saveUserSettings = (data) => {
    this.props.setLoadingStatus(true);
    this.props.updateUserSettings(data).then(() => {
      if (_.size(this.props.exceptions) === 0) {
        this.props.setLoadingStatus(false);
        this.props.getUser();
        // this.showSaveModal();
        NotificationManager.success(
          "",
          strings.get("App.settings.settingsSaved")
        );
      } else {
        NotificationManager.error(strings.get("Client.notifications.error"));
      }
    });
  };

  render() {
    let locale = language.getIso2();
    // let saveModal = (
    //   <CustomDropModal
    //     className="boron-modal no-body"
    //     ref="saveModal"
    //     onShow={this.handleShowModal}
    //   >
    //     <span>
    //       <h2>{strings.get("App.settings.settingsSaved")}</h2>
    //     </span>
    //   </CustomDropModal>
    // );
    return (
      <div className="ShareEmailSettingPage">
        {/* {saveModal} */}
        <Topbar
          title={strings.get("Client.profilePage.messages")}
          searchBox={true}
          currentView="ShareEmailSettingPage"
        />
        <div className="container">
          <Breadcrumbs>
            <Link to={`/${locale}/`}>
              {strings.get("Client.homePage.home")}
            </Link>
            <Link to={`/${locale}/profile`}>
              {strings.get("Client.homePage.profile")}
            </Link>
            <Link to={`/${locale}/messages/shareemail`}>
              {strings.get("Client.profilePage.messages")}
            </Link>
          </Breadcrumbs>

          <div className="container-row">
            <NavigationTabs
              currentItemId={5}
              currentLang={this.props.currentLang}
            />

            <div className="row">
              <NavigationLeftTabs
                currentItemId={0}
                currentLang={this.props.currentLang}
              />

              <ShareEmailSettingForm
                exceptions={this.props.exceptions}
                currentItem={this.props.profile}
                saveItem={this.saveUserSettings}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: authSelectors.getProfile(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUser: () => {
      dispatch(authActions.getUser());
    },
    updateUserSettings: (data) => {
      return dispatch(requiredOptionsActions.updateUserSettings(data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
    setLoadingStatus: (status) => {
      dispatch(settingsActions.setScreenLoading(status));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareEmailSettingPage);
