import React, { Component } from "react";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import _ from "lodash";
import { Link } from "react-router-dom";

import Breadcrumbs from "../../../components/Breadcrumbs";
import RequiredOptionsList from "../../../components/settings/requiredOptions/RequiredOptionsList";
import Topbar from "../../../components/Topbar";
import NavigationTabs from "../../../components/NavigationTabs";
import Footer from "../../../components/footer/Footer";
import UserSettingsForm from "../../../components/settings/requiredOptions/UserSettingsForm";
import CustomDropModal from "../../../components/PopUp";

import * as requiredOptionsActions from "../../../store/requiredOptions/actions";
import * as requiredOptionsSelectors from "../../../store/requiredOptions/selectors";
import * as authSelectors from "../../../store/auth/selectors";
import * as exceptionsSelectors from "../../../store/exceptions/selectors";
import * as exceptionsActions from "../../../store/exceptions/actions";

import strings from "../../../services/strings";
import auth from "../../../services/auth";
import language from "../../../services/language";

// import "../../Page.scss";

const browserHistory = createBrowserHistory();

class RequiredOptionsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    if (!auth.isAuthenticated()) {
      browserHistory.push("/");
      window.location.reload();
    } else {
      this.setState({ user: auth.getLocalUser() ? auth.getLocalUser() : null });

      this.props.fetchAllRequiredOptions();
    }
  }

  handleShowModal = () => {
    _.delay(() => {
      this.hideSaveModal();
    }, 500);
  };

  showSaveModal = () => {
    this.refs.saveModal.show();
    _.delay(() => {
      //window.location.reload();
      this.hideSaveModal();
    }, 1000);
  };

  hideSaveModal = () => {
    this.refs.saveModal.hide();
  };

  saveData = (data) => {
    let updateRequiredOptions = this.props.updateRequiredOptions(data);
    Promise.all([updateRequiredOptions]).then(() => {
      this.showSaveModal();
    });
  };

  saveUserSettings = (data) => {
    this.props.updateUserSettings(data).then(() => {
      if (_.size(this.props.exceptions) === 0) {
        this.showSaveModal();
      }
    });
  };

  render() {
    let locale = language.getIso2();
    let saveModal = (
      <CustomDropModal
        className="boron-modal no-body"
        ref="saveModal"
        onShow={this.handleShowModal}
      >
        <span>
          <h2>{strings.get("Client.settings.settingsSaved")}</h2>
        </span>
      </CustomDropModal>
    );

    return (
      <div className="RequiredOptionsPage setting-page">
        {saveModal}
        <Topbar
          title={strings.get("Client.profilePage.settings")}
          searchBox={true}
          currentView="RequiredOptionsPage"
        />
        <div className="breadcrumb-cont">
          <div className="container">
            <Breadcrumbs>
              <Link to={`/${locale}/`}>
                {strings.get("Client.homePage.home")}
              </Link>
              <Link to={`/${locale}/profile`}>
                {strings.get("Client.homePage.profile")}
              </Link>
              <Link to={`/${locale}/settings`}>
                {strings.get("Client.profilePage.settings")}
              </Link>
            </Breadcrumbs>
          </div>
        </div>
        <div className="inner-page-cont setting-cont">
          <div className="container">
            <NavigationTabs
              currentItemId={6}
              currentLang={this.props.currentLang}
            />
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="row">
                <RequiredOptionsList
                  items={this.props.requiredOptions}
                  currentItem={this.props.currentRequiredOption}
                  updateItem={this.saveData}
                  setCurrentItemId={this.props.setCurrentRequiredOptionId}
                  unsetCurrentItemId={this.props.unsetCurrentRequiredOptionId}
                />
              </div>
            </div>
            <div className="clearfix" />
            <UserSettingsForm
              exceptions={this.props.exceptions}
              currentItem={this.props.profile}
              saveItem={this.saveUserSettings}
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    requiredOptions: requiredOptionsSelectors.getItems(state),
    currentRequiredOption: requiredOptionsSelectors.getCurrentItem(state),
    profile: authSelectors.getProfile(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllRequiredOptions: () => {
      dispatch(requiredOptionsActions.fetchAllItems());
    },
    updateRequiredOptions: (data) => {
      dispatch(requiredOptionsActions.updateItem(data));
    },
    setCurrentRequiredOptionId: (id) => {
      dispatch(requiredOptionsActions.setCurrentItemId(id));
    },
    unsetCurrentRequiredOptionId: () => {
      dispatch(requiredOptionsActions.unsetCurrentItemId());
    },
    // getUser: () => {
    //     dispatch(authActions.getUser())
    // },
    updateUserSettings: (data) => {
      return dispatch(requiredOptionsActions.updateUserSettings(data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequiredOptionsPage);
