import Immutable from "seamless-immutable";
import { types } from "./actions";
import language from "../../services/language";

const initialState = Immutable({
  siteSettings: [],
  currentModal: "",
  cookieBannerStatus: false,
  screenLoading: false,
  currentLang: { id: language.get(), iso2: language.getIso2() },
});

// function fetchHeaderLogoDone(state, payload) {
//   return state.merge({
//     headerLogo: payload.item.imageURL
//   });
// }

// function fetchFooterLogoDone(state, payload) {
//   return state.merge({
//     footerLogo: payload.item.imageURL
//   });
// }

function fetchSiteSettingsDone(state, payload) {
  return state.merge({
    siteSettings: payload.item,
  });
}

function setCurrentModal(state, payload) {
  if (payload.currentModal === "") {
    return state.merge({
      currentModal: payload.currentModal,
    });
  } else if (
    payload.currentModal === "cookieConsentModal" &&
    !state.cookieBannerStatus
  ) {
    return state.merge({
      currentModal: payload.currentModal,
      cookieBannerStatus: !state.cookieBannerStatus,
    });
  } else {
    return state.merge({
      currentModal: payload.currentModal,
    });
  }
}

function setScreenLoading(state, payload) {
  return state.merge({
    screenLoading: payload.status,
  });
}

function setCurrentLang(state, payload) {
  return state.merge({
    currentLang: payload.lang,
  });
}

function setCookieBannerTrue(state) {
  return state.merge({
    cookieBannerStatus: !state.cookieBannerStatus,
  });
}

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    // case types.FETCH_HEADER_LOGO_DONE:
    //   return fetchHeaderLogoDone(state, action.payload);

    // case types.FETCH_FOOTER_LOGO_DONE:
    //   return fetchFooterLogoDone(state, action.payload);

    case types.FETCH_SITE_SETTINGS_DONE:
      return fetchSiteSettingsDone(state, action.payload);

    case types.SET_CURRENT_MODAL:
      return setCurrentModal(state, action.payload);

    case types.SET_SCREEN_LOADING:
      return setScreenLoading(state, action.payload);

    case types.SHOW_COOKIE_BANNER:
      return setCookieBannerTrue(state);

    case types.SET_CURRENT_LANGUAGE:
      return setCurrentLang(state, action.payload);

    default:
      return state;
  }
}
