import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import language from "../../services/language";

import "./ArticleList.scss";

class ArticleList extends Component {
  getImage = (item) => {
    if (item.imageURL) {
      return (
        <div
          className="categoryImage"
          style={{ backgroundImage: `url(${item.imageURL})` }}
        />
      );
    }
  };

  // getTitle = () => {
  //   if (_.size(this.props.items)) {
  //     return <h2></h2>;
  //   }
  // };

  getItems = () => {
    let locale = language.getIso2();
    let article = this.props.items;
    return _.map(article, (item) => {
      if (item.url) {
        return (
          <li key={`item-${item.id}`} className="infoItem col-sm-4">
            <a href={item.url} target="_blank">
              {item.name}
            </a>
          </li>
        );
      } else {
        if (item.content_type) {
          return (
            <li
              id={`infoItem${item.id}`}
              key={`item-${item.id}`}
              className="infoItem col-sm-4"
            >
              <a href={`/${locale}/article/${item.slug}`}>{item.name}</a>
            </li>
          );
        } else {
          return (
            <li key={`item-${item.id}`} className="infoItem col-sm-4">
              <a href={`/${locale}/article/${item.slug}`}>{item.name}</a>
            </li>
          );
        }
      }
    });
  };

  render() {
    return (
      <div
        className="ArticleList container"
        style={{ display: _.size(this.props.items) ? "block" : "none" }}
      >
        {/* {this.getTitle()} */}
        <ul className="row">{this.getItems()}</ul>
      </div>
    );
  }
}

ArticleList.propTypes = {
  items: PropTypes.array,
  assignedUsers: PropTypes.array,
};

export default ArticleList;
