import api from "../../services/api";
import language from "../../services/language";
import _ from "lodash";

import * as exceptionsActions from "../exceptions/actions";

import { NotificationManager } from "react-notifications";
import strings from "../../services/strings";

export const types = {
  // FETCH_HEADER_LOGO_DONE: 'settings.FETCH_HEADER_LOGO_DONE',
  // FETCH_FOOTER_LOGO_DONE: 'settings.FETCH_FOOTER_LOGO_DONE',
  FETCH_SITE_SETTINGS_DONE: "settings.FETCH_SITE_SETTINGS_DONE",
  SET_CURRENT_MODAL: "settings.SET_CURRENT_MODAL",
  SET_SCREEN_LOADING: "settings.SET_SCREEN_LOADING",
  SHOW_COOKIE_BANNER: "settings.SHOW_COOKIE_BANNER",
  SET_CURRENT_LANGUAGE: "settings.SET_CURRENT_LANGUAGE",
};

// export function fetchHeaderLogo() {
//     return async (dispatch, getState) => {
//         try {
//             let params = new Map();
//             params.set('language_id',language.get());

//             let item = await api.get('/settings', params);

//             if (item) {
// 	            dispatch({
// 	                type: types.FETCH_HEADER_LOGO_DONE,
// 	                payload: {
// 	                    item
// 	                }
// 	            });
//             }
//         } catch (e) {
//             dispatch(exceptionsActions.process(e));
//         }
//     }
// }

// export function fetchFooterLogo() {
//     return async (dispatch, getState) => {
//         try {
//             let params = new Map();
//             params.set('language_id',language.get());

//             let item = await api.get('/settings', params);

//             if (item) {
// 	            dispatch({
// 	                type: types.FETCH_FOOTER_LOGO_DONE,
// 	                payload: {
// 	                    item
// 	                }
// 	            });
//             }
//         } catch (e) {
//             dispatch(exceptionsActions.process(e));
//         }
//     }
// }

export function fetchSiteSettings() {
  return async (dispatch, getState) => {
    try {
      let params = new Map();
      params.set("language_id", language.get());

      let item = await api.get("/settings/site_settings", params);

      if (item) {
        dispatch({
          type: types.FETCH_SITE_SETTINGS_DONE,
          payload: {
            item,
          },
        });
      }
    } catch (e) {
      NotificationManager.error(strings.get("Exceptions.notifications.error"));
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function setCurrentModal(currentModal = "") {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_CURRENT_MODAL,
        payload: {
          currentModal,
        },
      });
    } catch (e) {
      NotificationManager.error(strings.get("Exceptions.notifications.error"));
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function setScreenLoading(status) {
  return {
    type: types.SET_SCREEN_LOADING,
    payload: {
      status,
    },
  };
}

export function setCookieBanner() {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SHOW_COOKIE_BANNER,
      });
    } catch (e) {
      NotificationManager.error(strings.get("Exceptions.notifications.error"));
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function setCurrentLang() {
  let lang = {};
  lang["id"] = language.get();
  lang["iso2"] = language.getIso2();

  return (dispatch) => {
    dispatch({
      type: types.SET_CURRENT_LANGUAGE,
      payload: lang,
    });
  };
}

export function renameAttachment(data, callBack) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      await api.put("/settings/rename_Attachment", params);
      if(callBack) callBack();
      NotificationManager.success(strings.get("Client.notifications.edited"));
    } catch (e) {
      NotificationManager.error(strings.get("Exceptions.notifications.error"));
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function notifyAdmin(data) {
  return async (dispatch) => {
    try {
      dispatch(setScreenLoading(true));
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      await api.post("/feedback", params);
      NotificationManager.success(
        strings.get("Exceptions.error_boundary.adminNotified")
      );
    } catch (e) {
      NotificationManager.error(strings.get("Exceptions.notifications.error"));
      dispatch(exceptionsActions.process(e));
    } finally {
      dispatch(setScreenLoading(false));
    }
  };
}
