import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import $ from "jquery";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import Topbar from "../../components/Topbar";
import Footer from "../../components/footer/Footer";
import Ad from "../../components/Ad";
import AdInArticle from "../../components/AdInArticle";
import Breadcrumbs from "../../components/Breadcrumbs";

import * as articleActions from "../../store/articles/actions";
import * as articleSelectors from "../../store/articles/selectors";
import * as categoriesActions from "../../store/articleCategories/actions";
import * as categoriesSelectors from "../../store/articleCategories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import { makeSEFUrl, makePATHUrl } from "../../helpers";
import language from "../../services/language";
import strings from "../../services/strings";

// import "../Page.scss";
class ArticlePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetched: false,
    };
  }

  componentDidMount() {
    this.props.fetchAllCategories();
    this.props.fetchArticles();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.articleId && !this.state.fetched) {
      nextProps.fetchItem(nextProps.articleId);
      nextProps.setCurrentCategoryId(nextProps.articleCategoryId);
      this.setState({ fetched: true });
    }
  }

  componentWillUnmount() {
    this.props.unsetCurrentItem();
    this.props.unsetCurrentCategoryId();
  }

  getContent = () => {
    let content = this.props.article.content;

    let SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
    while (SCRIPT_REGEX.test(content)) {
      content = content.replace(SCRIPT_REGEX, "");
    }
    $(".content .article").html(content);
  };

  getCategoryLink = (locale) => {
    let category = this.props.currentCategory;
    if (category) {
      return (
        <Link
          to={`/${locale}/articles/${makePATHUrl(category.slug, category.id)}`}
        >
          {category.name}
        </Link>
      );
    }
  };

  getMetaFields = () => {
    return (
      <Helmet>
        <title>{this.props.article.meta_title}</title>
        <meta
          name="description"
          content={this.props.article.meta_description}
        />
        <meta name="keywords" content={this.props.article.meta_keyword} />
      </Helmet>
    );
  };

  render() {
    this.getContent();
    let locale = language.getIso2();
    return (
      <div className="ArticlePage">
        {this.getMetaFields()}
        <Topbar
          title={
            this.props.article.name || strings.get("Client.homePage.title")
          }
          searchBox={true}
          // handleLangChange={this.props.handleLangChange}
          // currentLang={this.props.currentLang}
          divider={true}
          currentView="smallHeader"
          headerImage={this.props.article.bgImageURL}
        />
        <Breadcrumbs>
          <Link to={`/${locale}/`}>{strings.get("Client.homePage.home")}</Link>

          {_.isEmpty(this.props.subCategoriesList)
            ? null
            : this.props.subCategoriesList.map((subCategory, index) => [
                <Link
                  key={subCategory.id}
                  to={`/${locale}/articles/${makePATHUrl(
                    subCategory.slug,
                    subCategory.id
                  )}`}
                >
                  {subCategory.name}
                </Link>,
                index === 0 ? <span className="divider">/</span> : null,
                index === 0 ? (
                  <Link to={`/${locale}/articles`}>
                    {strings.get("Client.homePage.article.title")}
                  </Link>
                ) : null,
              ])}

          {_.isEmpty(this.props.subCategoriesList) ? (
            <Link to={`/${locale}/articles`}>
              {strings.get("Client.homePage.article.title")}
            </Link>
          ) : null}

          {this.getCategoryLink(locale)}

          <Link to={`/${locale}/articles/${this.props.article.slug}`}>
            {this.props.article.name}
          </Link>
        </Breadcrumbs>

        <div className="container-row">
          <div className="content">
            <div className="article col-sm-9" />
            <div className="ads col-sm-3 hidden-xs">
              <Ad />
            </div>
            <div className="ads col-xs-12">
              <AdInArticle />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function getSubCategoriesList(state, category) {
  if (_.isObject(category) && category.parent_id) {
    const subCat = categoriesSelectors.getItemById(state, category.parent_id);
    subCategoriesList.push(subCat);
    getSubCategoriesList(state, subCat);
  }
}

let subCategoriesList = [];
function mapStateToProps(state, ownProps) {
  const articles = state.articles.itemsById;
  if (articles) {
    let id;
    let articleCategoryId;
    _.map(articles, function (article) {
      if ((article.slug) === ownProps.match.params.articleSlug) {
        id = article.id;
        articleCategoryId = article.article_category_id;
      }
      if (id) {
        return false;
      }
    });
    subCategoriesList = [];
    let currentCategory = categoriesSelectors.getCurrentItem(state);
    getSubCategoriesList(state, currentCategory);
    return {
      articleId: id,
      articleCategoryId: articleCategoryId,
      article: articleSelectors.getItem(state),
      currentCategory: currentCategory,
      subCategoriesList: subCategoriesList.reverse(),
    };
  }
  return {
    article: articleSelectors.getItem(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchItem: (id) => {
      dispatch(articleActions.fetchItem(id));
    },
    fetchArticles: () => {
      dispatch(articleActions.fetchItems());
    },
    unsetCurrentItem: () => {
      dispatch(articleActions.unsetCurrentItem());
    },
    fetchAllCategories: () => {
      dispatch(categoriesActions.fetchAllItems());
    },
    setCurrentCategoryId: (id) => {
      dispatch(categoriesActions.setCurrentItemId(id));
    },
    unsetCurrentCategoryId: () => {
      dispatch(categoriesActions.unsetCurrentItemId());
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticlePage);
