import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Helmet } from "react-helmet";

import Topbar from "../../components/Topbar";
import Breadcrumbs from "../../components/Breadcrumbs";
import ArticleList from "../../components/article/ArticleList";
import SubCategoriesList from "../../components/articleCategories/SubCategoriesList";
import Footer from "../../components/footer/Footer";

import * as articleActions from "../../store/articles/actions";
import * as articleSelectors from "../../store/articles/selectors";
import * as categoriesActions from "../../store/articleCategories/actions";
import * as categoriesSelectors from "../../store/articleCategories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import { makePATHUrl } from "../../helpers";
import strings from "../../services/strings";
import language from "../../services/language";

class ArticleListPage extends Component {
  
  componentDidMount() {
    this.props.fetchAllCategories();
    this.props.fetchArticles();
    this.props.setCurrentCategoryId(this.props.match.params.artcatId);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.match.params.artcatId !== this.props.match.params.artcatId) {
      this.props.fetchAllCategories();
      this.props.fetchArticles();
      this.props.setCurrentCategoryId(newProps.match.params.artcatId);
    }
  }

  componentWillUnmount() {
    this.props.unsetCurrentCategoryId();
  }

  getCategoryLink = (locale) => {
    let category = this.props.currentCategory;
    if (category) {
      return (
        <Link
          to={`/${locale}/articles/${makePATHUrl(category.slug, category.id)}`}
        >
          {category.name}
        </Link>
      );
    }
  };

  handleMouseOver = (id) => {
    this.props.fetchArticle(id);
  };

  getMetaFields = () => {
    if (_.size(this.props.currentCategory) > 0) {
      return (
        <Helmet>
          <title>{this.props.currentCategory.meta_title}</title>
          <meta
            name="description"
            content={this.props.currentCategory.meta_description}
          />
          <meta
            name="keywords"
            content={this.props.currentCategory.meta_keyword}
          />
        </Helmet>
      );
    }
  };

  render() {
    let locale = language.getIso2();
    let meunItem = "";
    if (
      this.props.match.params &&
      this.props.match.params.artcatSlug &&
      this.props.match.params.artcatId
    ) {
      meunItem = makePATHUrl(
        this.props.match.params.artcatSlug,
        this.props.match.params.artcatId
      );
    }

    return (
      <div className="ArticleListPage">
        {this.getMetaFields()}
        <Topbar
          title={strings.get("Client.homePage.article.title")}
          subtitle={
            this.props.currentCategory ? this.props.currentCategory.name : null
          }
          description={
            this.props.currentCategory
              ? this.props.currentCategory.description
              : null
          }
          readMoreLink={
            this.props.currentCategory
              ? this.props.currentCategory.read_more_link
              : null
          }
          divider
          menuItem={meunItem}
        />

        <Breadcrumbs>
          <Link to={`/${locale}/`}>{strings.get("Client.homePage.home")}</Link>

          {_.isEmpty(this.props.subCategoriesList)
            ? this.getCategoryLink(locale)
            : this.props.subCategoriesList.map((subCategory, index) => [
                <Link
                  key={_.uniqueId(subCategory.id)}
                  to={`/${locale}/articles/${makePATHUrl(
                    subCategory.slug,
                    subCategory.id
                  )}`}
                >
                  {subCategory.name}
                </Link>,
                index === 0 ? <span className="divider">/</span> : null,
                index === 0 ? (
                  <Link to={`/${locale}/articles`}>
                    {strings.get("Client.homePage.article.title")}
                  </Link>
                ) : null,
              ])}

          {_.isEmpty(this.props.subCategoriesList) ? (
            <Link to={`/${locale}/articles`}>
              {strings.get("Client.homePage.article.title")}
            </Link>
          ) : null}

          {_.isEmpty(this.props.subCategoriesList)
            ? null
            : this.getCategoryLink(locale)}
        </Breadcrumbs>
        <div className="inner-page-cont">
          <SubCategoriesList
            items={this.props.subCategories}
            assignedUsers={
              this.props.currentCategory
                ? this.props.currentCategory.selectedUsers
                : null
            }
          />
          <ArticleList
            {...this.props}
            catName={
              this.props.match.params && this.props.match.params.catName
                ? this.props.match.params.catName
                : ""
            }
            items={this.props.articles}
            onMouseOver={this.handleMouseOver}
            description={
              this.props.currentCategory
                ? this.props.currentCategory.description
                : null
            }
          />
        </div>
        <Footer />
      </div>
    );
  }
}

function getSubCategoriesList(state, category) {
  if (_.isObject(category) && category.parent_id) {
    const subCat = categoriesSelectors.getItemById(state, category.parent_id);
    subCategoriesList.push(subCat);
    getSubCategoriesList(state, subCat);
  }
}

let subCategoriesList = [];
function mapStateToProps(state) {
  subCategoriesList = [];
  let currentCategory = categoriesSelectors.getCurrentItem(state);
  getSubCategoriesList(state, currentCategory);
  return {
    articles: articleSelectors.getItemsByCategory(
      state,
      currentCategory ? currentCategory.id : null
    ),
    subCategories: currentCategory ? currentCategory.children : null,
    currentCategory: currentCategory,
    subCategoriesList: subCategoriesList.reverse(),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchArticle: (id) => {
      dispatch(articleActions.fetchItem(id));
    },
    fetchArticles: () => {
      dispatch(articleActions.fetchItems());
    },
    fetchAllCategories: () => {
      dispatch(categoriesActions.fetchAllItems());
    },
    setCurrentCategoryId: (id) => {
      dispatch(categoriesActions.setCurrentItemId(id));
    },
    unsetCurrentCategoryId: () => {
      dispatch(categoriesActions.unsetCurrentItemId());
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleListPage);
