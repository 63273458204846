import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";

import CustomDropModal from "../PopUp";

import * as authActions from "../../store/auth/actions";
import * as categoriesActions from "../../store/categories/actions";
import * as categoriesSelectors from "../../store/categories/selectors";
import * as settingsSelectors from "../../store/settings/selectors";
import * as userActions from "../../store/user/actions";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import language from "../../services/language";
import strings from "../../services/strings";

import "./Footer.scss";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        price: "",
        title: "",
      },
    };
  }

  componentDidMount() {
    this.props.fetchArticleCategories();
    if (!_.size(this.props.contactSettings)) {
      this.props.fetchContactSettings();
    }

    // this.props.fetchFooterLogo();
    // setTimeout(() => this.props.fetchArticleCategories(), 500);
  }

  getArticles = (article) => {
    let locale = language.getIso2();
    return _.map(article, (item) => {
      if (item.url) {
        return (
          <li key={`item-${item.id}`} className="infoItem">
            <a href={item.url} target="_blank">
              {item.name}
            </a>
          </li>
        );
      } else {
        if (item.content_type) {
          return (
            <li
              id={`infoItem${item.id}`}
              key={`item-${item.id}`}
              className="infoItem"
            >
              <a href={`/${locale}/article/${item.slug}`}>{item.name}</a>
            </li>
          );
        } else {
          return (
            <li key={`item-${item.id}`} className="infoItem">
              <a href={`/${locale}/article/${item.slug}`}>{item.name}</a>
            </li>
          );
        }
      }
    });
  };

  getContacts = (articles) => {
    return _.map(articles, (item) => {
      return (
        <li key={`item-${item.id}`} className="contactItem">
          <span>{`${strings.get(
            "Client.homePage.footer.contactUs.phone"
          )}:`}</span>
          <span>+880 1723801729</span>
        </li>
      );
    });
  };

  getArticleCategories = () => {
    if (this.props.items) {
      return _.map(this.props.items, (item) => {
        if (item.name !== "Contact Us") {
          return (
            <div
              key={`item-${item.id}`}
              className="col-lg-3 col-md-3 col-sm-6 infoContainer"
            >
              <h3>{item.name}</h3>
              <ul className="information">
                {this.getArticles(item.visible_article)}
              </ul>
            </div>
          );
        } else {
          return (
            <div
              key={`item-${item.id}`}
              className="col-lg-3 col-md-3 col-sm-6 contactContainer"
            >
              <h3>{item.name}</h3>
              <ul className="contactUs">
                {this.getContacts(item.visible_article)}
              </ul>
            </div>
          );
        }
      });
    } else {
      return null;
    }
  };

  getContactContent = (item) => {
    if (item.key === "contact_phone") {
      return (
        <p key={`item-${item.key}`}>
          <span style={{ color: "white" }}>
            <em className="ion-ios-telephone">&nbsp;</em>
            {item.value}
          </span>
        </p>
      );
    } else if (item.key === "contact_fax") {
      return (
        <p key={`item-${item.key}`}>
          <span style={{ color: "white" }}>
            <em className="ion-ios-printer">&nbsp;</em>
            {item.value}
          </span>
        </p>
      );
    } else if (item.key === "contact_email") {
      return (
        <p key={`item-${item.key}`}>
          <span style={{ color: "white" }}>
            <em className="ion-ios-email">&nbsp;</em>
            {item.value}
          </span>
        </p>
      );
    } else if (item.key === "contact_address") {
      return (
        <p key={`item-${item.key}`}>
          <span style={{ color: "white" }}>
            <em className="ion-ios-location">&nbsp;</em>
            {item.value}
          </span>
        </p>
      );
    }
  };

  getContactSettings = () => {
    if (this.props.contactSettings) {
      return _.map(this.props.contactSettings, (item) => {
        return (
          <li
            key={`infoItem-${item.id}`}
            id={`infoItem${item.id}`}
            className="infoItem"
          >
            {this.getContactContent(item)}
          </li>
        );
      });
    } else {
      return null;
    }
  };

  // getSiteName = () => {
  //   let locale = JSON.parse(localStorage.getItem("language")).iso2;
  //   // console.log('this.props.contactSettings', this.props.contactSettings)
  //   return strings.getTitle(locale ? locale : 'NL').then(title => this.setState({title}))
  // };

  // getFooterLogo = () => <img src={this.props.footerLogo} />;

  handleDonateClick = () => {
    this.refs.donateModal.show();
  };

  handleInputChange = (e) => {
    let item = {};
    item[e.target.name] = e.target.value;
    this.setState({
      form: _.extend(this.state.form, item),
    });
  };

  handleModalHide = () => {
    this.props.clearExceptions();
    this.setState({
      form: {
        first_name: "",
        last_name: "",
        email: "",
        price: "",
      },
    });
  };

  handleSubmitClick = () => {
    this.props.donate(this.state.form);
  };

  handleCancelClick = () => {
    this.refs.donateModal.hide();
  };

  hasError = (inputName) => {
    return !!this.props.exceptions[inputName];
  };

  getErrorClass = (inputName, defaultClasses = "") => {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  };

  getErrorMessage = (inputName) => {
    return this.props.exceptions[inputName];
  };

  getDonateModal = (siteName = "", siteURL = "") => {
    let fnameLabel = this.hasError("first_name")
      ? `${strings.get("Client.registerPage.firstName")} ${this.getErrorMessage(
          "first_name"
        )}`
      : strings.get("Client.registerPage.firstName");
    let lnameLabel = this.hasError("last_name")
      ? `${strings.get("Client.registerPage.lastName")} ${this.getErrorMessage(
          "last_name"
        )}`
      : strings.get("Client.registerPage.lastName");
    let emailLabel = this.hasError("email")
      ? `${strings.get("Client.registerPage.email")} ${this.getErrorMessage(
          "email"
        )}`
      : strings.get("Client.registerPage.email");
    let priceLabel = this.hasError("price")
      ? `${strings.get("Client.donationModal.price")} ${this.getErrorMessage(
          "price"
        )}`
      : `${strings.get("Client.donationModal.price")}`;

    return (
      <CustomDropModal
        className="boron-modal form-modal"
        ref="donateModal"
        onHide={this.handleModalHide}
      >
        {/* <Modal
        className="boron-modal form-modal"
        ref="donateModal"
        onHide={this.handleModalHide}
      > */}
        <h2>
          {strings.get("Client.donationModal.title")} &nbsp;
          {/* <a href={this.props.siteURL} target="_blank">
            {this.props.siteName}
          </a> */}
          <a href={siteURL} target="_blank">
            {siteName}
          </a>
        </h2>
        <div className={this.getErrorClass("first_name", "form-group")}>
          <label className="control-label">{fnameLabel}</label>
          <input
            className="form-control"
            type="text"
            name="first_name"
            placeholder={strings.get("Client.registerPage.firstName")}
            value={this.state.form.first_name}
            onChange={this.handleInputChange}
          />
        </div>
        <div className={this.getErrorClass("last_name", "form-group")}>
          <label className="control-label">{lnameLabel}</label>
          <input
            className="form-control"
            type="text"
            name="last_name"
            placeholder={strings.get("Client.registerPage.lastName")}
            value={this.state.form.last_name}
            onChange={this.handleInputChange}
          />
        </div>
        <div className={this.getErrorClass("email", "form-group")}>
          <label className="control-label">{emailLabel}</label>
          <input
            className="form-control"
            type="text"
            name="email"
            placeholder={strings.get("Client.registerPage.email")}
            value={this.state.form.email}
            onChange={this.handleInputChange}
          />
        </div>
        <div className={this.getErrorClass("price", "form-group")}>
          <label className="control-label">{priceLabel}</label>
          <input
            className="form-control"
            type="text"
            name="price"
            placeholder={strings.get("Client.donationModal.pricePlaceholder")}
            value={this.state.form.price}
            onChange={this.handleInputChange}
          />
        </div>
        <div className="form-actions">
          <button
            className="btn btn-secondary"
            onClick={this.handleSubmitClick}
          >
            {strings.get("Client.donationModal.submit")}
          </button>
          <button className="btn btn-default" onClick={this.handleCancelClick}>
            {strings.get("Client.registerPage.cancel")}
          </button>
        </div>
        {/* </Modal> */}
      </CustomDropModal>
    );
  };

  render() {
    // let locale = JSON.parse(localStorage.getItem("language")).iso2;
    let locale = language.getIso2();
    const { siteSettings } = this.props;
    let siteName = "";
    let siteURL = "";
    _.map(siteSettings, (item) => {
      if (item.key === "client_site_name") {
        siteName = item.value;
      }
      if (item.key === "client_site_url") {
        siteURL = item.value;
      }
    });
    let footerLogo, siteSettingsByKey={};

    if (siteSettings) {
      if (siteSettings.length) {
        siteSettings.map((image, i) => {
          if (image.key === "footer_logo") {
            footerLogo = image.imageURL;
          }
        });
        _.map(siteSettings,({key,value})=>{
          siteSettingsByKey= {...siteSettingsByKey, [key]:value}
        })
      }
    }
    return (
      <div className="footer">
        {this.getDonateModal(siteName, siteURL)}
        <div className="container-row">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 footerMenuContainer">
              <h3>{siteName}</h3>
              <ul className="footerMenu">
                <li key={"footerMenu-0"} className="infoItem">
                  <Link to={`/${locale}/`}>
                    {strings.get("Client.homePage.home")}
                  </Link>
                </li>
                <li key={"footerMenu-1"} className="infoItem">
                  <Link to={`/${locale}/`}>
                    {strings.get("Client.homePage.title")}
                  </Link>
                </li>
                <li key={"footerMenu-2"} className="infoItem">
                  <Link to={`/${locale}/`}>
                    {strings.get("Client.homePage.news.title")}
                  </Link>
                </li>
                <li key={"footerMenu-3"} className="infoItem">
                  {/* <Link to={`/${locale}/`}> */}
                  <a onClick={this.props.logout}>
                    {strings.get("Client.homePage.logout")}
                  </a>
                  {/* </Link> */}
                </li>
              </ul>
            </div>
            {this.getArticleCategories()}

            <div className="col-lg-3 col-md-3 col-sm-6 infoContainer">
              <h3>{strings.get("Client.homePage.headerMenu.contact")}</h3>
              <ul className="information">{this.getContactSettings()}</ul>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 text-center socialContainer">
              <ul className="socialIcons text-center">
                <a className="socialIcon" href={`${siteSettingsByKey.facebook_link}`} target="_blank">
                  <li className="socialIcon facebook">                  
                      <i className="ion-social-facebook" />
                  </li>
                </a>
                <a className="socialIcon " href={`${siteSettingsByKey.instagram_link}`} target="_blank">
                  <li className="socialIcon instagram">
                      <i className="ion-social-instagram" />
                  </li>
                </a>
                <a className="socialIcon" href={`${siteSettingsByKey.linkedin_link}`} target="_blank" >
                  <li className="socialIcon linkedIn">
                      <i className="ion-social-linkedin" />
                  </li>
                </a>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="donate-button">
              <a
                className="btn btn-donate"
                onClick={this.handleDonateClick}
                href="#"
              >
                {strings.get("Client.homePage.footer.donate")}
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 text-center">
            <div className="copyright-box">
              <p className="copyright">
                &#9400; {new Date().getFullYear()}{" "}
                <span className="blue">{siteName}</span>{" "}
                {strings.get("Client.homePage.footer.copyright")}
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 text-center">
            <div className="logo">
              {/*<span>Juri</span>
                            <span>docs</span>*/}
              <img src={footerLogo} alt="logo" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    items: categoriesSelectors.getArticleCategories(state),
    contactSettings: categoriesSelectors.getContactSettings(state),
    siteSettings: settingsSelectors.getSiteSettings(state),
    // siteURL: settingsSelectors.getSiteURL(state),
    // siteName: settingsSelectors.getSiteName(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchArticleCategories: () => {
      dispatch(categoriesActions.fetchArticleCategories());
    },
    fetchContactSettings: () => {
      dispatch(categoriesActions.fetchContactSettings());
    },
    // fetchFooterLogo: () => {
    //   dispatch(settingsActions.fetchFooterLogo());
    // },
    donate: (data) => {
      dispatch(userActions.donation(data));
    },
    logout: () => {
      dispatch(authActions.logout());
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
