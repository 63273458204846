import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  RedditIcon,
  EmailIcon,
  WhatsappIcon,
} from "react-share";

import { Form, Field } from "react-final-form";
import CustomDropModal from "../PopUp";

import strings from "../../services/strings";

import "./SocialShareButton.scss";

class SocialShareDraftButton extends PureComponent {
  emails = [];

  toggleSocials = () => {
    this.props.callbackFromList(this.props.isOpen, this.props.item);
  };

  toggleModal = (value) => {
    const ref = this.refs.helperTextModal;
    if (ref) value ? ref.show() : ref.hide();
  };

  validAdded = (value) => {
    return this.emails.indexOf(value) > -1;
  };

  addEmail = (e, email, meta) => {
    e.preventDefault();
    this.emails.push(email.value);
  };

  removeEmail = (index) => {
    this.emails.splice(index, 1);
  };

  render() {
    const showSocials = this.props.isOpen;
    return (
      <div className={"socialShareBlock"}>
        <a onClick={this.toggleSocials} className="share cate-share">
          <i className="fas fa-share-square"></i>
        </a>
        {showSocials && this.renderDropdown()}
        {this.renderModal()}
      </div>
    );
  }

  renderDropdown=() =>{
    let url = this.props.url;

    let queries = "";
    if (url.indexOf("?") > -1) {
      queries = url.substring(url.indexOf("?"));
      url = url.substring(0, url.indexOf("?"));
    }

    let urlSuffix = this.props.urlSuffix;
    if (urlSuffix !== "default") {
      if (url.lastIndexOf("/me") > -1) {
        url = url.replace("/me", `/${urlSuffix}`);
      } else if (url.lastIndexOf("/client") > -1) {
        url = url.replace("/client", `/${urlSuffix}`);
      } else {
        url = url.concat(`/${urlSuffix}`);
      }
    }
    url = url.concat(queries);

    return (
      <div className={"socialDropdown"}>
        <ul>
          <li>
            <FacebookShareButton
              children={<FacebookIcon size={32} />}
              url={this.props.urlSuffix !== "default" ? url : this.props.url}
              quote={this.props.message}
            />
          </li>
          <li>
            <TwitterShareButton
              children={<TwitterIcon size={32} />}
              url={this.props.urlSuffix !== "default" ? url : this.props.url}
              title={this.props.message}
            />
          </li>
          <li>
            <TelegramShareButton
              children={<TelegramIcon size={32} />}
              url={this.props.urlSuffix !== "default" ? url : this.props.url}
              title={this.props.message}
            />
          </li>
          <li>
            <WhatsappShareButton
              children={<WhatsappIcon size={32} />}
              url={this.props.urlSuffix !== "default" ? url : this.props.url}
              title={this.props.message}
            />
          </li>
          <li>
            <RedditShareButton
              children={<RedditIcon size={32} />}
              url={this.props.urlSuffix !== "default" ? url : this.props.url}
              title={this.props.message}
            />
          </li>
          <li>
            <div
              role={"button"}
              type={"link"}
              onClick={() => this.toggleModal(true)}
            >
              <EmailIcon size={32} />
            </div>
          </li>
        </ul>
      </div>
    );
  }

  onSubmit = (values) => {
    let { content, email } = values;
    let currentLang = strings.getLanguage();
    let url = this.props.url;
    let queries = "";
    if (url.indexOf("?") > -1) {
      queries = url.substring(url.indexOf("?"));
      url = url.substring(0, url.indexOf("?"));
    }
    let urlSuffix = this.props.urlSuffix;
    if (urlSuffix !== "default") {
      if (url.lastIndexOf("/me") > -1) {
        url = url.replace("/me", `/${urlSuffix}`);
      } else if (url.lastIndexOf("/client") > -1) {
        url = url.replace("/me", `/${urlSuffix}`);
      } else {
        url = url.concat(`/${urlSuffix}`);
      }
    }
    url = url.concat(queries);
    // const {url, currentUser} = this.props;
    // const url = this.props.urlSuffix !== "default" ? `${this.props.url}/${this.props.urlSuffix}` : this.props.url;
    content = content.concat(this.props.message);
    content = content.replace(
      "**link placeholder**",
      `<a href="${url}"> Document</a>`
    );
    const body = {
      to: email ? [email] : this.emails,
      link: url,
      body: content,
      currentLang: currentLang.id,
    };
    this.props.sendEmails(body);
    this.toggleModal(false);
  };

  renderModal=() =>{
    // const { url } = this.props;
    const required = (value) => (value ? undefined : "Required");
    // const notRequired = (value) =>
    //   this.emails.length > 0 ? undefined : "Required";
    const regexForEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const mustBeEmail = (value) =>
      regexForEmail.test(value.toLowerCase())
        ? undefined
        : strings.get("Client.contactForm.emailInvalid");
    // const mustHaveLink = (value) =>
    //   value.includes("**link placeholder**")
    //     ? undefined
    //     : strings.get("Client.contactForm.linkPlaceholderRequired");
    const composeValidators = (...validators) => (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );
    const styleForError = { color: "red" };
    // const defaultText = `will be send to **link placeholder** draft`;
    const defaultText = "";

    return (
      <CustomDropModal
        className="boron-modal form-modal infoModal"
        ref={"helperTextModal"}
        onHide={this.toggleModal(false)}
      >
        <h4>{strings.get("Client.contactForm.checkboxes.shareEmail")}</h4>
        <Form
          initialValues={{ content: defaultText }}
          onSubmit={this.onSubmit}
          mutators={{
            cleanInput: (args, state, utils) => {
              utils.changeValue(state, "email", () => "");
            },
          }}
          render={({
            mutators,
            handleSubmit,
            reset,
            submitting,
            pristine,
            values,
            valid,
            focus,
            blur,
          }) => {
            const { emails } = this;

            return (
              <form onSubmit={handleSubmit}>
                {emails.length > 0 && (
                  <Field name="email" required={false}>
                    {({ input, meta, render }) => (
                      <div className="form-group">
                        {/* <label>{strings.get('Client.contactForm.checkboxes.callBack')}</label> */}
                        <input
                          name="email"
                          className="form-control emails-input"
                          {...input}
                          type="email"
                          placeholder={strings.get("Client.contactForm.email")}
                        />
                        <button
                          disabled={
                            !!mustBeEmail(input.value.toLowerCase()) ||
                            this.validAdded(input.value)
                          }
                          className="add-email btn btn-secondary"
                          onClick={(e, args, state, utils) => {
                            mutators.cleanInput(e, args, state, utils);
                            this.addEmail(e, input, meta);
                          }}
                        >
                          + Add
                        </button>
                        {this.validAdded(input.value) && (
                          <span style={styleForError}>Already Exist</span>
                        )}
                        {meta.error && meta.touched && (
                          <span style={styleForError}>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                )}
                {emails.length === 0 && (
                  <Field
                    name="email"
                    validate={composeValidators(required, mustBeEmail)}
                  >
                    {({ input, meta }) => (
                      <div className="form-group">
                        {/* <label>{strings.get('Client.contactForm.checkboxes.callBack')}</label> */}
                        <input
                          name="email"
                          className="form-control emails-input"
                          {...input}
                          type="email"
                          placeholder={strings.get("Client.contactForm.email")}
                        />
                        <button
                          disabled={
                            !!mustBeEmail(input.value.toLowerCase()) ||
                            this.validAdded(input.value)
                          }
                          className="add-email btn btn-secondary"
                          onClick={(e, args, state, utils) => {
                            mutators.cleanInput(e, args, state, utils);
                            this.addEmail(e, input, meta);
                          }}
                        >
                          + {strings.get("Client.contactForm.add")}
                        </button>
                        {meta.error && meta.touched && (
                          <span style={styleForError}>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                )}
                <div className="valid-emails form-group">
                  {emails.map((e, index) => (
                    <span
                      className="email-tag"
                      onClick={(_) => {
                        this.removeEmail(index);
                        blur("email");
                        focus("email");
                      }}
                      key={index}
                    >
                      {e}
                    </span>
                  ))}
                </div>
                <button
                  type="submit"
                  disabled={!valid || pristine}
                  className="btn btn-primary form-actions"
                >
                  {strings.get("Client.contactForm.btnSubmit")}
                </button>
              </form>
            );
          }}
        />
      </CustomDropModal>
    );
  }
}

SocialShareDraftButton.propTypes = {
  url: PropTypes.string,
};

export default SocialShareDraftButton;
