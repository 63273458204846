import React, { Component } from "react";
import _ from "lodash";
import LazyLoad from "react-lazyload";

import strings from "../../services/strings";

import "./PartnerList.scss";

class PartnerList extends Component {
  handleLeftArrowClick = (e) => {
    let arr = document.getElementsByClassName("partner");
    if (arr.length <= 4) return;

    let visibles = document.getElementsByClassName("partner visible");
    visibles = _.map(visibles, (item) => {
      return item;
    });
    for (let i = 0; i < arr.length; i++) {
      if (visibles.indexOf(arr[i]) >= 0) {
        let n = i;
        n++;
        if (n > arr.length - 1) return;

        arr[n].classList.add("visible");
      }
    }
    visibles[0].classList.remove("visible");
  };

  handleRightArrowClick = (e) => {
    let arr = document.getElementsByClassName("partner");
    if (arr.length <= 4) return;

    let visibles = document.getElementsByClassName("partner visible");
    visibles = _.map(visibles, (item) => {
      return item;
    });
    for (let i = 0; i < arr.length; i++) {
      if (visibles.indexOf(arr[i]) >= 0) {
        let n = i;
        n--;
        if (n < 0) return;

        arr[n].classList.add("visible");
      }
    }
    visibles[3].classList.remove("visible");
  };

  getItems = () => {
    let visiblesCount = 0;
    return _.map(this.props.items, (item, i) => {
      let className = "partner col-md-3 col-sm-3 col-xs-6";
      if (item.visible) {
        if (visiblesCount < 4) {
          className = "partner col-md-3 col-sm-3 col-xs-6 visible";
        }
        visiblesCount++;
      } else {
        className = "partner col-md-3 col-sm-3 col-xs-6";
      }
      return (
        <LazyLoad height={50} offset={100} key={_.uniqueId()}>
          <div className={className} key={`item-${i}`}>
            <a href={item.url} target="_blank">
              <img src={item.imageURL} alt={item.name} />
            </a>
          </div>
        </LazyLoad>
      );
    });
  };

  render() {
    return (
      <div className="partnerList">
        <div className="container-row">
          <div className="row">
            <h2>{strings.get("Client.homePage.ourPartners")}</h2>
            <div className="arrows">
              <div
                className="arrow ion-ios-arrow-left"
                onClick={this.handleLeftArrowClick}
              ></div>
              <div
                className="arrow ion-ios-arrow-right"
                onClick={this.handleRightArrowClick}
              ></div>
            </div>
            {this.getItems()}
          </div>
        </div>
      </div>
    );
  }
}

export default PartnerList;
