import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import Editor from "../../Editor";

import { browserHistory } from "../../../helpers";
import strings from "../../../services/strings";

import "./ShareEmailSettingForm.scss";
class ShareEmailSettingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentItemLoaded: false,
      form: {
        share_email_settings: {
          subject: "",
          message: "",
        },
      },
    };
  }

  componentDidMount() {
    this.tryLoadCurrentItem();
  }

  componentDidUpdate() {
    this.tryLoadCurrentItem();
  }

  tryLoadCurrentItem = () => {
    if (
      this.props.currentItem &&
      !_.isEmpty(this.props.currentItem) &&
      !this.state.currentItemLoaded
    ) {
      let form = _.extend({}, this.state.form);

      if (this.props.currentItem.settings) {
        let settingObject = JSON.parse(this.props.currentItem.settings);
        form = _.merge(form, settingObject);
      }

      this.setState({
        currentItemLoaded: true,
        form,
      });
    }
  };

  hasError = (inputName) => {
    return !!this.props.exceptions[inputName];
  };

  getErrorClass = (inputName, defaultClasses = "") => {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  };

  getErrorMessage = (inputName) => {
    return this.props.exceptions[inputName];
  };

  handleInputChange = (e) => {
    let form = _.set(this.state.form, e.target.name, e.target.value);
    this.setState({
      form: _.extend(this.state.form, form),
    });
  };

  handleMessageChange = (content, editor) => {
    let e = {
      target: {
        value: "",
        name: "",
      },
    };
    e.target.value = content;
    e.target.name = "share_email_settings.message";
    this.handleInputChange(e);
  };

  handleSaveClick = (e) => {
    e.preventDefault();
    this.props.saveItem(this.state);
    let { form } = this.state;
    if (form.share_email_settings.message === "") {
      form.share_email_settings.message =
        form.default_settings.share_email_message;
    }
    if (form.share_email_settings.subject === "") {
      form.share_email_settings.subject =
        form.default_settings.share_email_subject;
    }
    this.setState({ form });
  };

  handleDefaultClick = (e) => {
    e.preventDefault();
    let { form } = this.state;
    form.share_email_settings.message =
      form.default_settings.share_email_message;
    form.share_email_settings.subject =
      form.default_settings.share_email_subject;
    this.setState({
      form,
    });
  };

  render() {
    let shareEmailSettings = this.state.form.share_email_settings;

    return (
      <div className="col-xs-12 col-sm-9 col-md-9 ShareEmailSettingForm">
        <div className="panel panel-default">
          <div className="panel-heading">
            <h4>{strings.get("Client.settings.messages.shareEmail.title")}</h4>
          </div>
          <div className="panel-body">
            <form>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <label className="control-label">
                      {strings.get(
                        "Client.settings.messages.shareEmail.subject"
                      )}
                    </label>
                    <input
                      onChange={this.handleInputChange}
                      className="form-control"
                      name="share_email_settings.subject"
                      value={shareEmailSettings.subject}
                    ></input>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group tiny-mce">
                    <label className="control-label">
                      {strings.get(
                        "Client.settings.messages.shareEmail.message"
                      )}
                    </label>
                    <Editor
                      content={shareEmailSettings.message}
                      handleChange={this.handleMessageChange}
                      height={250}
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-actions">
                    <button
                      className="btn btn-primary"
                      onClick={this.handleSaveClick}
                    >
                      {strings.get("Client.profilePage.save")}
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={this.handleDefaultClick}
                      style={{ marginRight: "5px" }}
                    >
                      {strings.get("Client.profilePage.default")}
                    </button>
                    <a
                      href="#"
                      onClick={browserHistory.goBack}
                      title={strings.get(
                        "Client.profilePage.previousPageTitle"
                      )}
                    >
                      {strings.get("Client.profilePage.previousPage")}
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

ShareEmailSettingForm.propTypes = {
  currentItem: PropTypes.object,
  saveItem: PropTypes.func.isRequired,
};

export default ShareEmailSettingForm;
