import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import Switch from "../../Switch.js";
import CustomDropModal from "../../PopUp";

import strings from "../../../services/strings";

import "./RequiredOptionsList.scss";

class RequiredOptionsList extends Component {
  handleChange = (enabled) => {
    this.props.updateItem({
      required_fields: enabled ? 1 : 0,
    });
  };

  confirmStepVerification = () =>{
    this.props.updateItem({
      otp_verification: 0,
    });
    this.refs.stepVerificationModal.hide()
  }

  cancelStepVerification=() => {
    this.refs.stepVerificationModal.hide()
  }

  handleStepChange = (e) =>{
    if(!e){
      this.refs.stepVerificationModal.show()
    }
    else{
      this.props.updateItem({
        otp_verification: 1,
      });
    }
  }

  render() {
    let stepVerificationModalConfirm = (
      <CustomDropModal className="boron-modal" ref="stepVerificationModal">
        <div className="close-btn">
        <span
            className="ion-android-cancel clickable"
            onClick={this.cancelStepVerification}
          />
        </div>
        <div className="jd-modal">
        <label className="control-label"> 
          {strings.get("Client.settings.otpMessage")}
        </label>

          <button className="btn btn2" type="button" onClick={this.confirmStepVerification}>
            {strings.get("App.workflows.workflowForm.orderFieldsModal.save")}
          </button>
          <button className="btn btn3" type="button" onClick={this.cancelStepVerification}>
            {strings.get("App.workflows.workflowForm.orderFieldsModal.cancel")}
          </button>
        </div>
      </CustomDropModal>
    );
    
    let items = _.map(this.props.items, (value) => {
      return (
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 "> 
        <li className="list-group-item clearfix">
          {strings.get("Client.settings.requiredOptions")}
          <span className="ml-auto">
            <Switch
              docsize=""
              enabled={!!value.required_fields}
              onChange={(enabled) => this.handleChange(enabled)}
            />
          </span>
        </li>
        </div>
          <div className="col-xs-12 col-sm-12 col-md-6 ">
            <li className="list-group-item clearfix">
              {strings.get("Client.settings.otpVerification")}
              <span className="ml-auto">
                <Switch
                  docsize=""
                  enabled={!!value.otp_verification}
                  onChange={(enabled) => this.handleStepChange(enabled)}
                />
              </span>
            </li>
          </div>
        </div>
      );
    });

    return (
      <div className="RequiredOptionsList">
        <ul className="list-group">{items}</ul>
        {stepVerificationModalConfirm}
      </div>
    );
  }
}

RequiredOptionsList.propTypes = {
  items: PropTypes.object.isRequired,
  updateItem: PropTypes.func.isRequired,
  setCurrentItemId: PropTypes.func.isRequired,
  unsetCurrentItemId: PropTypes.func.isRequired,
};

export default RequiredOptionsList;
