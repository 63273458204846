import api from "../../services/api";
import language from "../../services/language";

import * as exceptionsActions from "../exceptions/actions";

export const types = {
  FETCH_ITEMS_DONE: "article.FETCH_ITEMS_DONE",
  FETCH_ITEM_DONE: "article.FETCH_ITEM_DONE",
  SET_CURRENT_ITEM_ID: "article.SET_CURRENT_ITEM_ID",
  CLEAR_CACHE: "article.CLEAR_CACHE",
  FETCH_ARTICLE_CATEGORIES_DONE: "article.FETCH_ARTICLE_CATEGORIES_DONE",
};

export function setCurrentItemId(id) {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id,
    },
  };
}

export function unsetCurrentItemId() {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id: null,
    },
  };
}

export function unsetCurrentItem() {
  return {
    type: types.CLEAR_CACHE,
  };
}

export function clearCache() {
  return {
    type: types.CLEAR_CACHE,
  };
}

export function fetchItems(deleteCache = false) {
  return async (dispatch) => {
    try {
      // Set additional params
      let params = new Map();
      // let filters = documentsSelectors.getFilters(state);
      // let sorter = documentsSelectors.getSorter(state);
      params.set("language_id", language.get());
      params.set("visible", "1");
      // params.set('expand', 'downloads');
      // params.set('name~', filters.searchTerm);
      // params.set('category_id', filters.categoryId ? filters.categoryId : '');
      // params.set('sort_by', sorter.column);
      // params.set('sort_desc', sorter.descending);

      // GET request from API
      let items = await api.get("/articles", params);

      // Clear cache if deleteCache is enabled
      if (deleteCache) {
        dispatch(clearCache());
      }

      dispatch({
        type: types.FETCH_ITEMS_DONE,
        payload: { items },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchItem(id) {
  return async (dispatch) => {
    try {
      // GET request from API
      let item = await api.get(`/articles/${id}`);
      dispatch({
        type: types.FETCH_ITEM_DONE,
        payload: {
          item,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}
