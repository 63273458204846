import React, { Component } from "react";
import LazyLoad from "react-lazyload";

import { Link } from "react-router-dom";
import _ from "lodash";

import language from "../../services/language";
import strings from "../../services/strings";
import config from "../../config";

import "./NewsList.scss";

class NewsList extends Component {
  getPreviewImage = (item) => {
    if (item.imageURL) {
      return (
        <img className="newsImage" src={item.imageURL} alt="previewImage" />
      );
    } else {
      return (
        <img
          className="newsImage"
          src={config.API_ENDPOINT + "/images/News.jpg"}
          alt="previewImage"
        />
      );
    }
  };

  getItems = () => {
    let locale = language.getIso2();
    let className = "row";
    return _.map(this.props.items, (item, i) => {
      className = i === 0 ? "row active" : "row";
      return (
        <LazyLoad height={50} offset={100} key={_.uniqueId()}>
          <div className={className} id={`container-${i}`} key={`item-${i}`}>
            <div className="col-md-5 leftContainer">
              {this.getPreviewImage(item)}
            </div>
            <div className="col-md-7 rightContainer">
              <p className="newsTitle">{item.name}</p>
              <p className="newsDate">
                {strings.get("Client.homePage.news.created")}:{" "}
                <span className="date">{item.created_at}</span>{" "}
                <span className="divider">|</span>{" "}
                {strings.get("Client.homePage.news.updated")}:{" "}
                <span className="date">{item.updated_at}</span>
              </p>
              <hr />
              <div
                className="newsContent"
                dangerouslySetInnerHTML={{ __html: item.preview_content }}
              />

              <Link to={`/${locale}/article/${item.slug}`}>
                {strings.get("Client.homePage.readMore")}
              </Link>
            </div>
          </div>
        </LazyLoad>
      );
    });
  };

  getBullets = () => {
    let className = "bullet";
    return _.map(this.props.items, (item, i) => {
      className = i === 0 ? "bullet active" : "bullet";
      return (
        <li
          id={`bullet-${i}`}
          className={className}
          key={`item-${i}`}
          onClick={this.handleBulletClick}
        />
      );
    });
  };

  handleBulletClick = (e) => {
    let prevBullet = document.getElementsByClassName("bullet active")[0];
    let prevId = prevBullet.id.split("bullet-")[1];
    let prevContainer = document.getElementById("container-" + prevId);
    let nextBullet = e.target;
    let nextId = e.target.id.split("bullet-")[1];
    let nextContainer = document.getElementById("container-" + nextId);
    prevContainer.classList.remove("active");
    nextContainer.classList.add("active");
    prevBullet.classList.remove("active");
    nextBullet.classList.add("active");
  };

  render() {
    return (
      <div className="newsList">
        <div className="container-row">
          <div className="col-xs-12">
            <h2>{strings.get("Client.homePage.news.title")}</h2>
            {this.getItems()}
            <ul className="bullets">{this.getBullets()}</ul>
          </div>
        </div>
      </div>
    );
  }
}

export default NewsList;
