
import React, { Component } from 'react'
// import autoBind from "react-autobind";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NotificationManager } from "react-notifications";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  } from "react-sortable-hoc";
import _ from "lodash";


import { CustomFadeModal } from "./PopUp";

import strings from "../services/strings";
import * as settingActions from "../store/settings/actions";
import './SortableActiveUrlLabel.scss'

const SortableDragger = SortableHandle(() => {
    return <i className="ion-navicon-round" />;
  });
  
  const SortableItem = SortableElement(({ value, index }) => {
    let fileFullName = "";
    let url = null;
    let renameAccess = false; //user can only rename a file if file already uploaded on server.
    debugger
    if(value.type === "local"){
      fileFullName = value.file.name
    }
    else {
      if (value.url.lastIndexOf("/") > 1) {
        console.log('name', value.url);
        renameAccess = true;
        let fileNameIndex = value.url.lastIndexOf("/");
        let fileExtensionIndex = value.url.lastIndexOf(".");
        let fileName = value.url.substring(
          fileNameIndex + 1,
          fileExtensionIndex
        );
        let fileExtension = value.url.substr(fileExtensionIndex);
        fileFullName = fileName.concat(fileExtension);
        url = value.url;
      } else {
        fileFullName = value.url;
      }
    }
    return (
      <tr className="ActiveLabel" onClick={value.handleItemClick}>
        <td style={{width:"10%", textAlign: "center"}}>
          {value.type !=="local" && <SortableDragger />}
        </td>
        <td style={{width:"65%"}}>
          <a href={url} target="_blank" style={{ color: "white"}}>
            {fileFullName}
          </a>
        </td >
        <td className="icon-container" style={{width:"25%"}}>
          <i
            className="label-close ion-close"
            onClick={(e)=>value.handleRemoveClick(e, value, value.index)}
          />
          <i
            className="label-close ion-edit"
            onClick={(e) => {value.handleRenameClick(e, renameAccess); value.changeUrl(value.url)}}
          />
        </td>
      </tr>
    );
  });
  
  const SortableList = SortableContainer(({ items }) => {
    let rows = _.map(items, (value, index) => {
      return <SortableItem key={`item-${index}`} index={index} value={value} />;
    });
  
    return <tbody>{rows}</tbody>;
  });

  class SortableActiveUrlLabel extends Component { 
      url= null;
      constructor(props) {
          super(props);
        this.state = {
            loading: false,
            fileName: "",
            error: ""
        }
    }


    handleItemClick = (index) => {
      if (this.props.onClick) {
        this.props.onClick(index);
      }
    };

  handleSortEnd = ({oldIndex, newIndex}) => {
    this.props.orderChange(newIndex, oldIndex)
  }

  handleRemoveClick = (e,item, index) => {
    e.stopPropagation();
    if (!this.props.checkShareFieldsExistsForUploadedFiles(item)) {
      this.props.handleCategoryRemoveServer(index);
    } else {
      NotificationManager.warning(
        "",
        strings.get("Client.notifications.accessDenied")
      );
    }
  };


  handleRenameClick = (event, renameAccess) => {
    event.stopPropagation();
    if (renameAccess) {
      if (
        typeof this.props.accessDenied !== "undefined" &&
        this.props.accessDenied
      ) {
        //if draft is shared and field access not granted
        NotificationManager.warning(
          "",
          strings.get("Client.notifications.accessDenied")
        );
      } else {
        this.refs.attachmentRenameModal.show();
      }
    } else {
      NotificationManager.info(
        strings.get("Client.notifications.attachmentRenameError")
      );
    }
  };

  attachmentRenameModalCancel = () => {
    this.refs.attachmentRenameModal.hide();
  };
  
  reloadDraft = () =>{
    _.delay(() => {
      this.props.fetchItems();
    }, 500);
    this.setState(
      {
        loading: false,
      },
      () => {
        this.attachmentRenameModalCancel();
      }
    );
  }


  attachmentRenameModalConfirm = async (url) => {
    if (this.state.fileName.trim()) {
      this.setState({
        loading: true,
        fileName: "",
        error: [],
      });
      let data = {};
      data.url = url;
      data.newName = this.state.fileName;
      if (this.props.currentDraftId) {
        if (this.props.currentDraftId.includes("_")) {
          data.currentDraftId = this.props.currentDraftId.split("_")[1];
        } else {
          data.currentDraftId = this.props.currentDraftId;
        }
      }
      await this.props.renameAttachment(data, this.reloadDraft());
     
    } else {
      let error = [];
      error.push("fileName");
      this.setState({
        error,
      });
    }
  };

  handleInputChange = (e) => {
    this.setState({
      fileName: e.target.value,
    });
  };

  getClassName = (classname) => {
    if (this.state.error.includes("fileName")) {
      return `${classname} has-error`;
    } else {
      return classname;
    }
  };

  changeUrl = (url) => { 
      this.url = url
  }


      render() {
        let attachmentRenameModal = (
            <CustomFadeModal className="boron-modal" ref="attachmentRenameModal">
              <div className="close-btn">
                <span
                  className="ion-android-cancel clickable"
                  onClick={this.attachmentRenameModalCancel}
                />
              </div>
              <div className="jd-modal">
                <label className="pull-left">
                  {strings.get("Client.attachments.renameModal.title")}
                </label>
                <br />
                <div className="editable-fields-box">
                  {this.state.loading ? (
                    <ReactLoading
                      type="spinningBubbles"
                      color="#36d7b7"
                      className="react-loading"
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder={strings.get(
                        "Client.attachments.renameModal.newNamePlaceholder"
                      )}
                      className={this.getClassName("form-control")}
                      value={this.state.fileName}
                      onChange={this.handleInputChange}
                      required
                    />
                  )}
                </div>
                <button
                  className="btn btn2"
                  onClick={() => this.attachmentRenameModalConfirm(this.url)}
                  disabled={this.state.loading}
                >
                  {strings.get("App.documents.documentForm.orderFieldsModal.save")}
                </button>
                <button
                  className="btn btn3"
                  onClick={this.attachmentRenameModalCancel}
                >
                  {strings.get("App.documents.documentForm.orderFieldsModal.cancel")}
                </button>
              </div>
            </CustomFadeModal>
          );
    
          const items = _.map(this.props.items,(item)=>{
              let callbacks = {}
              callbacks['type'] = this.props.type;
              callbacks["handleRemoveClick"] = (e,item, index)=>this.handleRemoveClick(e,item, index);
              callbacks['handleRenameClick'] = (e,access)=>this.handleRenameClick(e,access);
              callbacks['changeUrl']= (url)=>this.changeUrl(url);
              callbacks['handleItemClick'] = (index) => this.handleItemClick(index);
              return {...item,...callbacks}
          })

          return (
            <>
              {attachmentRenameModal}
              <table>
                <SortableList
                    items={items}
                    onSortEnd={this.handleSortEnd}
                    lockAxis="y"
                    useDragHandle={true}
                    lockToContainerEdges={true}
                    helperClass="SortableItem"
                />
              </table>
            </>
          )
      }
  }

  SortableActiveUrlLabel.propTypes = {
    items: PropTypes.any.isRequired
  };

  function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      renameAttachment: (data, callback: Function) => {
        dispatch(settingActions.renameAttachment(data, callback));
      },
    };
  }

  export default connect(mapStateToProps, mapDispatchToProps)(SortableActiveUrlLabel);