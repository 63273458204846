import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";

import * as authSelectors from "../store/auth/selectors";
import * as authActions from "../store/auth/actions";

import strings from "../services/strings";
import language from "../services/language";

import "./NavigationTabs.scss";
class NavigationTabs extends Component {
  getMenuItems(primaryId) {
    let locale = language.getIso2();
    let itemsContent = [
      [
        strings.get("Client.profilePage.profile"),
        `/profile`,
        "far fa-user-circle",
      ],
      [strings.get("Client.profilePage.drafts"), `/drafts`, "far fa-lightbulb"],
      // [strings.get("Client.profilePage.roles"), `/roles`, "fas fa-id-card"],
      [
        strings.get("Client.profilePage.shareddrafts"),
        `/shareddrafts`,
        "fas fa-share-alt",
      ],
      [
        strings.get("Client.homePage.myWorkflow.title"),
        `/myworkflows`,
        "fas fa-sitemap",
      ],
      [
        strings.get("Client.profilePage.downloads"),
        `/mydocuments`,
        "fas fa-cloud-download-alt",
      ],
      [
        strings.get("Client.profilePage.messages"),
        `/messages/shareemail`,
        "fas fa-bell",
      ],
      [
        strings.get("Client.profilePage.settings"),
        `/settings`,
        "fas fa-sliders-h",
      ],
    ];
    let items = _.map(itemsContent, (item, i) => (
      <Link
        className={
          i === primaryId
            ? "btn btn-primary right pull-left"
            : "btn btn-default right pull-left"
        }
        to={`/${locale + item[1]}`}
        key={i}
      >
        <i className={item[2]} /> <span> {item[0]}</span>
      </Link>
    ));
    return items;
  }

  handleLogoutClick() {
    this.props.logout();
  }

  render() {
    return (
      <div className="NavigationTabs navigation-settings-menu clearfix">
        {this.getMenuItems(this.props.currentItemId)}
        {/* <Link className="btn btn-default right pull-left" href="#" onClick={ this.handleLogoutClick }>{strings.get('Client.profilePage.logout')}</Link><br/><br/> */}
      </div>
    );
  }
}
NavigationTabs.propTypes = {
  currentItemId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    profile: authSelectors.getProfile(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => {
      dispatch(authActions.logout());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationTabs);
