import "whatwg-fetch";
import React from "react";
import { Route, Navigate, Routes as RoutesList } from "react-router-dom";
// import { linkedin } from 'react-linkedin-login-oauth2';

import HomePage from "./containers/home/HomePage";
// import RolesPage from "./containers/roles/RolesPage";
import UserProfilePage from "./containers/profile/UserProfilePage";
import RequiredOptionsPage from "./containers/settings/requiredOptions/RequiredOptionsPage";
import UserDocumentsPage from "./containers/documents/UserDocumentsPage";
import CategoriesListPage from "./containers/categories/CategoriesListPage";
import DocumentListPage from "./containers/documents/DocumentListPage";
import DocumentAlertPage from "./containers/documents/DocumentAlertPage";
import WorkflowCategoriesListPage from "./containers/workflowCategories/WorkflowCategoriesListPage";
import WorkflowListPage from "./containers/workflows/WorkflowListPage";
import WorkflowFormPage from "./containers/workflows/WorkflowFormPage";
import ArticleCategoriesListPage from "./containers/articleCategories/ArticleCategoriesListPage";
import ArticleListPage from "./containers/articles/ArticleListPage";
import ArticlePage from "./containers/articles/ArticlePage";
import Contacts from "./containers/contacts/ContactsPage";
import DraftsPage from "./containers/drafts/DraftsPage";
import MyWorkflowsPage from "./containers/myWorkflow/MyWorkflows";
import SharedDraftsPage from "./containers/sharedDrafts/SharedDraftsPage";
import ShareEmailSettingPage from "./containers/settings/shareEmail/ShareEmailSettingPage";
import ShareDraftInstructionPage from "./containers/settings/shareDraft/ShareDraftInstructionPage";
import AlertsPage from "./containers/settings/alerts/AlertsPage";
import MaintenancePage from "./containers/home/MaintenancePage";
import DonationPage from "./containers/home/DonationPage";
import PaymentPage from "./containers/home/PaymentPage";
import DocumentFormPageAuth from "./containers/documents/DocumentFormPageAuth";
import RoleDocumentFormPageAuth from "./containers/documents/RoleDocumentFormPageAuth";
import VerifyEmail from "./containers/verifyEmail/VerifyEmail";

export const Routes = () => (
  <RoutesList>
    {/* <Route path="/linkedin" component={linkedin} /> */}
    <Route path="/verifyEmail/:token" element={<VerifyEmail />} />
    <Route path="/approveEmail/:token" element={<VerifyEmail />} />
    <Route path="/maintenance/:id" element={<MaintenancePage />} />
    <Route path="/donation-success" element={<DonationPage />} />
    <Route path="/payment-success" element={<PaymentPage />} />
    <Route path="/" element={<HomePage />} />
    <Route path="/:locale" element={<HomePage />} />
    <Route path="/resetPassword/:token" element={<HomePage />} />
    {/* <Route path="/:locale/roles" component={RolesPage />} /> */}
    <Route path="/:locale/profile" element={<UserProfilePage />} />
    <Route path="/:locale/mydocuments" element={<UserDocumentsPage />} />
    <Route path="/:locale/settings" element={<RequiredOptionsPage />} />
    <Route path="/:locale/documenten" element={<CategoriesListPage />} />
    <Route
      exact
      path="/:locale/documenten/:catName-:catId"
      element={<DocumentListPage />}
    />
    <Route
      exact
      path="/:locale/documenten/:catName-:catId/:docName-:docId/alert/:id"
      element={<DocumentAlertPage />}
    />
    <Route
      exact
      path="/:locale/documenten/:catName-:catId/:docName-:docId/:id?/:type?"
      element={<DocumentFormPageAuth />}
    />
    <Route
      exact
      path="/:locale/role/documenten/:catName-:catId/:docName-:docId/:id?/:type?"
      element={<RoleDocumentFormPageAuth />}
    />
    <Route
      exact
      path="/:locale/workflow"
      element={<WorkflowCategoriesListPage />}
    />
    <Route
      exact
      path="/:locale/workflows/:catName-:catId"
      element={<WorkflowListPage />}
    />
    ,
    <Route
      exact
      path="/:locale/workflows/:catName-:catId/:workflowName-:workflowId/:id?/:type?"
      element={<WorkflowFormPage />}
    />
    <Route
      exact
      path="/:locale/articles"
      element={<ArticleCategoriesListPage />}
    />
    <Route
      exact
      path="/:locale/articles/:artcatSlug-:artcatId"
      element={<ArticleListPage />}
    />
    <Route path="/:locale/article/:articleSlug" element={<ArticlePage />} />
    <Route path="/:locale/contact" element={<Contacts />} />,
    <Route path="/:locale/drafts" element={<DraftsPage />} />,
    <Route path="/:locale/myworkflows" element={<MyWorkflowsPage />} />,
    <Route path="/:locale/shareddrafts" element={<SharedDraftsPage />} />
    <Route
      exact
      path="/:locale/messages/shareemail"
      element={<ShareEmailSettingPage />}
    />
    <Route
      exact
      path="/:locale/messages/sharedraft"
      element={<ShareDraftInstructionPage />}
    />
    <Route path="/:locale/messages/alerts" element={<AlertsPage />} />
    <Route path="*" element={<Navigate to="/" />} />

  </RoutesList>
);
