import React, { Component } from "react";
import PropTypes from "prop-types";

import "./Switch.scss";

class Switch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enabled: true,
    };
  }

  componentDidMount() {
    this.setState({
      enabled: !!this.props.enabled,
    });
  }

  componentDidUpdate() {
    if(!!this.props.enabled !== !!this.state.enabled){
      this.setState({
        enabled: !!this.props.enabled,
      });
    }
  }

  handleChange = (e) => {
    e.persist();
    this.setState(
      {
        enabled: e.target.checked,
      },
      () => {
        this.props.onChange(e.target.checked);
      }
    );
  };

  render() {
    return (
      <label className={`switch ${this.props.docsize}`}>
        <input
          type="checkbox"
          checked={this.state.enabled}
          onChange={this.handleChange}
        />
        <div className="slider round"></div>
      </label>
    );
  }
}

Switch.propTypes = {
  enabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Switch;
