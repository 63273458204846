import _ from "lodash";

export function getItem(state) {
  return state.articles.currentItem;
}

export function getArticleCategories(state) {
  return state.articles.articleCategories;
}

export function getItemsByCategory(state, catId) {
  let items = _.map(state.articles.idsByCategory[`_${catId}`], (itemId) => {
    return state.articles.itemsById[`_${itemId}`];
  });

  return _.filter(items, (item) => item);
}
