import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import CategorySingle from "./CategorySingle";

import strings from "../../services/strings";

import "./SubCategoryList.scss";
class SubCategoriesList extends Component {
  getImage = (item) => {
    if (item.imageURL) {
      return (
        <div
          className="categoryImage"
          style={{ backgroundImage: `url(${item.imageURL})` }}
        />
      );
    }
  };

  getTitle = () => {
    if (_.size(this.props.items)) {
      return <h2>{strings.get("Client.category.subcategories")}</h2>;
    }
  };

  getItems = () => {
    if (this.props.items) {
      return _.map(this.props.items, (item) => {
        return <CategorySingle key={item.id} item={item} />;
      });
    }
  };

  getAssignedUsers = () => {
    if (this.props.assignedUsers) {
      return _.map(this.props.assignedUsers, (user) => {
        return (
          <p
            key={`item-${user.id}`}
          >{`${user.first_name} ${user.last_name}`}</p>
        );
      });
    }
  };

  render() {
    return (
      <div
        className="SubCategoryList"
        style={{ display: _.size(this.props.items) ? "block" : "none" }}
      >
        <div className="inner-page-cont">
          <div className="container-row">
            <div className="DocCategoryList new-cate-list">
              {this.getItems()}
            </div>
          </div>
        </div>
        <div>{this.getAssignedUsers()}</div>
      </div>
    );
  }
}

SubCategoriesList.propTypes = {
  items: PropTypes.array,
  assignedUsers: PropTypes.array,
};

export default SubCategoriesList;
