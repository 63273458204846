import React, { Component } from "react";
import _ from "lodash";

import "./Breadcrumbs.scss";

class Breadcrumbs extends Component {
  getItems = () => {
    const children = [];
    this.props.children.forEach((child) => {
      if (_.isArray(child)) {
        child.forEach((elem) => {
          children.push(elem);
        });
      } else {
        children.push(child);
      }
    });
    let items = [];
    if (children) {
      _.each(children, (item, key) => {
        if (item) {
          items.push(<span key={_.uniqueId()}>{item}</span>);

          if (key !== children.length - 1) {
            items.push(
              <span className="divider" key={_.uniqueId()}>
                /
              </span>
            );
          }
        }
      });
    }

    return items;
  };

  render() {
    return (
      <div className="Breadcrumbs">
        <div className="container-row">{this.getItems()}</div>
      </div>
    );
  }
}

export default Breadcrumbs;
