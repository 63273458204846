import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";

import language from "../../services/language";
import { makeSEFSlug } from "../../helpers";

function CategorySingle({ item }) {
  return (
    <li className="col-sm-4" key={_.uniqueId()}>
      <Link to={`/${language.getIso2()}/workflows/${makeSEFSlug(item.slug)}-${item.id}`}>
        <p>{item.name}</p>
      </Link>
    </li>
  );
}
export default CategorySingle;
