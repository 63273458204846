import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import UserAlertLoader from "../../components/document/UserAlertLoader";

import * as documentsActions from "../../store/documents/actions";
import * as documentsSelectors from "../../store/documents/selectors";
import * as categoriesSelectors from "../../store/categories/selectors";
import * as stepsSelectors from "../../store/steps/selectors";
import * as authSelectors from "../../store/auth/selectors";
import * as draftsSelectors from "../../store/drafts/selectors";

// import "../Page.scss";
import { checkCookieConsent } from "../../helpers";

class DocumentAlertPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertId: null,
      alertType: null,
    };
  }

  callback = (result) => {
    if (Array.isArray(result)) {
      result.forEach((element) => {
        if (element.key !== "length") {
          sessionStorage.setItem(element.key, element.value);
        }
      });
    }
    let params = this.props.match.params;
    _.delay(() => {
      let url = `/${params.locale}/documenten/${params.catName}-${params.catId}/${params.docName}-${params.docId}`;
      this.props.router.push(url);
    }, 750);
  };
  componentDidMount() {
    if (!checkCookieConsent()) {
      window.location.replace("/");
    }
    let { alertId } = this.state;
    alertId = this.props.match.params.id;
    if (!isNaN(alertId)) {
      this.props.fetchAlertRelatedDocument(alertId, "download", this.callback);
    } else {
      this.props.fetchAlertRelatedDocument(
        alertId,
        "draftOrRole",
        this.callback
      );
    }
    this.setState({ alertId });
  }

  getMetaFields = () => {
    if (_.size(this.props.currentDocument) > 0) {
      return (
        <Helmet>
          <title>{this.props.currentDocument.meta_title}</title>
          <meta
            name="description"
            content={this.props.currentDocument.meta_description}
          />
          <meta
            name="keywords"
            content={this.props.currentDocument.meta_keyword}
          />
        </Helmet>
      );
    }
  };

  render() {
    return (
      <div>
        {this.getMetaFields()}
        <UserAlertLoader />
      </div>
    );
  }
}

function getSubCategoriesList(state, category) {
  if (_.isObject(category) && category.parent_id) {
    const subCat = categoriesSelectors.getItemById(state, category.parent_id);
    subCategoriesList.push(subCat);
    getSubCategoriesList(state, subCat);
  }
}

let subCategoriesList = [];

function mapStateToProps(state) {
  subCategoriesList = [];
  let currentCategory = categoriesSelectors.getCurrentItem(state);
  getSubCategoriesList(state, currentCategory);
  return {
    drafts: draftsSelectors.getItem(state),
    steps: stepsSelectors.getItems(state),
    currentCategory: currentCategory,
    allCategory: categoriesSelectors.getItems(state),
    currentDocument: documentsSelectors.getCurrentItem(state),
    profile: authSelectors.getProfile(state),
    denyMessage: documentsSelectors.getDenyMessage(state),
    subCategoriesList: subCategoriesList.reverse(),
    saved: draftsSelectors.isSaved(state),
    alreadyExist: draftsSelectors.alreadyExist(state),
    draftShareFields: draftsSelectors.getShareFields(state),
    fields: draftsSelectors.getFields(state),
    fieldsOrder: draftsSelectors.getFieldsOrder(state),
    selectors: draftsSelectors.getSelectors(state),
    clauses: draftsSelectors.getClauses(state),
    stepsFromValidation: draftsSelectors.getSteps(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAlertRelatedDocument: (id, type, callback) => {
      dispatch(documentsActions.fetchItemValues(id, type, callback));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentAlertPage);
