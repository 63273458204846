import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import ApiService from "./../../services/api";
import { makeSEFUrl } from "../../helpers";
import strings from "../../services/strings";
import language from "../../services/language";

export default class CategorySingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFlipped: false,
      data: [],
    };
  }

  handleToggle = (e) => {
    if (!this.state.isFlipped) {
      ApiService.get(`/category/info/${this.props.item.id}`).then(
        (data) => this._ismounted && this.setState({ data })
      );
    }
    e.preventDefault();
    this.setState({ isFlipped: !this.state.isFlipped });
  };

  componentWillUnmount() {
    this._ismounted = false;
  }

  componentDidMount() {
    this._ismounted = true;
    // ApiService.get(`/category/info/${this.props.item.id}`).then(
    //   data => this._ismounted && this.setState({ data })
    // );
  }

  timeConverter = (updated) => {
    return moment(updated).locale("nl").format("D MMMM YYYY").toLowerCase();
  };

  render() {
    const { item } = this.props;

    const locale = language.getIso2();

    return (
      <div className="flip-container">
        <div
          className={`col-sm-4 flipper${this.state.isFlipped ? " rotate" : ""}`}
        >
          <div className="col front">
            <Link
              to={`/${locale}/documenten/${makeSEFUrl(item.name)}-${item.id}`}
            >
              <p className="cate-name">{item.name}</p>
              {item.imageURL ? (
                <div
                  className="categoryImage"
                  style={{ backgroundImage: `url(${item.imageURL})` }}
                />
              ) : null}
              {this.state.data && (
                <i onClick={this.handleToggle} className="fas fa-info-circle" />
              )}
            </Link>
          </div>

          {this.state.data ? (
            <div className="col back">
              <p onClick={this.handleToggle} className="closeButton">
                <i className="fas fa-times" />
              </p>
              <p className="stats-heading">
                {strings.get("Client.category.stats")}
              </p>

              <table className="stats-table">
                <tbody>
                  <tr>
                    <td className="sats-option">
                      {strings.get("Client.category.documents")}{" "}
                    </td>
                    <td className="spe">:</td>
                    <td>
                      {this.state.data.countDocs
                        ? this.state.data.countDocs.docs
                        : 0}
                    </td>
                  </tr>

                  <tr>
                    <td>{strings.get("Client.category.downloads")}</td>
                    <td className="spe">:</td>
                    <td>
                      {this.state.data.countDownloads
                        ? this.state.data.countDownloads.downloads
                        : 0}
                    </td>
                  </tr>
                  {this.state.data.updated ? (
                    <tr>
                      <td> {strings.get("Client.category.updated")} </td>
                      <td className="spe">:</td>
                      <td>
                        {this.timeConverter(this.state.data.updated.date)}
                      </td>
                    </tr>
                  ) : null}
                  {this.state.data.assignedMembers &&
                  this.state.data.assignedMembers.length ? (
                    <tr>
                      <td> {strings.get("Client.category.lawyer")} </td>
                      <td className="spe">:</td>
                      <td>
                        {" "}
                        {this.state.data.assignedMembers.map((member) => (
                          <span key={`${member.first_name}${member.last_name}`}>
                            {" "}
                            {member.first_name} {member.last_name}
                          </span>
                        ))}
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
