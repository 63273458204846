import "whatwg-fetch";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import LoginPage from "./containers/auth/LoginPage";

import store from "./store";
import { PageView } from "./services/tracker";

const browserHistory = createBrowserHistory();

browserHistory.listen(() => {
  PageView();
});

ReactDOM.render(
  <Provider store={store}>
    <Router onUpdate={() => window.scrollTo(0, 0)} navigator={browserHistory} location={browserHistory.location} >
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<App />} />
      </Routes>
    </Router>
  </Provider>,
  document.getElementById("root")
);
