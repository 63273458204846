import documents from "../services/documents";
import React from "react";
import he from "he";
import parse from "html-react-parser";

// import { fromCSS } from "react-css";
import _ from "lodash";
import { createBrowserHistory } from "history";
export const browserHistory = createBrowserHistory();
// parser options 
const options = {
  replace: (domNode) => {
    if (domNode.attribs && domNode.attribs.class === "remove") {
      return <></>;
    }
  }
};

//replace space of string
export const makeSEFUrl = (title) =>
  makeSEFSlug(title).toString().toLowerCase().replace(/ /g, "_");

//replace - of slug
export const makeSEFSlug = (slug) =>
  slug.toString().toLowerCase().replace(/-/g, "_");

//replace space and append with id
export const makePATHUrl = (title, id) =>
  // `${title}-${id}`.toString().toLowerCase().replace(/ /g, "_");
  `${makeSEFSlug(title)}-${id}`.toString().toLowerCase().replace(/ /g, "_");

//parse query parameters and return variable value
export const getQueryParam = (url = "", variable) => {
  return new URLSearchParams(url.search).get(variable);
};

//check for mobile device
export const isMobile = () => {
  if (window.screen.width <= 500) {
    return true;
  } else {
    return false;
  }
};

//parse query parameters and return true if variable is available in query parameter
export const getQueryParamStatus = (url = "", variable) => {
  const searchParams = new URLSearchParams(url.search);
  return searchParams.has(variable) && searchParams.get(variable) !== ""
    ? true
    : false;
};

export const checkCookieConsent = () => {
  let cookieName = "CookieConsent" + "=";
  let allCookies = decodeURIComponent(document.cookie).split(";");
  for (const element of allCookies) {
    if (element.includes(cookieName)) {
      if (
        element.substring(element.indexOf("=") + 1).toLowerCase() === "true"
      ) {
        return true;
      }
      if (
        element.substring(element.indexOf("=") + 1).toLowerCase() === "false"
      ) {
        return false;
      }
    }
  }
  document.cookie =
    cookieName + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  return undefined;
};

export const checkAnalyticsCookieConsent = () => {
  let cookieName = "CookieAnalyticsConsent" + "=";
  let allCookies = decodeURIComponent(document.cookie).split(";");
  for (const element of allCookies) {
    if (element.includes(cookieName)) {
      if (
        element.substring(element.indexOf("=") + 1).toLowerCase() === "true"
      ) {
        return true;
      }
      if (
        element.substring(element.indexOf("=") + 1).toLowerCase() === "false"
      ) {
        return false;
      }
    }
  }
  document.cookie =
    cookieName + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const viewModals = {
  MILESTONE_DOC_ACCESS_DENY: "MILESTONE_DOC_ACCESS_DENY",
  SHARED_DOC_MESSAGE: "SHARED_DOC_MESSAGE",
  COOKIE_CONSENT: "cookieConsentModal",
  HIDDEN_CATEGORY_MESSAGE: "HIDDEN_CATEGORY_MESSAGE",
  LOG_IN_MESSAGE: "LOG_IN_MESSAGE",
  CATEGORY_CUSTOM_USER_ACCESS_MESSAGE: "CATEGORY_CUSTOM_USER_ACCESS_MESSAGE",
  CAROUSEL_SLIDE_READ_MORE: "CAROUSEL_SLIDE_READ_MORE",
  DRAFTS_DATA_RE_USE: "DRAFTS_DATA_RE_USE",
};

export const customScrollbar = {
  maxHeight: "350px",
  wordBreak: "break-word",
};

export const customCarouselSlideScrollbar = {
  maxHeight: "350px",
  wordBreak: "break-word",
  width: "98%",
};

export const acceptedFileFormats =
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, application/msword, application/vnd.ms-powerpoint, application/vnd.ms-excel, application/vnd.rar, application/zip, application/x-7z-compressed, application/x-zip-compressed, application/rtf, application/x-shockwave-flash, image/*, audio/*, video/*";

export const maximumFileSizeToUpload = 1024 * 1024 * 15; //15 MB

export const buildContent = (
  rules,
  showClausePreview = false,
  ruleName = "",
  props
) => {
  let content = [];

  _.each(rules, (rule) => {
    let element = null;
    let children = null;

    // Recursively build element children
    if (_.size(rule.children)) {
      // If rule is clause check if it's checked
      if (
        rule.type === documents.ruleTypes.CLAUSE &&
        (rule.value || showClausePreview)
      ) {
        children = buildContent(
          rule.children,
          showClausePreview,
          rule.name,
          props
        );
      }

      // If rule is selector check if a valid option is selected
      if (rule.type === documents.ruleTypes.SELECTOR) {
        let optionKey = _.findIndex(
          rule.children,
          (item) => item.name === rule.value
        );
        if (optionKey > -1 && _.size(rule.children[optionKey].children)) {
          children = buildContent(
            rule.children[optionKey].children,
            false,
            rule.name,
            props
          );
        }
      }

      // If rule is a carousel then check if a valid slide is selected
      if (rule.type === documents.ruleTypes.CAROUSEL) {
        let optionKey = _.findIndex(
          rule.children,
          (item) => item.name === rule.value
        );

        if (optionKey > -1 && _.size(rule.children[optionKey].children)) {
          children = buildContent(
            rule.children[optionKey].children,
            false,
            rule.name,
            props
          );
        } else {
          let value =
            rule.properties &&
            rule.properties.placeholder &&
            rule.properties.placeholder !== ""
              ? rule.properties.placeholder
              : rule.name;
          element = React.createElement(
            "span",
            {
              key: _.uniqueId("field-"),
              className: `input-field ${rule.name}`,
            },
            value + he.decode("&nbsp")
          );
          content = content.concat(element);
        }
      }
      // for carousel preview 
      if (!rule.type && rule.isClause === 0 ) {
          let value =
            rule.helperText &&
            rule.helperText !== ""
              ? rule.helperText
              : rule.name;
          element = React.createElement(
            "span",
            {
              key: _.uniqueId("field-"),
              className: `input-field ${rule.name}`,
            },
            parse(value,options)
          );          
          content = content.concat(element);
      }

      // If rule is html simply parse rest of the children
      if (rule.type === documents.ruleTypes.HTML) {
        children = buildContent(rule.children, false, "", props);
      }
    }

    if (rule.type === documents.ruleTypes.SELECTOR) {
      if(_.has(rule, "options") && _.size(rule.options)) {
        if(rule.value){
          element= [(
           <span key={`${_.uniqueId()}`}>{rule.options[rule.value]}</span>
          )];
          content= content.concat(element);
        }
        return;
      }  
    }

    // Build current element
    if (rule.type === documents.ruleTypes.TEXT) {
      element = React.createElement(
        "span",
        {
          key: _.uniqueId("text-"),
          className: ruleName,
        },
        he.decode(rule.value)
      );
      content = content.concat(element);
    } else if (rule.type === documents.ruleTypes.HTML) {
      element = React.createElement(
        rule.value,
        {
          key: _.uniqueId("html-"),
          className: ruleName,
          // style: rule.style ? fromCSS(`{${rule.style}}`) : null,
        },
        children
      );
      content = content.concat(element);
    } else if (rule.type === documents.ruleTypes.FIELD && rule.properties) {
      if (rule.properties.type === documents.ruleTypes.field.FILE_SELECTOR) {
        const ele = []

        if(_.some(props.attachments,(attach)=> attach.codeId == rule.name)) {
          ele.push(
            <p><strong><span>Evidence documents</span></strong></p>
          )
          const prefix = _.get(props, "document.prefix","");
          const attachments = _.filter(props.attachments, (a => a.codeId == rule.name))
          _.map(attachments, (attachment, index) => {
              let name= "";
              if (_.has(attachment, "file")) {
                name= attachment.file.name
            } else if (_.has(attachment, "url")) {
              const path = attachment.url.split('/');
              name= path[_.lastIndexOf(path) - 1]
            }
            ele.push(
              <p key={_.uniqueId()} >{`${prefix?`${prefix} `:""}${index + 1}- ${name} `}</p> 
            )
          });
        }
        content= content.concat(ele);
        //if field type is file-selector then skip this field from template preview
        return;
      }
      let value = rule.value;

      //To-do : Below commented code can be used for Double (or more) codes like [[field_2956 || field_231]]

      // let name = rule.name;
      // if(this.props.document && this.props.document.template){
      //   // const regex = `\( (\[\[(${name})\]\]){1} (\|\| \[\[([^\[\]]*)\]\] ){1,}\)`;
      //   // const reg = new RegExp(regex);
      //   // this.props.document.template.match(new RegExp(regex, 'gi'));
      //   // console.log(regex, this.props.document.template.match(new RegExp(`\( (\[\[(${name})\]\]){1} (\|\| \[\[([^\[\]]*)\]\] ){1,}\)`, 'gi')))
      //   // const str = this.props.document.template;
      //   // let m;

      //   // while ((m = regex.exec(str)) !== null) {
      //   //     // This is necessary to avoid infinite loops with zero-width matches
      //   //     if (m.index === regex.lastIndex) {
      //   //         regex.lastIndex++;
      //   //     }

      //   //     // The result can be accessed through the `m`-variable.
      //   //     m.forEach((match, groupIndex) => {
      //   //         console.log(`Found match, group ${groupIndex}: ${match}`);
      //   //     });
      //   // }
      //   // let es = `/\( (\[\[(${name})\]\]){1} (\|\| \[\[([^\[\]]*)\]\] ){1,}\)/g`;
      //   // console.log("reg before", es);
      //   // // const reg = new RegExp(es);
      //   // // console.log("reg");
      //   // // console.log(reg);
      //   // console.log(this.props.document.template.match(es));
      // }
      if (rule.options && rule.properties.options) {
        let valueIndex = rule.properties.options.indexOf(value);
        if (valueIndex > -1) {
          value = rule.options[valueIndex];
        }
      }

      let placeholderField = rule.properties.placeholderField;

      // Check if value and placeholderField is empty
      if (value === "" && !placeholderField) {
        //value = rule.properties.name;
        value = " ";
      } else if (value === "") {
        value = placeholderField;
      }

      element = React.createElement(
        "span",
        {
          key: _.uniqueId("field-"),
          className:
            rule.properties.type === "address_selector"
              ? `address-selector input-field ${rule.name}`
              : `input-field ${rule.name}`,
        },
        value + he.decode("&nbsp")
      );
      content = content.concat(element);
    } else {
      // content = content.concat(children);
      element = React.createElement(
        "span",
        {
          key: _.uniqueId("parent-field-"),
          className: ruleName,
        },
        children
      );
      content = content.concat(element);
    }
  });

  return content;
};

//this cookie will be used to help in 2 step authentication
const identityCookieName = "JD_IDN" + "=";

export const loadIdentifier = () => {
  let cookieValue = "";
  let allCookies = decodeURIComponent(document.cookie).split(";");
  for (const element of allCookies) {
    if (element.includes(identityCookieName)) {
      cookieValue = element.substring(element.indexOf("=") + 1).toLowerCase();
    }
  }
  return cookieValue;
};

export const setIdentifier = (value) => {
  document.cookie = identityCookieName + `${value}; max-age=2592000;`; // Max age 30 days
};
