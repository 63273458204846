import _ from 'lodash';

export function getItems(state) {
	return state.workflows.itemsById;
}

export function getItemsByCategory(state, catId) {
	let items =  _.map(state.workflows.idsByCategory[`${catId}`], (itemId) => {
		return state.workflows.itemsById[`_${itemId}`];
	});


	return _.filter(items, item => item);
}

export function getItemById(state, id) {
	return state.workflows.itemsById['_' + id];
}

export function getCurrentItem(state) {
	return state.workflows.currentItemId ? getItemById(state, state.workflows.currentItemId) : null;
}

export function getFilters(state) {
	return state.workflows.filters;
}

export function getPagination(state) {
	return state.workflows.pagination;
}

export function getSorter(state) {
	return state.workflows.sorter;
}

export function getSearchPlaceholder(state) {
	return state.workflows.placeholder;
}

export function getDenyMessage(state) {
    return state.workflows.denyMessage;
}

export function getStats(state) {
    return state.workflows.stats;
}

export function getCurrentWorkflowRulesValueChangeFlag(state) {
  return state.workflows.currentWorkflowChangeInValues;
}

export function getUserWorkflows(state) {
	return state.workflows.userWorkflows;
}

export function getUserCurrentWorkflow(state) {
	return state.workflows.userCurrentWorkflow;
}
