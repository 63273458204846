import _ from "lodash";
import { NotificationManager } from "react-notifications";

import api from "../../services/api";
import auth from "../../services/auth";
import language from "../../services/language";
import strings from "../../services/strings";
import { browserHistory, setIdentifier } from "../../helpers";

import * as exceptionsActions from "../exceptions/actions";
import * as settingsActions from "../settings/actions";

export const types = {
  GET_TOKEN_DONE: "auth.GET_TOKEN_DONE",
  FETCH_PROFILE_DONE: "auth.FETCH_PROFILE_DONE",
  FETCH_MAINTENANCE_SETTINGS_DONE: "auth.FETCH_MAINTENANCE_SETTINGS_DONE",
};

export function login(data, handleOtpSent = () => {}) {
  return async (dispatch) => {
    try {
      dispatch(settingsActions.setScreenLoading(true));
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // POST request to API
      let apiUrl = "/auth/login";
      if (data.provider) {
        // if login with social site
        apiUrl = "/auth/socialLogin";
      }

      let payload = await api.post(apiUrl, params);
      if (payload.error && payload.error === "Not Verified") {
        NotificationManager.error(
          strings.get("Exceptions.notifications.login.notVerified.message"),
          strings.get("Exceptions.notifications.login.notVerified.title"),
          5000
        );
      }

       if (payload.error && payload.error === "Admin Not Approved"){
        NotificationManager.error(
          strings.get("Exceptions.notifications.login.adminNotApproved.message"),
          strings.get("Exceptions.notifications.login.adminNotApproved.title"),
          5000
        );
       }

      if (payload.status === "OTP Sent") {
        NotificationManager.success(
          strings.get("Exceptions.notifications.login.otpSent")
        );
        handleOtpSent();
      }
      if (payload && payload.token) {
        auth.setLocalAccessToken(payload.token);
        let locale = language.getIso2() || "NL";
        let returnUrl = "/" + locale;
        if (localStorage.getItem("sharedDraftId")) {
          returnUrl += "/shareddrafts";
          localStorage.removeItem("sharedDraftId");
        }
        window.location.reload();
      }
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    } finally {
      dispatch(settingsActions.setScreenLoading(false));
    }
  };
}

export function otpSubmit(data) {
  return async (dispatch) => {
    try {
      dispatch(settingsActions.setScreenLoading(true));
      dispatch(exceptionsActions.clear());
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // POST request to API
      let payload = await api.post("/auth/verifyOtp", params);
      if (payload.status === "error") {
        if (payload.error && payload.error === "Invalid Otp") {
          NotificationManager.error(
            strings.get("Exceptions.notifications.login.invalidOtp")
          );
        } else {
          NotificationManager.error(
            strings.get("Exceptions.notifications.error")
          );
        }
      }
      if (payload.identifier) {
        setIdentifier(payload.identifier);
      }
      if (payload.token) {
        auth.setLocalAccessToken(payload.token);
        let locale = language.getIso2() || "NL";
        let returnUrl = "/" + locale;
        if (localStorage.getItem("sharedDraftId")) {
          returnUrl += "/shareddrafts";
          localStorage.removeItem("sharedDraftId");
        }
        window.location.reload();
      }
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    } finally {
      dispatch(settingsActions.setScreenLoading(false));
    }
  };
}

export function forgot(data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      await api.post("/auth/password/email", params);
      NotificationManager.success(
        strings.get("Exceptions.notifications.forgotPassword.message"),
        strings.get("Exceptions.notifications.forgotPassword.title")
      );
      _.delay(()=>{
        const language = JSON.parse(localStorage.getItem("language"))
        let locale = language ? language.iso2 : "NL";
        let returnUrl = "/" + locale;
        window.location = returnUrl;
        browserHistory.push(returnUrl);
        window.location.reload();
        dispatch(exceptionsActions.clear());
      },500)
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function resetPassword (data, callBack = () => {}) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      await api.post("/auth/password/reset", params);
      NotificationManager.success(
        strings.get("Exceptions.notifications.resetPassword.title")
      );
      _.delay(()=>{
        const language = JSON.parse(localStorage.getItem("language"))
        let locale = language ? language.iso2 : "NL";
        let returnUrl = "/" + locale;
        window.location = returnUrl;
        browserHistory.push(returnUrl);
        window.location.reload();
        dispatch(exceptionsActions.clear());
      }, 500)
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function getUser() {
  return async (dispatch) => {
    try {
      let params = new Map();
      // POST request to API
      let payload = await api.get("/auth/getUser", params);

      if (payload.user) {
        auth.setLocalUser(payload.user);
        localStorage.setItem("loggedInUserEmail", payload.user.email);

        dispatch({
          type: types.FETCH_PROFILE_DONE,
          payload: {
            profile: payload.user,
          },
        });
      }
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function register(data, callBack = () => {}) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // POST request to API
      let apiUrl = "/auth/register";
      if (data.provider) {
        // if register with social site
        apiUrl = "/auth/socialRegister";
      }
      if (apiUrl === "/auth/register") {
        dispatch(settingsActions.setScreenLoading(true));
      }
      await api.post(apiUrl, params);
      localStorage.removeItem("sharedDraftId");
      NotificationManager.success(
        strings.get("Exceptions.notifications.registration.success.message"),
        strings.get("Exceptions.notifications.registration.success.title")
      );
      callBack();
      // browserHistory.push("/");
      // window.location.reload();

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    } finally {
      dispatch(settingsActions.setScreenLoading(false));
    }
  };
}

export function logout() {
  return async (dispatch) => {
    await localStorage.clear();
    browserHistory.push("/");
    window.location.reload();
  };
}

export function checkMaintenance(id_language) {
  return async (dispatch) => {
    try {
      let params = new Map();
      let items = await api.get(
        `/settings/maintenance_setting/${id_language}`,
        params
      );
      dispatch({
        type: types.FETCH_MAINTENANCE_SETTINGS_DONE,
        payload: {
          items: items,
        },
      });
      return items;
      // _.forEach(items, function(item, key) {
      //   localStorage.setItem(item.key, item.value);
      //   if (item.key === "maintenance_img" && item.value) {
      //     localStorage.setItem(item.key, item.imageURL);
      //   }
      // });
      //   dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}


export function getLinkedInDetails  (code, callback) {
  return async (dispatch) =>{
    try {
      dispatch(settingsActions.setScreenLoading(true));
      let payload = await api.get(`/auth/requestLinkedIn/${code}`);
      callback(payload);
      dispatch(settingsActions.setScreenLoading(false));
    }
    catch (e) {
      dispatch(settingsActions.setScreenLoading(false));
      dispatch(exceptionsActions.process(e));
    }
  }
}

