import React, { Component } from "react";
import ReactLoading from "react-loading";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SortableHandle } from "react-sortable-hoc";
import { NotificationManager } from "react-notifications";
import _ from "lodash";

import { CustomFadeModal } from "./PopUp";

import strings from "../services/strings";
import * as settingActions from "../store/settings/actions";

import "./ActiveLabel.scss";

const SortableDragger = SortableHandle(() => {
  return <i className="label-drag ion-android-more-vertical"></i>;
});

class ActiveUrlLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "",
      loading: false,
      error: [],
    };
  }

  handleItemClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(this.props.value);
    }
  };

  handleRemoveClick = (e) => {
    e.stopPropagation();
    this.props.onRemove(this.props.value);
  };

  handleRenameClick = (event, renameAccess) => {
    event.stopPropagation();
    if (renameAccess) {
      if (
        typeof this.props.accessDenied !== "undefined" &&
        this.props.accessDenied
      ) {
        //if draft is shared and field access not granted
        NotificationManager.warning(
          "",
          strings.get("Client.notifications.accessDenied")
        );
      } else {
        this.refs.attachmentRenameModal.show();
      }
    } else {
      NotificationManager.info(
        strings.get("Client.notifications.attachmentRenameError")
      );
    }
  };

  attachmentRenameModalCancel = () => {
    this.refs.attachmentRenameModal.hide();
  };
  
  reloadDraft = () => {
    _.delay(() => {
      this.props.fetchItems();
    }, 500);
    this.setState(
      {
        loading: false,
      },
      () => {
        this.attachmentRenameModalCancel();
      }
    );
  }

  attachmentRenameModalConfirm = async (url) => {
    if (this.state.fileName.trim()) {
      this.setState({
        loading: true,
        fileName: "",
        error: [],
      });
      let data = {};
      data.url = url;
      data.newName = this.state.fileName;
      if (this.props.currentDraftId) {
        if (this.props.currentDraftId.includes("_")) {
          data.currentDraftId = this.props.currentDraftId.split("_")[1];
        } else {
          data.currentDraftId = this.props.currentDraftId;
        }
      }
      await this.props.renameAttachment(data, this.reloadDraft());
    } else {
      let error = [];
      error.push("fileName");
      this.setState({
        error,
      });
    }
  };

  handleInputChange = (e) => {
    this.setState({
      fileName: e.target.value,
    });
  };

  getClassName = (classname) => {
    if (this.state.error.includes("fileName")) {
      return `${classname} has-error`;
    } else {
      return classname;
    }
  };

  render() {
    let attachmentRenameModal = (
      <CustomFadeModal className="boron-modal" ref="attachmentRenameModal">
        <div className="close-btn">
          <span
            className="ion-android-cancel clickable"
            onClick={this.attachmentRenameModalCancel}
          />
        </div>
        <div className="jd-modal">
          <label className="pull-left">
            {strings.get("Client.attachments.renameModal.title")}
          </label>
          <br />
          <div className="editable-fields-box">
            {this.state.loading ? (
              <ReactLoading
                type="spinningBubbles"
                color="#36d7b7"
                className="react-loading"
              />
            ) : (
              <input
                type="text"
                placeholder={strings.get(
                  "Client.attachments.renameModal.newNamePlaceholder"
                )}
                className={this.getClassName("form-control")}
                value={this.state.fileName}
                onChange={this.handleInputChange}
                required
              />
            )}
          </div>
          <button
            className="btn btn2"
            onClick={() => this.attachmentRenameModalConfirm(url)}
            disabled={this.state.loading}
          >
            {strings.get("App.documents.documentForm.orderFieldsModal.save")}
          </button>
          <button
            className="btn btn3"
            onClick={this.attachmentRenameModalCancel}
          >
            {strings.get("App.documents.documentForm.orderFieldsModal.cancel")}
          </button>
        </div>
      </CustomFadeModal>
    );
    let labelClass = this.props.clickable
      ? "label-content clickable"
      : "label-content";

    let dragger = this.props.draggable ? <SortableDragger /> : null;
    let fileFullName = "";
    let url = null;
    let renameAccess = false; //user can only rename a file if file already uploaded on server
    if (this.props.name.lastIndexOf("/") > 1) {
      renameAccess = true;
      let fileNameIndex = this.props.name.lastIndexOf("/");
      let fileExtensionIndex = this.props.name.lastIndexOf(".");
      let fileName = this.props.name.substring(
        fileNameIndex + 1,
        fileExtensionIndex
      );
      let fileExtension = this.props.name.substr(fileExtensionIndex);
      fileFullName = fileName.concat(fileExtension);
      url = this.props.name;
    } else {
      fileFullName = this.props.name;
    }
    return (
      <div className="ActiveLabel" onClick={this.handleItemClick}>
        {attachmentRenameModal}
        <div className={labelClass}>
          {dragger}
          <span></span>
          <a href={url} target="_blank" style={{ color: "white" }}>
            {fileFullName}
          </a>
          <i
            className="label-close ion-close"
            onClick={this.handleRemoveClick}
          />
          <i
            className="label-close ion-edit"
            onClick={(e) => this.handleRenameClick(e, renameAccess)}
          />
        </div>
      </div>
    );
  }
}

ActiveUrlLabel.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  clickable: PropTypes.bool,
  draggable: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  fetchItems: PropTypes.func.isRequired,
  currentDraftId: PropTypes.string,
  accessDenied: PropTypes.bool,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    renameAttachment: (data, callback: Function) => {
      dispatch(settingActions.renameAttachment(data, callback));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveUrlLabel);
