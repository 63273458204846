import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import _ from "lodash";

import * as documentsActions from "../../store/documents/actions";
import * as documentsSelectors from "../../store/documents/selectors";
import * as categoriesActions from "../../store/categories/actions";
import * as categoriesSelectors from "../../store/categories/selectors";
import * as stepsActions from "../../store/steps/actions";
import * as settingsActions from "../../store/settings/actions";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
import Breadcrumbs from "../../components/Breadcrumbs";
import DocumentList from "../../components/document/DocumentList";
import SubCategoriesList from "../../components/category/SubCategoriesList";
import Footer from "../../components/footer/Footer";
import Pagination from "../../components/Pagination";
import PageSize from "../../components/PageSize";

import { makePATHUrl, browserHistory } from "../../helpers";
import strings from "../../services/strings";
import language from "../../services/language";


class DocumentListPage extends Component {
  componentDidMount() {
    // this.props.setCurrentCategoryId(this.props.match.params.catId);
    // this.props.setDocumentsCategoryId(this.props.match.params.catId);
    // this.props.fetchAllCategories();
    // this.props.fetchDocuments();
    // this.props.fetchCurrentCategory(this.props.match.params.catId);
    this.fetchProps(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.catId !== this.props.match.params.catId) {
      this.props.clearPagination();
      // this.props.fetchAllCategories();
      // this.props.fetchAllSteps();
      // this.props.setDocumentsCategoryId(this.props.match.params.catId);
      // this.props.setCurrentCategoryId(prevProps.match.params.catId);
      // this.props.fetchDocuments();
      // this.props.fetchCurrentCategory(this.props.match.params.catId);
      this.fetchProps(this.props);
    }
  }

  fetchProps = (props) => {
    this.props.setCurrentCategoryId(props.match.params.catId);
    this.props.setDocumentsCategoryId(props.match.params.catId);
    this.props.fetchDocuments();
    this.props.fetchCurrentCategory(props.match.params.catId);
  };

  componentWillUnmount() {
    // this.props.unsetCurrentCategoryId();
    this.props.setCurrentCategoryId(null);
    this.props.setDocumentsCategoryId(null);
    this.props.clearPagination();
  }

  getCategoryLink = (locale) => {
    let category = this.props.currentCategory;
    if (category) {
      return (
        <Link
          to={`/${locale}/documenten/${makePATHUrl(
            category.name,
            category.id
          )}`}
        >
          {category.name}
        </Link>
      );
    }
  };

  handleMouseOver = (id) => {
    this.props.fetchDocument(id, "listPage");
  };

  getMetaFields = () => {
    if (_.size(this.props.currentCategory) > 0) {
      return (
        <Helmet>
          <title>{this.props.currentCategory.meta_title}</title>
          <meta
            name="description"
            content={this.props.currentCategory.meta_description}
          />
          <meta
            name="keywords"
            content={this.props.currentCategory.meta_keyword}
          />
        </Helmet>
      );
    }
  };

  fetchCurrentDocument = (itemId) => {
    this.props.setCurrentDocumentId(itemId);
    this.props.fetchDocument(itemId);
  };

  render() {
    let subcatsDiv =
      _.size(this.props.subCategories) > 0 ? (
        <div className="inner-page-cont subcate-cont">
          <div className="container">
            <div className="row">
              <SubCategoriesList
                items={this.props.subCategories}
                assignedUsers={
                  this.props.currentCategory
                    ? this.props.currentCategory.selectedUsers
                    : null
                }
              />
            </div>
          </div>
        </div>
      ) : null;
    let locale = language.getIso2();
    return (
      <div className="DocumentListPage">
        {this.getMetaFields()}
        <Topbar
          title={strings.get("Client.homePage.title")}
          subtitle={
            this.props.currentCategory ? this.props.currentCategory.name : null
          }
          description={
            this.props.currentCategory
              ? this.props.currentCategory.description
              : null
          }
          readMoreLink={
            this.props.currentCategory
              ? this.props.currentCategory.read_more_link
              : null
          }
          // handleLangChange={this.props.handleLangChange}
          // currentLang={this.props.currentLang}
          divider
          menuItem={"categories"}
        />

        <div className="breadcrumb-cont">
          <div className="container">
            <Breadcrumbs>
              <Link to={`/${locale}/`}>
                {strings.get("Client.homePage.home")}
              </Link>
              <Link to={`/${locale}/documenten`}>
                {strings.get("Client.homePage.title")}
              </Link>
              {_.size(this.props.subCategoriesList)
                ? null
                : this.props.subCategoriesList.map((subCategory) => (
                    <Link
                      key={subCategory.id}
                      to={`/${locale}/documenten/${makePATHUrl(
                        subCategory.name,
                        subCategory.id
                      )}`}
                    >
                      {subCategory.name}
                    </Link>
                  ))}
              {this.getCategoryLink(locale)}
            </Breadcrumbs>
          </div>
        </div>
        {subcatsDiv}
        <div className="inner-page-cont documentlist-cont">
          <div className="container">
            <div className="PageSorter col-md-10">
              <div>
              <h2> {strings.get("Client.category.documents")} </h2>
              </div>
              <PageSize
              pagination={this.props.pagination}
              setPageSize={this.props.setPageSize}
              fetchItems={this.props.fetchDocuments}
            />
            </div>
            <DocumentList
              {...this.props}
              catName={this.props.match.params.catName}
              items={this.props.documents}
              onMouseOver={this.handleMouseOver}
              description={
                this.props.currentCategory
                  ? this.props.currentCategory.description
                  : null
              }
              fetchCurrentDocument={this.fetchCurrentDocument}
            />
            <Pagination
              pagination={this.props.pagination}
              setCurrentPage={this.props.setCurrentPage}
              fetchItems={this.props.fetchDocuments}
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function getSubCategoriesList(state, category) {
  if (_.isObject(category) && category.parent_id) {
    const subCat = categoriesSelectors.getItemById(state, category.parent_id);
    subCategoriesList.push(subCat);
    getSubCategoriesList(state, subCat);
  }
}

let subCategoriesList = [];
function mapStateToProps(state) {
  subCategoriesList = [];
  let currentCategory = categoriesSelectors.getCurrentItem(state);
  getSubCategoriesList(state, currentCategory);
  return {
    documents: documentsSelectors.getItemsByCategory(
      state,
      currentCategory ? currentCategory.id : null
    ),
    subCategories: currentCategory ? currentCategory.children : null,
    currentCategory: currentCategory,
    subCategoriesList: subCategoriesList.reverse(),
    currentDocument: documentsSelectors.getCurrentItem(state),
    exceptions: exceptionsSelectors.getItems(state),
    pagination: documentsSelectors.getPagination(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDocument: (id, type) => {
      dispatch(documentsActions.fetchItem(id, type));
    },
    setCurrentDocumentId: (id) => {
      dispatch(documentsActions.setCurrentItemId(id));
    },
    setDocumentsCategoryId: (id) => {
      dispatch(documentsActions.setCategoryId(id));
    },
    setCurrentPage: (pageNumber) => {
      dispatch(documentsActions.setCurrentPage(pageNumber));
    },
    setPageSize: (pageSize) => {
      dispatch(documentsActions.setPageSize(pageSize));
    },
    clearPagination : () => {
      dispatch(documentsActions.clearPagination());
    },
    sendEmails: (data) => {
      dispatch(documentsActions.sendEmails(data));
    },
    fetchDocuments: () => {
      dispatch(documentsActions.fetchItems());
    },
    fetchAllCategories: () => {
      dispatch(categoriesActions.fetchAllItems());
    },
    fetchCurrentCategory: (id) => {
      dispatch(categoriesActions.fetchItem(id));
    },
    fetchAllSteps: () => {
      dispatch(stepsActions.fetchAllItems());
    },
    setCurrentCategoryId: (id) => {
      dispatch(categoriesActions.setCurrentItemId(id));
    },
    // unsetCurrentCategoryId: () => {
    //   dispatch(categoriesActions.unsetCurrentItemId());
    // },
    setCurrentModal: (data) => {
      dispatch(settingsActions.setCurrentModal(data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentListPage);
