import React, { Component } from "react";
import { connect } from "react-redux";
import strings from "../../services/strings";
import language from "../../services/language";
import { Link } from "react-router-dom";
import _ from "lodash";

import "./SearchBoxWorkflow.scss";
import { makePATHUrl } from "../../helpers";

import * as workflowsActions from "../../store/workflows/actions";
import * as workflowsSelectors from "../../store/workflows/selectors";
import * as categoriesSelectors from "../../store/workflowCategories/selectors";

class SearchBoxWorkflow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
    };
  }

  fetched = [];

  componentDidMount() {
    this.props.fetchAllWorkflows();
    // this.props.getPlaceholder();
    window.addEventListener("click", this.handleWindowClick);
    // this.props.getDenyMessage();
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.currentLang !== this.props.currentLang)
  //     this.props.getPlaceholder();
  // }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleWindowClick);
  }

  getResult = () => {
    let locale = language.getIso2();
    let items = _.filter(this.props.workflows, (item) => {
      return !!item.name
        .toLocaleLowerCase()
        .match(this.state.searchTerm.toLocaleLowerCase());
    });

    items = _.map(items, (item) => {
      const category =
        item.categories[0] && this.props.getItemById(item.categories[0].id);
      if (category) {
        return (
          <li key={_.uniqueId()}>
            <Link
              to={`/${locale}/workflows/${makePATHUrl(
                category.name,
                category.id
              )}/${encodeURIComponent(makePATHUrl(item.name, item.id))}`}
              onMouseOver={() => this.handleMouseOver(item.id)}
            >
              {item.name}
            </Link>
          </li>
        );
      }
    });
    items = _.slice(items, 0, 5);

    if (this.state.searchTerm && _.size(items)) {
      return (
        <div className="list-wrapper">
          <ul>{items}</ul>
        </div>
      );
    }
  };

  handleMouseOver = (id) => {
    if (!_.find(this.fetched, (item) => item === id)) {
      this.props.fetchWorkflow(id);
      this.fetched.push(id);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleChange = (e) => {
    let searchTerm = e.target.value;
    this.setState({ searchTerm });
  };

  handleWindowClick = () => {
    this.setState({ searchTerm: "" });
  };

  render() {
    let className = "SearchBoxWorkflow " + this.props.className;
    return (
      <div className={className}>
        <form onSubmit={this.handleSubmit}>
          <input
            type="text"
            placeholder={strings.get("Client.workflows.contentSearch")}
            value={this.state.searchTerm}
            onChange={this.handleChange}
          />
          <i className="ion-search"></i>
        </form>
        {this.getResult()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    getItemById: (id) => categoriesSelectors.getItemById(state, id),
    workflows: workflowsSelectors.getItems(state),
    placeholder: workflowsSelectors.getSearchPlaceholder(state),
    denyMessage: workflowsSelectors.getDenyMessage(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchWorkflow: (id) => {
      dispatch(workflowsActions.fetchItem(id));
    },
    fetchAllWorkflows: () => {
      dispatch(workflowsActions.fetchAllItems());
    },
    // getPlaceholder: () => {
    //   dispatch(workflowsActions.getPlaceholder());
    // },
    // getDenyMessage: () => {
    //   dispatch(workflowsActions.getDenyMessage());
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBoxWorkflow);
