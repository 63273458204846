import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { Accordion, Card, ListGroup, Badge } from "react-bootstrap";

import * as categoriesActions from "../../store/categories/actions";
import * as categoriesSelectors from "../../store/categories/selectors";

import * as documentsActions from "../../store/documents/actions";

import strings from "../../services/strings";
import { CustomFadeModal } from "../PopUp";
import { viewModals } from "../../helpers";
import { NotificationManager } from "react-notifications";

const origin = {
  drafts: "drafts",
  workflows: "workflows",
};

const initialState = {
  categories: [],
  selectedDocs: {},
  origin: "",
  limit: 10,
  matchedValues: {},
};

class DraftsDataReUse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
  }
  componentDidMount() {
    if (!_.size(this.props.categories)) {
      this.props.fetchAllCategories();
    }
    if (!_.size(this.state.origin) && _.size(this.props.origin)) {
      if (this.props.origin === origin.drafts) {
        this.setState({ origin: origin.drafts });
      }
      if (this.props.origin === origin.workflows) {
        this.setState({ origin: origin.workflows });
      }
    }
    this.getCategoryTree();
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {
    if (this.props.draft.showModal === true) {
      this.getCategoryTree();
      this.showModal();
    }
    //incase of workflows show milestones as category
    if (
      this.props.milestones &&
      _.size(this.props.milestones) &&
      this.props.milestones !== this.state.categories
    ) {
      this.setState({ categories: this.props.milestones });
    }
  }

  showModal = () => {
    if (this.refs.DRAFTS_DATA_RE_USE) this.refs.DRAFTS_DATA_RE_USE.show();
  };

  getCategoryTree = (props = this.props) => {
    if (
      props.categories &&
      _.size(props.categories) &&
      !_.size(this.state.categories)
    ) {
      let categories = props.categories;
      categories = _.orderBy(categories, "parent_id", "asc");
      categories = this.nestedTree(categories);
      this.setState({ categories });
    }
  };

  nestedTree = (files, id = null, link = "parent_id") =>
    files
      .filter((item) => item[link] === id)
      .map((item) => ({ ...item, children: this.nestedTree(files, item.id) }));

  hideModal = () => {
    this.props.onHide();
    this.setState(
      {
        selectedDocs: {},
        matchedValues: {},
      },
      () => {
        if (this.refs.DRAFTS_DATA_RE_USE) this.refs.DRAFTS_DATA_RE_USE.hide();
      }
    );
  };

  getNumberOfSelectedDocs = () => {
    let selectedDocs = this.state.selectedDocs;
    let length = 0;
    for (const category in selectedDocs) {
      if (selectedDocs.hasOwnProperty(category)) {
        length = length + _.size(selectedDocs[category]);
      }
    }
    return length;
  };

  handleProcessDocuments = () => {
    let selectedDocs = this.state.selectedDocs;
    let ids = [];
    _.map(selectedDocs, (category) => {
      ids = ids.concat(Object.keys(category));
    });
    this.props.processDraftDataWithDocs(this.props.draftId, ids);
    this.hideModal();
  };

  toggleDocSelect = (event, doc) => {
    if (this.props.origin === origin.drafts) {
      if (
        this.state.limit > this.getNumberOfSelectedDocs() ||
        !event.target.checked
      ) {
        this.toggleDocsInList(doc);
      } else {
        NotificationManager.info(
          `${strings.get(
            "Exceptions.notifications.draftReuse.MaxNumberWarning"
          )} : 10`
        );
      }
    } else {
      this.toggleMilestoneDocsInList(doc);
    }
  };

  toggleDocsInList = (doc) => {
    let selectedDocs = this.state.selectedDocs;
    if (
      selectedDocs[doc.category_id] &&
      selectedDocs[doc.category_id][doc.id]
    ) {
      delete selectedDocs[doc.category_id][doc.id];
      if (!_.size(selectedDocs[doc.category_id])) {
        delete selectedDocs[doc.category_id];
      }
    } else {
      selectedDocs[doc.category_id] = selectedDocs[doc.category_id] || {};
      selectedDocs[doc.category_id][doc.id] = doc;
    }
    this.setState({ selectedDocs });
  };

  toggleMilestoneDocsInList = (doc) => {
    let selectedDocs = this.state.selectedDocs;
    if (
      selectedDocs[doc.pivot["milestone_id"]] &&
      selectedDocs[doc.pivot["milestone_id"]][doc.id]
    ) {
      delete selectedDocs[doc.pivot["milestone_id"]][doc.id];
      if (!_.size(selectedDocs[doc.pivot["milestone_id"]])) {
        delete selectedDocs[doc.pivot["milestone_id"]];
      }
    } else {
      selectedDocs[doc.pivot["milestone_id"]] =
        selectedDocs[doc.pivot["milestone_id"]] || {};
      selectedDocs[doc.pivot["milestone_id"]][doc.id] = doc;
    }
    this.setState({ selectedDocs });
  };

  toggleCategorySelect = (event, category) => {
    if (this.props.origin === origin.drafts) {
      if (
        this.state.limit > this.getNumberOfSelectedDocs() ||
        !event.target.checked
      ) {
        this.toggleCategoriesInList(event, category);
      } else {
        NotificationManager.info(
          `${strings.get(
            "Exceptions.notifications.draftReuse.MaxNumberWarning"
          )} : 10`
        );
      }
    } else {
      this.toggleCategoriesInList(event, category);
    }
  };

  toggleCategoriesInList = (event, category) => {
    let selectedDocs = this.state.selectedDocs;
    if (selectedDocs[category.id]) {
      delete selectedDocs[category.id];
    } else {
      let docs = selectedDocs[category.id] || {};
      let crossMaxDocsLimit = false;
      let selectedDocsCount = this.getNumberOfSelectedDocs();
      _.map(category.documents, (doc) => {
        if (this.state.limit > selectedDocsCount) {
          docs[doc.id] = doc;
          selectedDocsCount++;
        } else {
          crossMaxDocsLimit = true;
        }
      });
      if (crossMaxDocsLimit) {
        NotificationManager.info(
          `${strings.get(
            "Exceptions.notifications.draftReuse.MaxNumberWarning"
          )} : 10`
        );
      }
      if (_.size(docs)) {
        selectedDocs[category.id] = docs;
      } else {
        NotificationManager.info(
          strings.get(
            "Exceptions.notifications.draftReuse.noDirectDocInCategory"
          )
        );
      }
    }
    this.setState({ selectedDocs });
  };

  checkCategoryCheckBoxValue = ({ id, documents, ...rest }) => {
    let selectedDocs = this.state.selectedDocs;
    if (selectedDocs[id] && _.size(documents) === _.size(selectedDocs[id])) {
      return true;
    } else {
      return false;
    }
  };

  handleDraftMatch = async (docId) => {
    await this.props.matchDraftWithDoc(
      this.props.draft.draftId,
      docId,
      this.handleMatchPercentCallback
    );
  };

  handleMatchPercentCallback = (result) => {
    let matchedValues = this.state.matchedValues;
    matchedValues[result["docId"]] = result["percent"];
    this.setState({ matchedValues });
  };

  getDocumentCheckedStatus = (doc) => {
    if (this.state.origin === origin.drafts) {
      if (
        this.state.selectedDocs[doc.category_id] &&
        this.state.selectedDocs[doc.category_id][doc.id]
      ) {
        return true;
      }
      return false;
    }
    if (this.state.origin === origin.workflows) {
      if (
        this.state.selectedDocs[doc.pivot["milestone_id"]] &&
        this.state.selectedDocs[doc.pivot["milestone_id"]][doc.id]
      ) {
        return true;
      }
      return false;
    }
  };

  getDocsList = (docs) => {
    return (
      <ListGroup className="docs-list-container">
        {_.size(docs) ? (
          _.map(docs, (doc) => (
            <ListGroup.Item key={_.uniqueId()}>
              <input
                type="checkbox"
                title={strings.get("Client.draftDataReUse.parentCheckBoxTitle")}
                checked={this.getDocumentCheckedStatus(doc)}
                onChange={(e) => this.toggleDocSelect(e, doc)}
              />
              <span>{doc.name}</span>
              <button
                onClick={() => this.handleDraftMatch(doc.id)}
                className={
                  this.state.matchedValues[doc.id] ||
                  this.state.matchedValues[doc.id] === 0
                    ? "checked"
                    : ""
                }
              >
                {`${
                  this.state.matchedValues[doc.id] ||
                  this.state.matchedValues[doc.id] === 0
                    ? this.state.matchedValues[doc.id]
                    : "Check"
                } %`}
              </button>
            </ListGroup.Item>
          ))
        ) : (
          <ListGroup.Item>
            {strings.get("Client.draftDataReUse.noDocs")}
          </ListGroup.Item>
        )}
      </ListGroup>
    );
  };

  getCategoriesList = (categories) =>
    _.map(categories, (category) => {
      return (
        <Card key={_.uniqueId()}>
          <Card.Header>
            <input
              type="checkbox"
              title={strings.get("Client.draftDataReUse.parentCheckBoxTitle")}
              checked={this.checkCategoryCheckBoxValue(category)}
              onChange={(e) => this.toggleCategorySelect(e, category)}
            />
            <Accordion.Toggle eventKey={category.id}>
              <b>{category.name}</b>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={category.id}>
            <Card.Body>
              {this.getDocsList(category.documents)}
              {_.size(category.children)
                ? this.getParentCategoriesList(
                    category.children,
                    "child-accordion"
                  )
                : null}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    });

  getParentCategoriesList = (
    categories = this.state.categories,
    childClassName = ""
  ) => (
    <Accordion className={childClassName}>
      {this.getCategoriesList(categories)}
    </Accordion>
  );

  getDocsBadge = (selectedDocs = this.state.selectedDocs) => {
    let data = [];
    _.map(selectedDocs, (category) => {
      _.map(category, (doc) => {
        data.push(
          <Badge key={_.uniqueId()} className="doc-badges">
            {doc.name}{" "}
            <i
              className="ion-android-cancel clickable"
              onClick={(e) => this.toggleDocSelect(e, doc)}
            />
          </Badge>
        );
      });
    });
    return data;
  };

  docListModal = () => (
    <CustomFadeModal
      className="boron-modal draft-reuse-modal"
      ref={viewModals.DRAFTS_DATA_RE_USE}
      onHide={this.hideModal}
    >
      <div className="modal-content-container">
        <span className="helperName">
          <b>
            <h4>
              {strings.get("Client.draftDataReUse.modal.title")}
              {" : "}
              {this.props.draft.name && this.props.draft.name.toUpperCase()}
            </h4>
          </b>
          <div className="close-btn">
            <i
              className="ion-android-cancel clickable"
              onClick={this.hideModal}
            />
          </div>
        </span>
        <div className="selected-documents-container">
          <label>
            {strings.get("Client.draftDataReUse.modal.docListLabel")}
          </label>
          {" : "}
          {_.size(this.state.selectedDocs) ? (
            <>
              <div>{this.getDocsBadge()}</div>
              <button onClick={this.handleProcessDocuments}>
                {strings.get("Client.draftDataReUse.modal.buttonTitle")}
                {this.props.origin === origin.drafts
                  ? `  (${this.getNumberOfSelectedDocs()}/${this.state.limit})`
                  : `  (${this.getNumberOfSelectedDocs()})`}
              </button>
            </>
          ) : (
            <label>
              {strings.get("Client.draftDataReUse.modal.noDocSelection")}
            </label>
          )}
        </div>
        <div className="categories-container">
          {this.getParentCategoriesList()}
        </div>
      </div>
    </CustomFadeModal>
  );
  render() {
    return <div className="drafts-data-reuse">{this.docListModal()}</div>;
  }
}

DraftsDataReUse.propTypes = {
  draft: PropTypes.object.isRequired,
  origin: PropTypes.string.isRequired, // drafts or workflows
  onHide: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    categories: categoriesSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllCategories: (deleteCache) => {
      dispatch(categoriesActions.fetchAllItems(deleteCache));
    },
    matchDraftWithDoc: (draftId, docId, callbackFunc) => {
      dispatch(
        documentsActions.matchDraftWithDoc(draftId, docId, callbackFunc)
      );
    },
    processDraftDataWithDocs: (draftId, docId) => {
      dispatch(documentsActions.processDraftDataWithDocs(draftId, docId));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DraftsDataReUse);
