import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import _ from "lodash";

import api from "../../services/api";
import strings from "../../services/strings";

import "./Feedback.scss";

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      email: "",
      isOpen: false,
      isSendSuccess: false,
      sideTitle: strings.get("Client.feedback.sideTitle"),
      sideTitleLoading: false,
    };
  }

  componentDidMount() {
    if (
      this.props.sideTitle &&
      this.props.sideTitle !== "" &&
      this.props.sideTitle !== undefined
    ) {
      let sideTitle = this.props.sideTitle;
      this.setState({ sideTitle, sideTitleLoading: true });
    }
    _.delay(() => {
      if (this.state.sideTitle === undefined && !this.state.sideTitleLoading) {
        let sideTitle = strings.get("Client.feedback.sideTitle");
        this.setState({ sideTitle, sideTitleLoading: true });
      }
    }, 600);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.sideTitle) {
      let sideTitle = nextProps.sideTitle;
      this.setState({ sideTitle, sideTitleLoading: true });
    }
  }

  handleFeedbackHide = () => {
    this.setState({
      email: "",
      description: "",
    });
  };

  handleInputChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  onSubmit = (data) => {
    let params = new Map();
    _.map(data, (value, key) => {
      params.set(key, value);
    });
    params.set("url", window.location.href);
    params.set("subject", "Feedback");
    // POST request to API
    api
      .post("/feedback", params)
      .then((res) => console.log(res))
      .then(() => this.setState({ isSendSuccess: true }));
  };

  toggleVisibility = (e) => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    // var visibilityState = this.state.isOpen ? "visible" : "hidden";
    if (!this.state.sideTitle) {
      return false;
    }
    return (
      <div
        className={
          this.state.isOpen ? "feedback-box-open" : "feedback-box-closed"
        }
      >
        <div className="trigger" onClick={this.toggleVisibility}>
          <h4 className="triggerSlide">{this.state.sideTitle}</h4>
        </div>
        <div className="feedback-form">
          <div className="container-row">
            <div className="row">
              <div className="col-md-12 feedbackForm">
                <div
                  className="close-trigger-mobile"
                  onClick={this.toggleVisibility}
                >
                  <h3>
                    <span className="ion-android-cancel clickable"></span>
                  </h3>
                </div>
                <Form
                  onSubmit={this.onSubmit}
                  render={({
                    handleSubmit,
                    reset,
                    submitting,
                    pristine,
                    values,
                  }) => (
                    <form
                      id="feedback-form"
                      className="form-group"
                      onSubmit={handleSubmit}
                    >
                      {this.state.isSendSuccess ? (
                        <div className="alert alert-success">
                          {strings.get("Client.contactForm.successMsg")}
                        </div>
                      ) : null}
                      <h3>{strings.get("Client.feedback.title")}</h3>

                      <label className="feedback-label labelForModal">
                        {strings.get("Client.feedback.preinput")}
                      </label>
                      <Field name="description" component="textarea">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <textarea
                              className="form-control feedback-area"
                              {...input}
                              type="textarea"
                            />
                          </div>
                        )}
                      </Field>

                      <label className="feedback-label labelForModal">
                        {strings.get("Client.feedback.postinput")}
                      </label>

                      <Field name="email">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <input
                              className="form-control"
                              {...input}
                              type="email"
                              placeholder={strings.get("Client.feedback.email")}
                            />
                          </div>
                        )}
                      </Field>

                      <button
                        type="submit"
                        className={
                          this.state.isOpen
                            ? "btn btn1 pull-right feedback-button"
                            : null
                        }
                      >
                        {strings.get("Client.feedback.submit")}
                      </button>
                    </form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
