import _ from 'lodash';

export function getItem(state) {
	return state.drafts;
}

export function isSaved(state) {
	return state.drafts.saved ? state.drafts.saved : false;
}

export function alreadyExist(state) {
	return state.drafts.alreadyExist ? state.drafts.alreadyExist : false;
}

export function getFields(state){
    return state.drafts.fields;
}

export function getFieldsOrder(state){
    return state.drafts.fieldsOrder || [];
}

export function getSelectors(state){
    return state.drafts.selectors;
}

export function getAttachmentsUrl(state){
  return state.drafts.attachmentsUrl;
}

export function getClauses(state){
    return state.drafts.clauses;
}

export function getSteps(state){
    return state.drafts.steps;
}

export function getShareFields(state){
    return state.drafts.shareFields;
}

export function getFilters(state) {
	return state.drafts.filters;
}

export function getPagination(state) {
	return state.drafts.pagination;
}

export function getItemsByPage(state, page) {
	if (!state.drafts.idsByPage['_' + page]) {
		page = (getPagination(state)).previousPage;
	};
	return _.map(state.drafts.idsByPage['_' + page], (itemId) => {
		return state.drafts.itemsById['_' + itemId]
	});
}

export function getItemById(state, id) {
	return state.drafts.itemsById['_' + id];
}

export function getCurrentItem(state) {
	return state.drafts.currentItemId ? getItemById(state, state.drafts.currentItemId) : null;
}

export function getItems(state) {
	return state.drafts.itemsById;
}