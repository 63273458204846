import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import _ from "lodash";
import Dropzone from "react-dropzone";
import ReactAvatarEditor from "react-avatar-editor";

import CustomDropModal from "../PopUp";

import strings from "../../services/strings";

import "./UserForm.scss";
const pica = require("pica/dist/pica.min")();

class UserForm extends Component {
  setEditorRef = (editor) => (this.editor = editor);

  constructor(props) {
    super(props);
    this.state = {
      currentItemLoaded: false,
      file: null,
      croppedImgUrl: null,
      scale: 1,
      imageURL: null,
      fileRejected: false,
      form: {
        first_name: "",
        last_name: "",
        email: "",
        image: "",
        password: "",
        newPassword: "",
        confirmPassword: "",
      },
    };
  }

  componentDidMount() {
    this.tryLoadCurrentItem();
  }

  componentDidUpdate() {
    this.tryLoadCurrentItem();
  }

  tryLoadCurrentItem = () => {
    if (
      this.props.currentItem &&
      !_.isEmpty(this.props.currentItem) &&
      !this.state.currentItemLoaded
    ) {
      let form = _.extend({}, this.state.form);
      _.map(this.state.form, (value, key) => {
        form[key] = this.props.currentItem[key]
          ? this.props.currentItem[key]
          : this.state.form[key];
      });
      this.setState({
        currentItemLoaded: true,
        form,
        imageURL: this.props.currentItem && this.props.currentItem.imageURL,
      });
    }
  };

  hasError = (inputName) => {
    return !!this.props.exceptions[inputName];
  };

  getErrorClass = (inputName, defaultClasses = "") => {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  };

  getErrorMessage = (inputName) => {
    return this.props.exceptions[inputName];
  };

  getPreviewImage = () => {
    const { croppedImgUrl } = this.state;
    if (croppedImgUrl && !this.state.file) {
      return <img src={croppedImgUrl} alt="userImage"/>;
    } else {
      return this.state.imageURL ? <img src={this.state.imageURL} alt="userImage" /> : null;
    }
  };

  handleLogoutClick = () => {
    this.props.logout();
  };

  handleInputChange = (e) => {
    let form = {};
    form[e.target.name] = e.target.value;
    this.setState({
      form: _.extend(this.state.form, form),
    });
  };




  handleFileDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      this.setState({
        fileRejected: true,
      });
    } else {
      this.setState({
        file: _.first(acceptedFiles),
        fileRejected: false,
      });
    }
  };

  handleSaveClick = (e) => {
    e.preventDefault();
    this.props.saveItem(this.state);
  };

  handleSavePasswordClick = (e) => {
    this.props.updatePassword({
      password: this.state.form.password,
      newPassword: this.state.form.newPassword,
      confirmPassword: this.state.form.confirmPassword,
    });

    setTimeout(() => {
      if (
        !this.hasError("password") &&
        !this.hasError("newPassword") &&
        !this.hasError("confirmPassword")
      ) {
        this.hideModal();
        this.showSaveModal();
      }
    }, 500);
  };

  handleCancelClick = (e) => {
    e.preventDefault();
  };

  cropImage = async (e) => {
    // const croppedImgUrl = this.editor.getImageScaledToCanvas().toDataURL();
    const img = this.editor.getImage();
    var offScreenCanvas = document.createElement("canvas");

    offScreenCanvas.width = 250;
    offScreenCanvas.height = 250;

    const picaCanvas = await pica.resize(img, offScreenCanvas);

    picaCanvas.toBlob(
      blob => URL.createObjectURL(blob),
      "image/jpeg",
      1.5
    );

    const croppedImgUrl = picaCanvas.toDataURL("image/jpeg", 75)
    this.setState({
      file: null,
      croppedImgUrl,
    });
  };

  clearImage = (e) => {
    const { file, imageURL, croppedImgUrl } = this.state;
    const { currentItem } = this.props;
    let state = {
      file: null,
      croppedImgUrl: null,
    };

    if (file === null) {
      state["imageURL"] =
        croppedImgUrl === null
          ? currentItem &&
            currentItem.imageURL &&
            currentItem.imageURL !== imageURL
            ? currentItem.imageURL
            : ""
          : imageURL;
    }

    this.setState({
      ...state,
    });
  };

  showModal = () => {
    this.refs.modalChangePassword.show();
  };

  showSaveModal = () => {
    this.refs.saveModal.show();
  };

  hideSaveModal = () => {
    this.refs.saveModal.hide();
  };

  handleShowModal = () => {
    _.delay(() => {
      this.hideSaveModal();
    }, 500);
  };

  hideModal = () => {
    this.refs.modalChangePassword.hide();
  };

  render() {
    const {form} = this.state
    let firstNameLabel = this.hasError("first_name")
      ? `${strings.get("Client.profilePage.firstName")} ${this.getErrorMessage(
          "first_name"
        )}`
      : strings.get("Client.profilePage.firstName");
    let lastNameLabel = this.hasError("last_name")
      ? `${strings.get("Client.profilePage.lastName")} ${this.getErrorMessage(
          "last_name"
        )}`
      : strings.get("Client.profilePage.lastName");
    let emailLabel = this.hasError("email")
      ? `${strings.get("Client.profilePage.email")} ${this.getErrorMessage(
          "email"
        )}`
      : strings.get("Client.profilePage.email");
    let passwordLabel = this.hasError("password")
      ? `${strings.get(
          "Client.changePasswordPage.password"
        )} ${this.getErrorMessage("password")}`
      : strings.get("Client.changePasswordPage.password");
    let newPasswordLabel = this.hasError("newPassword")
      ? `${strings.get(
          "Client.changePasswordPage.newPassword"
        )} ${this.getErrorMessage("newPassword")}`
      : strings.get("Client.changePasswordPage.newPassword");
    let confirmPasswordLabel = this.hasError("confirmPassword")
      ? `${strings.get(
          "Client.changePasswordPage.confirmPassword"
        )} ${this.getErrorMessage("confirmPassword")}`
      : strings.get("Client.changePasswordPage.confirmPassword");
    let imageLabel = this.hasError("image")
      ? strings.get("Exceptions.imageTooBig")
      : strings.get("Client.profilePage.image");
    // let dropzoneContent = this.getPreviewImage()
    //   ? this.getPreviewImage()
    //   : () => {
    //       strings.get("Client.profilePage.chooseImage");
    //     };

    return (
      <div className="UserForm">
        <form className="col-sm-12 col-md-12">
          <div className="row">
            <div className="col-sm-12 col-md-7">
              <div className={this.getErrorClass("first_name", "form-group")}>
                <label className="control-label">{firstNameLabel}</label>
                <input
                  className="form-control"
                  type="text"
                  name="first_name"
                  value={form.first_name}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className={this.getErrorClass("last_name", "form-group")}>
                <label className="control-label">{lastNameLabel}</label>
                <input
                  className="form-control"
                  type="text"
                  name="last_name"
                  value={form.last_name}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className={this.getErrorClass("email", "form-group")}>
                <label className="control-label">{emailLabel}</label>
                <input
                  className="form-control"
                  type="text"
                  name="email"
                  value={form.email}
                  onChange={this.handleInputChange}
                />
              </div>

              <div className="form-group mt-10 mb-40 ">
                <a
                  className="btn btn-primary btn1 pull-left"
                  onClick={this.showModal}
                >
                  {strings.get("Client.profilePage.changePassword")}
                </a>
              </div>
            </div>

            <div className="col-sm-12 col-md-5">
              <div className={this.getErrorClass("image", "form-group")}>
                <label className="control-label">{imageLabel}</label>
                <div className="image-container">
                  {this.state.file || this.state.imageURL ? (
                    <input
                      key="crop-btn1"
                      type="button"
                      className="crop-image-btn"
                      onClick={this.clearImage}
                      value="x"
                    />
                  ) : (
                    ""
                  )}

                  {this.state.file ? (
                    <div className="croping-zone">
                      <ReactAvatarEditor
                        scale={this.state.scale}
                        rotate={0}
                        image={this.state.file}
                        width={150}
                        height={150}
                        style={{
                          border: "2px dotted #d2d2d2",
                          borderRadius: 3,
                        }}
                        ref={(ref) => this.setEditorRef(ref)}
                      />
                    </div>
                  ) : (
                    <Dropzone
                      className="dropzone"
                      onDrop={this.handleFileDrop}
                      multiple={false}
                      maxSize={4096000}
                      accept="image/*"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          {this.getPreviewImage() ? (
                            this.getPreviewImage()
                          ) : (
                            <span>
                              {strings.get("Client.profilePage.chooseImage")}
                            </span>
                          )}
                        </div>
                      )}
                      {/* {dropzoneContent} */}
                    </Dropzone>
                  )}
                </div>

                <div className="help-block">
                  {this.state.file
                    ? [
                        <input
                          key="slider"
                          type="range"
                          step="0.01"
                          min="0.1"
                          max="2"
                          name="scale"
                          value={this.state.scale}
                          onChange={(e) =>
                            this.setState({ [e.target.name]: e.target.value })
                          }
                        />,
                        <input
                          key="crop-btn"
                          type="button"
                          className="crop-image-btn"
                          onClick={this.cropImage}
                          value="Crop"
                        />,
                      ]
                    : ""}
                </div>
              </div>
              <div className="row">
                <div className="col-xs-6">
                  <button
                    className="btn  btn-primary btn1 opslann-btn1 btn-block"
                    onClick={this.handleSaveClick}
                  >
                    {strings.get("Client.profilePage.save")}
                  </button>
                </div>
                <div className="col-xs-6">
                  <Link
                    className="btn btn-default close-link btn2 sluit-btn1 btn-block"
                    to={"/"}
                  >
                    {" "}
                    <i className="ion-android-cancel" />{" "}
                    {strings.get("Client.profilePage.close")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <CustomDropModal className="boron-modal" ref="modalChangePassword">
            <div>
              <h2>{strings.get("Client.profilePage.changePassword")}</h2>
              <div className={this.getErrorClass("password", "form-group")}>
                <input
                  className="form-control"
                  type="password"
                  name="password"
                  placeholder={passwordLabel}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className={this.getErrorClass("newPassword", "form-group")}>
                <input
                  className="form-control"
                  type="password"
                  name="newPassword"
                  placeholder={newPasswordLabel}
                  onChange={this.handleInputChange}
                />
              </div>
              <div
                className={this.getErrorClass("confirmPassword", "form-group")}
              >
                <input
                  className="form-control"
                  type="password"
                  name="confirmPassword"
                  placeholder={confirmPasswordLabel}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="form-actions">
              <a
                className="btn btn-secondary"
                onClick={this.handleSavePasswordClick}
              >
                {strings.get("Client.changePasswordPage.save")}
              </a>
              <a className="btn btn-default" onClick={this.hideModal}>
                {strings.get("Client.changePasswordPage.cancel")}
              </a>
            </div>
          </CustomDropModal>
          <CustomDropModal
            className="boron-modal"
            ref="saveModal"
            onShow={this.handleShowModal}
          >
            <span>
              <h2>{strings.get("Client.settings.settingsSaved")}</h2>
            </span>
          </CustomDropModal>
        </form>
      </div>
    );
  }
}

UserForm.propTypes = {
  currentItem: PropTypes.object,
  saveItem: PropTypes.func.isRequired,
};

export default UserForm;
