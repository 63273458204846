import React from "react";

export default class AdInArticle extends React.Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return (
      <ins
        className="adsbygoogle"
        style={{
          display: "block",
          textAlign: "center",
        }}
        data-ad-layout="in-article"
        data-ad-format="fluid"
        data-ad-client="ca-pub-0090682890508732"
        data-ad-slot="4667003289"
        data-full-width-responsive="true"
      />
    );
  }
}
