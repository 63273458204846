import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import DropModal from "reboron/DropModal";
import FadeModal from "reboron/FadeModal";

export default class CustomDropModal extends Component {
  handleOnShow = () => {
    document.querySelector("body").style.overflow = "hidden";
    if (this.props && typeof this.props.onShow === "function") {
      this.props.onShow();
    }
  };

  handleOnHide = () => {
    if (!_.size(document.querySelectorAll(".boron-modal")))
      document.querySelector("body").style.overflow = "auto";
    if (this.props && typeof this.props.onHide === "function") {
      this.props.onHide();
    }
  };

  show = () => {
    this.refs.refrence.show();
  };

  hide = () => {
    this.refs.refrence.hide();
  };

  render() {
    return (
      <DropModal
        className={this.props.className ? this.props.className : ""}
        ref={"refrence"}
        backdrop={this.props.backdrop}
        keyboard={this.props.keyboard}
        onShow={this.handleOnShow}
        onHide={this.handleOnHide}
      >
        {this.props.children}
      </DropModal>
    );
  }
}

CustomDropModal.propTypes = {
  className: PropTypes.string.isRequired,
  onHide: PropTypes.func,
  onShow: PropTypes.func,
  backdrop: PropTypes.any,
  keyboard: PropTypes.bool,
  children: PropTypes.any,
};

CustomDropModal.defaultProps = {
  keyboard: true,
};

export class CustomFadeModal extends Component {
  handleOnShow = () => {
    document.querySelector("body").style.overflow = "hidden";
    if (typeof this.props.onShow === "function") {
      this.props.onShow();
    }
  };

  handleOnHide = () => {
    if (!_.size(document.querySelectorAll(".boron-modal")))
      document.querySelector("body").style.overflow = "auto";
    if (typeof this.props.onHide === "function") {
      this.props.onHide();
    }
  };

  show = () => {
    this.refs.refrence.show();
  };

  hide = () => {
    this.refs.refrence.hide();
  };

  render() {
    return (
      <FadeModal
        className={this.props.className ? this.props.className : ""}
        ref={"refrence"}
        backdrop={this.props.backdrop}
        keyboard={this.props.keyboard}
        onShow={this.handleOnShow}
        onHide={this.handleOnHide}
      >
        {this.props.children}
      </FadeModal>
    );
  }
}

CustomFadeModal.propTypes = {
  className: PropTypes.string.isRequired,
  onHide: PropTypes.func,
  onShow: PropTypes.func,
  backdrop: PropTypes.any,
  keyboard: PropTypes.bool,
  children: PropTypes.any,
};

CustomFadeModal.defaultProps = {
  keyboard: true,
};
