import { NotificationManager } from "react-notifications";
import api from "../../services/api";
import language from "../../services/language";
import _ from "lodash";

import * as exceptionsActions from "../exceptions/actions";
import * as settingsActions from "../settings/actions";
import * as draftsSelectors from "./selectors";
import strings from "../../services/strings";

export const types = {
  FETCH_DRAFT_DONE: "drafts.FETCH_DRAFT_DONE",
  FETCH_ALL_DRAFTS_DONE: "drafts.FETCH_ALL_DRAFTS_DONE",
  FETCH_DRAFT_DONE_IN_DOC: "drafts.FETCH_DRAFT_DONE_IN_DOC",
  FETCH_DELETE_DRAFT_DONE: "drafts.FETCH_DELETE_DRAFT_DONE",
  CREATE_DRAFT_DONE: "drafts.CREATE_DRAFT_DONE",
  DRAFT_ALREADY_EXIST: "drafts.DRAFT_ALREADY_EXIST",
  DISABLE_EXIST: "drafts.DISABLE_EXIST",
  CLEAR_DRAFTS: "drafts.CLEAR_DRAFTS",
  VALIDATE_ITEM_DONE: "drafts.VALIDATE_ITEM_DONE",
  FETCH_DRAFT_SHARE_FIELDS_DONE: "drafts.FETCH_DRAFT_SHARE_FIELDS_DONE",
  CLEAR_CACHE: "drafts.CLEAR_CACHE",
  CLEAR_SHARE_FIELDS_CACHE: "drafts.CLEAR_SHARE_FIELDS_CACHE",
  SET_CATEGORY_ID: "drafts.SET_CATEGORY_ID",
  SET_SAVED_WORKFLOW_ID: "drafts.SET_SAVED_WORKFLOW_ID",
  SET_FETCH_ONLY_WORKFLOW_DRAFTS: "drafts.SET_FETCH_ONLY_WORKFLOW_DRAFTS",
  SET_SEARCH_TERM: "drafts.SET_SEARCH_TERM",
  SET_CURRENT_PAGE: "drafts.SET_CURRENT_PAGE",
  SET_CURRENT_ITEM_ID: "drafts.SET_CURRENT_ITEM_ID",
  SET_DRAFTS_TYPE: "drafts.SET_DRAFTS_TYPE",
  UPDATE_DRAFTS_ORDER_CHANGE: "drafts.UPDATE_DRAFTS_ORDERS_CHANGE"
};

export function sendEmails(data) {
  return async (dispatch) => {
    try {
      dispatch(settingsActions.setScreenLoading(true));
      let params = new Map();
      _.map(data, (value, key) => {
        if (key !== "to") {
          params.set(key, value);
        } else {
          data.to.forEach((e, i) => {
            params.set(`to[${i}]`, e);
          });
        }
      });
      // PUT request to API
      await api.post(`/shareByEmail`, params);
      NotificationManager.success(
        strings.get("Exceptions.notifications.draftShared")
      );
      // browserHistory.push(`/userDocuments`);

      // dispatch(exceptionsActions.clear());
    } catch (e) {
      NotificationManager.error(strings.get("Exceptions.notifications.error"));
      dispatch(exceptionsActions.process(e));
    } finally {
      dispatch(settingsActions.setScreenLoading(false));
    }
  };
}

export function createDraft(data, documentComments = {}) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      params.set("documentComments", JSON.stringify(documentComments));
      // POST request to API
      let item = await api.post("/drafts", params);
      if (item && item.error && item.code === 409) {
        if (item.error !== "Draft already exist") {
          NotificationManager.error(item.error);
        } else {
          NotificationManager.warning(
            strings.get("Client.notifications.draftAlreadyExist")
          );
          dispatch({
            type: types.DRAFT_ALREADY_EXIST,
          });
        }
      }
      if (item && item.id) {
        NotificationManager.success(strings.get("Client.notifications.added"));
        // dispatch({
        //   type: types.CREATE_DRAFT_DONE,
        //   payload: {
        //     item,
        //   },
        // });
      }
      dispatch(exceptionsActions.clear());
    } catch (e) {
      NotificationManager.error(strings.get("Client.notifications.error"));
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function createRoleDraft(data, documentComments = {}) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      params.set("documentComments", JSON.stringify(documentComments));
      // POST request to API
      let item = await api.post("/roleDrafts", params);
      if (item && item.error && item.code === 409) {
        dispatch({
          type: types.DRAFT_ALREADY_EXIST,
        });
      }
      if (item && item.id) {
        NotificationManager.success(strings.get("Client.notifications.added"));
        // dispatch({
        //   type: types.CREATE_DRAFT_DONE,
        //   payload: {
        //     item,
        //   },
        // });
      }
      dispatch(exceptionsActions.clear());
    } catch (e) {
      NotificationManager.error(strings.get("Client.notifications.error"));
      dispatch(exceptionsActions.process(e));
    }
  };
}

//Update Owner Draft - Revision
export function updateDraft(id, data, type, documentComments = {}) {
  return async (dispatch) => {
    try {
      dispatch(settingsActions.setScreenLoading(true));
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      params.set("language_id", language.get());
      params.set("documentComments", JSON.stringify(documentComments));
      params.set("link", window.location.href);
      if (type === "doc") {
        params.set("is_role", 0);
      } else {
        params.set("is_role", 1);
      }
      // PUT request to API
      await api.post(`/drafts/revision`, params);
      dispatch(settingsActions.setScreenLoading(false));
      dispatch(fetchDraft(id));
      NotificationManager.success(strings.get("Client.notifications.added"));
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(settingsActions.setScreenLoading(false));
      NotificationManager.error(strings.get("Client.notifications.error"));
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function freshExist() {
  return async (dispatch) => {
    dispatch({
      type: types.DISABLE_EXIST,
    });
  };
}

export function clearDrafts() {
  return async (dispatch) => {
    dispatch({
      type: types.CLEAR_DRAFTS,
    });
  };
}

export function setDraftType(type = "drafts") {
  return async (dispatch) => {
    dispatch({
      type: types.SET_DRAFTS_TYPE,
      payload: {
        type,
      },
    });
  };
}

export function updateMyDraft(data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // PUT request to API
      await api.post(`/drafts/update`, params);
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchDraftByDoc(documentId) {
  return async (dispatch) => {
    try {
      let params = new Map();
      let item = await api.get(`/drafts/bydocument/${documentId}`, params);
      if (item.id) {
        dispatch({
          type: types.FETCH_DRAFT_DONE,
          payload: {
            item,
          },
        });
      }
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchDraft(id) {
  return async (dispatch) => {
    try {
      let params = new Map();
      let item = await api.get(`/drafts/${id}`, params);
      if (item) {
        dispatch({
          type: types.FETCH_DRAFT_DONE,
          payload: {
            item,
          },
        });
      }
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchRoleDraft(id) {
  return async (dispatch) => {
    try {
      let params = new Map();
      let item = await api.get(`/roleDrafts/${id}`, params);
      if (item) {
        dispatch({
          type: types.FETCH_DRAFT_DONE,
          payload: {
            item,
          },
        });
      }
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchDraftShareFields(id) {
  return async (dispatch) => {
    try {
      let params = new Map();
      let item = await api.get(`/drafts/${id}`, params);
      if (item) {
        dispatch({
          type: types.FETCH_DRAFT_SHARE_FIELDS_DONE,
          payload: {
            item,
          },
        });
      }
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchAllDrafts(
  deleteCache = false,
  userWorkflowId = undefined
) {
  return async (dispatch, getState) => {
    let state = getState();
    try {
      let params = new Map();
      //this condition applied for fetch all drafts related to a saved workflow
      if (!userWorkflowId) {
        let filters = draftsSelectors.getFilters(state);
        let pagination = draftsSelectors.getPagination(state);
        params.set("expand", "document,reminder");
        if (filters.categoryId) params.set("category_id", filters.categoryId);
        if (filters.searchTerm) params.set("name", filters.searchTerm);
        if (filters.savedWorkflowId)
          params.set("userWorkflowId", filters.savedWorkflowId);
        if (filters.fetchOnlyWorkflowDraft)
          params.set("fetchOnlyWorkflowDraft", filters.fetchOnlyWorkflowDraft);
        params.set("type", filters.type);
        params.set("page_size", pagination.pageSize);
        params.set("page_number", deleteCache ? 1 : pagination.currentPage);
      } else {
        params.set("userWorkflowId", userWorkflowId);
      }

      // GET request from API
      let [response, items] = await api.get(`/drafts`, params, true);

      // Clear cache if deleteCache is enabled
      if (deleteCache) {
        dispatch(clearCache());
      }

      dispatch({
        type: types.FETCH_ALL_DRAFTS_DONE,
        payload: {
          totalPages: parseInt(response.headers.get("X-Total-Pages")),
          items,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchAllRoleDrafts(deleteCache = false) {
  return async (dispatch, getState) => {
    let state = getState();
    try {
      let params = new Map();
      let filters = draftsSelectors.getFilters(state);
      let pagination = draftsSelectors.getPagination(state);
      params.set("expand", "document,reminder");
      if (filters.categoryId) params.set("category_id", filters.categoryId);
      if (filters.searchTerm) params.set("name", filters.searchTerm);

      params.set("page_size", pagination.pageSize);
      params.set("page_number", deleteCache ? 1 : pagination.currentPage);

      // GET request from API
      let [response, items] = await api.get(`/roleDrafts`, params, true);

      // Clear cache if deleteCache is enabled
      if (deleteCache) {
        dispatch(clearCache());
      }

      dispatch({
        type: types.FETCH_ALL_DRAFTS_DONE,
        payload: {
          totalPages: parseInt(response.headers.get("X-Total-Pages")),
          items,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function deleteDraft(id, userWorkflowId = undefined) {
  return async (dispatch) => {
    try {
      // DELETE request to API
      await api.delete("/drafts/" + id);
      NotificationManager.success(strings.get("Client.notifications.deleted"));
      if (userWorkflowId) {
        dispatch(fetchAllDrafts(true, userWorkflowId));
      } else {
        dispatch(fetchAllDrafts());
      }
      dispatch(exceptionsActions.clear());
      //Hide by Nad. It will not remove the row in DraftsList, Because item undefined.
      // dispatch({
      // 	type: types.FETCH_DELETE_DRAFT_DONE,
      // 	payload: {
      // 		id
      // 	}
      // });
    } catch (e) {
      NotificationManager.error(strings.get("Client.notifications.error"));
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function clearCache() {
  return {
    type: types.CLEAR_CACHE,
  };
}

export function clearShareFieldsCache() {
  return {
    type: types.CLEAR_SHARE_FIELDS_CACHE,
  };
}

export function validateItem(id) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("type", "withoutProcess");
      // GET request to API
      let { fields, fieldsOrder, selectors, clauses, steps } = await api.post(
        `/documents/${id}/clientvalidate`,
        params
      );

      dispatch(clearShareFieldsCache());

      dispatch({
        type: types.VALIDATE_ITEM_DONE,
        payload: {
          fields,
          fieldsOrder,
          selectors,
          clauses,
          steps,
        },
      });
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function updateItemShareFields(id, data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("share_fields", JSON.stringify(data.newFields));
      params.set("share_fields_message", data.message);
      // PUT request to API
      await api.put(`/drafts/${id}/shareFields`, params);
      // browserHistory.push(`/documents/${id}`);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function updateDraftName(id, data, userWorkflowId = undefined) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // PUT request to API
      let item = await api.put(`/drafts/${id}/name`, params);
      if (item.error) {
        if (item.error === "Name already exist.")
          NotificationManager.error(
            strings.get("Client.notifications.nameAlreadyExist")
          );
      } else {
        NotificationManager.success(strings.get("Client.notifications.edited"));
      }
      if (userWorkflowId) {
        dispatch(fetchAllDrafts(true, userWorkflowId));
      }
      // browserHistory.push(`/documents/${id}`);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      NotificationManager.error(strings.get("Client.notifications.error"));
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function setCategoryId(id) {
  return {
    type: types.SET_CATEGORY_ID,
    payload: {
      id,
    },
  };
}

export function setSavedWorkflowId(id) {
  return {
    type: types.SET_SAVED_WORKFLOW_ID,
    payload: {
      id,
    },
  };
}

export function setFetchOnlyWorkflowDraftStatus(status) {
  return {
    type: types.SET_FETCH_ONLY_WORKFLOW_DRAFTS,
    payload: {
      status,
    },
  };
}

export function setSearchTerm(searchTerm) {
  return {
    type: types.SET_SEARCH_TERM,
    payload: {
      searchTerm,
    },
  };
}

export function setCurrentPage(page) {
  return {
    type: types.SET_CURRENT_PAGE,
    payload: {
      page,
    },
  };
}

export function setCurrentItemId(id) {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id,
    },
  };
}

export function unsetCurrentItemId() {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id: null,
    },
  };
}

export function updateItemReminder(id, data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // PUT request to API
      await api.post(`/drafts/${id}/setreminder`, params);
      // browserHistory.push(`/documents/${id}`);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function  updateDraftsOrder (newIndex, oldIndex) {
  return async (dispatch, getState) => {
    const state = getState()
    try{
        const attachmentUrl = _.map(state.drafts.attachmentsUrl,a=> a);
        attachmentUrl.splice(newIndex, 0, attachmentUrl.splice(oldIndex, 1)[0]);
        dispatch({type: types.UPDATE_DRAFTS_ORDER_CHANGE, payload: attachmentUrl})
    }
    catch(err) {

    }
  }
}
