import React, { Component } from "react";
import { connect } from "react-redux";

import { CustomFadeModal } from "../components/PopUp";

import strings from "../services/strings";
import { viewModals } from "../helpers";

class MessageToLogIn extends Component {
  componentDidUpdate() {
    if (this.props.currentModal === viewModals.SHARED_DOC_MESSAGE) {
      this.showSharedMessageModal();
    }
    if (this.props.currentModal === viewModals.LOG_IN_MESSAGE) {
      this.showLogInMessageModal();
    }
    if (
      this.props.currentModal === viewModals.HIDDEN_CATEGORY_MESSAGE ||
      this.props.currentModal === viewModals.CATEGORY_CUSTOM_USER_ACCESS_MESSAGE
    ) {
      this.showHiddenCategoryMessageModal();
    }
  }

  handleLoginClick = (event) => {
    this.hideLogInMessageModal();
    this.hideSharedDocMessageModal();
    this.props.handleLoginClick(event);
  };

  handleRegisterClick = (event) => {
    this.hideLogInMessageModal();
    this.hideSharedDocMessageModal();
    this.props.handleRegisterClick(event);
  };

  hideLogInMessageModal = () => {
    this.refs.logInMessageModal.hide();
  };

  hideSharedDocMessageModal = () => {
    this.setCurrentModal();
    this.refs.sharedMessageModal.hide();
  };

  setCurrentModal = (modelName = "") => {
    this.props.setCurrentModal(modelName);
  };

  showLogInMessageModal = () => {
    this.refs.logInMessageModal.show();
  };

  showSharedMessageModal = () => {
    this.refs.sharedMessageModal.show();
  };

  showHiddenCategoryMessageModal = () => {
    if (this.refs.hiddenCategoryMessageModal) {
      this.refs.hiddenCategoryMessageModal.show();
    }
  };

  hideHiddenCategoryMessageModal = () => {
    this.refs.hiddenCategoryMessageModal.hide();
  };

  render() {
    let logInMessageModal = (
      <CustomFadeModal
        className="boron-modal form-modal"
        ref="logInMessageModal"
      >
        <div className="close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.hideLogInMessageModal}
          />
        </div>
        <div style={{ color: "black", fontSize: "large" }}>
          {strings.get("Client.loginMessage.plainDocUrl")}
        </div>
        <div className="form-actions">
          <button className="btn btn-primary" onClick={this.handleLoginClick}>
            {strings.get("Client.homePage.headerMenu.login")}
          </button>
          <button
            className="btn btn-primary"
            onClick={this.handleRegisterClick}
          >
            {strings.get("Client.homePage.headerMenu.register")}
          </button>
        </div>
      </CustomFadeModal>
    );

    let hiddenCategoryMessageModal = (
      <CustomFadeModal
        className="boron-modal form-modal"
        ref="hiddenCategoryMessageModal"
      >
        <div className="close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.hideHiddenCategoryMessageModal}
          />
        </div>
        <div style={{ color: "black", fontSize: "large" }}>
          {this.props.currentModal === viewModals.HIDDEN_CATEGORY_MESSAGE
            ? strings.get("Client.loginMessage.hiddenCategoryMessage")
            : ""}
          {this.props.currentModal ===
          viewModals.CATEGORY_CUSTOM_USER_ACCESS_MESSAGE
            ? strings.get("Client.loginMessage.categoryCustomUserAccessMessage")
            : ""}
          {}
        </div>
      </CustomFadeModal>
    );

    let sharedMessageModal = (
      <CustomFadeModal
        className="boron-modal form-modal"
        ref="sharedMessageModal"
        onHide={this.hideSharedDocMessageModal}
      >
        <div className="close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.hideSharedDocMessageModal}
          />
        </div>
        <div style={{ color: "black", fontSize: "large" }}>
          {strings.get("Client.loginMessage.sharedDocUrl")}
        </div>
        <div className="form-actions">
          <button className="btn btn-primary" onClick={this.handleLoginClick}>
            {strings.get("Client.homePage.headerMenu.login")}
          </button>
          <button
            className="btn btn-primary"
            onClick={this.handleRegisterClick}
          >
            {strings.get("Client.homePage.headerMenu.register")}
          </button>
        </div>
      </CustomFadeModal>
    );

    return (
      <div>
        {logInMessageModal}
        {sharedMessageModal}
        {hiddenCategoryMessageModal}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageToLogIn);
