import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";

import Breadcrumbs from "../../../components/Breadcrumbs";
import CustomDropModal from "../../../components/PopUp";
import Topbar from "../../../components/Topbar";
import NavigationTabs from "../../../components/NavigationTabs";
import NavigationLeftTabs from "../../../components/NavigationLeftTabs";
import Footer from "../../../components/footer/Footer";
import ShareDraftInstructionForm from "../../../components/settings/shareDraft/ShareDraftInstructionForm";

import * as requiredOptionsActions from "../../../store/requiredOptions/actions";
import * as authActions from "../../../store/auth/actions";
import * as authSelectors from "../../../store/auth/selectors";
import * as exceptionsSelectors from "../../../store/exceptions/selectors";
import * as exceptionsActions from "../../../store/exceptions/actions";

import { browserHistory } from "../../../helpers";
import strings from "../../../services/strings";
import auth from "../../../services/auth";
import language from "../../../services/language";

// import "../../Page.scss";

class ShareDraftInstructionPage extends Component {
  componentDidMount() {
    if (!auth.isAuthenticated()) {
      browserHistory.push("/");
      window.location.reload();
    }
  }

  handleShowModal = () => {
    _.delay(() => {
      this.hideSaveModal();
    }, 1000);
  };

  showSaveModal = () => {
    this.refs.saveModal.show();
  };

  hideSaveModal = () => {
    this.refs.saveModal.hide();
  };

  saveUserSettings = (data) => {
    this.props.updateUserSettings(data).then(() => {
      if (_.size(this.props.exceptions) === 0) {
        this.props.getUser();
        this.showSaveModal();
      }
    });
  };

  render() {
    let locale = language.getIso2();
    let saveModal = (
      <CustomDropModal
        className="boron-modal no-body"
        ref="saveModal"
        onShow={this.handleShowModal}
      >
        <span>
          <h2>{strings.get("App.settings.settingsSaved")}</h2>
        </span>
      </CustomDropModal>
    );
    return (
      <div className="ShareDraftInstructionPage">
        {saveModal}
        <Topbar
          title={strings.get("Client.homePage.title")}
          subtitle={strings.get("Client.homePage.subTitle")}
          searchBox
          currentView="userProfilePage"
        />
        <div className="container">
          <Breadcrumbs>
            <Link to={`/${locale}/`}>
              {strings.get("Client.homePage.home")}
            </Link>
            <Link to={`/${locale}/profile`}>
              {strings.get("Client.homePage.profile")}
            </Link>
            <Link to={`/${locale}/messages/shareemail`}>
              {strings.get("Client.profilePage.messages")}
            </Link>
          </Breadcrumbs>

          <div className="container-row">
            <NavigationTabs
              currentItemId={5}
              currentLang={this.props.currentLang}
            />

            <div className="row">
              <NavigationLeftTabs
                currentItemId={1}
                currentLang={this.props.currentLang}
              />

              <ShareDraftInstructionForm
                exceptions={this.props.exceptions}
                currentItem={this.props.profile}
                saveItem={this.saveUserSettings}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: authSelectors.getProfile(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUser: () => {
      dispatch(authActions.getUser());
    },
    updateUserSettings: (data) => {
      return dispatch(requiredOptionsActions.updateUserSettings(data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareDraftInstructionPage);
