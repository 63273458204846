import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import Topbar from "../Topbar";

import * as documentsSelectors from "../../store/documents/selectors";

import strings from "../../services/strings";

class LoadCurrentDocInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDoc: this.props.currentDocument,
      description: this.props.currentDocument
        ? this.props.currentDocument.description
        : "",
      currentDocRules: this.props.currentDocRules,
      expandGlossaries: {},
      glossariesCodeNameToId: {},
      glossariesCodeIdToName: {},
    };
  }

  componentDidMount() {
    this.loadGlossaries(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps &&
      nextProps.glossaries &&
      nextProps.glossaries !== this.props.glossaries &&
      !_.size(this.state.glossaries)
    ) {
      this.loadGlossaries(nextProps);
    }

    if (
      nextProps.currentDocument !== this.props.currentDocument &&
      nextProps.currentDocument !== this.state.currentDoc
    ) {
      this.setState(
        {
          currentDoc: nextProps.currentDocument,
          description: nextProps.currentDocument
            ? nextProps.currentDocument.description
            : "",
        },
        () => {
          this.setDocDescription();
        }
      );
    }
    if (
      nextProps.currentDocValueChangeFlag !==
      this.props.currentDocValueChangeFlag
    ) {
      if (nextProps.currentDocValueChangeFlag.paths) {
        this.buildLabel(
          nextProps.currentDocument
            ? nextProps.currentDocument.description
            : "",
          nextProps.currentDocValueChangeFlag.paths
        );
      }
    }
  }

  loadGlossaries = (props = this.props) => {
    if (
      props.glossaries &&
      _.size(props.glossaries) &&
      !_.size(this.state.expandGlossaries)
    ) {
      let temp = {};
      let tempCodeNameToId = {};
      let tempCodeIdToName = {};
      let _glossaries = props.glossaries;
      _.map(_glossaries, (element) => {
        if (element.clauses.length) {
          element.clauses.forEach((clause) => {
            temp[`${clause.name}`] = clause;
            tempCodeNameToId[`${clause.name}`] = `clause_${clause.id}`;
            tempCodeIdToName[`clause_${clause.id}`] = `${clause.name}`;
          });
        }
        if (element.fields.length) {
          element.fields.forEach((field) => {
            temp[`${field.name}`] = field;
            tempCodeNameToId[`${field.name}`] = `field_${field.id}`;
            tempCodeIdToName[`field_${field.id}`] = `${field.name}`;
          });
        }
        if (element.selectors.length) {
          element.selectors.forEach((selector) => {
            temp[`${selector.name}`] = selector;
            tempCodeNameToId[`${selector.name}`] = `selector_${selector.id}`;
            tempCodeIdToName[`selector_${selector.id}`] = `${selector.name}`;
          });
        }
      });
      this.setState(
        {
          expandGlossaries: temp,
          glossariesCodeNameToId: tempCodeNameToId,
          glossariesCodeIdToName: tempCodeIdToName,
        },
        () => {
          this.setDocDescription();
        }
      );
    }
  };

  setDocDescription = () => {
    if (
      this.props.currentDocValueChangeFlag &&
      this.props.currentDocValueChangeFlag.paths &&
      _.size(this.props.currentDocValueChangeFlag.paths)
    ) {
      return false;
    }
    let {
      glossaries,
      description,
      glossariesNameId,
      glossariesIdName,
    } = this.state;
    if (_.size(glossaries) && description !== "") {
      let _label = this.props.currentDocument
        ? this.props.currentDocument.description
        : description;
      let result = strings.getFromBetween.get(_label || "", "[[", "]]");
      result.forEach((e) => {
        const reg = new RegExp(e);
        // if there's a translation - use it
        const _value =
          sessionStorage[glossariesNameId[e] + "_translation"] ||
          sessionStorage[glossariesNameId[e]] ||
          sessionStorage[e + "_translation"] ||
          sessionStorage[e];
        if (_value !== "" && !!_value) {
          _label = _label.replace(reg, _value + " ").replace(/\[\[|\]\]/g, "");
        } else {
          // work out pattern [[*.*_*]] for selects
          if (e.indexOf(".") > -1) {
            const field = e.split(".")[0];
            // const values = e.split(".")[1].split("_");
            const selected =
              sessionStorage[field] || sessionStorage[glossariesNameId[field]];
            const currentGlossaryItem =
              glossaries[field] || glossaries[glossariesIdName[field]];

            //if value exist in local storage.
            if (!!selected) {
              if (currentGlossaryItem[selected]) {
                _label = _label
                  .replace(reg, currentGlossaryItem[selected])
                  .replace(/\[\[|\]\]/g, "");
              } else {
                _label = _label
                  .replace(reg, currentGlossaryItem["placeholderField"])
                  .replace(/\[\[|\]\]/g, "");
              }
            }
            //if not exist in local storage, then use placeholder.
            else {
              if (currentGlossaryItem) {
                _label = _label
                  .replace(reg, currentGlossaryItem["placeholderField"])
                  .replace(/\[\[|\]\]/g, "");
              } else {
                _label = _label.replace(reg, "");
              }
            }
          } else {
            const storageValue =
              sessionStorage[e] || sessionStorage[glossariesNameId[e]];
            if (storageValue) {
              _label = _label
                .replace(reg, storageValue)
                .replace(/\[\[|\]\]/g, "");
            } else {
              if (glossaries[glossariesIdName[e]]) {
                _label = _label
                  .replace(
                    reg,
                    glossaries[glossariesIdName[e]].placeholderField
                  )
                  .replace(/\[\[|\]\]/g, "");
              }
              if (glossaries[e]) {
                _label = _label
                  .replace(reg, glossaries[e].placeholderField)
                  .replace(/\[\[|\]\]/g, "");
              }
            }
          }
        }
      });
      this.setState({
        description: _label,
      });
    }
  };

  buildLabel = (_label, paths) => {
    let {
      expandGlossaries,
      glossariesCodeNameToId,
      glossariesCodeIdToName,
    } = this.state;
    // separate out all selectors and loop through
    let result = strings.getFromBetween.get(_label || "", "[[", "]]");

    result.forEach((e) => {
      const reg = new RegExp(e);

      //if multiple codes are responsible for name of a step
      let stepNameOptions = e.split("||");
      if (stepNameOptions.length > 1) {
        stepNameOptions.forEach((element) => {
          let temp_element = element;
          element = element.trim();
          let optionValue = "";
          if (element.indexOf(".") > -1) {
            let firstCode = element.split(".")[0];
            optionValue =
              sessionStorage[firstCode + "_translation"] ||
              sessionStorage[firstCode] ||
              sessionStorage[
                glossariesCodeNameToId[firstCode] + "_translation"
              ] ||
              sessionStorage[glossariesCodeNameToId[firstCode]];
          } else {
            optionValue =
              sessionStorage[element + "_translation"] ||
              sessionStorage[element] ||
              sessionStorage[
                glossariesCodeNameToId[element] + "_translation"
              ] ||
              sessionStorage[glossariesCodeNameToId[element]];
          }
          if (optionValue !== "" && !!optionValue) {
            _label = _label.replace(e, optionValue).replace(/\[\[|\]\]/g, "");
          } else if (
            stepNameOptions.indexOf(temp_element) + 1 ===
            stepNameOptions.length
          ) {
            // work out pattern [[*.*_*]] for selects
            if (stepNameOptions[0].trim().indexOf(".") > -1) {
              const field = stepNameOptions[0].trim().split(".")[0];
              const values = stepNameOptions[0].trim().split(".")[1].split("_");
              const selected =
                sessionStorage[glossariesCodeNameToId[field]] ||
                sessionStorage[field];
              //if value exist in local storage.
              if (!!selected) {
                const _keys = ["field", "selector", "if"];
                _keys.forEach((type) => {
                  if (
                    this.paths[type] &&
                    (this.paths[type][glossariesCodeNameToId[field]] ||
                      this.paths[type][field])
                  ) {
                    let path = null;
                    if (this.paths[type][field]) {
                      path = this.paths[type][field];
                    } else {
                      path = this.paths[type][glossariesCodeNameToId[field]];
                    }
                    const rule = path[Object.keys(path)[0]].node;

                    let i = 0;
                    for (var item in rule.options) {
                      if (item === selected) break;
                      else i++;
                    }
                    _label = _label
                      .replace(e, values[i])
                      .replace(/\[\[|\]\]/g, "");
                  }
                });
              }
              //if not exist in local storage, then use placeholder.
              else {
                const _keys = ["field", "selector", "if"];
                _keys.forEach((type) => {
                  if (
                    this.paths[type] &&
                    (this.paths[type][glossariesCodeNameToId[field]] ||
                      this.paths[type][field])
                  ) {
                    let path = null;
                    if (this.paths[type][field]) {
                      path = this.paths[type][field];
                    } else {
                      path = this.paths[type][glossariesCodeNameToId[field]];
                    }
                    const rule = path[Object.keys(path)[0]].node;
                    let _placeholder = null;
                    if (rule.properties) {
                      _placeholder = rule.properties.placeholderField;
                    }

                    if (_placeholder) {
                      _label = _label
                        .replace(e, _placeholder)
                        .replace(/\[\[|\]\]/g, "");
                    }
                  } else {
                    _label = _label.replace(e, "").replace(/\[\[|\]\]/g, "");
                  }
                });
              }
            } else {
              if (expandGlossaries && _.size(expandGlossaries)) {
                if (
                  expandGlossaries[stepNameOptions[0].trim()] &&
                  expandGlossaries[stepNameOptions[0].trim()].placeholderField
                ) {
                  _label = _label
                    .replace(
                      e,
                      expandGlossaries[stepNameOptions[0].trim()]
                        .placeholderField
                    )
                    .replace(/\[\[|\]\]/g, "");
                } else if (
                  expandGlossaries[
                    glossariesCodeIdToName[stepNameOptions[0].trim()]
                  ] &&
                  expandGlossaries[
                    glossariesCodeIdToName[stepNameOptions[0].trim()]
                  ].placeholderField
                ) {
                  _label = _label
                    .replace(
                      e,
                      expandGlossaries[
                        glossariesCodeIdToName[stepNameOptions[0].trim()]
                      ].placeholderField
                    )
                    .replace(/\[\[|\]\]/g, "");
                } else {
                  _label = _label
                    .replace(e, "< ## >")
                    .replace(/\[\[|\]\]/g, "");
                }
              }
            }
          }
        });
      } else {
        //if single code is responsible for name of a step

        // if there's a translation - use it
        const _value =
          sessionStorage[glossariesCodeNameToId[e] + "_translation"] ||
          sessionStorage[glossariesCodeNameToId[e]];
        if (_value !== "" && !!_value) {
          _label = _label.replace(reg, _value).replace(/\[\[|\]\]/g, "");
        } else {
          // work out pattern [[*.*_*]] for selects
          if (e.indexOf(".") > -1) {
            const field = e.split(".")[0];
            const values = e.split(".")[1].split("_");
            const selected = sessionStorage[glossariesCodeNameToId[field]];
            //if value exist in local storage.
            if (!!selected) {
              const _keys = ["field", "selector", "if"];
              _keys.forEach((e) => {
                if (
                  this.paths[e] &&
                  this.paths[e][glossariesCodeNameToId[field]]
                ) {
                  const path = this.paths[e][glossariesCodeNameToId[field]];
                  const rule = path[Object.keys(path)[0]].node;

                  let i = 0;
                  for (var item in rule.options) {
                    if (item === selected) break;
                    else i++;
                  }
                  _label = _label
                    .replace(reg, values[i])
                    .replace(/\[\[|\]\]/g, "");
                }
              });
            }
            //if not exist in local storage, then use placeholder.
            else {
              const _keys = ["field", "selector", "if"];
              _keys.forEach((e) => {
                if (
                  this.paths[e] &&
                  this.paths[e][glossariesCodeNameToId[field]]
                ) {
                  const path = this.paths[e][glossariesCodeNameToId[field]];
                  const rule = path[Object.keys(path)[0]].node;
                  let _placeholder = null;
                  if (rule.properties) {
                    _placeholder = rule.properties.placeholderField;
                  }

                  if (_placeholder) {
                    _label = _label
                      .replace(reg, _placeholder)
                      .replace(/\[\[|\]\]/g, "");
                  }
                } else {
                  _label = _label.replace(reg, "").replace(/\[\[|\]\]/g, "");
                }
              });
            }
          } else {
            // look for placeholder in ???
            const _path =
              this.paths["field"] &&
              this.paths["field"][e] &&
              this.paths["field"][e][0];
            if (_path) {
              const _placeholder =
                _path[Object.keys(_path)[0]].node.properties.placeholderField;
              if (_placeholder) {
                _label = _label
                  .replace(reg, _placeholder)
                  .replace(/\[\[|\]\]/g, "");
              } else {
                _label = _label
                  .replace(reg, "< ## >")
                  .replace(/\[\[|\]\]/g, "");
              }
            } else {
              // if there's no placeholder then find it in all glossaries
              if (expandGlossaries && _.size(expandGlossaries)) {
                if (
                  expandGlossaries[e] &&
                  expandGlossaries[e].placeholderField
                ) {
                  _label = _label
                    .replace(reg, expandGlossaries[e].placeholderField)
                    .replace(/\[\[|\]\]/g, "");
                } else if (
                  expandGlossaries[glossariesCodeIdToName[e]] &&
                  expandGlossaries[glossariesCodeIdToName[e]].placeholderField
                ) {
                  _label = _label
                    .replace(
                      reg,
                      expandGlossaries[glossariesCodeIdToName[e]]
                        .placeholderField
                    )
                    .replace(/\[\[|\]\]/g, "");
                } else {
                  _label = _label
                    .replace(reg, "< ## >")
                    .replace(/\[\[|\]\]/g, "");
                }
              }
            }
          }
        }
      }
    });
    this.setState({
      description: _label,
    });
  };

  render() {
    return (
      <Topbar
        title={
          this.props.currentDocument ? this.props.currentDocument.name : null
        }
        subtitle={
          this.props.currentCategory ? this.props.currentCategory.name : null
        }
        description={this.state.description}
        readMoreLink={
          this.props.currentDocument
            ? this.props.currentDocument.read_more_link
            : null
        }
        // handleLangChange={this.props.handleLangChange}
        // currentLang={this.props.currentLang}
        divider
        menuItem={"categories"}
        documentInfo={this.props.currentDocument}
        headerImage={
          this.props.currentDocument
            ? this.props.currentDocument.bgImageURL
            : null
        }
        setDocDescription={this.setDocDescription}
      />
    );
  }
}
LoadCurrentDocInfo.propTypes = {
  currentDocument: PropTypes.object,
  currentCategory: PropTypes.object,
  handleLangChange: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    currentDocValueChangeFlag: documentsSelectors.getCurrentDocRulesValueChangeFlag(
      state
    ),
  };
}

export default connect(mapStateToProps)(LoadCurrentDocInfo);
