import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Helmet } from "react-helmet";

import * as workflowsActions from "../../store/workflows/actions";
import * as workflowsSelectors from "../../store/workflows/selectors";
import * as categoriesActions from "../../store/workflowCategories/actions";
import * as categoriesSelectors from "../../store/workflowCategories/selectors";
import * as settingsActions from "../../store/settings/actions";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
import Breadcrumbs from "../../components/Breadcrumbs";
import WorkflowList from "../../components/workflow/WorkflowList";
import SubCategoriesList from "../../components/workflowCategories/SubCategoriesList";
import Footer from "../../components/footer/Footer";

import strings from "../../services/strings";
import { makePATHUrl, browserHistory } from "../../helpers";
import language from "../../services/language";

class WorkflowListPage extends Component {
  componentDidMount() {
    // this.props.setCurrentCategoryId(this.props.match.params.catId);
    // this.props.fetchAllCategories();
    // this.props.fetchWorkflows();
    this.fetchProps(this.props);
    _.delay(() => {
      this.checkForRedirect();
    }, 2500);
  }

  checkForRedirect = () => {
    if (typeof this.props.currentCategory === "undefined") {
      browserHistory.push("/");
      window.location.reload();
    }
  };

  componentDidUpdate(newProps) {
    if (newProps.match.params.catId !== this.props.match.params.catId) {
      this.fetchProps(this.props);
      // this.props.fetchAllCategories();
      // this.props.fetchWorkflows();
      // this.props.setCurrentCategoryId(newProps.match.params.catId);
    }
  }

  fetchProps = (props) => {
    this.props.setCurrentCategoryId(props.match.params.catId);
    this.props.fetchAllCategories();
    this.props.fetchWorkflows();
  };

  componentWillUnmount() {
    // this.props.unsetCurrentCategoryId();
    this.props.setCurrentCategoryId(null);
  }

  getCategoryLink = (locale) => {
    let category = this.props.currentCategory;
    if (category) {
      return (
        <Link
          to={`/${locale}/workflows/${makePATHUrl(category.name, category.id)}`}
        >
          {category.name}
        </Link>
      );
    }
  };

  handleMouseOver = (id) => {
    this.props.fetchWorkflow(id);
  };

  getMetaFields = () => {
    if (_.size(this.props.currentCategory) > 0) {
      return (
        <Helmet>
          <title>{this.props.currentCategory.meta_title}</title>
          <meta
            name="description"
            content={this.props.currentCategory.meta_description}
          />
          <meta
            name="keywords"
            content={this.props.currentCategory.meta_keyword}
          />
        </Helmet>
      );
    }
  };

  fetchCurrentWorkflow = (itemId) => {
    this.props.setCurrentWorkflowId(itemId);
    this.props.fetchWorkflow(itemId);
  };

  render() {
    let subcatsDiv =
      _.size(this.props.subCategories) > 0 ? (
        <div className="inner-page-cont subcate-cont">
          <div className="container">
            <div className="row">
              <SubCategoriesList
                items={this.props.subCategories}
                assignedUsers={
                  this.props.currentCategory
                    ? this.props.currentCategory.selectedUsers
                    : null
                }
              />
            </div>
          </div>
        </div>
      ) : null;
    let locale = language.getIso2();
    return (
      <div className="WorkflowListPage">
        {this.getMetaFields()}
        <Topbar
          title={strings.get("Client.homePage.workflow.title")}
          subtitle={
            this.props.currentCategory ? this.props.currentCategory.name : null
          }
          description={
            this.props.currentCategory
              ? this.props.currentCategory.description
              : null
          }
          readMoreLink={
            this.props.currentCategory
              ? this.props.currentCategory.read_more_link
              : null
          }
          divider
          menuItem={"workflowCategories"}
        />

        <div className="breadcrumb-cont">
          <div className="container">
            <Breadcrumbs>
              <Link to={`/${locale}/`}>
                {strings.get("Client.homePage.home")}
              </Link>
              <Link to={`/${locale}/workflow`}>
                {strings.get("Client.homePage.workflow.title")}
              </Link>
              {_.isEmpty(this.props.subCategoriesList)
                ? null
                : this.props.subCategoriesList.map((subCategory) => (
                    <Link
                      key={subCategory.id}
                      to={`/${locale}/workflows/${makePATHUrl(
                        subCategory.name,
                        subCategory.id
                      )}`}
                    >
                      {subCategory.name}
                    </Link>
                  ))}
              {this.getCategoryLink(locale)}
            </Breadcrumbs>
          </div>
        </div>
        {subcatsDiv}
        <div className="inner-page-cont workflowlist-cont">
          <div className="container">
            <h2> {strings.get("Client.category.workflows")} </h2>
            <WorkflowList
              {...this.props}
              catName={this.props.match.params.catName}
              catId={this.props.match.params.catId}
              items={this.props.workflows}
              onMouseOver={this.handleMouseOver}
              description={
                this.props.currentCategory
                  ? this.props.currentCategory.description
                  : null
              }
              fetchCurrentWorkflow={this.fetchCurrentWorkflow}
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function getSubCategoriesList(state, category) {
  if (_.isObject(category) && category.parent_id) {
    const subCat = categoriesSelectors.getItemById(state, category.parent_id);
    subCategoriesList.push(subCat);
    getSubCategoriesList(state, subCat);
  }
}

let subCategoriesList = [];
function mapStateToProps(state) {
  subCategoriesList = [];
  let currentCategory = categoriesSelectors.getCurrentItem(state);
  getSubCategoriesList(state, currentCategory);

  return {
    workflows: workflowsSelectors.getItemsByCategory(
      state,
      currentCategory
        ? currentCategory.id
        : state.workflowCategories.currentItemId || null
    ),
    subCategories: currentCategory ? currentCategory.children : null,
    currentCategory: currentCategory,
    subCategoriesList: subCategoriesList.reverse(),
    currentWorkflow: workflowsSelectors.getCurrentItem(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchWorkflow: (id) => {
      dispatch(workflowsActions.fetchItem(id));
    },
    setCurrentWorkflowId: (id) => {
      dispatch(workflowsActions.setCurrentItemId(id));
    },
    sendEmails: (data) => {
      dispatch(workflowsActions.sendEmails(data));
    },
    fetchWorkflows: () => {
      dispatch(workflowsActions.fetchItems());
    },
    fetchAllCategories: () => {
      dispatch(categoriesActions.fetchAllItems());
    },
    setCurrentCategoryId: (id) => {
      dispatch(categoriesActions.setCurrentItemId(id));
    },
    unsetCurrentCategoryId: () => {
      dispatch(categoriesActions.unsetCurrentItemId());
    },
    setCurrentModal: (data) => {
      dispatch(settingsActions.setCurrentModal(data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowListPage);
