import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";

import * as workflowsActions from "../../store/workflows/actions";
import * as workflowsSelectors from "../../store/workflows/selectors";
import * as categoriesActions from "../../store/workflowCategories/actions";
import * as categoriesSelectors from "../../store/workflowCategories/selectors";
import * as authActions from "../../store/auth/actions";
import * as authSelectors from "../../store/auth/selectors";
import * as settingSelectors from "../../store/settings/selectors";
import * as settingActions from "../../store/settings/actions";
import * as draftsActions from "../../store/drafts/actions";
import * as draftsSelectors from "../../store/drafts/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
import Footer from "../../components/footer/Footer";
import Breadcrumbs from "../../components/Breadcrumbs";
import WorkflowForm from "../../components/workflow/WorkflowForm";

import { makePATHUrl, checkCookieConsent } from "../../helpers";
import language from "../../services/language";
import strings from "../../services/strings";

// import "../Page.scss";

class WorkflowFormPage extends Component {
  componentDidMount() {
    if (!checkCookieConsent()) {
      window.location.replace("/");
    } else {
      this.props.fetchWorkflow(this.props.match.params.workflowId);
      this.props.fetchAllCategories();
      this.props.setCurrentCategoryId(this.props.match.params.catId);
      this.props.setCurrentWorkflowId(this.props.match.params.workflowId);
      this.props.fetchLoggedInUser();
      if (this.props.match.params && this.props.match.params.id) {
        this.fetchCurrentWorkflow();
      }
    }
  }

  componentWillUnmount() {
    this.props.unsetCurrentCategoryId();
  }

  fetchCurrentWorkflow = () => {
    this.props.fetchUserWorkflow(this.props.match.params.id);
    this.props.fetchAllDrafts(true, this.props.match.params.id);
  };

  getCategoryLink = (locale) => {
    let category = this.props.currentCategory;
    if (category) {
      return (
        <Link
          to={`/${locale}/workflows/${makePATHUrl(category.name, category.id)}`}
        >
          {category.name}
        </Link>
      );
    }
  };

  getWorkflowLink = (locale) => {
    let doc = this.props.currentWorkflow;
    let category = this.props.currentCategory;
    if (doc && category) {
      return (
        <Link
          to={`/${locale}/workflows/${makePATHUrl(
            category.name,
            category.id
          )}/${makePATHUrl(doc.name, doc.id)}`}
        >
          {doc.name}
        </Link>
      );
    }
  };

  getMetaFields = () => {
    if (_.size(this.props.currentWorkflow) > 0) {
      return (
        <Helmet>
          <title>{this.props.currentWorkflow.meta_title}</title>
          <meta
            name="description"
            content={this.props.currentWorkflow.meta_description}
          />
          <meta
            name="keywords"
            content={this.props.currentWorkflow.meta_keyword}
          />
        </Helmet>
      );
    }
  };

  render() {
    let locale = language.getIso2();
    return (
      <div className="WorkflowFormPage">
        {this.getMetaFields()}
        <Topbar
          title={
            this.props.currentWorkflow ? this.props.currentWorkflow.name : null
          }
          subtitle={
            this.props.currentCategory ? this.props.currentCategory.name : null
          }
          description={
            this.props.currentWorkflow
              ? this.props.currentWorkflow.description
              : null
          }
          readMoreLink={
            this.props.currentWorkflow
              ? this.props.currentWorkflow.read_more_link
              : null
          }
          // handleLangChange={this.props.handleLangChange}
          // currentLang={this.props.currentLang}
          divider
          menuItem={"workflowCategories"}
          workflowInfo={this.props.currentWorkflow}
          headerImage={
            this.props.currentWorkflow
              ? this.props.currentWorkflow.bgImageURL
              : null
          }
        />

        <Breadcrumbs>
          <Link to={`/${locale}/`}>{strings.get("Client.homePage.home")}</Link>
          <Link to={`/${locale}/workflow`}>
            {strings.get("Client.homePage.workflow.title")}
          </Link>
          {_.isEmpty(this.props.subCategoriesList)
            ? null
            : this.props.subCategoriesList.map((subCategory) => (
                <Link
                  key={subCategory.id}
                  to={`/${locale}/workflows/${makePATHUrl(
                    subCategory.name,
                    subCategory.id
                  )}`}
                >
                  {subCategory.name}
                </Link>
              ))}
          {this.getCategoryLink(locale)}
          {this.getWorkflowLink(locale)}
        </Breadcrumbs>

        <WorkflowForm
          currentCategory={this.props.currentCategory}
          currentItem={this.props.currentWorkflow}
          setCurrentModal={this.props.setCurrentModal}
          match={this.props.match}
          userCurrentWorkflow={this.props.userCurrentWorkflow}
          drafts={this.props.drafts}
          currentModal={this.props.currentModal}
          updateDraftName={this.props.updateDraftName}
          deleteDraft={this.props.deleteDraft}
          fetchCurrentWorkflow={this.fetchCurrentWorkflow}
        />
        <Footer />
      </div>
    );
  }
}

function getSubCategoriesList(state, category) {
  if (_.isObject(category) && category.parent_id) {
    const subCat = categoriesSelectors.getItemById(state, category.parent_id);
    subCategoriesList.push(subCat);
    getSubCategoriesList(state, subCat);
  }
}

let subCategoriesList = [];

function mapStateToProps(state) {
  subCategoriesList = [];
  let currentCategory = categoriesSelectors.getCurrentItem(state);
  getSubCategoriesList(state, currentCategory);
  return {
    currentCategory: currentCategory,
    allCategory: categoriesSelectors.getItems(state),
    currentWorkflow: workflowsSelectors.getCurrentItem(state),
    profile: authSelectors.getProfile(state),
    subCategoriesList: subCategoriesList.reverse(),
    userCurrentWorkflow: workflowsSelectors.getUserCurrentWorkflow(state),
    drafts: draftsSelectors.getItems(state),
    currentModal: settingSelectors.getCurrentModal(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchWorkflow: (id) => {
      dispatch(workflowsActions.fetchItem(id));
    },
    fetchUserWorkflow: (id) => {
      dispatch(workflowsActions.fetchUserWorkflow(id));
    },
    fetchAllDrafts: (deleteCache, userWorkflowId) => {
      return dispatch(
        draftsActions.fetchAllDrafts(deleteCache, userWorkflowId)
      );
    },
    updateDraftName: (id, data, userWorkflowId) => {
      dispatch(draftsActions.updateDraftName(id, data, userWorkflowId));
    },
    deleteDraft: (id, userWorkflowId) => {
      dispatch(draftsActions.deleteDraft(id, userWorkflowId));
    },
    fetchAllCategories: () => {
      dispatch(categoriesActions.fetchAllItems());
    },
    setCurrentWorkflowId: (id) => {
      dispatch(workflowsActions.setCurrentItemId(id));
    },
    unsetCurrentWorkflowId: () => {
      dispatch(workflowsActions.unsetCurrentItemId());
    },
    setCurrentCategoryId: (id) => {
      dispatch(categoriesActions.setCurrentItemId(id));
    },
    unsetCurrentCategoryId: () => {
      dispatch(categoriesActions.unsetCurrentItemId());
    },
    fetchLoggedInUser: () => {
      dispatch(authActions.getUser());
    },
    setCurrentModal: (data) => {
      dispatch(settingActions.setCurrentModal(data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowFormPage);
