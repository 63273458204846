import Immutable from "seamless-immutable";
import { types } from "./actions";

const initialState = Immutable({
  profile: {},
  maintenance_items: {},
});

// Clear cached info
function clearCache(state) {
  return state.merge({
    profile: {},
    maintenance_items: {},
  });
}

// Save profile to store
function fetchProfileDone(state, payload) {
  return state.merge({
    profile: payload.profile,
  });
}

function setMaintenanceSettings(state, payload) {
  // let maintenanceData = _.fromPairs(
  //   _.map(payload.items, i => [i.key, i.value])
  // );

  return state.merge({
    maintenance_items: payload.items,
  });
}

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.CLEAR_CACHE:
      return clearCache(state);

    case types.FETCH_PROFILE_DONE:
      return fetchProfileDone(state, action.payload);

    case types.FETCH_MAINTENANCE_SETTINGS_DONE:
      return setMaintenanceSettings(state, action.payload);

    default:
      return state;
  }
}
