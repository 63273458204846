import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import CategorySingle from "./CategorySingle";

import "./CategoryList.scss";

function CategoryList(props) {
  const getItems = () => {
    if (props.items) {
      let count = 0;
      return _.map(props.items, (item) => {
        if (count > props.limit) {
          return;
        }
        if (!item.parent_id) {
          count++;
        }
        return <CategorySingle key={item.id} item={item} />;
      });
    }
  };

  return (
    <div className="inner-page-cont">
      <div className="container-row">
        <div className="CategoryList container">
          <ul className="row">{getItems()}</ul>
        </div>
      </div>
    </div>
  );
}

CategoryList.propTypes = {
  items: PropTypes.object,
};

export default CategoryList;
