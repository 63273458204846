import React, { Component } from "react";
import { OldSocialLogin as SocialLogin } from "react-social-login";
import PropTypes from 'prop-types'
import useLinkedIn from 'react-linkedin-login-oauth2'
import base64 from '../../helpers/base64'
import _ from 'lodash'
class SocialButton extends Component {

  handleFailure = (err) => {
    console.log('linked in error --  > ', err);
  }

  handleSuccess = (data) => {
    if (data.code)
      this.props.handleLinkedin(data.code, this.callback)
  }

  callback = (data) => {
    if (data)
      this.props.handleSocial(data);
  }

  handleClick = (click) => {
    console.log('linked in handleClick --  > ', click);
  }

  getPlainText = (hash) => {
    return base64.atob(hash);
  }

  render() {

    const facebook_key = _.filter(this.props.siteSettings, (settings) => settings.key === "facebook_key")[0]
    const linkedin_key = _.filter(this.props.siteSettings, (settings) => settings.key === "linkedin_key")[0]

    return (
      <div>
        <SocialLogin
          provider="facebook"
          appId={this.getPlainText(facebook_key.value)}
          callback={this.props.handleSocial}
        >
          <button className="socialLoginBtn socialLoginBtn--facebook">
            <div className="icon-div">
              <i className="ion-social-facebook" />
            </div>
            <div className="icon-text-div">{this.props.fbBtnLabel}</div>
          </button>
        </SocialLogin>

        {/*
      <SocialLogin
        provider='google'
        appId={config.GOOGLE_APP_ID}
        callback={this.props.handleSocial}
      >
        <button className="socialLoginBtn socialLoginBtn--google">
          <div className="icon-div"><i className="ion-social-google"/></div>
          <div className="icon-text-div">{this.props.googleBtnLebel}</div>
        </button>
      </SocialLogin>
      */}
        <useLinkedIn
          // callback={this.props.handleSocial}

          className="socialLoginBtn"
          text='LinkedIn'
          clientId={this.getPlainText(linkedin_key.value)}
          onFailure={this.handleFailure}
          onSuccess={this.handleSuccess}
          // onSuccess={this.props.handleSocial}
          // supportIE
          redirectUri={`${window.location.origin}/linkedin`}
          scope="r_liteprofile,r_emailaddress"
          state={_.uniqueId()}
        // redirectPath='/linkedin'
        >
          <div className="socialLoginBtn socialLoginBtn--linkedin">
            <div className="icon-div">
              <i className="ion-social-linkedin" />
            </div>
            <div className="icon-text-div">{this.props.linkedinBtnLabel}</div>
          </div>
        </useLinkedIn>
        {/* <SocialLogin
          provider="linkedin"
          appId={config.LINKEDIN_APP_ID}
          callback={this.props.handleSocial}
        >
          <button className="socialLoginBtn socialLoginBtn--linkedin">
            <div className="icon-div">
              <i className="ion-social-linkedin" />
            </div>
            <div className="icon-text-div">{this.props.linkedinBtnLabel}</div>
          </button>
        </SocialLogin> */}
      </div>
    );
  }
}

SocialButton.propTypes = {
  siteSettings: PropTypes.any.isRequired
}

export default SocialButton;
