import React, { Component } from "react";
import PropTypes from "prop-types";
import DropZone from "react-dropzone";
import {
  OverlayTrigger,
  // Tooltip,
  Popover,
  ButtonToolbar,
  // Button,
} from "react-bootstrap";
import Select, { components } from "react-select";
import PerfectScrollbar from "react-perfect-scrollbar";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import NotificationManager from "react-notifications/lib/NotificationManager";
import moment from "moment";
import validURL from "valid-url";
import _ from "lodash";
// import he from "he";
import DatePicker, { registerLocale } from "react-datepicker";
import { nl } from "date-fns/locale";
import { parseISO } from "date-fns";

import ActiveUrlLabel from "../ActiveUrlLabel";
import SortableActiveUrlLabel from "../SortableActiveUrlLabel";
import CarouselContainer from "./CarouselContainer";
import CustomDropModal from "../PopUp";

import api from "../../services/api";
import auth from "../../services/auth";
import strings from "../../services/strings";
import documents from "../../services/documents";
import language from "../../services/language";
import {
  makeSEFUrl,
  maximumFileSizeToUpload,
  acceptedFileFormats,
  // buildContent,
  customScrollbar,
  isMobile,
} from "../../helpers";

import img from "../../static/images/option-icon1.png";
// import img2 from "../../static/images/option-icon2.png";
// import img3 from "../../static/images/option-icon3.png";
// import img4 from "../../static/images/option-icon4.png";
// import upIcon from "../../static/images/up.png";
// import downIcon from "../../static/images/down.png";
import img_wht from "../../static/images/option-icon1-white.png";
import img2_wht from "../../static/images/option-icon5-white.png";
import img3_wht from "../../static/images/option-icon3-white.png";
import img4_wht from "../../static/images/option-icon4-white.png";

import "./DocumentFormFields.scss";

registerLocale("nl", nl);

const dummyForm = (
  <SkeletonTheme highlightColor="darkgrey">
    <Skeleton width={"75%"} />
    <Skeleton count={1} height={55} />
    <SkeletonTheme color="#F2F2F2" highlightColor="#F2F2F2">
      <Skeleton height={27} />
    </SkeletonTheme>

    <Skeleton width={"75%"} />
    <Skeleton count={1} height={55} />
    <SkeletonTheme color="#F2F2F2" highlightColor="#F2F2F2">
      <Skeleton height={27} />
    </SkeletonTheme>

    <Skeleton width={"75%"} />
    <Skeleton count={1} height={55} />
    <SkeletonTheme color="#F2F2F2" highlightColor="#F2F2F2">
      <Skeleton height={27} />
    </SkeletonTheme>

    <Skeleton width={"75%"} />
    <Skeleton count={1} height={55} />
    <SkeletonTheme color="#F2F2F2" highlightColor="#F2F2F2">
      <Skeleton height={27} />
    </SkeletonTheme>

    <Skeleton width={"75%"} />
    <Skeleton count={1} height={55} />
    <SkeletonTheme color="#F2F2F2" highlightColor="#F2F2F2">
      <Skeleton height={27} />
    </SkeletonTheme>
  </SkeletonTheme>
);

class DocumentFormFields extends Component {
  // a field can have multiple paths
  // as long as it can be used multiple times in the document
  paths = {};

  // keep track of rendered fields
  rendered = {};

  constructor(props) {
    super(props);
    this.state = {
      pendingDownload: false,
      helperModal: "",
      helperText: "",
      warningModalLabel: "",
      warningModalText: "",
      in: false,
      name: "",
      rulesReady: false,
      saveAsRole: false,
      highLightStepKeys: this.props.highLightStepKeys
        ? this.props.highLightStepKeys
        : [],
      labelStyle: {
        color: "limegreen",
      },
      boxStyle: {
        borderColor: "limegreen",
      },
      documentComments: this.props.documentComments
        ? this.props.documentComments
        : {},
      addComment: "",
      editCommentId: "",
      editCommentValue: "",
      workflowName: "",
      incorrectCompanyAddressDetails: {
        id: null,
        name: null,
        address: "",
      },
    };
  }

  componentDidMount() {
    this.setState({ draftName: false });
    window.addEventListener("beforeunload", () => {
      localStorage.removeItem("customStyle");
    });
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", () => {
      localStorage.removeItem("customStyle");
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.highLightStepKeys !== this.props.highLightStepKeys) {
      this.setState({
        highLightStepKeys: nextProps.highLightStepKeys,
      });
      _.delay(() => {
        this.setState({ highLightStepKeys: [] });
      }, 15000);
    }
    if (nextProps.documentComments !== this.state.documentComments) {
      this.setState({
        documentComments: nextProps.documentComments,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      _.size(this.props.userCurrentWorkflow) &&
      this.state.workflowName === ""
    ) {
      this.setState({ workflowName: this.props.userCurrentWorkflow.name });
    }
    if (
      this.props.rules &&
      !this.state.rulesReady &&
      !!this.props.steps &&
      this.props.steps
    ) {
      // build All paths
      this.props.steps.forEach((e) => {
        this.buildForm(e.id, this.props.rules);
      });
      this.setState({ rulesReady: true });
      this.props.handleProgressBar();
    }
    if (this.state.rulesReady && this.paths.field) {
      // this.props.onChange(this.props.rules, this.paths);
    }
    this.state.draftName =
      this.state.draftName !== false
        ? this.state.draftName
        : this.props.draftName
        ? this.props.draftName
        : false;
    // this.state.draftName = this.state.draftName !== false ? this.state.draftName : (this.props.draftName ? this.props.draftName : "new draft");
    if (this.props.alreadyExist) {
      this.props.onFreshExist();
      this.showWarningSaveModal();
    }
  }

  showModal1 = () => {
    this.refs.modal2.show();
  };

  hideModal1 = () => {
    this.refs.modal2.hide();
  };

  buildLabel = (_label, item) => {
    _label = this.props.buildLabel(_label, item);
    return _label;
  };

  getTitle = () => {
    if (this.props.currentStep) {
      //todo title placeholder
      return this.buildLabel(this.props.currentStep.name);
    } else {
      return (
        <div className="formTopOptionsLoader">
          <SkeletonTheme highlightColor="darkgrey">
            <Skeleton height={40} />
          </SkeletonTheme>
        </div>
      );
    }
  };

  getFieldClass = (item, defaultClasses = "") => {
    if (
      _.size(this.props.rulesOrder) &&
      this.props.rulesOrder[item.name] === undefined
    ) {
      defaultClasses = `${defaultClasses} fieldOrderPadding`;
    }
    if (
      _.size(this.props.hasErrors) &&
      this.props.hasErrors[item.name] &&
      item.properties.isRequired === 2
    ) {
      return `${defaultClasses} has-dangerous`;
    } else if (
      _.size(this.props.hasErrors) &&
      this.props.hasErrors[item.name]
    ) {
      return `${defaultClasses} has-error`;
    }
    return defaultClasses;
  };

  getFieldOrder = (itemName, lastKeyOrder) => {
    if (this.props.rulesOrder[itemName]) {
      if (
        this.props.rulesOrder[itemName].order ||
        this.props.rulesOrder[itemName].order === 0
      ) {
        lastKeyOrder = this.props.rulesOrder[itemName].order;
      }
    }
    return lastKeyOrder;
  };

  getFieldStyle = (itemName) => {
    let paddingRight = "5px";
    let width = 100;
    if (
      this.props.rulesOrder[itemName] &&
      this.props.rulesOrder[itemName].size &&
      !isMobile()
    ) {
      width = this.props.rulesOrder[itemName].size;
    }

    return {
      width: `${width}%`,
      paddingRight: paddingRight,
    };
  };

  getLabelClass = (labelStyle = "labelStyle") => {
    if (isMobile()) {
      return `${labelStyle} labelStyle-mobile`;
    } else {
      return labelStyle;
    }
  };

  showHelperModal = async (event, item) => {
    let helperContentLink = validURL.isUri(item.properties.helperLink) ? (
      <button className="btn btn3" onClick={() => this.handleHelperClick(item)}>
        {strings.get("Client.documentContent.helperModalButtons.link")}
      </button>
    ) : (
      ""
    );
    let helperText = this.buildLabel(item.properties.helperText);
    let description = item.properties
      ? this.getDescription(item.properties.description, item.name)
      : "";
    let helperModal = this.buildLabel(description, item);
    await this.setState({
      // helperModal: event.target.parentNode.parentNode.querySelector("label")
      //   .textContent,
      helperModal,
      // helperText: event.target.parentNode.parentNode.querySelector(
      //   ".helperText"
      // ).textContent,
      helperText,
      helperLink: helperContentLink,
    });
    await this.forceUpdate();
    await this.refs.helperTextModal.show();
  };

  hideHelperModal = () => {
    this.refs.helperTextModal.hide();
  };

  getHelper = (item) => {
    if (item.properties && item.properties.helperText) {
      this.helperTextModalContent = (
        <div className="helperTextContent">
          <span className="helperName">
            <b>{this.state.helperModal}</b>
            <div className="close-btn">
              <i
                className="ion-android-cancel clickable"
                onClick={this.hideHelperModal}
              />
            </div>
          </span>
          <br />
          <PerfectScrollbar style={customScrollbar}>
            <span className="helperText">
              <div
                dangerouslySetInnerHTML={{
                  // __html: this.buildLabel(this.state.helperText, item),
                  __html: this.state.helperText,
                }}
                style={{ marginTop: 20 }}
              />
            </span>
            <br />
          </PerfectScrollbar>
          {/*<span>{helperContentLink}</span>*/}
          <span>{this.state.helperLink}</span>
          <button className="btn btn2" onClick={this.hideHelperModal}>
            {strings.get("Client.documentContent.helperModalButtons.gotIt")}
          </button>
        </div>
      );

      return (
        <div
          className="helper clickable"
          onClick={(e) => this.showHelperModal(e, item)}
        >
          <i className="ion-help-circled" />
        </div>
      );
    }
  };

  getWarning = (item) => {
    if (item && item.properties) {
      if (
        (item.properties.isRequired &&
          item.properties.isRequired === "1" &&
          item.properties.warningText) ||
        (item.properties.isRequired === "2" && item.properties.warningTextRwdo)
      )
        return (
          <div
            ref={item.name}
            className="warning clickable"
            onClick={() => this.showWarningModal(item)}
          >
            <i className="ion-alert-circled" />
          </div>
        );
    }
  };

  gotItWarningModal = (item) => {
    //Check item is "Rquired with deny option"
    if (item.properties.isRequired === "2")
      this.saveDatatoLocalStorage("accept_rwdo_risk", item.name);
    this.hideWarningModal();
  };

  readMoreWarningModal = (item) => {
    if (item.properties.helperLink) {
      window.open(
        item.properties.helperLink,
        "_blank" // <- This is what makes it open in a new window.
      );
    }
    this.hideWarningModal();
  };

  hideWarningModal = () => {
    this.refs.warningModal.hide();
  };

  saveDatatoLocalStorage = (item, data) => {
    let a = [];
    if (sessionStorage.getItem(item) !== null) {
      // Parse the serialized data back into an aray of objects
      a = JSON.parse(sessionStorage.getItem(item));
    }

    // Push the new data (whether it be an object or anything else) onto the array
    if (!_.includes(a, data)) {
      a.push(data);
    }
    // Re-serialize the array back into a string and store it in sessionStorage
    sessionStorage.setItem(item, JSON.stringify(a));
  };

  showWarningModal = async (item) => {
    let text = "";
    if (item.properties.isRequired === "1" && item.properties.warningText) {
      text = item.properties.warningText;
    } else if (
      item.properties.isRequired === "2" &&
      item.properties.warningTextRwdo
    ) {
      text = item.properties.warningTextRwdo;
    }

    let labelContent = "";
    if (item.type === "field") {
      labelContent = _.isObject(item.properties.label)
        ? item.properties.label.default
        : item.properties.label;
    } else {
      labelContent = _.isObject(item.properties.description)
        ? item.properties.description.default
        : item.properties.description;
    }

    await this.setState({
      warningModalLabel: this.buildLabel(labelContent, item),
      warningModalText: this.buildLabel(text, item),
    });

    // const myScrollbar = {
    //   maxHeight: 400,
    // };

    this.warningTextModalContent = (
      <div className="helperTextContent">
        <span className="helperName">
          <b>{this.state.warningModalLabel}</b>
          <div className="close-btn">
            <i
              className="ion-android-cancel clickable"
              onClick={this.hideWarningModal}
            />
          </div>
        </span>
        <br />
        <PerfectScrollbar style={customScrollbar}>
          <span className="helperText">
            <div
              dangerouslySetInnerHTML={{ __html: this.state.warningModalText }}
              style={{ marginTop: 20 }}
            />
          </span>
          <br />
        </PerfectScrollbar>
        <button
          className="btn btn3"
          onClick={() => this.readMoreWarningModal(item)}
        >
          {strings.get("Client.documentContent.helperModalButtons.link")}
        </button>
        <button
          className="btn btn2"
          onClick={() => this.gotItWarningModal(item)}
        >
          {strings.get("Client.documentContent.helperModalButtons.gotIt")}
        </button>
      </div>
    );

    await this.forceUpdate();
    await this.refs.warningModal.show();
  };

  //START Clause Preview
  getClausePreview = (item) => {
    if (_.size(item.children) > 0) {
      return (
        <div
          ref={item.name}
          title="Preview"
          className="preview clickable"
          onClick={() => this.showClausePreviewModal(item)}
        >
          <i className="ion-eye" />
        </div>
      );
    }
  };

  showClausePreviewModal = (item) => {
    // const clausePreviewScrollbar = {
    //   maxHeight: 400,
    // };
    this.clausePreviewModalContent = (
      <div className="helperTextContent">
        <div className="close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.hideClausePreviewModal}
          />
        </div>
        <PerfectScrollbar style={customScrollbar}>
          <span className="helperText">
            <div className="content">
              {this.props.handlePrepareItemPreview(item, true)}
            </div>
          </span>
          <br />
        </PerfectScrollbar>
        <button className="btn btn2" onClick={this.hideClausePreviewModal}>
          {strings.get("Client.documentContent.helperModalButtons.gotIt")}
        </button>
      </div>
    );
    this.forceUpdate();
    this.refs.clausePreviewModal.show();
  };

  hideClausePreviewModal = () => {
    this.refs.clausePreviewModal.hide();
  };
  //END

  getButtons = () => {
    let buttons = [];
    const styleForButton = {
      fontSize: 25 + "px",
      verticalAlign: "sub",
      lineHeight: 0,
    };

    if (this.props.isLastStep) {
      buttons.push(
        <button
          className="btn btn2 pull-right download"
          disabled={this.state.pendingDownload}
          onClick={this.handleDownloadClick}
          key={_.uniqueId()}
        >
          <i className="ion-ios-cloud-download" />
          {strings.get("Client.documentContent.btnDownload")}
        </button>
      );
    } else {
      buttons.push(
        <button
          className="btn btn1 pull-right"
          onClick={this.handleNextStepClick}
          key={_.uniqueId()}
        >
          {strings.get("Client.documentContent.btnNext")}{" "}
          <span style={styleForButton}>&raquo;</span>
        </button>
      );
    }

    if (!this.props.isFirstStep) {
      buttons.push(
        <button
          className="btn btn1"
          onClick={(e) => this.handlePrevStepClick(e, this.path)}
          key={_.uniqueId()}
        >
          <span style={styleForButton}>&laquo;</span>{" "}
          {strings.get("Client.documentContent.btnPrev")}
        </button>
      );
    }
    return buttons;
  };

  getForm = () => {
    if (this.props.currentStep && this.props.rules) {
      // we want to rebuild the form
      // so we reset rendered fields tracker
      this.rendered = {};
      let form = this.buildForm(this.props.currentStep.id, this.props.rules);
      this.props.onRender(this.rendered);

      // group and order elements
      form = _.sortBy(form, ["props.order"]);
      if(window.location.pathname === "/NL/documenten/huurder_woonruimte-51/protest_opzegging_(huurachterstand)-142" && form.length === 1){
        form[0].props.style.width = "100%"
      } 
      return form;
    }
  };

  buildCarousel = (...data) => {
    let label = this.getDescription(
      data[0].properties.description,
      data[0].name
    );
    if (data[0].options && data[0].options.length > 0)
      return (
        <div
          key={`carousel-${data[0].properties.name}`}
          className={this.getFieldClass(data[0])}
          order={this.getFieldOrder(data[0].name, data[3])}
          style={this.getFieldStyle(data[0].name)}
        >
          <CarouselContainer
            carousel_label={label}
            name={
              data[0].properties.placeholder &&
              data[0].properties.placeholder !== ""
                ? data[0].properties.placeholder
                : data[0].name
            }
            options={data[0].options}
            onAddToTemplate={(optionIndex) =>
              this.onAddToTemplate(
                optionIndex,
                data[0],
                this.paths[data[0].type][data[0].name]
              )
            }
            parent_carousel={data[0]}
            buildLabel={this.buildLabel}
            getDescription={this.getDescription}
            getHelper={this.getHelper}
            getWarning={this.getWarning}
            getClausePreview={this.getClausePreview}
            showCarouselPreviewModal={this.showClausePreviewModal}
          />
        </div>
      );
    return "";
  };

  shouldRender = (node, path, currentStepId) => {
    let shouldRender = true;
    // if(node.type === "carousel")
    // debugger
    // if (node.type === "carousel" && node.properties && node.properties.stepId === currentStepId) {
    //   return shouldRender;
    // }
    // if node is not a field
    // there is no need for render and processing
    if (!documents.isRuleField(node)) {
      return false;
    }

    // if field step is different than the current step
    // don't render it
    if (node.properties && node.properties.stepId !== currentStepId) {
      return false;
    }

    // init rendered and paths type
    if (!this.rendered[node.type]) this.rendered[node.type] = {};
    if (!this.paths[node.type]) this.paths[node.type] = {};

    // init paths object for this specific field
    if (!this.paths[node.type][node.name])
      this.paths[node.type][node.name] = {};
    let paths = this.paths[node.type][node.name];

    let pathStringMatches = false;
    if (_.size(paths)) {
      let oldPathString = paths[_.first(_.keys(paths))].value.toString();
      let pathString = path.toString();
      if (
        typeof node.properties != "undefined" &&
        node.properties.stepId === "23"
      ) {
        pathStringMatches = _.find(_.keys(paths), pathString);
        // pathStringMatches = _.endsWith(oldPathString, pathString);
      } else {
        pathStringMatches = oldPathString !== pathString;
      }
    }
    // if the field already has a different path added
    // there is no need to add the input again in the form
    if (_.size(paths) && pathStringMatches) {
      // propagate existing value to the current field
      let rules = _.extend(this.props.rules, {});
      if (rules.asMutable) rules = rules.asMutable();

      rules = this.replaceValue(
        rules,
        paths[_.first(_.keys(paths))].node.value,
        path
      );
      shouldRender = false;
    } else {
      // mark field as rendered
      this.rendered[node.type][node.name] = node;
    }

    paths[path.toString()] = {
      node,
      value: path,
    };

    this.props.setPaths(this.paths);
    return shouldRender;
  };

  replaceValue = (form, value, path) => {
    if (form.asMutable) {
      form = form.asMutable();
    }
    if (_.size(path) === 0) {
      // replace value in the rules tree
      form.value = value;
      return form;
    }
    form[_.head(path)] = this.replaceValue(
      form[_.head(path)],
      value,
      _.tail(path)
    );
    return form;
  };

  renderedKeys = "";

  buildForm = (stepId, variables, path = [], lastKeyOrder = 0) => {
    let form = [],
      self = this,
      tempDocuments = documents;
    _.each(variables, (node, key, arr) => {
      let element = null;
      // let keys = self.renderedKeys ? self.renderedKeys : "";

      // check whether or not current node should be rendered
      // and build it accordingly
      if (self.shouldRender(node, path.concat(key), stepId)) {
        if (node.type === tempDocuments.ruleTypes.SELECTOR) {
          element = self.buildSelector(
            node,
            path.concat(key),
            arr,
            lastKeyOrder
          );
        } else if (node.type === tempDocuments.ruleTypes.CLAUSE) {
          element = self.buildClause(node, path.concat(key), arr, lastKeyOrder);
        } else if (node.type === tempDocuments.ruleTypes.FIELD) {
          element = self.buildField(node, path.concat(key), arr, lastKeyOrder);
        } else if (node.type === tempDocuments.ruleTypes.CAROUSEL) {
          element = self.buildCarousel(
            node,
            path.concat(key),
            arr,
            lastKeyOrder
          );
        }
      }

      if (element) {
        form.push(element);
      }

      // build recursively the rest of the form tree
      if (_.size(node.children)) {
        // if node is clause check if it's checked
        if (node.type === tempDocuments.ruleTypes.CLAUSE && node.value) {
          let nodes = self.buildForm(
            stepId,
            node.children,
            path.concat(key).concat("children"),
            this.props.rulesOrder[node.name] &&
              this.props.rulesOrder[node.name].order
              ? this.props.rulesOrder[node.name].order
              : lastKeyOrder
          );
          form = form.concat(nodes);
        }

        // if node is selector check if a valid option is selected
        else if (node.type === tempDocuments.ruleTypes.SELECTOR) {
          let optionKey = _.findIndex(
            node.children,
            (item) => item.name === node.value
          );

          if (optionKey > -1 && _.size(node.children[optionKey].children)) {
            let nodes = self.buildForm(
              stepId,
              node.children[optionKey].children,
              path
                .concat(key)
                .concat("children")
                .concat(optionKey)
                .concat("children"),
              this.props.rulesOrder[node.name] &&
                this.props.rulesOrder[node.name].order
                ? this.props.rulesOrder[node.name].order
                : lastKeyOrder
            );
            form = form.concat(nodes);
          }
        }

        // if node is a carousel then check if a valid slide is selected
        else if (node.type === tempDocuments.ruleTypes.CAROUSEL) {
          let optionKey = _.findIndex(
            node.children,
            (item) => item.name === node.value
          );

          if (optionKey > -1 && _.size(node.children[optionKey].children)) {
            let nodes = self.buildForm(
              stepId,
              node.children[optionKey].children,
              path
                .concat(key)
                .concat("children")
                .concat(optionKey)
                .concat("children"),
              this.props.rulesOrder[node.name] &&
                this.props.rulesOrder[node.name].order
                ? this.props.rulesOrder[node.name].order
                : lastKeyOrder
            );
            form = form.concat(nodes);
          }
        }

        // if node is html simply parse rest of the children
        else if (node.type === tempDocuments.ruleTypes.HTML) {
          let nodes = self.buildForm(
            stepId,
            node.children,
            path.concat(key).concat("children"),
            this.props.rulesOrder[node.name] &&
              this.props.rulesOrder[node.name].order
              ? this.props.rulesOrder[node.name].order
              : lastKeyOrder
          );
          form = form.concat(nodes);
        }
      }
    });

    return form;
  };

  getDescription = (data, codeName) => {
    let selectorType = this.props.selectorType;
    let label_preference = "";
    let marked_codes = [];
    let currentDoc = this.props.document;
    if (currentDoc.label_preference) {
      let acceptedPrefrences = ["default", "me", "client"];
      if (acceptedPrefrences.includes(currentDoc.label_preference)) {
        label_preference = currentDoc.label_preference;
      }
    }
    if (currentDoc.marked_codes) {
      try {
        marked_codes = JSON.parse(currentDoc.marked_codes);
        if (
          typeof marked_codes === "object" &&
          marked_codes.includes(codeName)
        ) {
          label_preference = "me";
        }
      } catch (error) {
        console.trace(error);
      }
    }
    const description = _.isObject(data)
      ? data[selectorType] === undefined || data[selectorType] === ""
        ? data[label_preference]
          ? data[label_preference]
          : data.default
        : data[selectorType]
      : data;
    return description;
  };

  // getInnerSelectorCustomStyle = (element) => {
  //   if (element.children) {
  //     element.children.forEach((options) => {
  //       if (options.children) {
  //         this.getInnerSelectorCustomStyle(options.children);
  //       } else if (
  //         options.type === "text" &&
  //         options.value.match(/{[0-9]+?%}/)
  //       ) {
  //         let styleObj = JSON.parse(localStorage.getItem("customStyle"))
  //           ? JSON.parse(localStorage.getItem("customStyle"))
  //           : {};
  //         let signIndex = element.children(options);
  //         let targetCode = element.children[signIndex - 1].name;
  //         let matchObj = options.value.match(/{[0-9]+?%}/);
  //         let match = Object.values(matchObj)[0];
  //         styleObj[targetCode] = match.substring(1, match.length - 1);
  //         localStorage.setItem("customStyle", JSON.stringify(styleObj));
  //       }
  //     });
  //   }
  // };

  popoverTop = (item) => {
    if (item === "false" || isMobile()) {
      return (
        <Popover id="popover-positioned-left" className="labelPopoverFalse" />
      );
    } else {
      return (
        <Popover id="popover-positioned-left" className="labelPopover">
          <strong>{item}</strong>
        </Popover>
      );
    }
  };

  handleAddComment = (field_name) => {
    let { addComment } = this.state;
    let current_time = new Date();
    let utcDate =
      current_time.getUTCFullYear() +
      "-" +
      (current_time.getUTCMonth() + 1) +
      "-" +
      current_time.getUTCDate() +
      " " +
      current_time.getUTCHours() +
      ":" +
      current_time.getUTCMinutes() +
      ":" +
      (current_time.getUTCSeconds() > 9
        ? current_time.getUTCSeconds()
        : `0${current_time.getUTCSeconds()}`);
    let newComment = {
      code: field_name,
      comment: addComment,
      created_at: utcDate,
    };
    if (newComment.comment.length) this.props.handleAddComment(newComment);
    this.setState({ addComment: "" });
  };

  handleDeleteAllComment = (field_name) => {
    this.props.handleDeleteAllComment(field_name);
  };

  handleEditComment = (field_name, index) => {
    let { documentComments, editCommentId } = this.state;
    if (editCommentId.length === 0) {
      if (
        this.props.profile &&
        documentComments[field_name][index].user_id &&
        documentComments[field_name][index].user_id.id
      )
        if (
          this.props.profile.id ===
          documentComments[field_name][index].user_id.id
        ) {
          this.setState({
            editCommentId: field_name.concat("-").concat(index),
            editCommentValue: documentComments[field_name][index].comment,
          });
          document
            .querySelector(`#${field_name.concat("-").concat(index)}`)
            .removeAttribute("disabled");
        }
    }
  };

  handleUpdateComment = (field_name, index) => {
    this.props.handleUpdateComment(
      this.state.editCommentValue,
      field_name,
      index
    );
    document
      .querySelector(`#${field_name.concat("-").concat(index)}`)
      .setAttribute("disabled", true);
    this.setState({
      editCommentId: "",
      editCommentValue: "",
    });
  };

  handleCommentEditvalue = (event, field_name, index) => {
    let { editCommentValue } = this.state;
    editCommentValue = event.target.value;
    this.setState({ editCommentValue });
  };

  getCommentIcon = (item) => {
    if (!auth.isAuthenticated()) {
      return null;
    }
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom"
        overlay={this.getComments(item.name)}
      >
        <i
          className="ion-chatbubbles"
          style={{
            marginLeft: "-10px",
            marginRight: "5px",
            color: this.state.documentComments[item.name] ? "green" : "grey",
          }}
        />
      </OverlayTrigger>
    );
  };

  getComments = (field_name) => {
    let { documentComments } = this.state;
    let commentData = (
      <div className="row comments">
        <div
          className="col-md-12 col-xs-12 col-sm-12 no-comment-container"
          // style={{ textAlign: "centre", margin: "5px 5px" }}
        >
          {strings.get("Client.documentContent.comments.noComments")}
        </div>
      </div>
    );
    if (documentComments[field_name]) {
      let comments = documentComments[field_name];
      commentData = Object.keys(comments).map((key, index) => {
        let created_at = new Date(comments[index].created_at + " GMT");
        // let formatted_date = created_at.getFullYear() + "-" + (created_at.getMonth() + 1) + "-" + created_at.getDate() + " " + created_at.getHours() + ":" + created_at.getMinutes();
        return (
          <div key={index} className="row comments">
            <div
              className="col-md-12 col-xs-12 col-sm-12 comment-body"
              // style={{ padding: "5px", display: "flex", alignItems: "center" }}
            >
              <textarea
                className="form-control comment-view"
                // style={{
                //   borderRadius: "3px",
                //   maxWidth: "80%",
                //   resize: "none",
                //   marginTop: "5px",
                // }}
                disabled
                rows="2"
                id={field_name.concat("-").concat(index)}
                value={
                  this.state.editCommentId ===
                  field_name.concat("-").concat(index)
                    ? this.state.editCommentValue
                    : documentComments[field_name][index].comment
                }
                onChange={(e) =>
                  this.handleCommentEditvalue(e, field_name, index)
                }
              />
              {this.state.editCommentId ===
              field_name.concat("-").concat(index) ? (
                <button
                  className="btn btn-info commentAlterButton"
                  onClick={() => this.handleUpdateComment(field_name, index)}
                >
                  {strings.get("Client.documentContent.comments.update")}
                </button>
              ) : (
                <button
                  className="btn btn-primary commentAlterButton"
                  onClick={() => this.handleEditComment(field_name, index)}
                >
                  {strings.get("Client.documentContent.comments.edit")}
                </button>
              )}
            </div>
            <div>
              <div
                className="user-name"
                // style={{ marginBottom: "5px", padding: "5px", float: "left" }}
              >
                {`#${comments[index].user_id.first_name}`}
              </div>
              <div
                className="post-date"
                // style={{ marginBottom: "5px", padding: "5px", float: "right" }}
              >
                {`@${created_at.toString().substr(4, 17)}`}
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <Popover id={field_name} className="commentPopover">
        <div
          className="comments-container"
          // style={{ textAlign: "center", minWidth: "200px" }}
        >
          <div
            className="comment-box-title-container"
            // style={{ paddingBottom: 25 }}
          >
            <div
              className="float-left"
              // style={{ float: "left" }}
            >
              <span>
                {strings.get("Client.documentContent.comments.comments")}
              </span>
            </div>
            <div
              className="float-right"
              // style={{ float: "right" }}
            >
              <button
                className="btn btn-warning comment-delete-button"
                // style={{
                //   borderRadius: "3px",
                //   backgroundColor: "#c29032",
                //   borderColor: "#c29032",
                //   padding: "6px 15px",
                // }}
                onClick={() => this.handleDeleteAllComment(field_name)}
              >
                {strings.get("Client.documentContent.comments.deleteAll")}
              </button>
            </div>
          </div>
          <hr
            className="first-comment-divider"
            // style={{ margin: "10px 10px" }}
          />
          <div
            className="comments-data-container"
            // style={{
            //   maxHeight: "325px",
            //   margin: "0px -10px",
            //   overflowY: "auto",
            // }}
          >
            {commentData}
          </div>
          <hr
            className="second-comment-divider"
            // style={{ margin: "15px 15px", padding: "1px" }}
          />
          <div
            className="display-flex"
            // style={{ display: "flex" }}
          >
            <input
              type="text"
              placeholder={`${strings.get(
                "Client.documentContent.comments.addComment"
              )}..`}
              className="form-control new-comment-input"
              // style={{ borderRadius: "3px", maxWidth: "80%" }}
              value={this.state.addComment}
              onChange={(e) => {
                this.setState({ addComment: e.target.value });
              }}
            />
            <button
              className="btn btn-success comment-add-button"
              // style={{
              //   borderRadius: "3px",
              //   marginLeft: "5px",
              //   padding: "6px 15px",
              // }}
              onClick={() => this.handleAddComment(field_name)}
            >
              {strings.get("Client.documentContent.comments.add")}
            </button>
          </div>
        </div>
      </Popover>
    );
  };

  buildSelector = (item, path, arr = [], lastKeyOrder = 0) => {
    let itemName =
      item.properties && item.properties.code_full_name
        ? item.properties.code_full_name
        : item.name;
    let selectorName = itemName.split("_");
    let companySelector = false;
    if (
      _.size(selectorName) > 0 &&
      selectorName[
        item.properties && item.properties.code_full_name ? 1 : 0
      ] === documents.ruleTypes.ADDRSELE
    ) {
      companySelector = true;
    }
    const description = item.properties
      ? this.getDescription(item.properties.description, item.name)
      : "";

    const { Option } = components;

    const customOption = (props) => {
      return (
        <Option {...props}>
          <div>
            <div className="custom-address-selector-option">
              <span>{props.data.label}</span>
              <i
                className="ion-compose"
                onClick={() => props.data.reportAddress(props.data)}
                title={strings.get(
                  "Client.draftsPage.customAddressSelector.helperTitle"
                )}
              />
            </div>
            {!!props.data.companyCategory && <p>{props.data.companyCategory}</p>}
          </div>
        </Option>
      );
    };

    const handleClickOnReportAddress = ({ id, label, ...props }) => {
      this.setIncorrectCompanyAddressDetails(id, label);
      this.showIncorrectCompanyAddressModal();
    };
    if (companySelector) {
      let self = this;
      let options = [];
      _.forEach(item.children, function (option, key) {
        let company = {
          id: option.id,
          value: `${option.name}~${option.companyCategory?option.companyCategory:''}`,
          selectingValue: option.name,
          companyCategory: option.companyCategory,
          label: self.buildLabel(option.name),
          reportAddress: handleClickOnReportAddress,
        };
        options.push(company);
      });
      let result = _.find(options, function (o) {
        return o.selectingValue === item.value;
      });
      return (
        <div
          key={`selector-${item.name}`}
          className={this.getFieldClass(item, "form-group testing")}
          type={1}
          order={this.getFieldOrder(item.name, lastKeyOrder)}
          style={this.getFieldStyle(item.name)}
        >
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="top"
            overlay={
              this.props.rulesOrder[item.name] &&
              this.props.rulesOrder[item.name].size &&
              this.props.rulesOrder[item.name].size < 100
                ? this.popoverTop(description)
                : this.popoverTop("false")
            }
          >
            <div>
              <label
                style={
                  this.state.highLightStepKeys.length &&
                  this.state.highLightStepKeys.includes(item.name)
                    ? this.state.labelStyle
                    : null
                }
                // className="labelStyle"
                className={this.getLabelClass()}
              >
                {description}
              </label>
              <div className="helperText" style={{ display: "none" }}>
                {item.properties ? item.properties.helperText : ""}
              </div>
              {this.getHelper(item)}
              {this.getWarning(item)}
              <Select
                isDisabled={this.checkShareFieldsExists(item)}
                className="form-control"
                classNamePrefix="address-selector"
                value={result}
                onChange={(e) =>
                  this.handleAddressChange(
                    e,
                    this.paths[item.type][item.name],
                    item,
                    true
                  )
                }
                options={options}
                isClearable={true}
                components={{ Option: customOption }}
              />
              <div
                style={{
                  right: "30px",
                  position: "absolute",
                  marginTop: "-40px",
                  fontSize: "19px",
                }}
              >
                {this.getCommentIcon(item)}
              </div>
            </div>
          </OverlayTrigger>
        </div>
      );
    } else {
      let options=[]
      if(_.has(item,"options")){
        options = _.map(item.options,(option, index)=>(
          <option key={option} value={index}>
           {option}
          </option>
        ))
      } else {
        options = _.map(item.children, (option) => (
          <option key={option.name} value={option.name}>
            {option.name}
          </option>
        ));
      }
      return (
        <div
          key={`selector-${item.name}`}
          className={this.getFieldClass(item, "form-group")}
          type={1}
          order={this.getFieldOrder(item.name, lastKeyOrder)}
          style={this.getFieldStyle(item.name)}
        >
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="top"
            overlay={
              this.props.rulesOrder[item.name] &&
              this.props.rulesOrder[item.name].size &&
              this.props.rulesOrder[item.name].size < 100
                ? this.popoverTop(this.buildLabel(description, item))
                : this.popoverTop("false")
            }
          >
            <div>
              <label
                style={
                  this.state.highLightStepKeys.length &&
                  this.state.highLightStepKeys.includes(item.name)
                    ? this.state.labelStyle
                    : null
                }
                // className="labelStyle"
                className={this.getLabelClass()}
              >
                {this.buildLabel(description, item)}
              </label>
              <div className="helperText" style={{ display: "none" }}>
                {item.properties ? item.properties.helperText : ""}
              </div>
              {this.getHelper(item)}
              {this.getWarning(item)}
              <select
                disabled={this.checkShareFieldsExists(item)}
                className="form-control"
                value={item.value}
                onChange={(e) =>
                  this.handleInputChange(
                    e,
                    this.paths[item.type][item.name],
                    item,
                    true
                  )
                }
                style={
                  this.state.highLightStepKeys.length &&
                  this.state.highLightStepKeys.includes(item.name)
                    ? this.state.boxStyle
                    : null
                }
              >
                <option value="" />
                {options}
              </select>
              <div
                style={{
                  right: "30px",
                  position: "absolute",
                  marginTop: "-40px",
                  fontSize: "19px",
                }}
              >
                {this.getCommentIcon(item)}
              </div>
            </div>
          </OverlayTrigger>
        </div>
      );
    }
  };

  buildClause = (item, path, arr = [], lastKeyOrder = 0) => {
    let type = "checkbox";
    let radioGroup = false;
    let fieldName = "";
    if (item.properties && typeof item.properties.groupName != "undefined") {
      fieldName = item.properties.groupName;
      if (fieldName) {
        radioGroup = _.includes(fieldName, "radiogroup");
        if (radioGroup) {
          type = "radio";
        }
      }
    }
    let description = item.properties
      ? this.getDescription(item.properties.description, item.name)
      : "";
    return (
      <div
        key={`clause-${type === "radio" ? `radio-field-${fieldName}-` : ""}${
          item.name
        }`}
        className={this.getFieldClass(item, "checkbox")}
        type={2}
        order={this.getFieldOrder(item.name, lastKeyOrder)}
        style={this.getFieldStyle(item.name)}
      >
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="top"
          overlay={
            this.props.rulesOrder[item.name] &&
            this.props.rulesOrder[item.name].size &&
            this.props.rulesOrder[item.name].size < 100
              ? this.popoverTop(this.buildLabel(description, item))
              : this.popoverTop("false")
          }
        >
          <div>
            <label
              style={
                this.state.highLightStepKeys.length &&
                this.state.highLightStepKeys.includes(item.name)
                  ? { width: "80%", ...this.state.labelStyle }
                  : { width: "80%" }
              }
              // className="labelStyle"
              className={this.getLabelClass()}
            >
              <input
                disabled={this.checkShareFieldsExists(item)}
                name={fieldName}
                type={type}
                checked={item.value}
                onChange={(e) =>
                  this.handleCheckboxChange(
                    e,
                    this.paths[item.type][item.name],
                    item,
                    radioGroup
                  )
                }
              />
              {/* {this.buildLabel(description, item)} */}
              <p style={{ paddingRight: "35px" }}>
                {this.buildLabel(description, item)}
              </p>
            </label>
            <div className="helperText" style={{ display: "none" }}>
              {item.properties ? item.properties.helperText : ""}
            </div>
            <div style={{ right: "55px", position: "absolute", top: "0px" }}>
              {this.getCommentIcon(item)}
            </div>
            {this.getHelper(item)}
            {this.getWarning(item)}
            {this.getClausePreview(item)}
          </div>
        </OverlayTrigger>
      </div>
    );
  };

  buildField = (item, path, arr = [], lastKeyOrder = 0) => {
    if (typeof item.properties != "undefined") {
      let label = this.getDescription(item.properties.label, item.name);
      if (item.properties.type === documents.ruleTypes.field.TEXT) {
        return (
          <div
            key={`field-${item.name}`}
            className={this.getFieldClass(item, "form-group")}
            type={0}
            order={this.getFieldOrder(item.name, lastKeyOrder)}
            style={this.getFieldStyle(item.name)}
          >
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="top"
              overlay={
                this.props.rulesOrder[item.name] &&
                this.props.rulesOrder[item.name].size &&
                this.props.rulesOrder[item.name].size < 100
                  ? this.popoverTop(this.buildLabel(label, item))
                  : this.popoverTop("false")
              }
            >
              <div>
                <label
                  // className="labelForModal labelStyle"
                  className={this.getLabelClass("labelForModal labelStyle")}
                  style={
                    this.state.highLightStepKeys.length &&
                    this.state.highLightStepKeys.includes(item.name)
                      ? this.state.labelStyle
                      : null
                  }
                >
                  {this.buildLabel(label, item)}
                </label>
                <div className="helperText" style={{ display: "none" }}>
                  {item.properties.helperText}
                </div>
                {this.getHelper(item)}
                {this.getWarning(item)}
                {/* <div className="form-group"> */}
                <div className="input-group">
                  <input
                    readOnly={this.checkShareFieldsExists(item)}
                    className="form-control"
                    type="text"
                    value={item.value}
                    placeholder={
                      item.properties.placeholder &&
                      this.buildLabel(item.properties.placeholder)
                    }
                    onChange={(e) =>
                      this.handleInputChange(
                        e,
                        this.paths[item.type][item.name],
                        item
                      )
                    }
                    style={
                      this.state.highLightStepKeys.length &&
                      this.state.highLightStepKeys.includes(item.name)
                        ? this.state.boxStyle
                        : null
                    }
                  />
                  <div className="input-group-addon">
                    {this.getCommentIcon(item)}

                    <i
                      className="ion-close-circled clickable"
                      onClick={(e) =>
                        this.handleInputClear(
                          e,
                          this.paths[item.type][item.name],
                          item
                        )
                      }
                    />
                  </div>
                </div>
                {/* </div> */}
              </div>
            </OverlayTrigger>
          </div>
        );
      } else if (item.properties.type === documents.ruleTypes.field.TEXTAREA) {
        return (
          <div
            key={`field-${item.name}`}
            className={this.getFieldClass(item, "form-group")}
            type={0}
            order={this.getFieldOrder(item.name, lastKeyOrder)}
            style={this.getFieldStyle(item.name)}
          >
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="top"
              overlay={
                this.props.rulesOrder[item.name] &&
                this.props.rulesOrder[item.name].size &&
                this.props.rulesOrder[item.name].size < 100
                  ? this.popoverTop(this.buildLabel(label, item))
                  : this.popoverTop("false")
              }
            >
              <div>
                <label
                  style={
                    this.state.highLightStepKeys.length &&
                    this.state.highLightStepKeys.includes(item.name)
                      ? this.state.labelStyle
                      : null
                  }
                  // className="labelStyle"
                  className={this.getLabelClass()}
                >
                  {this.buildLabel(label, item)}
                </label>
                <div className="helperText" style={{ display: "none" }}>
                  {item.properties.helperText}
                </div>
                {this.getHelper(item)}
                {this.getWarning(item)}
                <textarea
                  readOnly={this.checkShareFieldsExists(item)}
                  className="form-control"
                  value={item.value}
                  rows="3"
                  placeholder={item.properties.placeholder}
                  onChange={(e) =>
                    this.handleInputChange(
                      e,
                      this.paths[item.type][item.name],
                      item
                    )
                  }
                  style={
                    this.state.highLightStepKeys.length &&
                    this.state.highLightStepKeys.includes(item.name)
                      ? this.state.boxStyle
                      : null
                  }
                />
                <div
                  style={{
                    right: "20px",
                    position: "absolute",
                    marginTop: "-40px",
                    fontSize: "19px",
                  }}
                >
                  {this.getCommentIcon(item)}
                </div>
              </div>
            </OverlayTrigger>
          </div>
        );
      } else if (item.properties.type === documents.ruleTypes.field.NUMERIC) {
        return (
          <div
            key={`field-${item.name}`}
            className={this.getFieldClass(item, "form-group")}
            type={0}
            order={this.getFieldOrder(item.name, lastKeyOrder)}
            style={this.getFieldStyle(item.name)}
          >
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="top"
              overlay={
                this.props.rulesOrder[item.name] &&
                this.props.rulesOrder[item.name].size &&
                this.props.rulesOrder[item.name].size < 100
                  ? this.popoverTop(this.buildLabel(label, item))
                  : this.popoverTop("false")
              }
            >
              <div>
                <label
                  style={
                    this.state.highLightStepKeys.length &&
                    this.state.highLightStepKeys.includes(item.name)
                      ? this.state.labelStyle
                      : null
                  }
                  // className="labelStyle"
                  className={this.getLabelClass()}
                >
                  {this.buildLabel(label, item)}
                </label>
                <div className="helperText" style={{ display: "none" }}>
                  {item.properties.helperText}
                </div>
                {this.getHelper(item)}
                {this.getWarning(item)}
                <input
                  readOnly={this.checkShareFieldsExists(item)}
                  className="form-control"
                  type="text"
                  value={item.value}
                  placeholder={item.properties.placeholder}
                  onChange={(e) =>
                    this.handleNumericChange(
                      e,
                      this.paths[item.type][item.name],
                      item
                    )
                  }
                  style={
                    this.state.highLightStepKeys.length &&
                    this.state.highLightStepKeys.includes(item.name)
                      ? this.state.boxStyle
                      : null
                  }
                />
                <div
                  style={{
                    right: "5px",
                    position: "absolute",
                    marginTop: "-40px",
                  }}
                >
                  {this.getCommentIcon(item)}
                </div>
              </div>
            </OverlayTrigger>
          </div>
        );
      } else if (item.properties.type === documents.ruleTypes.field.SELECTOR) {
        let options = _.map(item.properties.options, (option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ));

        return (
          <div
            key={`field-${item.name}`}
            className={this.getFieldClass(item, "form-group")}
            type={0}
            order={this.getFieldOrder(item.name, lastKeyOrder)}
            style={this.getFieldStyle(item.name)}
          >
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="top"
              overlay={
                this.props.rulesOrder[item.name] &&
                this.props.rulesOrder[item.name].size &&
                this.props.rulesOrder[item.name].size < 100
                  ? this.popoverTop(this.buildLabel(label, item))
                  : this.popoverTop("false")
              }
            >
              <div>
                <label
                  style={
                    this.state.highLightStepKeys.length &&
                    this.state.highLightStepKeys.includes(item.name)
                      ? this.state.labelStyle
                      : null
                  }
                  // className="labelStyle"
                  className={this.getLabelClass()}
                >
                  {this.buildLabel(label, item)}
                </label>
                <div className="helperText" style={{ display: "none" }}>
                  {item.properties.helperText}
                </div>
                {this.getHelper(item)}
                {this.getWarning(item)}
                <select
                  disabled={this.checkShareFieldsExists(item)}
                  className="form-control"
                  value={item.value}
                  onChange={(e) =>
                    this.handleInputChange(
                      e,
                      this.paths[item.type][item.name],
                      item
                    )
                  }
                  style={
                    this.state.highLightStepKeys.length &&
                    this.state.highLightStepKeys.includes(item.name)
                      ? this.state.boxStyle
                      : null
                  }
                >
                  <option value="" />
                  {options}
                </select>
                <div
                  style={{
                    right: "30px",
                    position: "absolute",
                    marginTop: "-40px",
                    fontSize: "19px",
                  }}
                >
                  {this.getCommentIcon(item)}
                </div>
              </div>
            </OverlayTrigger>
          </div>
        );
      } else if (
        item.properties.type === documents.ruleTypes.field.GENDER_SELECTOR
      ) {
        return (
          <div
            key={`field-${item.name}`}
            className={this.getFieldClass(item, "form-group")}
            type={0}
            order={this.getFieldOrder(item.name, lastKeyOrder)}
            style={this.getFieldStyle(item.name)}
          >
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="top"
              overlay={
                this.props.rulesOrder[item.name] &&
                this.props.rulesOrder[item.name].size &&
                this.props.rulesOrder[item.name].size < 100
                  ? this.popoverTop(this.buildLabel(label, item))
                  : this.popoverTop("false")
              }
            >
              <div>
                <label
                  style={
                    this.state.highLightStepKeys.length &&
                    this.state.highLightStepKeys.includes(item.name)
                      ? this.state.labelStyle
                      : null
                  }
                  // className="labelStyle"
                  className={this.getLabelClass()}
                >
                  {this.buildLabel(label, item)}
                </label>
                <div className="helperText" style={{ display: "none" }}>
                  {item.properties.helperText}
                </div>
                {this.getHelper(item)}
                {this.getWarning(item)}
                <select
                  disabled={this.checkShareFieldsExists(item)}
                  className="form-control"
                  value={item.value}
                  onChange={(e) =>
                    this.handleInputChange(
                      e,
                      this.paths[item.type][item.name],
                      item
                    )
                  }
                  style={
                    this.state.highLightStepKeys.length &&
                    this.state.highLightStepKeys.includes(item.name)
                      ? this.state.boxStyle
                      : null
                  }
                >
                  <option value="" />
                  <option value={documents.genders.MALE}>
                    {item.properties.male}
                  </option>
                  <option value={documents.genders.FEMALE}>
                    {item.properties.female}
                  </option>
                </select>
                <div
                  style={{
                    right: "30px",
                    position: "absolute",
                    marginTop: "-40px",
                    fontSize: "19px",
                  }}
                >
                  {this.getCommentIcon(item)}
                </div>
              </div>
            </OverlayTrigger>
          </div>
        );
      }
      //Below Address selector concept is not used.
      else if (
        item.properties.type === documents.ruleTypes.field.ADDRESS_SELECTOR
      ) {
        let options = [];
        _.forEach(item.properties.options, function (value, key) {
          let company = {
            value: value,
            label: key,
          };
          options.push(company);
        });
        let result = _.find(options, function (o) {
          return o.value === item.value;
        });

        return (
          <div
            key={`field-${item.name}`}
            className={this.getFieldClass(item, "form-group")}
            type={0}
            order={this.getFieldOrder(item.name, lastKeyOrder)}
            style={this.getFieldStyle(item.name)}
          >
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="top"
              overlay={
                this.props.rulesOrder[item.name] &&
                this.props.rulesOrder[item.name].size &&
                this.props.rulesOrder[item.name].size < 100
                  ? this.popoverTop(this.buildLabel(label, item))
                  : this.popoverTop("false")
              }
            >
              <div>
                <label
                  style={
                    this.state.highLightStepKeys.length &&
                    this.state.highLightStepKeys.includes(item.name)
                      ? this.state.labelStyle
                      : null
                  }
                  // className="labelStyle"
                  className={this.getLabelClass()}
                >
                  {this.buildLabel(label, item)}
                </label>
                <div className="helperText" style={{ display: "none" }}>
                  {item.properties.helperText}
                </div>
                {this.getHelper(item)}
                {this.getWarning(item)}
                <Select
                  isDisabled={this.checkShareFieldsExists(item)}
                  className="form-control"
                  classNamePrefix="address-selector"
                  value={result}
                  onChange={(e) =>
                    this.handleAddressChange(
                      e,
                      this.paths[item.type][item.name],
                      item
                    )
                  }
                  options={options}
                  isClearable={true}
                />
                <div
                  style={{
                    right: "30px",
                    position: "absolute",
                    marginTop: "-40px",
                    fontSize: "19px",
                  }}
                >
                  {this.getCommentIcon(item)}
                </div>
              </div>
            </OverlayTrigger>
          </div>
        );
      } else if (item.properties.type === documents.ruleTypes.field.DATE) {
        return (
          <div
            key={`field-${item.name}`}
            className={this.getFieldClass(item, "form-group")}
            type={0}
            order={this.getFieldOrder(item.name, lastKeyOrder)}
            style={this.getFieldStyle(item.name)}
          >
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="top"
              overlay={
                this.props.rulesOrder[item.name] &&
                this.props.rulesOrder[item.name].size &&
                this.props.rulesOrder[item.name].size < 100
                  ? this.popoverTop(this.buildLabel(label, item))
                  : this.popoverTop("false")
              }
            >
              <div>
                <label
                  style={
                    this.state.highLightStepKeys.length &&
                    this.state.highLightStepKeys.includes(item.name)
                      ? this.state.labelStyle
                      : null
                  }
                  // className="labelStyle"
                  className={this.getLabelClass()}
                >
                  {this.buildLabel(label, item)}
                </label>
                <div className="helperText" style={{ display: "none" }}>
                  {item.properties.helperText}
                </div>
                {this.getHelper(item)}
                {this.getWarning(item)}
                <DatePicker
                  // readOnly={this.checkShareFieldsExists(item)}
                  disabled={this.checkShareFieldsExists(item)}
                  fixedHeight
                  dateFormat='d MMMM yyyy'
                  locale="nl"
                  className="form-control"
                  selected={
                    item.value  
                      ? parseISO(moment(item.value, 'DD MMMM YYYY').toISOString())
                      : null
                  }
                  onChange={(date) =>
                    this.handleDateInputChange(
                      date,
                      this.paths[item.type][item.name],
                      item
                    )
                  }
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                <div
                  style={{
                    right: "20px",
                    position: "absolute",
                    marginTop: "-40px",
                    fontSize: "19px",
                  }}
                >
                  {this.getCommentIcon(item)}
                </div>
              </div>
            </OverlayTrigger>
          </div>
        );
      } else if (
        item.properties.type === documents.ruleTypes.field.FILE_SELECTOR
      ) {
        // let attachDocumentOnServer = this.getAttachDocumentOnServer(item.name);
        let attachDocumentOnServer = this.sortableAttachDocumentOnServer(item.name);
        let getAttachDocumentOnLocal = this.getAttachDocumentOnLocal(item.name);
        let flag = false;
        // attachDocumentOnServer.forEach((element) => {
        //   if (element) {
        //     flag = true;
        //   }
        // });
        // getAttachDocumentOnLocal.forEach((element) => {
        //   if (element) {
        //     flag = true;
        //   }
        // });
        return (
          <div
            key={`field-${item.name}`}
            className={this.getFieldClass(item, "form-group")}
            type={0}
            order={this.getFieldOrder(item.name, lastKeyOrder)}
            style={this.getFieldStyle(item.name)}
          >
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="top"
              overlay={
                this.props.rulesOrder[item.name] &&
                this.props.rulesOrder[item.name].size &&
                this.props.rulesOrder[item.name].size < 100
                  ? this.popoverTop(this.buildLabel(label, item))
                  : this.popoverTop("false")
              }
            >
              <div>
                <div className="file-selector-container">
                  <i
                    className={
                      flag
                        ? "far fa-check-square file-selector right"
                        : "far fa-plus-square file-selector square"
                    }
                  />
                  <label
                    // className="labelStyle"
                    className={this.getLabelClass()}
                  >
                    {/* {this.buildLabel(label, item)} */}
                  </label>
                  <DropZone
                    className="dropzone"
                    onDrop={(accepted, rejected) => {
                      if (!this.checkShareFieldsExists(item))
                        this.props.handleFileSelect(
                          accepted,
                          rejected,
                          item.name
                        );
                    }}
                    multiple={true}
                    maxSize={maximumFileSizeToUpload}
                    accept={acceptedFileFormats}
                    disableClick={this.checkShareFieldsExists(item)}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <span>
                          {item.properties && item.properties.placeholder
                            ? item.properties.placeholder
                            : strings.get(
                                "Client.homePage.workflowForm.uploadMilestoneTitle"
                              )}
                        </span>
                      </div>
                    )}
                  </DropZone>
                  <OverlayTrigger
                    placement="top"
                    overlay={this.props.tooltip}
                    trigger="click"
                    rootClose
                  >
                    <i className="ion-help-circled supported-file-show" />
                  </OverlayTrigger>
                </div>
                <div className="helperText" style={{ display: "none" }}>
                  {item.properties.helperText}
                </div>
                {this.getHelper(item)}
                {this.getWarning(item)}
              </div>
            </OverlayTrigger>
            <div className="attachDocumentsList">
              {getAttachDocumentOnLocal}
              {attachDocumentOnServer}
            </div>
          </div>
        );
      }
    }
  };

  sortableAttachDocumentOnServer = (code) =>{
    const attachList = _.filter(this.props.attachDocumentOnServer,(item)=> item && item.url && item.codeId === code );
    
    return (
      <SortableActiveUrlLabel 
        items= {attachList} 
        checkShareFieldsExistsForUploadedFiles={this.props.checkShareFieldsExistsForUploadedFiles}
        handleCategoryRemoveServer={this.props.handleCategoryRemoveServer}
        orderChange={this.props.orderChange}
        fetchItems={this.props.fetchDraft}
        currentDraftId={this.props.currentDraftId}
      />)

  }

  getAttachDocumentOnLocal = (code) => {
    const attachList = _.filter(this.props.attachDocument,(item)=> item && item.file && item.codeId === code );
    return (
      <SortableActiveUrlLabel 
        type="local"
        items= {attachList} 
        checkShareFieldsExistsForUploadedFiles={this.props.checkShareFieldsExistsForUploadedFiles}
        handleCategoryRemoveServer={this.props.handleCategoryRemove}
        orderChange={this.props.orderChange}
        fetchItems={this.props.fetchDraft}
        currentDraftId={this.props.currentDraftId}
      />
  )};

  checkShareFieldsExists = (item) => {
    if (this.props.saved && this.props.logged && !this.props.isShared) {
      return false;
    }
    if (this.props.draftShareFields && this.props.saved) {
      let draftShareFields = this.props.draftShareFields
        ? JSON.parse(this.props.draftShareFields)
        : {};
      let is_readyOnly = !draftShareFields.hasOwnProperty(item.name);
      return is_readyOnly;
    }
    return false;
  };

  handleHelperClick = (item) => {
    let link = item.properties.helperLink;
    if (validURL.isUri(link)) {
      window.open(link, "_blank");
    }
  };

  handleAddressChange = (e, paths, item, selectorChanged = false) => {
    if (selectorChanged) {
      if (item.children && item.children.length) {
        item.children.forEach((element) => {
          if (
            element.type &&
            element.type === "option" &&
            element.name !== item.value
          ) {
            element = this.removeUnUsedValuesFromStorage(element);
          }
        });
      }
    }

    if (e) {
      this.handleFieldChange(e.selectingValue || e.value, paths, item);
    } else {
      this.handleFieldChange("", paths, item);
    }

    // if (selectorChanged) {
    //   let self = this;
    //   _.delay(function() {
    //     self.checkForReload(paths[_.first(_.keys(paths))].node);
    //   }, 1000);
    // }
  };

  handleInputChange = async (e, paths, item, selectorChanged = false) => {
    if (selectorChanged) {
      if (item.options && item.options.length){
        _.forEach(item.options, (option) => {
          if(option.type && 
          option.type === "option" &&
          option.name === item.value){
            option = this.removeUnUsedValuesFromStorage(option);
          }
        })
      }
      if (item.children && item.children.length) {
        item.children.forEach((element) => {
          if (
            element.type &&
            element.type === "option" &&
            element.name === item.value
          ) {
            element = this.removeUnUsedValuesFromStorage(element);
          }
        });
      }
    }
    this.handleFieldChange(e.target.value, paths, item);

    if (selectorChanged) {
      let rules = _.extend(this.props.rules, {});
      if (rules.asMutable) {
        rules = rules.asMutable();
      }
      //update values in rules by finding them in session storage
      rules = await this.updateRuleValuesFromSessionStorage(rules);
      this.paths = {};
      this.rendered = {};
      this.props.onChange(rules, this.paths, item, false);
    }
  };

  handleNumericChange = async (e, paths, item) => {
    let value = e.target.value;
    let reg = new RegExp("[a-zA-Z]+$");
    if (!reg.test(value)) {
      this.handleFieldChange(e.target.value, paths, item);
    } else {
      NotificationManager.warning(
        "",
        strings.get("Exceptions.notifications.noAlphabetAllowed")
      );
    }
  };

  removeUnUsedValuesFromStorage = (item) => {
    if (
      item.type &&
      (item.type === documents.ruleTypes.FIELD ||
        item.type === documents.ruleTypes.SELECTOR ||
        item.type === documents.ruleTypes.CLAUSE ||
        item.type === documents.ruleTypes.CAROUSEL)
    ) {
      if (sessionStorage.getItem(item.name)) {
        sessionStorage.removeItem(item.name);
      }
      if (item.value) {
        if (
          item.type === documents.ruleTypes.FIELD ||
          item.type === documents.ruleTypes.SELECTOR ||
          item.type === documents.ruleTypes.CAROUSEL
        ) {
          item.value = "";
        }
        if (item.type === documents.ruleTypes.CLAUSE) {
          item.value = false;
        }
      }
    }
    if (item.children && item.children.length) {
      item.children.forEach((element) => {
        this.removeUnUsedValuesFromStorage(element);
      });
    }
    return item;
  };

  updateRuleValuesFromSessionStorage = (rules) => {
    let clauses = [];
    let steps = this.props.steps;
    steps.forEach((element) => {
      if (element.codes.clauses && Object.keys(element.codes.clauses).length) {
        let clausesArray = Object.values(element.codes.clauses);
        clausesArray.forEach((element) => {
          clauses.push(`clause_${element.id}`);
        });
      }
    });

    _.each(this.paths, (elementType) => {
      _.each(elementType, (element) => {
        _.each(element, (path) => {
          if (
            sessionStorage.getItem(path.node.name) &&
            sessionStorage.getItem(path.node.name) !== ""
          ) {
            if (clauses.length && clauses.includes(path.node.name)) {
              if (sessionStorage.getItem(path.node.name) === "true") {
                rules = this.replaceValue(
                  rules,
                  sessionStorage.getItem(path.node.name),
                  path.value
                );
              }
            } else {
              rules = this.replaceValue(
                rules,
                sessionStorage.getItem(path.node.name),
                path.value
              );
            }
          }
        });
      });
    });
    return rules;
  };

  // checkForReload = (item) => {
  //   if (item.value) {
  //     let selectedObj = _.find(item.children, { name: item.value });
  //     let childrenExists = _.has(selectedObj, "children");
  //     if (childrenExists) {
  //       let selectorFields = _.filter(selectedObj.children, {
  //         type: "selector",
  //       });
  //     }
  //   }
  // };

  handleDateInputChange = (date, paths, item) => {
    let dateOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    if (date) {
      this.handleFieldChange(
        new Date(date).toLocaleDateString("nl-NL", dateOptions),
        paths,
        item
      );
    }
  };

  onAddToTemplate = (optionIndex, item, paths) => {
    if (item.children && item.children.length) {
      item.children.forEach((element) => {
        if (
          element.type &&
          element.type === "option" &&
          element.name === item.value
        ) {
          element = this.removeUnUsedValuesFromStorage(element);
        }
      });
    }
    this.handleFieldChange(optionIndex, paths, item);
    //TO-DO : options value will be modified in rules tree using path
    // let rules = this.props.rules && this.props.rules.map(item=>{
    //   if(item.type === 'carousel' && item.value.id === option.glossary_carousel_id){
    //     let itemTmp = {...item};
    //     let options = item.options.map(optionItem=>{
    //       if(optionItem.id === option.id) return {
    //         ...optionItem,
    //         isAddedToTemplate: true
    //       }
    //       return {
    //         ...optionItem,
    //         isAddedToTemplate: false
    //       }
    //     })

    //     return {
    //       ...itemTmp,
    //       options
    //     }
    //   }
    //   return item;
    // });

    // let name = `carousel_option_${option.id}`
    // this.props.onAddCarouselToTemplate(rules, name);
  };

  handleCheckboxChange = async (e, paths, item, radioGroup) => {
    if (radioGroup) {
      //For Access this in a forEach loop, Create one Variable like below.
      let self = this;
      let sibilings = self.props.rules;
      _.each(paths, (path) => {
        let arr = path.value;
        _.forEach(arr, (element, index, array) => {
          if (index !== array.length - 1) {
            sibilings = sibilings[element];
          }
        });
      });
      // if (_.size(sibilings) > 0) {
      //   _.forEach(sibilings, function(item) {
      //     if (item.type === "if") {
      //       if (typeof item.properties.groupName != "undefined") {
      //         let fieldName = item.properties.groupName;
      //         if (fieldName) {
      //           radioGroup = _.includes(fieldName, "radiogroup");
      //           if (radioGroup) {
      //             let virtualPath = self.paths[item.type][item.name];
      //             self.handleFieldChange(false, virtualPath, item, false);
      //           }
      //         }
      //       }
      //     }
      //   });

      let siblingClausesGroupName = [];
      if (
        item &&
        item.properties &&
        item.properties.groupName !== "undefined" &&
        item.properties.groupName
      ) {
        siblingClausesGroupName.push(item.properties.groupName);
      }
      if (_.size(sibilings) > 0) {
        _.forEach(sibilings, function (item) {
          if (item.type === "if") {
            if (typeof item.properties.groupName != "undefined") {
              let fieldName = item.properties.groupName;
              if (fieldName) {
                if (!siblingClausesGroupName.includes(fieldName)) {
                  siblingClausesGroupName.push(fieldName);
                } else {
                  radioGroup = _.includes(fieldName, "radiogroup");
                  if (radioGroup) {
                    let virtualPath = self.paths[item.type][item.name];
                    self.handleFieldChange(false, virtualPath, item, false);
                  }
                }
              }
            }
          }
        });
      }
    }
    this.handleFieldChange(e.target.checked, paths, item, e.target.checked);

    //update values in rules by finding them in session storage
    // let rules = _.extend(this.props.rules, {});
    // if (rules.asMutable) {
    //   rules = rules.asMutable();
    // }
    // rules = await this.updateRuleValuesFromSessionStorage(rules);
    // this.paths = {};
    // this.rendered = {};
    // this.props.onChange(rules, this.paths, item, false);
  };

  handleFieldChange = (value, paths, item, previewScrollY = false) => {
    if (!!item) {
      if (item.properties && item.properties[value]) {
        sessionStorage[item.name + "_translation"] = item.properties[value];
      }
      if (
        value === "" &&
        sessionStorage.getItem(item.name + "_translation") !== null
      ) {
        sessionStorage[item.name + "_translation"] = "";
      }
      sessionStorage[item.name] = value;
      //this.props.onProgressBarRender(item);
    }
    let rules = _.extend(this.props.rules, {});
    if (rules.asMutable) {
      rules = rules.asMutable();
    }
    _.each(paths, (path) => {
      rules = this.replaceValue(rules, value, path.value);
    });
    this.props.onChange(rules, this.paths, item, previewScrollY);
  };

  handleDownloadClick = async (e) => {
    e.preventDefault();
    this.setState({ pendingDownload: true });

    await this.props.onDownload();

    this.setState({ pendingDownload: false });
  };

  handlePrevStepClick = (e) => {
    e.preventDefault();
    this.props.onPrevStep();
    this.setState({ in: !this.state.in });
  };

  handleNextStepClick = (e) => {
    e.preventDefault();
    this.props.onNextStep();
    this.setState({ in: !this.state.in });
  };

  handleClearClick = (e) => {
    e.preventDefault();
    sessionStorage.clear();

    window.location.reload();
  };

  handleInputClear = (e, paths, item) => {
    e.preventDefault();
    if (!this.checkShareFieldsExists(item)) {
      this.handleFieldChange("", paths, item);
    }
  };

  handleSaveDraft = (e) => {
    // let user = JSON.parse(localStorage.getItem("user"));
    // let data = {
    //   user_id: user.id,
    //   document_id: this.props.document.id,
    // };
    this.showSaveAsModal();
    this.refs.modal2.hide();
  };

  handleSaveAsRole = (e) => {
    // let user = JSON.parse(localStorage.getItem("user"));
    // let data = {
    //   user_id: user.id,
    //   document_id: this.props.document.id,
    // };
    //TODO: should check this draft name
    this.setState({ draftName: "new draft for role" });
    this.setState({ saveAsRole: true });
    this.showSaveAsModal();
    this.refs.modal2.hide();
  };

  showSaveModal = () => {
    this.refs.saveDraftModal.show();
  };

  showSaveAsModal = () => {
    if (!this.state.draftName) {
      this.setState({ draftName: "new draft" });
    }
    this.refs.saveAsDraftModal.show();
  };

  hideSaveAsDraftModal = () => {
    this.refs.saveAsDraftModal.hide();
    this.refs.saveAsDraftWarning.hide();
  };

  hideSaveAsDraftWarning = () => {
    this.refs.saveAsDraftWarning.hide();
  };

  showWarningSaveModal = () => {
    this.refs.saveAsDraftWarning.show();
  };

  saveAsSubmit = (event) => {
    event.preventDefault();
    if (this.state.draftName === "") {
      // event.preventDefault();
      return false;
    }
    this.hideSaveAsDraftModal();
    if (this.state.saveAsRole) {
      this.props.onDraftCreateAsRole(this.state.draftName);
    } else {
      this.props.onDraftCreate({
        draftName: this.state.draftName,
        workflowName: this.state.workflowName,
      });
    }
  };

  onUpdateDraft = () => {
    this.props.onDraftUpdate(this.state.draftName);
    this.refs.saveAsDraftWarning.hide();
    // event.preventDefault();
  };

  changeDraftName = (e) => {
    this.setState({ draftName: e.target.value });
  };

  changeWorkflowName = (e) => {
    if (_.size(this.props.userCurrentWorkflow)) {
      NotificationManager.info('Re-name this from "My Workflow" area ');
    } else {
      this.setState({ workflowName: e.target.value });
    }
  };

  handleSaveOwnerDraft = () => {
    this.refs.saveOwnerWarning.show();
  };

  hideSaveOwnerWarning = () => {
    this.refs.saveOwnerWarning.hide();
  };

  toggleSaveOwnerWarning = () => {
    this.refs.saveOwnerWarning.show();
  };

  onUpdateOwnerDraft = () => {
    this.props.onDraftOwnerUpdate();
    this.refs.saveOwnerWarning.hide();
  };

  getFieldsCount = () => {
    if (this.props.rules && !!this.props.steps) {
      // build All paths
      let fieldsCount = [];
      this.props.steps.forEach((e) => {
        let tempSWF = [],
          stepWiseFields = this.buildForm(e.id, this.props.rules) || [];
        //count first, because only fixed fields are taken into account later in this method
        let totalFieldsInStep = _.size(stepWiseFields);
        let radioGroupKeys = [];
        if (this.props.rulesOrder && this.props.rulesOrder.length) {
          stepWiseFields = Object.values(
            stepWiseFields.reduce(
              (acc, cur) => Object.assign(acc, { [cur.props.order]: cur }),
              {}
            )
          );
        }
        stepWiseFields.map((sFDatas, sFIndex) => {
          if (sFDatas.key.search("clause-radio-field") !== -1) {
            let tSFRKey = sFDatas.key.split("-")[3];
            if (!radioGroupKeys.includes(tSFRKey)) {
              radioGroupKeys.push(tSFRKey);
            } else {
              totalFieldsInStep = totalFieldsInStep - 1;
            }
          }
          if (
            this.props.glossariesCodeIdToName &&
            sFDatas.key.search("field-") !== -1
          ) {
            //skip count if field type is a file selector
            let tSFRKey = sFDatas.key.split("-")[1];
            if (this.props.glossariesCodeIdToName[tSFRKey]) {
              let properties = this.props.expandGlossaries[
                this.props.glossariesCodeIdToName[tSFRKey]
              ];
              if (properties.type === "file_selector") {
                totalFieldsInStep = totalFieldsInStep - 1;
              }
            }
          }
          tempSWF.push(sFDatas.key);
        });
        tempSWF = _.uniq(tempSWF);

        fieldsCount.push({
          id: e.id,
          total: totalFieldsInStep,
          filled: 0,
          keys: tempSWF.map((t) => t.split(/[-]+/).pop()),
          orig: tempSWF,
        });
      });

      //match filled fields with session storage and update filled count
      // if (this.props.currentStep) {
      let clauses = [];
      let steps = this.props.steps;
      steps.forEach((element) => {
        if (
          element.codes.clauses &&
          Object.keys(element.codes.clauses).length
        ) {
          let clausesArray = Object.values(element.codes.clauses);
          clausesArray.forEach((element) => {
            clauses.push(`clause_${element.id}`);
          });
        }
      });
      let tempCount = [];
      fieldsCount.forEach((element) => {
        // if (element.id === this.props.currentStep.id) {
        element.filled = 0;
        element.keys.forEach((key) => {
          if (
            sessionStorage.getItem(key) &&
            sessionStorage.getItem(key) !== ""
          ) {
            if (clauses.length && clauses.includes(key)) {
              if (sessionStorage.getItem(key) === "true") {
                element.filled++;
              }
            } else {
              element.filled++;
            }
          }
        });
        let temp = JSON.stringify(fieldsCount);
        tempCount = JSON.parse(temp);
        // }
      });
      fieldsCount = tempCount;
      // }
      return fieldsCount;
    }
  };

  handlePreviewClick = () => {
    this.props.handlePreviewClick();
    this.refs.modal2.hide();
  };

  handlePreviewOffClick = () => {
    this.props.handlePreviewOffClick();
  };

  hidePopoverModal = () => {
    if (this.refs.overlay) {
      this.refs.overlay.hide();
    }
  };
  goBackToWorkflowUrl = (currentWorkflow) => {
    if (_.size(currentWorkflow)) {
      let locale = language.getIso2();
      return `${window.location.protocol}//${
        window.location.host
      }/${locale}/workflows/${makeSEFUrl(currentWorkflow.category.name)}-${
        currentWorkflow.category.id
      }/${makeSEFUrl(currentWorkflow.workflow.name)}-${
        currentWorkflow.workflow.id
      }/${currentWorkflow.id}`;
    } else {
      return "";
    }
  };

  workflowUrlTitle = (currentWorkflow) => {
    return `${strings.get("Client.draftsPage.goBackToWorkflow")} '${
      currentWorkflow.name
    }'`;
  };

  getPopover = () => {
    const popoverRight = (
      <Popover id="popover-positioned-right" title="" className="popuplist">
        {/* <div className="close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.hidePopoverModal}
          />
        </div> */}
        <div className="buttons-doc menu-opt">
          {this.props.logged ? (
            <button
              className="btn pull-right button-space save"
              onClick={this.handleSaveDraft}
            >
              <span className="btn-label">
                <img src={img_wht} alt="saveDraft" />
              </span>
              &nbsp;
              {strings.get("Client.draftsPage.saveMyDrafts")}
            </button>
          ) : (
            ""
          )}
          {this.props.logged && !this.props.isWorkflow && (
            <button
              className="btn pull-right button-space preview"
              onClick={this.handleSaveAsRole}
            >
              <span className="btn-label">
                <img src={img4_wht} alt="saveRole" />
              </span>
              {strings.get("Client.documentContent.saveAsRole")}
            </button>
          )}
          {this.props.logged & this.props.saved ? (
            <button
              className="btn pull-right button-space download"
              onClick={this.handleSaveOwnerDraft}
            >
              <span className="btn-label">
                <img src={img2_wht} alt="saveDraftToOwner" />
              </span>
              &nbsp;
              {strings.get("Client.draftsPage.saveOwnerDraft")}
            </button>
          ) : (
            ""
          )}

          <button
            className="btn pull-right button-space danger"
            onClick={this.handleClearClick}
          >
            <span className="btn-label">
              <img src={img3_wht} alt="erase" />
            </span>
            &nbsp;
            {strings.get("Client.documentContent.btnErase")}
          </button>
          <button
            className="btn pull-right button-space preview"
            onClick={this.handlePreviewClick}
          >
            <span className="btn-label">
              <img src={img4_wht} alt="preview" />
            </span>
            {strings.get("Client.documentContent.preview")}
          </button>
        </div>
      </Popover>
    );

    return (
      <div className={"doc-options-container"}>
        <ButtonToolbar className="option-menu-cont">
          <OverlayTrigger
            // ref="overlay"
            trigger="click"
            placement="bottom"
            overlay={popoverRight}
            rootClose
          >
            <button
              type="button"
              className="option-menu small-device opt btn btn-default"
            >
              {strings.get("Client.documentContent.documentOptions")}
            </button>
          </OverlayTrigger>
        </ButtonToolbar>
        <ButtonToolbar className="option-menu-cont">
          <OverlayTrigger
            // ref="overlay"
            trigger="click"
            placement="bottom"
            overlay={popoverRight}
            rootClose
          >
            <button className="option-menu wide-device opt btn btn-default">
              {strings.get("Client.documentContent.options")}
            </button>
          </OverlayTrigger>
        </ButtonToolbar>
        {this.props.saved &&
        (!this.props.logged || (this.props.logged && this.props.isShared)) ? (
          <ButtonToolbar className="option-menu-cont">
            <button
              className="option-menu wide-device opt btn btn-default doc-upload"
              onClick={this.handleSaveOwnerDraft}
            >
              <a title={strings.get("Client.draftsPage.saveOwnerDraft")}>
                <i className="fas fa-cloud-upload-alt" />
              </a>
            </button>
          </ButtonToolbar>
        ) : null}
        {this.props.logged &&
        this.props.isWorkflow &&
        _.size(this.props.userCurrentWorkflow) &&
        !this.props.isShared ? (
          <ButtonToolbar className="option-menu-cont">
            <button className="btn-success wide-device opt btn btn-success doc-upload">
              <a
                title={this.workflowUrlTitle(this.props.userCurrentWorkflow)}
                href={this.goBackToWorkflowUrl(this.props.userCurrentWorkflow)}
              >
                <i className="fas fa-sitemap" />
              </a>
            </button>
          </ButtonToolbar>
        ) : null}
      </div>
    );
  };

  getMenuRight = () => {
    let buttonHiding = {
      display: this.props.showPreview ? "none" : "block",
    };
    let buttonShowing = {
      display: this.props.showPreview ? "block" : "none",
    };
    return (
      <div className="col-md-4 buttons-doc">
        {this.props.logged ? (
          <button
            className="btn pull-right button-space save"
            onClick={this.handleSaveDraft}
          >
            <span className="btn-label">
              <img src={img_wht} alt="save" />
            </span>{" "}
            {strings.get("Client.draftsPage.saveMyDrafts")}
          </button>
        ) : (
          ""
        )}
        {this.props.logged && !this.props.isWorkflow ? (
          <button
            className="btn pull-right button-space preview"
            onClick={this.handleSaveAsRole}
          >
            <span className="btn-label">
              <img src={img4_wht} alt="role" />
            </span>{" "}
            {strings.get("Client.documentContent.saveAsRole")}
          </button>
        ) : (
          ""
        )}

        {this.props.logged & this.props.saved ? (
          <button
            className="btn pull-right button-space download"
            onClick={this.handleSaveOwnerDraft}
          >
            <span className="btn-label">
              <img src={img2_wht} alt="download" />
            </span>{" "}
            {strings.get("Client.draftsPage.saveOwnerDraft")}
          </button>
        ) : (
          ""
        )}

        <button
          className="btn pull-right button-space danger"
          onClick={this.handleClearClick}
        >
          <span className="btn-label">
            <img src={img3_wht} alt="erase" />
          </span>{" "}
          {strings.get("Client.documentContent.btnErase")}
        </button>

        <button
          className="btn pull-right button-space preview"
          style={buttonHiding}
          onClick={this.handlePreviewClick}
        >
          <span className="btn-label">
            <img src={img4_wht} alt="preview" />
          </span>{" "}
          {strings.get("Client.documentContent.preview")}
        </button>
        <button
          className="btn pull-right button-space preview"
          style={buttonShowing}
          onClick={this.handlePreviewOffClick}
        >
          <span className="btn-label">
            <img src={img4_wht} alt="preview" />
          </span>{" "}
          {strings.get("Client.documentContent.previewFrom")}
        </button>
      </div>
    );
  };

  showIncorrectCompanyAddressModal = () => {
    this.refs.incorrectCompanyAddressModal.show();
  };

  hideIncorrectCompanyAddressModal = () => {
    if (this.refs.incorrectCompanyAddressModal)
      this.refs.incorrectCompanyAddressModal.hide();
    this.setIncorrectCompanyAddressDetails();
  };

  setIncorrectCompanyAddressDetails = (
    id = null,
    label = null,
    address = ""
  ) => {
    let incorrectCompanyAddressDetails = this.state
      .incorrectCompanyAddressDetails;
    incorrectCompanyAddressDetails.id = id;
    incorrectCompanyAddressDetails.name = label;
    incorrectCompanyAddressDetails.address = address;
    this.setState({ incorrectCompanyAddressDetails });
  };

  handleReportCompanyAddressChange = (event) => {
    this.setIncorrectCompanyAddressDetails(
      this.state.incorrectCompanyAddressDetails.id,
      this.state.incorrectCompanyAddressDetails.name,
      event.target.value.trimStart()
    );
  };

  sendCorrectAddressOfCompany = async () => {
    if (
      this.state.incorrectCompanyAddressDetails.name &&
      this.state.incorrectCompanyAddressDetails.address
    ) {
      //send an email to admin
      let data = {};
      data.description = `A User suggested a new address "${this.state.incorrectCompanyAddressDetails.address}" of "${this.state.incorrectCompanyAddressDetails.name}" company. Company id is "${this.state.incorrectCompanyAddressDetails.id}" in the databse.`;
      data.email = "";
      data.url = "";
      data.subject = `User Suggested or Report a new address of company "${this.state.incorrectCompanyAddressDetails.name}"`;

      await this.props.notifyAdmin(data);
      this.hideIncorrectCompanyAddressModal();
    } else {
      NotificationManager.warning(
        strings.get("Exceptions.notifications.fieldRequired")
      );
    }
  };
  render() {
    let columnWidth = "";
    let menuRight = "";
    if (!this.props.showPreviewUp || !this.props.showPreview) {
      columnWidth = "col-md-8";
      menuRight = this.getMenuRight();
    }
    let helperTextModal = (
      <CustomDropModal className="boron-modal helperText" ref="helperTextModal">
        {this.helperTextModalContent}
      </CustomDropModal>
    );
    let warningModal = (
      <CustomDropModal className="boron-modal warningText" ref="warningModal">
        {this.warningTextModalContent}
      </CustomDropModal>
    );
    let clausePreviewModal = (
      <CustomDropModal
        className="boron-modal clausePreview"
        ref="clausePreviewModal"
      >
        {this.clausePreviewModalContent}
      </CustomDropModal>
    );
    let saveDraftModal = (
      <CustomDropModal className="boron-modal warningText" ref="saveDraftModal">
        {strings.get("Client.draftsPage.draftSaved")}
      </CustomDropModal>
    );

    let reportIncorrectCompanyAddressModal = (
      <CustomDropModal
        className="boron-modal"
        ref="incorrectCompanyAddressModal"
        onHide={this.hideIncorrectCompanyAddressModal}
      >
        <div className="close-btn">
          <span
            className="ion-android-cancel clickable"
            onClick={this.hideIncorrectCompanyAddressModal}
          />
        </div>
        <h4>
          {strings.get("Client.draftsPage.customAddressSelector.modal.title")}
        </h4>
        <div className="form-group report-company-selector">
          <div className="form-group">
            <label className="labelStyle">
              {strings.get(
                "Client.draftsPage.customAddressSelector.modal.companyNameLabel"
              )}
            </label>
            <input
              value={
                this.state.incorrectCompanyAddressDetails.name
                  ? this.state.incorrectCompanyAddressDetails.name
                  : ""
              }
              disabled
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label className="labelStyle">
              {strings.get(
                "Client.draftsPage.customAddressSelector.modal.companyAddressLabel"
              )}
            </label>
            <textarea
              className="form-control"
              onChange={this.handleReportCompanyAddressChange}
              placeholder={strings.get(
                "Client.draftsPage.customAddressSelector.modal.companyAddressInputPlaceholder"
              )}
              value={
                this.state.incorrectCompanyAddressDetails.address
                  ? this.state.incorrectCompanyAddressDetails.address
                  : ""
              }
            />
          </div>
        </div>
        <button className="btn btn2" onClick={this.sendCorrectAddressOfCompany}>
          {strings.get(
            "Client.draftsPage.customAddressSelector.modal.reportButtonLabel"
          )}
        </button>
        <button
          className="btn btn3"
          onClick={this.hideIncorrectCompanyAddressModal}
        >
          {strings.get("Client.draftsPage.cancel")}
        </button>
      </CustomDropModal>
    );
    let saveAsDraftModal = (
      <CustomDropModal
        className="boron-modal warningText"
        ref="saveAsDraftModal"
      >
        <div className="close-btn">
          <span
            className="ion-android-cancel clickable"
            onClick={this.hideSaveAsDraftModal}
          />
        </div>
        <form onSubmit={this.saveAsSubmit}>
          {this.props.isWorkflow && (
            <input
              className="form-control draftName"
              placeholder={strings.get(
                "Client.draftsPage.worflowNamePlaceholder"
              )}
              value={this.state.workflowName}
              onChange={this.changeWorkflowName}
            />
          )}
          <input
            className="form-control draftName"
            placeholder={strings.get("Client.draftsPage.draftNamePlaceholder")}
            value={this.state.draftName}
            onChange={this.changeDraftName}
          />
          <button className="btn btn2" type="submit">
            {/* <i className="ion-android-drafts"></i> */}
            {strings.get("Client.draftsPage.save")}
          </button>
          <button
            className="btn btn3"
            onClick={this.hideSaveAsDraftModal}
            type="button"
          >
            {/* <i className="ion-android-cancel"></i> */}
            {strings.get("Client.draftsPage.cancel")}
          </button>
        </form>
      </CustomDropModal>
    );

    let saveAsDraftWarning = (
      <CustomDropModal
        className="boron-modal warningText"
        ref="saveAsDraftWarning"
      >
        <h4>
          {strings.get("Client.draftsPage.saveWarningStart")}
          <strong>{this.props.document ? this.props.document.name : ""}</strong>
          {strings.get("Client.draftsPage.saveWarningEnd")}
        </h4>
        <button className="btn btn-success" onClick={this.onUpdateDraft}>
          {strings.get("Client.draftsPage.save")}
        </button>
        <button
          className="btn btn-default pull-right"
          onClick={this.hideSaveAsDraftWarning}
        >
          {strings.get("Client.draftsPage.cancel")}
        </button>
      </CustomDropModal>
    );

    let saveOwnerWarning = (
      <CustomDropModal
        className="boron-modal warningText"
        ref="saveOwnerWarning"
      >
        <div className="close-btn">
          <span
            className="ion-android-cancel clickable"
            onClick={this.hideSaveOwnerWarning}
          />
        </div>
        <h4>{strings.get("Client.draftsPage.saveOwnerDraft")}</h4>
        <button className="btn btn2" onClick={this.onUpdateOwnerDraft}>
          {strings.get("Client.draftsPage.save")}
        </button>
        <button className="btn btn3" onClick={this.hideSaveOwnerWarning}>
          {strings.get("Client.draftsPage.cancel")}
        </button>
      </CustomDropModal>
    );

    let modal2 = (
      <CustomDropModal ref="modal2" className="modal_full">
        <div className="close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.hideModal1}
          />
        </div>
        <div className="button_main">
          <div className="top_part">{this.getMenuRight()}</div>
        </div>
      </CustomDropModal>
    );
    return (
      <div
        className={
          isMobile()
            ? "DocumentFormFieldsContainer mobile-view"
            : "DocumentFormFieldsContainer"
        }
      >
        {reportIncorrectCompanyAddressModal}
        <div className={columnWidth}>
          <div className="DocumentFormFields" ref="wrapper">
            <div className="title">
              {this.getTitle()}
              {this.getPopover()}
            </div>
            <div className="form">
              {helperTextModal}
              <div>
                {this.getForm() === undefined ? dummyForm : false}
                <div
                  className="demo_test"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  {this.getForm()}
                </div>
                <div
                  className="form-group"
                  style={{ float: "left", width: "100%" }}
                >
                  <div className="input-group">{this.getButtons()}</div>
                </div>
              </div>

              {warningModal}
              {clausePreviewModal}
            </div>
            {saveDraftModal}
            {saveAsDraftModal}
            {saveAsDraftWarning}
            {saveOwnerWarning}
          </div>
        </div>
        <div
          className={
            this.props.showPreview &&
            ((this.props.isWorkflow &&
              _.size(this.props.userCurrentWorkflow)) ||
              (!this.props.logged && this.props.saved) ||
              (this.props.logged && this.props.saved && this.props.isShared))
              ? "popover-rightside for-upload-icon"
              : "popover-rightside"
          }
        >
          {this.getPopover()}

          <div className="btn_rw_all">
            <div className="btn_full">
              <button
                type="button"
                className="option-menu opt btn btn-default"
                onClick={this.showModal1}
              >
                {strings.get("Client.documentContent.documentOptions")}
              </button>
            </div>
            {modal2}
          </div>
        </div>
        <div className="right-menu">{menuRight}</div>
      </div>
    );
  }
}

DocumentFormFields.propTypes = {
  rules: PropTypes.array,
  currentStep: PropTypes.object,
  isFirstStep: PropTypes.bool,
  isLastStep: PropTypes.bool,
  hasErrors: PropTypes.object,
  onRender: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onPrevStep: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  buildLabel: PropTypes.func.isRequired,
};

export default DocumentFormFields;
