import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import CategorySingle from "./CategorySingle";

import "./CategoryList.scss";

class CategoryList extends Component {
  getItems = () => {
    if (this.props.items) {
      let count = 0;
      return _.map(this.props.items, (item) => {
        if (count > this.props.limit) {
          return;
        }
        if (!item.parent_id) {
          count++;
        }
        return !item.parent_id ? (
          <CategorySingle key={item.id} item={item} />
        ) : (
          false
        );
      });
    }
  };

  render() {
    return (
      <div className="inner-page-cont">
        <div className="container-row">
          <div className="DocCategoryList container new-cate-list">
            {this.getItems()}
          </div>
        </div>
      </div>
    );
  }
}

CategoryList.propTypes = {
  items: PropTypes.object,
};

export default CategoryList;
