import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Topbar from "../../components/Topbar";
import CategoryList from "../../components/workflowCategories/CategoryList";
import Footer from "../../components/footer/Footer";
import Breadcrumbs from "../../components/Breadcrumbs";

import * as workflowCategoriesSelectors from "../../store/workflowCategories/selectors";
import * as workflowCategoriesActions from "../../store/workflowCategories/actions";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import strings from "../../services/strings";
import language from "../../services/language";

class WorkflowCategoriesListPage extends Component {
  componentDidMount() {
    this.props.fetchAllCategories();
  }

  render() {
    let parentCategories = Object.keys(this.props.categories).reduce(
      (result, key) => {
        if (this.props.categories[key].parent_id) {
          return result;
        }
        return {
          ...result,
          [key]: this.props.categories[key],
        };
      },
      {}
    );
    let locale = language.getIso2();
    return (
      <div className="WorkflowCategoriesListPage">
        <Topbar
          title={strings.get("Client.homePage.moreCategories")}
          subtitle={""}
          searchBoxWorkflow={true}
          menuItem={"workflowCategories"}
        />
        <Breadcrumbs>
          <Link to={`/${locale}/`}>{strings.get("Client.homePage.home")}</Link>
          <Link to={`/${locale}/workflows`}>
            {strings.get("Client.homePage.workflow.title")}
          </Link>
        </Breadcrumbs>
        <CategoryList items={parentCategories && parentCategories} />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: workflowCategoriesSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllCategories: () => {
      dispatch(workflowCategoriesActions.fetchAllItems());
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkflowCategoriesListPage);
