import api from "../../services/api";
import language from "../../services/language";
import _ from "lodash";
import strings from "../../services/strings";
import * as settingsActions from "../settings/actions";
import * as exceptionsActions from "../exceptions/actions";
import * as workflowsSelectors from "./selectors";

import { NotificationManager } from "react-notifications";

import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory();

export const types = {
  FETCH_ALL_ITEMS_DONE: "workflows.FETCH_ALL_ITEMS_DONE",
  FETCH_ITEMS_DONE: "workflows.FETCH_ITEMS_DONE",
  FETCH_ITEM_DONE: "workflows.FETCH_ITEM_DONE",
  FETCH_STATS_DONE: "categories.FETCH_STATS_DONE",
  SET_SORTER: "workflows.SET_SORTER",
  SET_SEARCH_TERM: "workflows.SET_SEARCH_TERM",
  SET_CATEGORY_ID: "workflows.SET_CATEGORY_ID",
  FETCH_PLACEHOLDER_DONE: "workflows.FETCH_PLACEHOLDER_DONE",
  FETCH_DENY_MESSAGE: "workflows.FETCH_DENY_MESSAGE",
  SET_CURRENT_PAGE: "workflows.SET_CURRENT_PAGE",
  SET_CURRENT_ITEM_ID: "workflows.SET_CURRENT_ITEM_ID",
  TOGGLE_SORTER: "workflows.TOGGLE_SORTER",
  CLEAR_CACHE: "workflows.CLEAR_CACHE",
  SET_CURRENT_WORKFLOW_RULES: "workflows.SET_CURRENT_WORKFLOW_RULES",
  FETCH_CURRENT_USER_WORKFLOW_DONE:
    "workflows.FETCH_CURRENT_USER_WORKFLOW_DONE",
  FETCH_SAVED_WORKFLOW_DONE: "workflows.FETCH_SAVED_WORKFLOW_DONE",
};

export function getUserForkflows(deleteCache = false) {
  return async (dispatch, getState) => {
    try {
      let params = new Map();
      let pagination = workflowsSelectors.getPagination(getState());
      params.set("page_size", pagination.pageSize);
      params.set("page_number", pagination.currentPage);

      if (deleteCache) {
        let filters = workflowsSelectors.getFilters(getState());

        if (filters.categoryId) params.set("catId", filters.categoryId);
        if (filters.searchTerm) params.set("name", filters.searchTerm);
      }
      let [response, items] = await api.get("/userWorkflows", params, true);

      if (deleteCache) dispatch(clearCache());
      dispatch({
        type: types.FETCH_CURRENT_USER_WORKFLOW_DONE,
        payload: {
          items,
          totalPages: parseInt(response.headers.get("X-Total-Pages")),
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchUserWorkflow(id, readOnly = false) {
  return async (dispatch) => {
    try {
      let params = new Map();
      if (readOnly) {
        //if readOnly is true means a workflow without milestone attachments
        params.set("readOnly", true);
      }
      let item = await api.get(`/userWorkflow/${id}`, params);

      dispatch({
        type: types.FETCH_SAVED_WORKFLOW_DONE,
        payload: {
          item,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function deleteUserWorkflow(id) {
  return async (dispatch) => {
    try {
      // DELETE request to API
      let item = await api.delete("/userWorkflows/" + id);
      if (item.error) {
        NotificationManager.error(
          strings.get("Client.notifications.unauthorizedRequest")
        );
      } else {
        NotificationManager.success(
          strings.get("Client.notifications.deleted")
        );
      }
      dispatch(getUserForkflows());
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function addUserWorkflow(data) {
  return async (dispatch) => {
    try {
      dispatch(settingsActions.setScreenLoading(true));
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });

      let item = await api.put("/userWorkflow", params);

      if (typeof item === "undefined") {
        NotificationManager.warning(strings.get("Client.notifications.error"));
      } else {
        if (item.error) {
          if (item.error === "ITEM_EXIST") {
            NotificationManager.error(
              strings.get("Client.notifications.nameAlreadyExist")
            );
          }
        } else {
          // let refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + `/${item.id}`;
          // window.location.href = refresh;
          NotificationManager.success(
            strings.get("Client.notifications.requestAccepted")
          );
          return item;
        }
      }
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    } finally {
      dispatch(settingsActions.setScreenLoading(false));
    }
  };
}

export function downloadUserWorkflow(id, getZipURL) {
  //getZipURL is a callback function, required when url will be used for share a workflow
  return async (dispatch) => {
    try {
      // download request to API
      let payload = await api.get(`/userWorkflows/${id}/download`);
      if (typeof payload === "undefined") {
        NotificationManager.warning(strings.get("Client.notifications.error"));
      } else {
        if (typeof getZipURL === "function") {
          getZipURL(payload.url);
        } else {
          NotificationManager.success(
            strings.get("Client.notifications.requestAccepted")
          );
          let a = document.createElement("a");
          a.href = payload.url;
          a.target = payload.target;
          a.click();
        }
      }

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function shareUserWorkflowByEmail(data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        if (key !== "to") {
          params.set(key, value);
        } else {
          data.to.forEach((e, i) => {
            params.set(`to[${i}]`, e);
          });
        }
      });
      // PUT request to API
      try {
        await api.post(`/userWorkflows/shareByEmail`, params);
        NotificationManager.success(
          strings.get("Client.notifications.requestAccepted")
        );
      } catch (error) {
        NotificationManager.error(strings.get("Client.notifications.error"));
      }
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function updateUserWorkflowName(id, data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // PUT request to API
      let item = await api.put(`/userWorkflows/${id}/name`, params);
      if (item.error) {
        NotificationManager.error(
          strings.get("Client.notifications.nameAlreadyExist")
        );
      } else {
        NotificationManager.success(strings.get("Client.notifications.edited"));
      }
      dispatch(getUserForkflows());
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function setCurrentDocRulesValueChangeFlag(paths) {
  return {
    type: types.SET_CURRENT_WORKFLOW_RULES,
    payload: {
      paths,
    },
  };
}

export function setCategoryId(id) {
  return {
    type: types.SET_CATEGORY_ID,
    payload: {
      id,
    },
  };
}

export function sendEmails(data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        if (key !== "to") {
          params.set(key, value);
        } else {
          data.to.forEach((e, i) => {
            params.set(`to[${i}]`, e);
          });
        }
      });
      // PUT request to API
      await api.post(`/shareByEmail`, params);

      // dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function setCurrentPage(page) {
  return {
    type: types.SET_CURRENT_PAGE,
    payload: {
      page,
    },
  };
}

export function setCurrentItemId(id) {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id,
    },
  };
}

export function setShareDraftId(id) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("draft_id", id);
      await api.put(`/addsharedraft`, params);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function unsetCurrentItemId() {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id: null,
    },
  };
}

export function toggleSorter(column) {
  return {
    type: types.TOGGLE_SORTER,
    payload: {
      column,
    },
  };
}

export function setSorter(sorter) {
  return {
    type: types.SET_SORTER,
    payload: {
      sorter,
    },
  };
}

export function setSearchTerm(searchTerm) {
  return {
    type: types.SET_SEARCH_TERM,
    payload: {
      searchTerm,
    },
  };
}

export function clearCache() {
  return {
    type: types.CLEAR_CACHE,
  };
}

export function fetchAllItems() {
  return async (dispatch, getState) => {
    try {
      let params = new Map();
      params.set("language_id", language.get());
      params.set("expand", "downloads");
      params.set("sort_by", "name");

      let items = await api.get("/workflows", params);
      dispatch(clearCache());
      dispatch({
        type: types.FETCH_ALL_ITEMS_DONE,
        payload: {
          items,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchItemValues(id, type, callback = () => {}) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("id", id);
      params.set("type", type);

      // GET request from API
      api.get(`/workflows/alert`, params).then((item) => {
        callback(item);
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function getPlaceholder() {
  return async (dispatch, getState) => {
    try {
      let params = new Map();
      params.set("language_id", language.get());

      let item = await api.get("/settings/search_placeholder", params);

      if (item) {
        dispatch({
          type: types.FETCH_PLACEHOLDER_DONE,
          payload: {
            item,
          },
        });
      }
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function getDenyMessage() {
  return async (dispatch, getState) => {
    try {
      let item = await api.get("/settings/deny_message");
      //dispatch(clearCache());

      if (item) {
        dispatch({
          type: types.FETCH_DENY_MESSAGE,
          payload: {
            item,
          },
        });
      }
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchItems(deleteCache = false) {
  return async (dispatch, getState) => {
    let state = getState();
    try {
      // Set additional params
      let params = new Map();
      let filters = workflowsSelectors.getFilters(state);
      let sorter = workflowsSelectors.getSorter(state);
      params.set("language_id", language.get());
      // params.set('expand', 'downloads');
      params.set("name~", filters.searchTerm);
      params.set(
        "category_id",
        filters.categoryId
          ? filters.categoryId
          : state.workflowCategories.currentItemId || ""
      );
      params.set("sort_by", sorter.column);
      params.set("sort_desc", sorter.descending);

      // GET request from API
      let items = await api.get("/workflows", params);

      // Clear cache if deleteCache is enabled
      if (deleteCache) {
        dispatch(clearCache());
      }

      dispatch({
        type: types.FETCH_ITEMS_DONE,
        payload: { items },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchItem(id) {
  return async (dispatch) => {
    try {
      let params = new Map();
      // params.set('withRulesTree', 'true');
      // params.set('expand', 'downloads');
      params.set("published", "1");
      // GET request from API
      let item = await api.get(`/workflows/${id}`, params);
      dispatch({
        type: types.FETCH_ITEM_DONE,
        payload: {
          item,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function createItem(data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // POST request to API
      await api.post("/workflows", params);
      browserHistory.push(`/workflows`);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function updateItem(id, data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // PUT request to API
      await api.put(`/workflows/${id}`, params);
      browserHistory.push(`/workflows`);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function uploadItemLogo(id, file) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("file", file);
      // POST request to API
      await api.postFiles(`/workflows/${id}/image`, params);

      dispatch(fetchItem(id));
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function createItemWithLogo(data, file) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // POST request to API
      let item = await api.post("/workflows", params);
      browserHistory.push(`/workflows`);

      params = new Map();
      params.set("file", file);
      // POST request to API for Upload
      await api.postFiles(`/workflows/${item.id}/image`, params);

      dispatch(fetchItem(item.id));
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}
export function downloadItem(id, data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // POST request to API
      let payload = await api.post(`/workflows/${id}/download`, params);

      let a = document.createElement("a");
      a.href = payload.url;
      a.target = payload.target;
      a.click();

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function deleteItem(id) {
  return async (dispatch) => {
    try {
      // DELETE request to API
      await api.delete("/workflows/" + id);
      dispatch(fetchItems());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchStats() {
  return async (dispatch) => {
    try {
      let items = await api.get("/stats");

      dispatch({
        type: types.FETCH_STATS_DONE,
        payload: { items },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}
