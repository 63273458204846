import React, { Component } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Breadcrumbs from "../../../components/Breadcrumbs";
import CustomDropModal from "../../../components/PopUp";
import Topbar from "../../../components/Topbar";
import NavigationTabs from "../../../components/NavigationTabs";
import NavigationLeftTabs from "../../../components/NavigationLeftTabs";
import Footer from "../../../components/footer/Footer";
import AlertList from "../../../components/settings/alerts/AlertList";
import DocumentCategoryFilter from "../../../components/document/DocumentCategoryFilter";
// import SearchBar from "../../../components/sharedDrafts/SearchBar";
import SearchBar from "../../../components/drafts/SearchBar";
import Pagination from "../../../components/Pagination";

import * as categoriesActions from "../../../store/categories/actions";
import * as categoriesSelectors from "../../../store/categories/selectors";
import * as userAlertsActions from "../../../store/userAlerts/actions";
import * as userAlertsSelectors from "../../../store/userAlerts/selectors";
import * as exceptionsSelectors from "../../../store/exceptions/selectors";
import * as exceptionsActions from "../../../store/exceptions/actions";

import language from "../../../services/language";
import { browserHistory } from "../../../helpers";
import strings from "../../../services/strings";
import auth from "../../../services/auth";

class AlertsPage extends Component {
  componentDidMount() {
    if (!auth.isAuthenticated()) {
      browserHistory.push("/");
      window.location.reload();
    } else {
      this.props.fetchUserAlerts();
      this.props.fetchAllCategories();
    }
  }

  render() {
    let locale = language.getIso2();

    let saveModal = (
      <CustomDropModal
        className="boron-modal no-body"
        ref="saveModal"
        onShow={this.handleShowModal}
      >
        <span>
          <h2>{strings.get("App.settings.settingsSaved")}</h2>
        </span>
      </CustomDropModal>
    );
    return (
      <div className="AlertsPage Profilepage">
        {saveModal}

        <Topbar
          title={strings.get("Client.homePage.title")}
          subtitle={strings.get("Client.homePage.subTitle")}
          searchBox
          currentView="AlertsPage"
        />
        <div className="container">
          <Breadcrumbs>
            <Link to={`/${locale}/`}>
              {strings.get("Client.homePage.home")}
            </Link>
            <Link to={`/${locale}/profile`}>
              {strings.get("Client.homePage.profile")}
            </Link>
            <Link to={`/${locale}/messages/shareemail`}>
              {strings.get("Client.profilePage.messages")}
            </Link>
          </Breadcrumbs>

          <div className="container-row">
            <NavigationTabs
              currentItemId={5}
              currentLang={this.props.currentLang}
            />

            <div className="row">
              <NavigationLeftTabs
                currentItemId={2}
                currentLang={this.props.currentLang}
              />

              <div className="col-xs-12 col-sm-9 col-md-9 inner-page-cont AlertList">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4>
                      {strings.get("Client.settings.messages.alerts.title")}
                    </h4>
                  </div>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <DocumentCategoryFilter
                          filters={this.props.filters}
                          categories={this.props.categories}
                          fetchItems={this.props.fetchUserAlerts}
                          setCategoryId={this.props.setCategoryId}
                        />
                        <SearchBar
                          placeholder={strings.get(
                            "Client.downloadsPage.documentName"
                          )}
                          searchTerm={
                            this.props.filters
                              ? this.props.filters.searchTerm
                              : ""
                          }
                          fetchItems={this.props.fetchUserAlerts}
                          setSearchTerm={this.props.setSearchTerm}
                        />
                      </div>
                    </div>
                    <AlertList
                      items={this.props.userAlerts}
                      sorter={this.props.sorter}
                    />
                    <Pagination
                      pagination={this.props.pagination}
                      setCurrentPage={this.props.setCurrentPage}
                      fetchItems={this.props.fetchUserAlerts}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userAlerts: userAlertsSelectors.getItemsByPage(
      state,
      userAlertsSelectors.getPagination(state).currentPage
    ),
    sorter: userAlertsSelectors.getSorter(state),
    filters: userAlertsSelectors.getFilters(state),
    pagination: userAlertsSelectors.getPagination(state),
    categories: categoriesSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllCategories: () => {
      dispatch(categoriesActions.fetchAllItems());
    },
    setSorter: (sorter) => {
      dispatch(userAlertsActions.setSorter(sorter));
    },
    setCurrentPage: (page) => {
      dispatch(userAlertsActions.setCurrentPage(page));
    },
    setSearchTerm: (searchTerm) => {
      dispatch(userAlertsActions.setSearchTerm(searchTerm));
    },
    fetchUserAlerts: (deleteCache) => {
      dispatch(userAlertsActions.fetchItems(deleteCache));
    },
    setCategoryId: (id) => {
      dispatch(userAlertsActions.setCategoryId(id));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertsPage);
