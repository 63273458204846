import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import UserForm from "../../components/user/UserForm";
import NavigationTabs from "../../components/NavigationTabs";
import Topbar from "../../components/Topbar";
import Footer from "../../components/footer/Footer";
import CustomDropModal from "../../components/PopUp";
import Breadcrumbs from "../../components/Breadcrumbs";

import * as authActions from "../../store/auth/actions";
import * as authSelectors from "../../store/auth/selectors";
import * as userActions from "../../store/user/actions";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import { browserHistory } from "../../helpers";
import language from "../../services/language";
import auth from "../../services/auth";
import strings from "../../services/strings";

class UserProfilePage extends Component {
  componentDidMount() {
    if (!auth.isAuthenticated()) {
      browserHistory.push("/");
      window.location.reload();
    } else {
      this.setState({ user: auth.getLocalUser() ? auth.getLocalUser() : null });
    }
  }

  handleShowModal = () => {
    _.delay(() => {
      this.hideSaveModal();
    }, 500);
  };

  showSaveModal = () => {
    this.refs.saveModal.show();
  };

  hideSaveModal = () => {
    this.refs.saveModal.hide();
  };

  saveUser = async (data) => {
    await this.props.updateUser(data.form);
    this.props.getUser();

    if (data.croppedImgUrl) {
      await this.props.uploadUserImage(data.croppedImgUrl);
    }

    this.showSaveModal();
  };

  savePassword = (data) => {
    this.props.updatePassword(data);
  };

  render() {
    let locale = language.getIso2();

    let saveModal = (
      <CustomDropModal
        className="boron-modal no-body"
        ref="saveModal"
        onShow={this.handleShowModal}
      >
        <span>
          <h2>{strings.get("Client.settings.settingsSaved")}</h2>
        </span>
      </CustomDropModal>
    );
    return (
      <div className="UserProfilePage Profilepage">
        {saveModal}
        <Topbar
          title={strings.get("Client.profilePage.profile")}
          searchBox
          // handleLangChange={this.props.handleLangChange}
          // currentLang={this.props.currentLang}
          currentView="UserProfilePage"
          menuItem={"profile"}
        />

        <div className="breadcrumb-cont">
          <div className="container">
            <Breadcrumbs>
              <Link to={`/${locale}/`}>
                {strings.get("Client.homePage.home")}
              </Link>
              <Link to={`/${locale}/profile`}>
                {strings.get("Client.homePage.profile")}
              </Link>
              <Link to={`/${locale}/profile`}>
                {strings.get("Client.profilePage.profile")}
              </Link>
            </Breadcrumbs>
          </div>
        </div>
        <div className="inner-page-cont">
          <div className="container">
            <NavigationTabs
              currentView="userProfilePage"
              currentItemId={0}
              currentLang={this.props.currentLang}
            />
            <UserForm
              exceptions={this.props.exceptions}
              currentItem={this.props.profile}
              saveItem={this.saveUser}
              updatePassword={this.savePassword}
              currentLang={this.props.currentLang}
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: authSelectors.getProfile(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUser: () => {
      dispatch(authActions.getUser());
    },
    updateUser: (data) => {
      return dispatch(userActions.updateUser(data));
    },
    updatePassword: (data) => {
      return dispatch(userActions.updatePassword(data));
    },
    uploadUserImage: (file) => {
      return dispatch(userActions.uploadUserImage(file));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserProfilePage);
