import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import ReactCollapsingTable from "react-collapsing-table";
import PropTypes from "prop-types";

import { makeSEFUrl } from "../../helpers";
import language from "../../services/language";
import strings from "../../services/strings";

import "./DraftList.scss";

let componentInstance = null;

const TableDraftDraftname = ({ row, accessor }) => {
  return (
    <span>
      <a href={componentInstance.processLink(row)}>{row.name}</a>
    </span>
  );
};

const TableDraftUpdatedat = ({ row, accessor }) => {
  return (
    <span>{componentInstance.timeConverter(row.latestrevision_modified)}</span>
  );
};

const TableDraftDocname = ({ row, accessor }) => {
  return (
    <span className="template-name">
      <a href={componentInstance.processTemplateLink(row)} target="_blank">
        {row.document.name}
      </a>
    </span>
  );
};

const TableDraftCatname = ({ row, accessor }) => {
  return (
    <span>
      <a href={componentInstance.processCategoryLink(row)} target="_blank">
        {row.category.name}
      </a>
    </span>
  );
};

class DraftsList extends Component {
  constructor(props) {
    super(props);
    componentInstance = this;
    this.state = {
      // columnsLoaded: false,
      reboot: false,
      openShare: "0",
      draftId: "",
      fieldsLoaded: false,
      stepsLoaded: false,
      formShareFieldsLoaded: false,
      formShareFields: {},
    };
  }

  getTitle = () => {
    if (_.size(this.props.items)) {
      return <h2>{strings.get("Client.draftsPage.title")}</h2>;
    }
  };

  processLink = (item) => {
    let locale = language.getIso2();
    return `/${locale}/documenten/${makeSEFUrl(item.category.name)}-${
      item.document.category_id
    }/${makeSEFUrl(item.document.name)}-${item.document.id}/${
      item.latestrevision_id
    }`;
  };

  processTemplateLink = (item) => {
    let locale = language.getIso2();
    return `/${locale}/documenten/${makeSEFUrl(item.category.name)}-${
      item.document.category_id
    }/${makeSEFUrl(item.document.name)}-${item.document.id}`;
  };

  processCategoryLink = (item) => {
    let locale = language.getIso2();
    return `/${locale}/documenten/${makeSEFUrl(item.category.name)}-${
      item.document.category_id
    }`;
  };

  getRevisions = (draft) => {
    if (draft) {
      return _.map(draft.revisions, (revisionDraft) => {
        return (
          <option
            value={this.processRevisionLink(draft, revisionDraft.id)}
            key={revisionDraft.id}
          >
            {strings.get("Client.draftsPage.revision")} {revisionDraft.revision}
          </option>
        );
      });
    }
  };

  handleChange = (e) => {
    window.location = e.target.value;
  };

  timeConverter = (updated) => {
    return moment(updated.date).format("DD-MM-YYYY HH:mm:ss");
  };
  /*End*/

  // getItems() {
  //   if(this.props.sharedDrafts){
  //     return _.map(this.props.sharedDrafts, (item) => {
  //       return (
  //         <tr key={item.id}>
  //           <td>
  //               <a href={this.processLink(item)}>{item.name}</a>
  //           </td>
  //           <td>{this.timeConverter(item.latestrevision_modified)}</td>
  //           <td>{item.document.name}</td>
  //           <td>{item.category.name}</td>
  //         </tr>
  //       );
  //     });
  //   }
  // }

  getDocumentRows = () => {
    let rows = [];
    if (this.props.sharedDrafts) {
      _.forEach(this.props.sharedDrafts, function (item, key) {
        rows.push(item);
      });
    }

    // //After rows loaded, then set columnsLoaded to True.
    // if (!this.state.columnsLoaded && rows.length > 0) {
    //   _.delay(() => {
    //     this.setState({
    //       columnsLoaded: true,
    //     });
    //   }, 500);
    // }
    return rows;
  };

  getDocumentColumns = () => {
    let columns = [
      {
        accessor: "draftname",
        label: strings.get("Client.draftsPage.draftName"),
        priorityLevel: 1,
        position: 1,
        minWidth: 150,
        sortable: false,
        CustomComponent: TableDraftDraftname,
      },
      {
        accessor: "updatedat",
        label: strings.get("Client.draftsPage.lastModified"),
        priorityLevel: 2,
        position: 2,
        minWidth: 150,
        sortable: false,
        CustomComponent: TableDraftUpdatedat,
      },
      {
        accessor: "docname",
        label: strings.get("Client.draftsPage.docName"),
        priorityLevel: 3,
        position: 3,
        minWidth: 150,
        sortable: false,
        CustomComponent: TableDraftDocname,
      },
      {
        accessor: "catname",
        label: strings.get("Client.draftsPage.category"),
        priorityLevel: 4,
        position: 4,
        minWidth: 150,
        sortable: false,
        CustomComponent: TableDraftCatname,
      },
    ];
    return columns;
  };

  render() {
    let rows = this.getDocumentRows();
    let columns = this.getDocumentColumns();

    return (
      <div className="shareddraftslist ">
        <div className="row">
          <div className="col-sm-12">
            {/* {this.state.columnsLoaded ? ( */}
            {_.size(rows) ? (
              <ReactCollapsingTable
                theme="react-collapsible-custom-theme"
                rows={rows}
                columns={columns}
              />
            ) : (
              <div className="col-sm-12 defaultMsg">
                <div>{strings.get("Client.documentsList.noFiles")}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

DraftsList.propTypes = {
  items: PropTypes.array,
  onMouseOver: PropTypes.func,
};

export default DraftsList;
