import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import * as authSelectors from "../../store/auth/selectors";
import * as workflowActions from "../../store/workflows/actions";
import * as workflowSelectors from "../../store/workflows/selectors";
import * as workflowCategoriesActions from "../../store/workflowCategories/actions";
import * as workflowCategoriesSelectors from "../../store/workflowCategories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Breadcrumbs from "../../components/Breadcrumbs";
import WorkflowsList from "../../components/userWorkflow/WorkflowsList";
import NavigationTabs from "../../components/NavigationTabs";
import Topbar from "../../components/Topbar";
import Footer from "../../components/footer/Footer";
import DraftCategoryFilter from "../../components/drafts/DraftCategoryFilter";
import SearchBar from "../../components/drafts/SearchBar";
import Pagination from "../../components/Pagination";

import language from "../../services/language";
import strings from "../../services/strings";
import auth from "../../services/auth";
import { browserHistory } from "../../helpers";

class MyWorkflowsPage extends Component {
  componentDidMount() {
    if (!auth.isAuthenticated()) {
      browserHistory.push("/");
      window.location.reload();
    } else {
      this.props.fetchUserWorkflows();
      this.props.fetchAllCategories();
    }
  }

  render() {
    let locale = language.getIso2();
    return (
      <div className="UserProfilePage Profilepage">
        <Topbar
          title={strings.get("Client.homePage.myWorkflow.title")}
          searchBox={true}
          // handleLangChange={this.props.handleLangChange}
          // currentLang={this.props.currentLang}
          currentView="draftsPage"
        />

        <div className="breadcrumb-cont">
          <div className="container">
            <Breadcrumbs>
              <Link to={`/${locale}/`}>
                {strings.get("Client.homePage.home")}
              </Link>
              <Link to={`/${locale}/profile`}>
                {strings.get("Client.homePage.profile")}
              </Link>
              <Link to={`/${locale}/myworkflows`}>
                {strings.get("Client.homePage.myWorkflow.title")}
              </Link>
            </Breadcrumbs>
          </div>
        </div>
        <div className="inner-page-cont Draftpage">
          <div className="container">
            <NavigationTabs
              currentItemId={3}
              currentLang={this.props.currentLang}
            />
            <div className="row">
              <div className="cate-filter1">
                <DraftCategoryFilter
                  filters={this.props.filters}
                  categories={this.props.allCategories}
                  fetchItems={this.props.fetchUserWorkflows}
                  setCategoryId={this.props.setCategoryId}
                />
                <SearchBar
                  placeholder={strings.get("Client.workflows.nameSearch")}
                  searchTerm={this.props.filters.searchTerm}
                  fetchItems={this.props.fetchUserWorkflows}
                  setSearchTerm={this.props.setSearchTerm}
                />
              </div>
            </div>
            <WorkflowsList
              {...this.props}
              deleteUserWorkflow={this.props.deleteUserWorkflow}
              updateUserWorkflowName={this.props.updateUserWorkflowName}
              downloadUserWorkflow={this.props.downloadUserWorkflow}
              shareUserWorkflow={this.props.shareUserWorkflow}
            />
            <div className="col-sm-12 text-center" style={{ display: "flex", justifyContent: "center" }}>
              <Pagination
                pagination={this.props.pagination}
                setCurrentPage={this.props.setCurrentPage}
                fetchItems={this.props.fetchUserWorkflows}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: authSelectors.getProfile(state),
    filters: workflowSelectors.getFilters(state),
    pagination: workflowSelectors.getPagination(state),
    currentUserWorkflows: workflowSelectors.getUserWorkflows(state),
    allCategories: workflowCategoriesSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUserWorkflows: (deleteCache) => {
      return dispatch(workflowActions.getUserForkflows(deleteCache));
    },
    deleteUserWorkflow: (id) => {
      return dispatch(workflowActions.deleteUserWorkflow(id));
    },
    downloadUserWorkflow: (id, getZipURL) => {
      return dispatch(workflowActions.downloadUserWorkflow(id, getZipURL));
    },
    shareUserWorkflow: (data) => {
      return dispatch(workflowActions.shareUserWorkflowByEmail(data));
    },
    updateUserWorkflowName: (id, data) => {
      return dispatch(workflowActions.updateUserWorkflowName(id, data));
    },
    fetchAllCategories: (filtered = false) => {
      dispatch(workflowCategoriesActions.fetchAllItems(filtered));
    },
    setCategoryId: (id) => {
      dispatch(workflowActions.setCategoryId(id));
    },
    setSearchTerm: (searchTerm) => {
      dispatch(workflowActions.setSearchTerm(searchTerm));
    },
    setCurrentPage: (page) => {
      dispatch(workflowActions.setCurrentPage(page));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MyWorkflowsPage);
