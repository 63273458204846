import React, { Component } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { nl } from "date-fns/locale";
import { parseISO } from "date-fns";
import _ from "lodash";
import PropTypes from "prop-types";
import ReactCollapsingTable from "react-collapsing-table";
import moment from "moment";

import CustomDropModal from "../PopUp";

import filetype1 from "../../static/images/type1-icon.png";
import alert2 from "../../static/images/alert2.png";

import { makeSEFUrl, browserHistory } from "../../helpers";
import language from "../../services/language";
import auth from "../../services/auth";
import strings from "../../services/strings";

import "./UserDocumentList.scss";

let componentInstance = null;

registerLocale("nl", nl);

const TableDownloadFile = ({ row, accessor }) => {
  return (
    <span>
      <div className="edit-icon">
        <a
          className="edit"
          onClick={() => componentInstance.handleEditFieldsClick(row)}
        >
          <i className="fas fa-pencil-alt"></i>
        </a>
      </div>
      <a href={row.downloadURL} target="_blank">
        {row.file}
      </a>
      <br />
      <small>{row.downloadDate}</small>
    </span>
  );
};

const TableDownloadType = ({ row, accessor }) => {
  return <img src={filetype1} width="23" alt="file" />;
};

const TableDownloadUrl = ({ row, accessor }) => {
  return (
    <span>
      <a
        href={componentInstance.processLink(row)}
        target="_blank"
        className="doc-name"
      >
        {row.document.name}
      </a>
      <br />
      <small>
        <a href={componentInstance.processCategoryLink(row)} target="_blank">
          {row.category_name}
        </a>
      </small>
    </span>
  );
};

const TableDownloadAlert = ({ row, accessor }) => {
  return (
    <span>
      <a
        className="set-reminder"
        title="Set/Edit"
        onClick={() => componentInstance.handleSetReminderClick(row.id)}
      >
        <img src={alert2} width="25" />
      </a>
    </span>
  );
};

class UserDocumentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      columnsLoaded: false,
      downloadId: "",
      editDownloadName: "",
      all_related_documents: [],
      reminderFormFieldsLoaded: false,
      reminderForm: {
        datetime: "",
        email: "",
        message: "",
        related_documents: [],
      },
    };
    componentInstance = this;
  }

  componentDidMount() {
    if (!auth.isAuthenticated()) {
      browserHistory.push("/");
      window.location.reload();
    } else {
      this.setState({
        user: auth.getLocalUser() ? auth.getLocalUser() : null,
      });
    }
  }

  showDeleteModal = () => {
    this.refs.deleteModal.show();
  };

  hideDeleteModal = () => {
    this.refs.deleteModal.hide();
  };

  handleDeleteClick = (id) => {
    this.props.setCurrentItemId(id);
    this.showDeleteModal();
  };
  handleConfirmDeleteClick = () => {
    this.props.deleteItem(this.props.currentItem.id);
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  };
  handleCancelDeleteClick = () => {
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  };

  handleEditFieldsClick = (download) => {
    this.setState({
      editDownloadName: download.file,
      downloadId: download.id,
    });
    _.delay(() => {
      this.showEditFieldsModal();
    }, 500);
  };

  showEditFieldsModal = () => {
    let modal = this.refs.editFieldsModal;
    modal.show();
  };

  editableFields = () => {
    return (
      <div className="editable-fields-box">
        <input
          type="text"
          className="form-control"
          name="editable_download_name"
          value={this.state.editDownloadName}
          onChange={this.handleDownloadNameInputChange}
        />
      </div>
    );
  };

  handleDownloadNameInputChange = (e) => {
    this.setState({
      editDownloadName: e.target.value,
    });
  };

  editFieldsModalConfirm = () => {
    let newFields = {};
    if (this.state.editDownloadName) {
      newFields["name"] = this.state.editDownloadName;
    }
    this.props.updateItemDownloadName(this.state.downloadId, newFields);
    this.refs.editFieldsModal.hide();
  };

  editFieldsModalCancel = () => {
    this.refs.editFieldsModal.hide();
  };

  getDocumentRows = () => {
    let rows = [];
    if (this.props.items) {
      _.forEach(this.props.items, function (item, key) {
        rows.push(item);
      });
    }
    //After rows loaded, then set columnsLoaded to True.
    if (!this.state.columnsLoaded && rows.length > 0) {
      _.delay(() => {
        this.setState({
          columnsLoaded: true,
        });
      }, 500);
    }
    return rows;
  };

  getDocumentColumns = () => {
    let columns = [
      {
        accessor: "file",
        label: strings.get("Client.downloadsPage.reference"),
        priorityLevel: 1,
        position: 1,
        minWidth: 150,
        sortable: false,
        CustomComponent: TableDownloadFile,
      },
      {
        accessor: "type",
        label: strings.get("Client.downloadsPage.type"),
        priorityLevel: 2,
        position: 2,
        minWidth: 150,
        sortable: false,
        CustomComponent: TableDownloadType,
      },
      {
        accessor: "downloadURL",
        label: strings.get("Client.downloadsPage.documentName"),
        priorityLevel: 3,
        position: 3,
        minWidth: 150,
        sortable: false,
        CustomComponent: TableDownloadUrl,
      },
      {
        accessor: "alert",
        label: strings.get("Client.downloadsPage.allert"),
        priorityLevel: 4,
        position: 4,
        minWidth: 150,
        sortable: false,
        CustomComponent: TableDownloadAlert,
      },
    ];
    return columns;
  };

  componentDidUpdate(prevProps, prevState) {
    if (_.size(this.props.currentItem) > 0) {
      let reminder = this.props.currentItem.reminder;
      if (reminder && !this.state.reminderFormFieldsLoaded) {
        let reminderForm = _.extend({}, this.state.reminderForm);
        _.map(this.state.reminderForm, (value, key) => {
          if (key === "related_documents") {
            let { all_related_documents } = this.state;
            let allDocIds = [];
            let strArray = reminder[key].split(",");
            for (var i = 0; i < strArray.length; i++) {
              strArray[i] = parseInt(strArray[i]);
            }
            Object.keys(all_related_documents).map((value, index) => {
              allDocIds.push(all_related_documents[index].id);
            });
            strArray.forEach((element) => {
              if (allDocIds.includes(element)) {
                reminderForm[key].push(element);
              }
            });
            // reminderForm[key] = (reminder[key]) ? strArray : this.state.reminderForm[key];
          } else {
            reminderForm[key] = reminder[key]
              ? reminder[key]
              : this.state.reminderForm[key];
          }
        });
        this.setState({
          reminderFormFieldsLoaded: true,
          reminderForm,
        });
      }
    }
  }

  handleSetReminderClick = (id) => {
    this.props.unsetCurrentItemId();
    this.handleReminderFormModalHide();
    _.delay(() => {
      this.props.setCurrentItemId(id);
      if (this.state.reminderForm.email === "") {
        let item = {};
        item["email"] = this.state.user.email;
        this.setState({
          reminderForm: _.extend(this.state.reminderForm, item),
          all_related_documents: this.props.currentItem.related_documents,
        });
      }
      this.setState(
        {
          downloadId: id,
          all_related_documents: this.props.currentItem.related_document,
        },
        () => {
          this.addRelatedDocToReminder();
        }
      );
      this.showReminderFormModal();
    }, 500);
  };

  addRelatedDocToReminder = () => {
    let { all_related_documents, reminderForm } = this.state;
    Object.keys(all_related_documents).map((index) => {
      reminderForm.related_documents.push(all_related_documents[index].id);
    });
    this.setState({ reminderForm });
  };

  showReminderFormModal = () => {
    let modal = this.refs.reminderFormModal;
    if (
      !(
        this.props.exceptions.template &&
        this.props.exceptions.template.notDefined
      )
    )
      modal.show();
  };

  handleInputChange = (e) => {
    let item = {};
    item[e.target.name] = e.target.value;
    this.setState({
      reminderForm: _.extend(this.state.reminderForm, item),
    });
  };

  handleDateInputChange = (date, name) => {
    let dateOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let item = {};
    item[name] = new Date(date).toLocaleDateString("nl-NL", dateOptions);
    this.setState({
      reminderForm: _.extend(this.state.reminderForm, item),
    });
  };

  handleReminderFormCancelClick = () => {
    this.refs.reminderFormModal.hide();
  };

  handleReminderFormSaveClick = () => {
    let updateItem = this.props.updateItemReminder(
      this.state.downloadId,
      this.state.reminderForm
    );
    Promise.all([updateItem]).then(() => {
      _.delay(() => {
        if (_.size(this.props.exceptions) === 0) {
          this.handleReminderFormCancelClick();
        }
      }, 4000);
    });
  };

  handleReminderFormModalHide = () => {
    this.props.clearReminderException();
    this.setState({
      downloadId: "",
      reminderFormFieldsLoaded: false,
      reminderForm: {
        datetime: "",
        email: "",
        message: "",
        related_documents: [],
      },
    });
  };

  hasError = (inputName) => {
    return !!this.props.exceptions[inputName];
  };

  getErrorClass = (inputName, defaultClasses = "") => {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  };

  getErrorMessage = (inputName) => {
    return this.props.exceptions[inputName];
  };

  processLink = (item) => {
    // let locale = JSON.parse(localStorage.getItem('language')).iso2 || 'NL';
    let locale = language.getIso2();
    return `${window.location.protocol}//${
      window.location.host
    }/${locale}/documenten/${makeSEFUrl(item.category_name)}-${
      item.document.category_id
    }/${makeSEFUrl(item.document.name)}-${item.document.id}`;
  };

  processCategoryLink = (item) => {
    // let locale = JSON.parse(localStorage.getItem('language')).iso2 || 'NL';
    let locale = language.getIso2();
    return `${window.location.protocol}//${
      window.location.host
    }/${locale}/documenten/${makeSEFUrl(item.category_name)}-${
      item.document.category_id
    }`;
  };

  // getDocuments(){
  //     if (this.props.items) {
  //         return _.map(this.props.items, (item) => {
  //            return (
  //                <tr key={ item.id }>
  //                    <td>
  //                      { item.file }<br/>
  //                      <small>{ item.downloadDate }</small>
  //                    </td>
  //                    <td><i className="ion-document-text document-icon"></i></td>
  //                    <td>
  //                      <div className="details">
  //                        <div className="name">
  //                          <a href={ item.downloadURL } target="_blank">
  //                               { item.document.name }
  //                          </a><br/>
  //                          <small>{item.category_name }</small>
  //                        </div>
  //                      </div>
  //                    </td>
  //                    <td>:-</td>
  //
  //                </tr>
  //            );
  //         });
  //     }
  // }

  // handleDocChange = (event) =>{
  //   let id = event.target.name;
  //   let {all_related_documents, reminderForm} = this.state;

  //   if(reminderForm.related_documents.includes(parseInt(id))){
  //     reminderForm.related_documents.splice(reminderForm.related_documents.indexOf(parseInt(id)),1);
  //   }
  //   else {
  //     Object.keys(all_related_documents).map((index)=>{
  //       if(all_related_documents[index].id === id)
  //       {
  //         reminderForm.related_documents.push(all_related_documents[index].id);
  //       }
  //     });
  //   }
  //   this.setState({ reminderForm });
  // }

  render() {
    let datetimeError = this.hasError("datetime")
      ? `${strings.get(
          "Client.downloadsPage.reminderForm.reminder_date"
        )} ${this.getErrorMessage("datetime")}, Format should be "Y-m-d"`
      : "";
    let emailError = this.hasError("email")
      ? `${strings.get(
          "Client.downloadsPage.reminderForm.email"
        )} ${this.getErrorMessage("email")}`
      : "";
    let messageError = this.hasError("message")
      ? `${strings.get(
          "Client.downloadsPage.reminderForm.message"
        )} ${this.getErrorMessage("message")}`
      : "";

    let editFieldsModalContent = (
      <div className="jd-modal">
        <label className="pull-left">
          {strings.get("Client.editModal.title")}
        </label>
        <br />
        {this.editableFields()}
        <button className="btn btn2" onClick={this.editFieldsModalConfirm}>
          {strings.get("App.documents.documentForm.orderFieldsModal.save")}
        </button>
        <button className="btn btn3" onClick={this.editFieldsModalCancel}>
          {strings.get("App.documents.documentForm.orderFieldsModal.cancel")}
        </button>
      </div>
    );

    let deleteModalContent = this.props.currentItem ? (
      <span>
        <h2>
          {strings.get("App.deleteModal.message", {
            itemName: this.props.currentItem.name,
          })}
        </h2>
        <div className="form-actions">
          <button
            className="btn btn-lg btn-danger"
            onClick={this.handleConfirmDeleteClick}
          >
            {strings.get("App.deleteModal.delete")}
          </button>
          <button
            className="btn btn-lg btn-default"
            onClick={this.handleCancelDeleteClick}
          >
            {strings.get("App.deleteModal.cancel")}
          </button>
        </div>
      </span>
    ) : null;

    // let options = this.props.currentItem ? (
    //   _.map(this.props.currentItem.related_document, doc => {
    //     // console.log(this.state.reminderForm.related_documents, doc.id)
    //   return (<div key={doc.id}><input type="checkbox" name={doc.id} onChange={this.handleDocChange} checked={this.state.reminderForm.related_documents.includes(doc.id)} /> {doc.name}</div> )
    //   })) : null;
    let options = this.props.currentItem
      ? _.map(this.props.currentItem.related_document, (doc) => {
          return <div key={doc.id}> {doc.name} </div>;
        })
      : null;

    let reminderFormModalContent = this.props.currentItem ? (
      <div className="reminder-form">
        <div className="form-group text-left">
          <label className="control-label">
            {strings.get("Client.downloadsPage.reminderForm.reminder_date")}
          </label>
          <DatePicker
            fixedHeight
            locale="nl"
            placeholderText={strings.get(
              "Client.downloadsPage.reminderForm.date_placeholder"
            )}
            dateFormat="d MMMM yyyy"
            minDate={parseISO(moment().toISOString())}
            className={this.getErrorClass("datetime", "form-control")}
            selected={
              this.state.reminderForm.datetime
                ? parseISO(
                    moment(this.state.reminderForm.datetime).toISOString()
                  )
                : null
            }
            onChange={(date) => this.handleDateInputChange(date, "datetime")}
          />
          <small className="text-danger">{datetimeError}</small>
        </div>
        <div className="form-group text-left">
          <label className="control-label">
            {strings.get("Client.downloadsPage.reminderForm.email")}
          </label>
          <input
            className={this.getErrorClass("email", "form-control")}
            type="text"
            name="email"
            value={this.state.reminderForm.email}
            onChange={this.handleInputChange}
          />
          <small className="text-danger">{emailError}</small>
        </div>
        <div className="form-group text-left">
          <label className="control-label">
            {strings.get("Client.downloadsPage.reminderForm.message")}
          </label>
          <textarea
            className={this.getErrorClass("message", "form-control")}
            name="message"
            placeholder={strings.get(
              "Client.downloadsPage.reminderForm.message_placeholder"
            )}
            value={this.state.reminderForm.message}
            onChange={this.handleInputChange}
          ></textarea>
          <small className="text-danger">{messageError}</small>
        </div>
        <div className="form-group text-left">
          <label className="control-label">
            {strings.get("Client.downloadsPage.reminderForm.related_documents")}
          </label>
          {options}
          {this.state.all_related_documents &&
          !this.state.all_related_documents.length > 0 ? (
            <div>{strings.get("Client.documentsList.noFiles")}</div>
          ) : null}
        </div>
        <div className="form-actions download-cancel">
          <button
            className="btn btn2 mr-20"
            onClick={this.handleReminderFormSaveClick}
          >
            <i className="ion-clock"></i>
            {strings.get("Client.documentContent.downloadModal.btnSave")}
          </button>
          <button
            className="btn btn4"
            onClick={this.handleReminderFormCancelClick}
          >
            <i className="ion-android-cancel"></i>
            {strings.get("Client.documentContent.downloadModal.btnCencel")}
          </button>
        </div>
      </div>
    ) : null;

    let rows = this.getDocumentRows();
    let columns = this.getDocumentColumns();

    return (
      <div className="UserDocumentList">
        <div className="row">
          <div className="col-sm-12">
            <span className="DocumentList">
              <CustomDropModal className="boron-modal" ref="deleteModal">
                {/* <Modal className="boron-modal" ref="deleteModal"> */}
                {deleteModalContent}
                {/* </Modal> */}
              </CustomDropModal>
              <CustomDropModal className="boron-modal" ref="editFieldsModal">
                {/* <Modal className="boron-modal" ref="editFieldsModal"> */}
                <div className="close-btn">
                  <span
                    className="ion-android-cancel clickable"
                    onClick={this.editFieldsModalCancel}
                  ></span>
                </div>
                {editFieldsModalContent}
                {/* </Modal> */}
              </CustomDropModal>
              <CustomDropModal
                className="boron-modal"
                ref="reminderFormModal"
                onHide={this.handleReminderFormModalHide}
              >
                {/* <Modal
                className="boron-modal"
                ref="reminderFormModal"
                onHide={this.handleReminderFormModalHide}
              > */}
                <div className="close-btn">
                  <i
                    className="ion-android-cancel clickable"
                    onClick={this.handleReminderFormCancelClick}
                  ></i>
                </div>
                {reminderFormModalContent}
                {/* </Modal> */}
              </CustomDropModal>

              {this.state.columnsLoaded ? (
                <ReactCollapsingTable
                  theme="react-collapsible-custom-theme"
                  rows={rows}
                  columns={columns}
                />
              ) : (
                ""
              )}

              {/*<table className="table">
                          <thead>
                              <tr>
                                  <th>{strings.get('Client.downloadsPage.reference')}</th>
                                  <th>{strings.get('Client.downloadsPage.type')}</th>
                                  <th>{strings.get('Client.downloadsPage.documentName')}</th>
                                  <th>{strings.get('Client.downloadsPage.allert')}</th>
                              </tr>
                          </thead>
                          <tbody>
                              { this.getDocuments() }
                          </tbody>
                      </table>*/}
            </span>
          </div>
          {this.state.columnsLoaded && rows.length === 0 ? (
            <div className="col-sm-12 defaultMsg">
              <div>{strings.get("Client.documentsList.noFiles")}</div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
UserDocumentList.propTypes = {
  items: PropTypes.array.isRequired,
  sorter: PropTypes.object.isRequired,
  fetchItems: PropTypes.func.isRequired,
  setCurrentItemId: PropTypes.func.isRequired,
  unsetCurrentItemId: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  toggleSorter: PropTypes.func.isRequired,
};

export default UserDocumentList;
