import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import * as categoriesSelectors from "../../store/categories/selectors";
import * as authSelectors from "../../store/auth/selectors";
import * as categoriesActions from "../../store/categories/actions";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import strings from "../../services/strings";

import img from "../../static/images/site_maintenance.png";

// import "../Page.scss";

class MaintenancePage extends Component {
  componentDidMount() {
    this.props.fetchContactSettings();
  }

  render() {
    const { siteSettings, maintenanceSettings } = this.props;
    let contactEmail = "";
    _.map(siteSettings, (item) => {
      if (item.key === "contact_email") {
        contactEmail = item.value;
      }
    });
    let heading,
      desc,
      dynamic_img = "";
    _.map(maintenanceSettings, (item, index) => {
      if (item.key === "maintenance_heading") {
        heading = item.value;
      }
      if (item.key === "maintenance_desc") {
        desc = item.value;
      }
      if (item.key === "maintenance_img") {
        dynamic_img = item.imageURL;
      }
    });

    return (
      <div className="MaintenancePage">
        <article>
          <h1>{heading}</h1>
          <div>
            <figure>
              <img
                className="maintenance_img"
                src={dynamic_img ? dynamic_img : img}
                alt="maintenance"
              />
            </figure>
            <p>{desc}</p>
            <p>{`${strings.get("Client.loginPage.email")}: ${contactEmail}`}</p>
            <br />
            <a href={`/login`} target="_blank">
              {strings.get("Client.loginPage.login")}
            </a>
          </div>
        </article>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    contactSettings: categoriesSelectors.getContactSettings(state),
    maintenanceSettings: authSelectors.getMaintenanceSettings(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchContactSettings: () => {
      dispatch(categoriesActions.fetchContactSettings());
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenancePage);
