import _ from 'lodash';

export function getItemsByPage(state, page) {
	if (!state.userAlerts.idsByPage['_' + page]) {
		page = (getPagination(state)).previousPage;
	}
	return _.map(state.userAlerts.idsByPage['_' + page], (itemId) => {
		return state.userAlerts.itemsById['_' + itemId]
	})
}

export function getItemById(state, id) {
	return state.userAlerts.itemsById['_' + id];
}

export function getFilters(state) {
	return state.userAlerts.filters;
}

export function getPagination(state) {
	return state.userAlerts.pagination;
}

export function getSorter(state) {
	return state.userAlerts.sorter;
}