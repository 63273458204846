import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";

import * as authSelectors from "../../store/auth/selectors";
import * as authActions from "../../store/auth/actions";
import * as settingSelectors from "../../store/settings/selectors";
import * as settingActions from "../../store/settings/actions";

import strings from "../../services/strings";
import language from "../../services/language";
import { Event } from "../../services/tracker";
import auth from "../../services/auth";
import { checkCookieConsent, checkAnalyticsCookieConsent } from "../../helpers";

import "./Cookie.scss";

function Cookie(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [functionalCookie, setFunctionalCookie] = useState(true);
  const [analyticsCookie, setAnalyticsCookie] = useState(true);

  useEffect(() => {
    if (!auth.isAuthenticated() && checkCookieConsent() === undefined) {
      setIsOpen(true);
    }
    if (checkAnalyticsCookieConsent()) {
      setAnalyticsCookie(true);
    }
    if (checkAnalyticsCookieConsent() === false) {
      setAnalyticsCookie(true);
    }
  }, []);

  useEffect(() => {
    if (props.cookieBannerStatus) {
      setIsOpen(true);
    }
  });

  const toggleVisibility = (event) => {
    if (props.cookieBannerStatus) {
      props.setCookieBannerStatus();
    }
    props.setCurrentModal("");
    setIsOpen(isOpen ? false : true);
  };

  const handleCookie = (event, status) => {
    let expiryDay = 1;
    var d = new Date();
    d.setTime(d.getTime() + expiryDay * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();

    let cookieConsentName = "CookieConsent" + "=";
    let cookieAnalyticsName = "CookieAnalyticsConsent" + "=";
    document.cookie = `${cookieConsentName}${status};Path=/;${expires}`;
    if (status === "true" && analyticsCookie) {
      document.cookie = `${cookieAnalyticsName}${status};Path=/;${expires}`;
    } else {
      if (auth.isAuthenticated()) {
        props.logout();
      }
      document.cookie = `${cookieAnalyticsName}false;Path=/;${expires}`;
    }
    toggleVisibility(event);
  };

  const getActionButtons = () => {
    return (
      <div className="action-buttons">
        <button
          className={isOpen ? "btn accept pull-right" : null}
          onClick={(event) => {
            handleCookie(event, "true");
          }}
        >
          {strings.get("Client.homePage.iUnderstand")}
        </button>
        <button
          className={isOpen ? "btn decline pull-right" : null}
          onClick={(event) => {
            handleCookie(event, "false");
          }}
        >
          {strings.get("Client.homePage.iDecline")}
        </button>
      </div>
    );
  };

  const getCookieTypes = () => {
    return (
      <div className="cookie-types">
        <div>
          <input
            type="checkbox"
            name="functionalCookie"
            checked={functionalCookie}
            onChange={(e) => setFunctionalCookie(e.target.checked)}
            disabled
          />
          <label className="control-label">
            {strings.get(
              "Client.homePage.cookieConsentModal.functionalCookies"
            )}
          </label>
        </div>

        <div>
          <input
            type="checkbox"
            name="analyticsCookie"
            checked={analyticsCookie}
            onChange={(e) => {
              setAnalyticsCookie(e.target.checked);
            }}
          />
          <label className="control-label">
            {strings.get("Client.homePage.cookieConsentModal.analyticsCookies")}
          </label>
        </div>
      </div>
    );
  };

  const { maintenanceSettings } = props;

  let cookie_msg = "";
  _.map(maintenanceSettings, (item) => {
    if (item.key === "maintenance_cookie_msg") {
      cookie_msg = item.value;
    }
  });
  if (!strings.get("Client.homePage.cookieConsentModal.sideButtonTitle")) {
    return false;
  }
  return (
    <div className={isOpen ? "cookie-box-open" : "cookie-box-closed"}>
      <div className="trigger" onClick={toggleVisibility}>
        <h6 className="triggerSlide">
          {strings.get("Client.homePage.cookieConsentModal.sideButtonTitle")}
        </h6>
      </div>
      <div className="cookie-form">
        <div className="container-row">
          <div className="row">
            <div className="col-md-12 feedbackForm">
              <span
                className="ion-android-cancel clickable"
                onClick={toggleVisibility}
              ></span>

              <div>
                <h4>
                  {strings.get(
                    "Client.homePage.cookieConsentModal.bannerTitle"
                  )}
                </h4>

                <p className="cookie-text">
                  {cookie_msg}
                  <Link
                    to={`/${language.getIso2()}/article/disclaimer-cookies`}
                    target="_blank"
                    onClick={() => {
                      Event(
                        "Disclaimer",
                        "User clicked to read policy",
                        "Read more policy"
                      );
                    }}
                  >
                    {strings.get("Client.homePage.readMore")}
                  </Link>
                </p>
                {getCookieTypes()}
                {getActionButtons()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    maintenanceSettings: authSelectors.getMaintenanceSettings(state),
    cookieBannerStatus: settingSelectors.getCookieBannerStatus(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCookieBannerStatus: () => {
      dispatch(settingActions.setCookieBanner());
    },
    setCurrentModal: (data) => {
      dispatch(settingActions.setCurrentModal(data));
    },
    logout: () => {
      dispatch(authActions.logout());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Cookie);
