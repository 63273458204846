import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import scrollToComponent from "react-scroll-to-component";

import Topbar from "../../components/Topbar";
import CategoryList from "../../components/category/CategoryList";
import NewsList from "../../components/news/NewsList";
import PartnerList from "../../components/partners/PartnerList";
import TeamList from "../../components/team/TeamList";
import Counter from "../../components/counter/Counter";
import Footer from "../../components/footer/Footer";
import UserAlertLoader from "../../components/document/UserAlertLoader";

import * as categoriesActions from "../../store/categories/actions";
import * as categoriesSelectors from "../../store/categories/selectors";
import * as documentsActions from "../../store/documents/actions";
import * as documentsSelectors from "../../store/documents/selectors";
import * as partnersActions from "../../store/partners/actions";
import * as partnersSelectors from "../../store/partners/selectors";
import * as stepsActions from "../../store/steps/actions";
import * as articleCategoriesActions from "../../store/articleCategories/actions";
import * as articleCategoriesSelectors from "../../store/articleCategories/selectors";
import * as settingsSelectors from "../../store/settings/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import strings from "../../services/strings";
import language from "../../services/language";

class HomePage extends Component {
  componentDidMount() {
    this.props.fetchAllCategories();
    this.props.fetchStats();
    // this.props.fetchAllSteps();
    this.props.fetchAllPartners();
    this.props.fetchTeamMembers();
    this.props.fetchItemBySlug("nieuws");
    console.log("React Version 16.13.1");
  }

  handleNewsClick = () => {
    scrollToComponent(this.refs.newsContainer, { duration: 1500 });
  };

  render() {
    let locale = language.getIso2();
    const { siteSettings } = this.props;
    let headerLogo, bannerImg, bannerBottomImg;
    // let footerLogo;

    if (siteSettings) {
      if (siteSettings.length) {
        siteSettings.map((image, i) => {
          if (image.key === "footer_logo") {
            // footerLogo = image.imageURL;
          } else if (image.key === "bottom_banner_img") {
            bannerBottomImg = image.imageURL;
          }
        });
      }
    }

    let stats = [
      {
        title: "category",
        count: this.props.stats ? this.props.stats.category : null,
      },
      {
        title: "document",
        count: this.props.stats ? this.props.stats.document : null,
      },
      {
        title: "download",
        count: this.props.stats ? this.props.stats.download : null,
      },
      { title: "user", count: this.props.stats ? this.props.stats.user : null },
    ];

    if (!strings.get("Client.homePage.title")) {
      return <UserAlertLoader />;
    }

    return (
      <div className="HomePage">
        <Topbar
          title={strings.get("Client.homePage.title")}
          subtitle={strings.get("Client.homePage.subTitle")}
          searchBox={true}
          handleNewsClick={this.handleNewsClick}
          menuItem={"home"}
          headerLogo={headerLogo}
          bannerImg={bannerImg}
        />
        <div className="content">
          <h2>{strings.get("Client.homePage.popularCategories")}</h2>
          <CategoryList items={this.props.categories} limit={5} />
          <Link className="btn moreCategories" to={`/${locale}/documenten`}>
            {strings.get("Client.homePage.moreCategories")}
          </Link>
          <NewsList
            items={
              this.props.news && this.props.news.visible_article
                ? this.props.news.visible_article
                : {}
            }
            ref="newsContainer"
          />
          <PartnerList items={this.props.partners} />
          <LazyLoad height={100} offset={100}>
            <TeamList items={this.props.teamMembers} />
          </LazyLoad>
          <LazyLoad height={100} offset={100}>
            <Counter items={stats} bannerBottomImg={bannerBottomImg} />
          </LazyLoad>
        </div>
        <LazyLoad height={100} offset={100}>
          <Footer />
        </LazyLoad>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: categoriesSelectors.getItems(state),
    stats: documentsSelectors.getStats(state),
    partners: partnersSelectors.getItems(state),
    teamMembers: partnersSelectors.getTeam(state),
    news: articleCategoriesSelectors.getItemBySlug(state),
    siteSettings: settingsSelectors.getSiteSettings(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllCategories: () => {
      dispatch(categoriesActions.fetchAllItems());
    },
    fetchAllSteps: () => {
      dispatch(stepsActions.fetchAllItems());
    },
    fetchStats: () => {
      dispatch(documentsActions.fetchStats());
    },
    fetchAllPartners: () => {
      dispatch(partnersActions.fetchAllItems());
    },
    fetchTeamMembers: () => {
      dispatch(partnersActions.fetchTeam());
    },
    fetchItemBySlug: (slug) => {
      dispatch(articleCategoriesActions.fetchItemBySlug(slug));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
