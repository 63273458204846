import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";

import { makePATHUrl } from "../helpers";
import language from "../services/language";
import * as documentsActions from "../store/documents/actions";
import * as documentsSelectors from "../store/documents/selectors";
import * as categoriesSelectors from "../store/categories/selectors";

import "./SearchBox.scss";
import strings from "../services/strings";

class SearchBox extends Component {
  fetched = [];

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
    };
  }

  componentDidMount() {
    // this.props.fetchAllDocuments();
    // this.props.getPlaceholder();
    window.addEventListener("click", this.handleWindowClick);
    // this.props.getDenyMessage();
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.currentLang !== this.props.currentLang)
  //     this.props.getPlaceholder();
  // }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleWindowClick);
  }

  getResult = () => {
    let locale = language.getIso2();
    let items = _.filter(this.props.documents, (item) => {
      return !!item.name
        .toLocaleLowerCase()
        .match(this.state.searchTerm.toLocaleLowerCase());
    });

    items = _.map(items, (item) => {
      const category = this.props.getItemById(item.category_id);
      if (category) {
        return (
          <li key={_.uniqueId()}>
            {/* <Link to={`${locale}/documenten/${makePATHUrl(category.name, category.id)}/${makePATHUrl(item.name, item.id)}`} onMouseOver={() => this.handleMouseOver(item.id) }>{ item.name }</Link> */}
            <Link
              to={`/${locale}/documenten/${makePATHUrl(
                category.name,
                category.id
              )}/${encodeURIComponent(makePATHUrl(item.name, item.id))}`}
            >
              {item.name}
            </Link>
          </li>
        );
      }
    });
    items = _.slice(items, 0, 5);

    if (this.state.searchTerm && _.size(items)) {
      return (
        <div className="list-wrapper">
          <ul>{items}</ul>
        </div>
      );
    }
  };

  handleMouseOver = (id) => {
    if (!_.find(this.fetched, (item) => item === id)) {
      this.props.fetchDocument(id);
      this.fetched.push(id);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleChange = (e) => {
    this.setState({ searchTerm: e.target.value },()=>{
      this.props.setSearchTerm(this.state.searchTerm);
      this.props.fetchAllDocuments();
    });
  };

  handleWindowClick = () => {
    this.setState({ searchTerm: "" });
  };

  render() {
    let className = "SearchBox " + this.props.className;
    return (
      <div className={className}>
        <form onSubmit={this.handleSubmit}>
          <input
            type="text"
            // placeholder={this.props.placeholder}
            placeholder={strings.get("Client.documentsList.searchPlaceholder")}
            value={this.state.searchTerm}
            onChange={this.handleChange}
          />
          <i className="ion-search"></i>
        </form>
        {this.getResult()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    getItemById: (id) => categoriesSelectors.getItemById(state, id),
    documents: documentsSelectors.getItems(state),
    placeholder: documentsSelectors.getSearchPlaceholder(state),
    denyMessage: documentsSelectors.getDenyMessage(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDocument: (id) => {
      dispatch(documentsActions.fetchItem(id));
    },
    setSearchTerm: (searchTerm) => {
      dispatch(documentsActions.setSearchTerm(searchTerm));
    },
    fetchAllDocuments: () => {
      dispatch(documentsActions.fetchAllItems());
    },
    // getPlaceholder: () => {
    //   dispatch(documentsActions.getPlaceholder());
    // },
    // getDenyMessage: () => {
    //   dispatch(documentsActions.getDenyMessage());
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
