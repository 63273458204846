import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { NotificationManager } from "react-notifications";

import * as settingsActions from "../../store/settings/actions";

import strings from "../../services/strings";
import api from "../../services/api";

import { browserHistory } from "../../helpers";

class VerifyEmail extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.setScreenLoading(true);
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.token
    )
      this.confirmEmail();
  }

  confirmEmail = async () => {
    let token = this.props.match.params.token;
    const isVerify = this.props.match.path.search('verifyEmail') !== -1;
    const apiUrl = isVerify? `/users/verifyEmail/${token}` : `/users/approveEmail/${token}`
    let data = await api.get(apiUrl);
    let url = "/";
    if (data.error) {
      NotificationManager.error(
        strings.get("Exceptions.notifications.userEmailVerifiedFalse")
      );
      url = "/NL/contact";
    } else {
      NotificationManager.success(
        strings.get("Exceptions.notifications.userEmailVerifiedTrue")
      );
    }
    _.delay(() => {
      browserHistory.push(url);
      window.location.reload();
    }, 1000);
  };

  render() {
    return true;
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setScreenLoading: (status) => {
      dispatch(settingsActions.setScreenLoading(status));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
