import React from "react";
import PerfectScrollbar from 'react-perfect-scrollbar'
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import PropTypes from "prop-types";

import { CustomFadeModal } from "../PopUp";

import {
  buildContent,
  viewModals,
  customScrollbar,
  customCarouselSlideScrollbar,
} from "../../helpers";
import strings from "../../services/strings";

import "./CarouselContainer.scss";

var Carousel = require("react-responsive-carousel").Carousel;

class CarouselContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOptionIndex: 0,
    };
  }

  componentDidMount() {
    this.loadCurrentSlideIndex(this.props.parent_carousel);
  }

  loadCurrentSlideIndex = (carousel) => {
    if (carousel.value !== "") {
      let currentOptionIndex = parseInt(carousel.value.split("_")[1]);
      this.setState({ currentOptionIndex });
    }
  };

  onOpenModalCarousel = () => {
    this.refs.modalCarousel.show();
  };

  hideModalCarousel = () => {
    this.refs.modalCarousel.hide();
  };

  onChange = (index) => {
    this.setState({
      currentOptionIndex: index,
    });
  };

  onClickItem = (index) => {
    this.setState({
      currentOptionIndex: index,
    });
  };

  handleConfirmAddToTemplate = () => {
    this.props.onAddToTemplate(`option_${this.state.currentOptionIndex}`);
    this.hideModalCarousel();
  };

  handleConfirmReadmore = () => {
    if (
      this.props.parent_carousel &&
      this.props.parent_carousel.options &&
      _.size(this.props.parent_carousel.options) &&
      this.props.parent_carousel.options[this.state.currentOptionIndex]
    ) {
      let option = this.props.parent_carousel.options[
        this.state.currentOptionIndex
      ];
      this.setState(
        {
          option,
        },
        () => {
          this.showSlideReadMoreModel();
        }
      );
    }
  };

  handleReadMore = () => {
    if (this.state.option && this.state.option.helperLink) {
      window.open(
        this.state.option.helperLink,
        "_blank" // <- This is what makes it open in a new window.
      );
    } else {
      NotificationManager.info(
        "",
        strings.get("Client.notifications.informationUnAvailable")
      );
    }
    this.hideSlideReadMoreModel();
  };

  hideSlideReadMoreModel = () => {
    this.setState(
      {
        option: {},
      },
      () => {
        if (this.refs.CAROUSEL_SLIDE_READ_MORE)
          this.refs.CAROUSEL_SLIDE_READ_MORE.hide();
      }
    );
  };

  showSlideReadMoreModel = () => {
    if (this.refs.CAROUSEL_SLIDE_READ_MORE)
      this.refs.CAROUSEL_SLIDE_READ_MORE.show();
  };

  getSlideReadMoreModel = () => {
    // const helperScrollbar = {
    //   maxHeight: 400,
    // };
    let helperText =
      this.state.option && this.state.option.helperText
        ? this.state.option.helperText
        : "";
    let helperTextTitle =
      this.state.option && this.state.option.name ? this.state.option.name : "";

    return (
      <CustomFadeModal
        className="boron-modal"
        ref={viewModals.CAROUSEL_SLIDE_READ_MORE}
        onHide={this.hideSlideReadMoreModel}
      >
        <span className="helperName">
          <b>{this.props.buildLabel(helperTextTitle)}</b>
          <div className="close-btn">
            <i
              className="ion-android-cancel clickable"
              onClick={this.hideSlideReadMoreModel}
            />
          </div>
        </span>
        <br />
        <PerfectScrollbar style={customScrollbar}>
          <span className="helperText">
            <div
              dangerouslySetInnerHTML={{
                __html: this.props.buildLabel(helperText),
              }}
              style={{ marginTop: 20 }}
            />
          </span>
          <br />
        </PerfectScrollbar>
        <div className="form-actions">
          {this.state.option && this.state.option.helperLink ? (
            <button className="btn btn3" onClick={this.handleReadMore}>
              {strings.get("Client.documentContent.helperModalButtons.link")}
            </button>
          ) : null}

          <button className="btn btn2" onClick={this.hideSlideReadMoreModel}>
            {strings.get("Client.documentContent.helperModalButtons.gotIt")}
          </button>
        </div>
      </CustomFadeModal>
    );
  };

  buildClause=(item, path, arr = [], lastKeyOrder = 0) =>{
    let description = item.properties
      ? this.props.getDescription(item.properties.description, item.name)
      : "";
    return (
      <div
        key={_.uniqueId()}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <label
          style={{ width: "85%", textAlign: "initial" }}
          className="labelStyle"
        >
          {this.props.buildLabel(description)}
        </label>
        <div className="helperText" style={{ display: "none" }}>
          {item && item.properties ? item.properties.helperText : ""}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "15%",
            flexDirection: "row-reverse",
          }}
        >
          {this.props.getHelper(item)}
          {this.props.getWarning(item)}
          {this.props.getClausePreview(
            item && item.properties ? item.properties : {}
          )}
        </div>
      </div>
    );
  }

  render() {
    let modalCarousel = (
      <CustomFadeModal
        className="boron-modal carousel-model"
        ref="modalCarousel"
      >
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.hideModalCarousel}
          />
        </div>

        <Carousel
          showArrows={true}
          onChange={this.onChange}
          onClickItem={this.onClickItem}
          showThumbs={false}
          selectedItem={this.state.currentOptionIndex}
        >
          {this.props.parent_carousel.options.map((item, index) => {
            let clause = undefined;
            let value = undefined;
            if (item.isClause === 1) {
              clause = this.buildClause(item);
            } else {
              value = buildContent(item.children, false, "", this.props);
            }
            return (
              <div key={index} className="slide-data-container">
                {/* <div className=""> */}
                <p className="slide-title">
                {item.isClause !==1 && (
                  <span 
                    title="More info" 
                    className={"slider-title-eye"} 
                    onClick={() => this.props.showCarouselPreviewModal(item)}
                  >
                    <i class="ion-help-circled"></i>
                  </span>
                )} {item.name}
                </p>
                {/* </div> */}
                <PerfectScrollbar style={customCarouselSlideScrollbar}>
                  {clause ? clause : value ? value : ""}
                </PerfectScrollbar>
              </div>
            );
          })}
        </Carousel>
        <div className="form-actions">
          <button className="btn btn3" onClick={this.handleConfirmReadmore}>
            {strings.get(
              "Client.draftsPage.carouselPopUp.actionButtons.readMoreLink"
            )}
          </button>
          <button
            className="btn btn2"
            onClick={this.handleConfirmAddToTemplate}
          >
            {strings.get(
              "Client.draftsPage.carouselPopUp.actionButtons.addToTemplate"
            )}
          </button>
        </div>
      </CustomFadeModal>
    );
    return (
      <div className="CarouselContainer">
        {this.getSlideReadMoreModel()}
        <button className="btn-carousel" onClick={this.onOpenModalCarousel}>
          <i className="fa fa-crosshairs" />
          <span>{this.props.buildLabel(this.props.carousel_label)}</span>
        </button>
        {modalCarousel}
      </div>
    );
  }
}

CarouselContainer.propTypes = {
  carousel_label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  parent_carousel: PropTypes.object.isRequired,
  buildLabel: PropTypes.func.isRequired,
  getDescription: PropTypes.func.isRequired,
  getClausePreview: PropTypes.func.isRequired,
};
export default CarouselContainer;
