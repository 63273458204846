import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";

import Breadcrumbs from "../../components/Breadcrumbs";
import Topbar from "../../components/Topbar";
import NavigationTabs from "../../components/NavigationTabs";
import UserDocumentList from "../../components/document/UserDocumentList";
import UserDocumentSorter from "../../components/document/UserDocumentSorter";
import DocumentCategoryFilter from "../../components/document/DocumentCategoryFilter";
import Pagination from "../../components/Pagination";
import Footer from "../../components/footer/Footer";
import UserDocumentFilter from "../../components/document/UserDocumentFilter";
import CustomDropModal from "../../components/PopUp";

import * as authActions from "../../store/auth/actions";
import * as userActions from "../../store/user/actions";
import * as userDocumentsSelectors from "../../store/userDocuments/selectors";
import * as userDocumentsActions from "../../store/userDocuments/actions";
import * as categoriesActions from "../../store/categories/actions";
import * as categoriesSelectors from "../../store/categories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import { browserHistory } from "../../helpers";
import strings from "../../services/strings";
import auth from "../../services/auth";
import language from "../../services/language";

// import "../Page.scss";

class UserDocumentsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      filter: "all",
    };
  }

  componentDidMount() {
    if (!auth.isAuthenticated()) {
      browserHistory.push("/");
      window.location.reload();
    } else {
      this.props.fetchDocuments();
      this.props.fetchAllCategories();
      this.props.fetchUserDocuments(true);
      this.setState({ user: auth.getLocalUser() ? auth.getLocalUser() : null });
    }
  }
  handleShowModal = () => {
    _.delay(() => {
      this.hideSaveModal();
    }, 500);
  };
  showSaveModal = () => {
    this.refs.saveModal.show();
  };
  hideSaveModal = () => {
    this.refs.saveModal.hide();
  };

  updateItemDownloadName = (draftId, data) => {
    this.props.updateDownloadName(draftId, data);
    _.delay(() => {
      this.props.fetchDocuments();
    }, 500);
  };

  updateItemReminder = (downloadId, data) => {
    this.props.updateDownloadReminder(downloadId, data);
    _.delay(() => {
      this.props.fetchDocuments();
    }, 500);
  };

  clearReminderException = () => {
    this.props.clearExceptions();
  };

  handleFilter = () => (e) => {
    this.setState({
      filter: e.target.value,
    });
  };

  render() {
    const { filter } = this.state;
    let locale = language.getIso2();
    let userDocuments;
    if (
      this.props.userDocuments !== undefined &&
      this.props.userDocuments.length
    ) {
      if (filter === "paid") {
        userDocuments = this.props.userDocuments.filter(
          (doc) => doc.document.payed === 1
        );
      } else if (filter === "free") {
        userDocuments = this.props.userDocuments.filter(
          (doc) => doc.document.payed === 0
        );
      } else {
        userDocuments = this.props.userDocuments;
      }
    } else {
      userDocuments = [];
    }
    return (
      <div className="UserDocumentsPage Profilepage">
        <CustomDropModal
          className="boron-modal no-body"
          ref="saveModal"
          onShow={this.handleShowModal}
        >
          <span>
            <h2>{strings.get("Client.settings.settingsSaved")}</h2>
          </span>
        </CustomDropModal>

        <Topbar
          title={strings.get("Client.profilePage.downloads")}
          searchBox={true}
        />
        <div className="breadcrumb-cont">
          <div className="container">
            {" "}
            <Breadcrumbs>
              <Link to={`/${locale}/`}>
                {strings.get("Client.homePage.home")}
              </Link>
              <Link to={`/${locale}/profile`}>
                {strings.get("Client.homePage.profile")}
              </Link>
              <Link to={`/${locale}/mydocuments`}>
                {strings.get("Client.profilePage.downloads")}
              </Link>
            </Breadcrumbs>
          </div>
        </div>
        <div className="inner-page-cont downloadpage">
          <div className="container">
            <NavigationTabs
              currentItemId={4}
              currentLang={this.props.currentLang}
            />
            {/* <div className="col-sm-7"> */}
            <div className="row">
              <DocumentCategoryFilter
                filters={this.props.filters}
                categories={this.props.categories}
                fetchItems={this.props.fetchDocuments}
                setCategoryId={this.props.setCategoryId}
              />
              <UserDocumentSorter
                sorter={this.props.sorter}
                fetchItems={this.props.fetchUserDocuments}
                setSorter={this.props.setSorter}
              />
              <UserDocumentFilter
                handleFilter={this.handleFilter()}
                filter={this.state.filter}
              />
            </div>
            {/* </div> */}
            <UserDocumentList
              {...this.props}
              items={userDocuments}
              currentItem={this.props.currentUserDocument}
              sorter={this.props.sorter}
              fetchItems={this.props.fetchUserDocuments}
              setCurrentItemId={this.props.setCurrentUserDocumentId}
              unsetCurrentItemId={this.props.unsetCurrentUserDocumentId}
              deleteItem={this.props.deleteUserDocument}
              toggleSorter={this.props.toggleSorter}
              updateItemReminder={this.updateItemReminder}
              clearReminderException={this.clearReminderException}
              updateItemDownloadName={this.updateItemDownloadName}
            />
            <div className="col-sm-12 text-center" style={{ display: "flex", justifyContent: "center" }}>
              <Pagination
                pagination={this.props.pagination}
                setCurrentPage={this.props.setCurrentPage}
                fetchItems={this.props.fetchDocuments}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    exceptions: exceptionsSelectors.getItems(state),
    categories: categoriesSelectors.getItems(state),
    userDocuments: userDocumentsSelectors.getItemsByPage(
      state,
      userDocumentsSelectors.getPagination(state).currentPage
    ),
    sorter: userDocumentsSelectors.getSorter(state),
    filters: userDocumentsSelectors.getFilters(state),
    pagination: userDocumentsSelectors.getPagination(state),
    currentUserDocument: userDocumentsSelectors.getCurrentItem(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllCategories: () => {
      dispatch(categoriesActions.fetchAllItems());
    },
    getUser: () => {
      dispatch(authActions.getUser());
    },
    updateUser: (data) => {
      dispatch(userActions.updateUser(data));
    },
    uploadUserImage: (file) => {
      dispatch(userActions.uploadUserImage(file));
    },
    fetchUserDocuments: (deleteCache) => {
      dispatch(userDocumentsActions.fetchItems(deleteCache));
    },
    setSearchTerm: (searchTerm) => {
      dispatch(userDocumentsActions.setSearchTerm(searchTerm));
    },
    toggleSorter: (searchTerm) => {
      dispatch(userDocumentsActions.toggleSorter(searchTerm));
    },
    setSorter: (sorter) => {
      dispatch(userDocumentsActions.setSorter(sorter));
    },
    setCurrentPage: (page) => {
      dispatch(userDocumentsActions.setCurrentPage(page));
    },

    setCurrentUserDocumentId: (id) => {
      dispatch(userDocumentsActions.setCurrentItemId(id));
    },
    unsetCurrentUserDocumentId: () => {
      dispatch(userDocumentsActions.unsetCurrentItemId());
    },
    deleteUserDocument: (id) => {
      dispatch(userDocumentsActions.deleteItem(id));
    },
    setCategoryId: (id) => {
      dispatch(userDocumentsActions.setCategoryId(id));
    },
    fetchDocuments: (deleteCache) => {
      dispatch(userDocumentsActions.fetchItems(deleteCache));
    },
    updateDownloadReminder: (id, data) => {
      dispatch(userDocumentsActions.updateItemReminder(id, data));
    },
    updateDownloadName: (id, data) => {
      dispatch(userDocumentsActions.updateDownloadName(id, data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDocumentsPage);
