import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import strings from "../../../services/strings";

import "./ShareDraftInstructionForm.scss";

class ShareDraftInstructionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentItemLoaded: false,
      form: {
        share_draft_instruction: {
          message: "",
        },
      },
    };
  }

  componentDidMount() {
    this.tryLoadCurrentItem();
  }

  componentDidUpdate() {
    this.tryLoadCurrentItem();
  }

  tryLoadCurrentItem = () => {
    if (
      this.props.currentItem &&
      !_.isEmpty(this.props.currentItem) &&
      !this.state.currentItemLoaded
    ) {
      let form = _.extend({}, this.state.form);

      if (this.props.currentItem.settings) {
        let settingObject = JSON.parse(this.props.currentItem.settings);
        form = _.merge(form, settingObject);
      }

      this.setState({
        currentItemLoaded: true,
        form,
      });
    }
  };

  hasError = (inputName) => {
    return !!this.props.exceptions[inputName];
  };

  getErrorClass = (inputName, defaultClasses = "") => {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  };

  getErrorMessage = (inputName) => {
    return this.props.exceptions[inputName];
  };

  handleInputChange = (e) => {
    let form = _.set(this.state.form, e.target.name, e.target.value);
    this.setState({
      form: _.extend(this.state.form, form),
    });
  };

  handleSaveClick = (e) => {
    e.preventDefault();
    this.props.saveItem(this.state);
    let { form } = this.state;
    if (form.share_draft_instruction.message === "") {
      form.share_draft_instruction.message =
        form.default_settings.share_draft_instruction_message;
    }
    this.setState({ form });
  };

  handleDefaultClick = (e) => {
    e.preventDefault();
    let { form } = this.state;
    form.share_draft_instruction.message =
      form.default_settings.share_draft_instruction_message;
    this.setState({ form });
  };
  render() {
    let shareDraftInstruction = this.state.form.share_draft_instruction;

    return (
      <div className="col-xs-12 col-sm-9 col-md-9 ShareDraftInstructionForm">
        <div className="panel panel-default">
          <div className="panel-heading">
            <h4>{strings.get("Client.settings.messages.shareDraft.title")}</h4>
          </div>
          <div className="panel-body">
            <form>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group tiny-mce">
                    <label className="control-label">
                      {strings.get(
                        "Client.settings.messages.shareEmail.message"
                      )}
                    </label>
                    <textarea
                      onChange={this.handleInputChange}
                      className="form-control"
                      name="share_draft_instruction.message"
                      value={shareDraftInstruction.message}
                    ></textarea>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-actions">
                    <button
                      className="btn btn-primary"
                      onClick={this.handleSaveClick}
                    >
                      {strings.get("Client.profilePage.save")}
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={this.handleDefaultClick}
                    >
                      {strings.get("Client.profilePage.default")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

ShareDraftInstructionForm.propTypes = {
  currentItem: PropTypes.object,
  saveItem: PropTypes.func.isRequired,
};

export default ShareDraftInstructionForm;
