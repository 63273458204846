import React from "react";
import PropTypes from "prop-types";

import "./PageSize.scss";

function PageSize(props) {
  const getPageSize = () => {
    if (props.pagination) {
      return props.pagination.pageSize;
    }
    return "";
  };

  const handleChange = (e) => {
    let pageSize = e.target.value;
    props.setPageSize(pageSize);
    props.fetchItems(true);
  };

  return (
    <div className="PageSize">
      <select
        className="form-control"
        name="sorter"
        value={getPageSize()}
        onChange={handleChange}
      >
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
      </select>
    </div>
  );
}

PageSize.propTypes = {
  sorter: PropTypes.object.isRequired,
  setPageSize: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
};

export default PageSize;
