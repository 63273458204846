import React, { Component } from "react";
import _ from "lodash";

import CategorySingle from "./CategorySingle";

import "./CategoryList.scss";

function CategoryList(props) {
  const getItems = () => {
    if (props.items) {
      let count = 0;
      return _.map(props.items, (item) => {
        if (count > props.limit) {
          return;
        }
        if (!item.parent_id) {
          count++;
        }
        return !item.parent_id ? (
          <CategorySingle key={item.id} item={item} />
        ) : (
          false
        );
      });
    }
  };

  return (
    <div className="inner-page-cont">
      <div className="container-row">
        <div
          style={{
            display: "inline-block",
            textAlign: "center",
            padding: "15px 0",
          }}
          className="CategoryList container"
        >
          <ul style={{ margin: "0" }} className="row">
            {getItems()}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CategoryList;
