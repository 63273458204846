import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import _ from "lodash";
import Dropzone from "react-dropzone";
import ReactAvatarEditor from "react-avatar-editor";
import PropTypes from "prop-types";

import CustomDropModal from "../../PopUp";

import strings from "../../../services/strings";

import "./UserSettingsForm.scss";

const nl2br = require("react-nl2br");

class UserSettingsForm extends Component {
  setEditorRef = (editor) => (this.editor = editor);

  constructor(props) {
    super(props);
    this.maxWidth = props.maxWidth ? props.maxWidth : 250;
    this.maxHeight = props.maxHeight ? props.maxHeight : 150;
    this.state = {
      currentItemLoaded: false,
      headerlogo: null,
      croppedImgUrl: null,
      scale: 1,
      imageURL: null,
      fileRejected: false,
      form: {
        document_settings: {
          fontfamily: "verdana",
          fontsize: "12",
          headerlogoposition: "left",
          addresstxt: "",
          headerfontsize: "12",
          headercompanyposition: "right",
          footertxt: "",
          footerfontsize: "12",
          footerposition: "left",
        },
      },
    };
  }

  componentDidMount() {
    this.tryLoadCurrentItem();
  }

  componentDidUpdate() {
    this.tryLoadCurrentItem();
  }

  tryLoadCurrentItem = () => {
    if (
      this.props.currentItem &&
      !_.isEmpty(this.props.currentItem) &&
      !this.state.currentItemLoaded
    ) {
      let form = _.extend({}, this.state.form);

      if (this.props.currentItem.settings) {
        let settingObject = JSON.parse(this.props.currentItem.settings);
        form = _.merge(form, settingObject);
      }

      this.setState({
        currentItemLoaded: true,
        imageURL: this.props.currentItem.header_logo_url,
        form,
      });
    }
  };

  cropImage = (e) => {
    const croppedImgUrl = this.editor.getImageScaledToCanvas().toDataURL();
    this.setState({
      headerlogo: null,
      croppedImgUrl,
    });
  };

  clearImage = (e) => {
    const { headerlogo, imageURL, croppedImgUrl } = this.state;
    const { currentItem } = this.props;
    let state = {
      headerlogo: null,
      croppedImgUrl: null,
    };

    if (headerlogo === null) {
      state["imageURL"] =
        croppedImgUrl === null
          ? currentItem &&
            currentItem.header_logo_url &&
            currentItem.header_logo_url !== imageURL
            ? currentItem.header_logo_url
            : ""
          : imageURL;
    }

    this.setState({
      ...state,
    });
  };

  hasError = (inputName) => {
    return !!this.props.exceptions[inputName];
  };

  getErrorClass = (inputName, defaultClasses = "") => {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  };

  getErrorMessage = (inputName) => {
    return this.props.exceptions[inputName];
  };

  getDocLogoImage = (imgClass = "") => {
    const { croppedImgUrl } = this.state;
    if (croppedImgUrl && !this.state.file) {
      return (
        <img className={imgClass} src={croppedImgUrl} alt="userImageSample" />
      );
    } else {
      return this.state.imageURL ? (
        <img className={imgClass} src={this.state.imageURL} alt="userImage" />
      ) : null;
    }
  };

  handleInputChange = (e) => {
    let form = _.set(this.state.form, e.target.name, e.target.value);
    this.setState({
      form: _.extend(this.state.form, form),
    });
  };

  handleFileDrop = (acceptedFiles, rejectedFiles) => {
    $(".errorUpload").html("");
    if (rejectedFiles.length) {
      this.setState({
        fileRejected: true,
      });
    } else {
      // let imageErrorMsg = `${strings.get("Client.settings.errorlogo")}`;
      let file = _.first(acceptedFiles);
      var image = new Image();
      image.addEventListener("load", () => {
        this.setState({
          headerlogo: file,
          fileRejected: false,
        });
      });
      image.src = window.URL.createObjectURL(file);
    }
  };

  handleSaveClick = (e) => {
    e.preventDefault();
    this.props.saveItem(this.state);
  };

  headerAppearance = () => {
    let dropzoneContentDisp = this.getDocLogoImage("img-responsive");
    let documentSettings = this.state.form.document_settings;
    let headerLogoposition = documentSettings.headerlogoposition;

    let headerCompanyTxt = documentSettings.addresstxt;
    let headerCompanyposition = documentSettings.headercompanyposition;
    let headerCompanyFontsize = documentSettings.headerfontsize + "px";

    let logoCnt = <div className="company-logo">{dropzoneContentDisp}</div>;

    let addressCnt = (
      <div
        className="Company-address"
        style={{ fontSize: headerCompanyFontsize }}
      >
        <address>{nl2br(headerCompanyTxt)}</address>
      </div>
    );

    let logoContent = {
        left: "",
        center: "",
        right: "",
      },
      addressContent = {
        left: "",
        center: "",
        right: "",
      };

    logoContent[headerLogoposition] = logoCnt;
    addressContent[headerCompanyposition] = addressCnt;

    return (
      <div className="row">
        <div className="headerpos col-xs-4 text-left" id="leftCont">
          {logoContent.left}
          {addressContent.left}
        </div>
        <div className="headerpos col-xs-4 text-center">
          {logoContent.center}
          {addressContent.center}
        </div>
        <div className="headerpos col-xs-4 text-right">
          {logoContent.right}
          {addressContent.right}
        </div>
      </div>
    );
  };

  render() {
    let imageError = this.hasError("headerlogo")
      ? `${strings.get("Client.settings.headerlogo")} ${this.getErrorMessage(
          "headerlogo"
        )}`
      : strings.get("Client.settings.headerlogo");
    // let dropzoneContent = this.getDocLogoImage()
    //   ? this.getDocLogoImage()
    //   : strings.get("Client.profilePage.chooseImage");

    let documentSettings = this.state.form.document_settings;

    let FontFamily = documentSettings.fontfamily;
    let bodyFontsize = documentSettings.fontsize + "px";
    let headerCompanyTxt = documentSettings.addresstxt;
    let headerCompanyposition = documentSettings.headercompanyposition;
    let footerPosition = documentSettings.footerposition;
    let footerFontsize = documentSettings.footerfontsize + "px";

    return (
      <div className="UserSettingsForm">
        <div className="row row-eq-height">
          <div className="col-xs-12 col-sm-12 col-md-6 setting-left">
            <div className="">
              <h4>{strings.get("Client.settings.documentsettings")}</h4>

              <div className="">
                <form onSubmit={this.onSubmit}>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="control-label">
                          {strings.get("Client.settings.fontsize")}
                        </label>
                        <input
                          onChange={this.handleInputChange}
                          className="form-control"
                          name="document_settings.fontsize"
                          type="number"
                          value={documentSettings.fontsize}
                          min="12"
                          max="20"
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="control-label">
                          {strings.get("Client.settings.headerlogoposition")}
                        </label>
                        <select
                          onChange={this.handleInputChange}
                          className="form-control"
                          name="document_settings.headerlogoposition"
                          value={documentSettings.headerlogoposition}
                        >
                          <option value="left">
                            {strings.get("Client.settings.positionleft")}
                          </option>
                          <option value="center">
                            {strings.get("Client.settings.positioncenter")}
                          </option>
                          <option value="right">
                            {strings.get("Client.settings.positionright")}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="control-label">
                          {strings.get("Client.settings.fontfamily")}
                        </label>
                        <select
                          onChange={this.handleInputChange}
                          className="form-control"
                          name="document_settings.fontfamily"
                          value={documentSettings.fontfamily}
                        >
                          <option value="arial">
                            {strings.get("Client.settings.font.arial")}
                          </option>
                          <option value="Courier New">
                            {strings.get("Client.settings.font.courierNew")}
                          </option>
                          <option value="Georgia">
                            {strings.get("Client.settings.font.georgia")}
                          </option>
                          <option value="tahoma">
                            {strings.get("Client.settings.font.tahoma")}
                          </option>
                          <option value="Times New Roman">
                            {strings.get("Client.settings.font.timesNewRoman")}
                          </option>
                          <option value="verdana">
                            {strings.get("Client.settings.font.verdana")}
                          </option>
                          <option value="Source serif pro">
                            {strings.get("Client.settings.font.sourceSerifPro")}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="control-label">
                          {strings.get("Client.settings.headerfontsize")}
                        </label>
                        <input
                          onChange={this.handleInputChange}
                          className="form-control"
                          name="document_settings.headerfontsize"
                          type="number"
                          value={documentSettings.headerfontsize}
                          min="12"
                          max="20"
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="control-label">
                          {strings.get("Client.settings.headercompanyposition")}
                        </label>
                        <select
                          onChange={this.handleInputChange}
                          className="form-control"
                          name="document_settings.headercompanyposition"
                          value={headerCompanyposition}
                        >
                          <option value="left">
                            {strings.get("Client.settings.positionleft")}
                          </option>
                          <option value="center">
                            {strings.get("Client.settings.positioncenter")}
                          </option>
                          <option value="right">
                            {strings.get("Client.settings.positionright")}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="control-label">
                          {strings.get("Client.settings.addresstxt")}
                        </label>
                        <textarea
                          onChange={this.handleInputChange}
                          className="form-control text-area-setting1"
                          name="document_settings.addresstxt"
                          value={headerCompanyTxt}
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="control-label">
                          {strings.get("Client.settings.footerfontsize")}
                        </label>
                        <input
                          onChange={this.handleInputChange}
                          className="form-control"
                          name="document_settings.footerfontsize"
                          type="number"
                          value={documentSettings.footerfontsize}
                          min="12"
                          max="20"
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="control-label">
                          {strings.get("Client.settings.footerposition")}
                        </label>
                        <select
                          onChange={this.handleInputChange}
                          className="form-control"
                          name="document_settings.footerposition"
                          value={footerPosition}
                        >
                          <option value="left">
                            {strings.get("Client.settings.positionleft")}
                          </option>
                          <option value="center">
                            {strings.get("Client.settings.positioncenter")}
                          </option>
                          <option value="right">
                            {strings.get("Client.settings.positionright")}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="control-label">
                          {strings.get("Client.settings.footertxt")}
                        </label>
                        <textarea
                          onChange={this.handleInputChange}
                          className="form-control text-area-setting1"
                          name="document_settings.footertxt"
                          value={documentSettings.footertxt}
                        />
                      </div>
                    </div>
                    <div className="clearfix" />
                    <div className="col-xs-12 col-md-6">
                      <div
                        className={this.getErrorClass(
                          "headerlogo",
                          "form-group"
                        )}
                      >
                        <label className="control-label">{imageError}</label>
                        <div className="image-container">
                          {this.state.headerlogo || this.state.imageURL ? (
                            <input
                              key="crop-btn1"
                              type="button"
                              className="crop-image-btn"
                              onClick={this.clearImage}
                              value="x"
                            />
                          ) : (
                            ""
                          )}

                          {this.state.headerlogo ? (
                            <div className="croping-zone">
                              <ReactAvatarEditor
                                scale={this.state.scale}
                                rotate={0}
                                image={this.state.headerlogo}
                                width={250}
                                height={150}
                                style={{
                                  border: "2px dotted #d2d2d2",
                                  borderRadius: 3,
                                }}
                                ref={(ref) => this.setEditorRef(ref)}
                              />
                            </div>
                          ) : (
                            <Dropzone
                              className="dropzone"
                              onDrop={this.handleFileDrop}
                              multiple={false}
                              maxSize={4096000}
                              accept="image/*"
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  {this.getDocLogoImage() ? (
                                    this.getDocLogoImage()
                                  ) : (
                                    <span>
                                      {strings.get(
                                        "Client.profilePage.chooseImage"
                                      )}
                                    </span>
                                  )}
                                </div>
                              )}
                              {/* {dropzoneContent} */}
                            </Dropzone>
                          )}
                        </div>

                        <div className="help-block">
                          {this.state.headerlogo
                            ? [
                                <input
                                  key="slider"
                                  type="range"
                                  step="0.01"
                                  min="0.1"
                                  max="2"
                                  name="scale"
                                  value={this.state.scale}
                                  onChange={(e) =>
                                    this.setState({
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                />,
                                <input
                                  key="crop-btn"
                                  type="button"
                                  className="crop-image-btn"
                                  onClick={this.cropImage}
                                  value="Crop"
                                />,
                              ]
                            : strings.get("Client.settings.headerlogohint")}
                        </div>
                        <small className="text-danger errorUpload" />
                      </div>
                    </div>
                    <div className="clearfix" />
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="form-actions">
                        <button
                          className="btn btn-primary btn1 opslann-btn1"
                          onClick={this.handleSaveClick}
                        >
                          {strings.get("Client.profilePage.save")}
                        </button>
                        <Link
                          className="btn btn-default close-link btn2 sluit-btn1"
                          to={"/"}
                        >
                          {" "}
                          <i className="ion-android-cancel" />{" "}
                          {strings.get("Client.profilePage.close")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 setting-right">
            <div className="DocumentFormPreview">
              {/* <div className="panel-heading">
                                <h4> {strings.get('Client.settings.document_preview')}</h4>
                            </div> */}
              <div
                className="panel-body docpreview"
                style={{ fontFamily: FontFamily }}
              >
                <div className="col-xs-12 doc-header">
                  {this.headerAppearance()}
                </div>
                <div className="col-xs-12 doc-body">
                  <div
                    className="Company-description"
                    style={{ fontSize: bodyFontsize }}
                  >
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aenean quis consequat lectus. Fusce dictum vulputate elit.
                      Fusce ut pharetra ante. Pellentesque lacus elit, facilisis
                      sit amet nisi non, venenatis pulvinar libero. Mauris dui
                      felis, mollis id accumsan non, pharetra eu dui.
                      Pellentesque habitant morbi tristique senectus et netus et
                      malesuada fames ac turpis egestas. Nunc et vestibulum
                      odio. Cras lobortis vulputate metus, vel blandit sapien
                      eleifend ut.
                    </p>
                  </div>
                </div>
                <div
                  className="col-xs-12  doc-footer"
                  style={{
                    textAlign: footerPosition,
                    fontSize: footerFontsize,
                    fontFamily: FontFamily,
                  }}
                >
                  <p>{nl2br(documentSettings.footertxt)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomDropModal
          className="boron-modal"
          ref="saveModal"
          onShow={this.handleShowModal}
        >
          <span>
            <h2>{strings.get("App.settings.settingsSaved")}</h2>
          </span>
        </CustomDropModal>
      </div>
    );
  }
}

UserSettingsForm.propTypes = {
  currentItem: PropTypes.object,
  saveItem: PropTypes.func.isRequired,
};

export default UserSettingsForm;
