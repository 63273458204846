import React, { Component } from "react";
import _ from "lodash";
import validURL from "valid-url";
import PropTypes from "prop-types";

import SocialShareButton from "../social/SocialShareButton";
import LoadSavedData from "./LoadSavedData";
import CustomDropModal from "../PopUp";

import { makeSEFUrl, checkCookieConsent } from "../../helpers";
import language from "../../services/language";
import auth from "../../services/auth";
import strings from "../../services/strings";

import "./DocumentList.scss";

class DocumentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoModal: false,
      modalName: null,
      modalDescription: null,
      modalReadMoreLink: null,
      openShare: null,
      shareMsg: "",
      shareMsgLoaded: false,
      item: {},
    };
  }

  showInfoModal = (name, description, readMoreLink) => {
    this.setState(
      {
        modalName: name,
        modalDescription: description,
        modalReadMoreLink: readMoreLink,
      },
      this.refs.helperTextModal.show()
    );
  };

  hideInfoModal = () => {
    this.refs.helperTextModal.hide();
  };

  meerInformationIcon = (event, desc, moreLink, readMoreLink) => {
    event.preventDefault();
    this.showInfoModal(desc, moreLink, readMoreLink);
  };

  goToLink = (item, locale) => {
    if (checkCookieConsent()) {
      let link = this.processLink(item, locale);
      window.location = link;
    } else {
      this.props.setCurrentModal("cookieConsentModal");
    }
  };
  processLink = (item, locale) => {
    return `${window.location.protocol}//${
      window.location.host
    }/${locale}/documenten/${this.props.catName}-${
      item.category_id
    }/${encodeURIComponent(makeSEFUrl(item.name))}-${item.id}`;
  };

  toggleDocDetails = (itemId) => {
    if (this.refs[`doc-details-${itemId}`].style.display === "none") {
      this.refs[`doc-details-${itemId}`].style.display = `block`;
    } else this.refs[`doc-details-${itemId}`].style.display = `none`;
  };

  getPrice = (item) => {
    return parseFloat(item.price) && item.payed
      ? `${strings.get("App.documents.price.currencySign")}${item.price}`
      : `${strings.get("App.documents.price.free")}`;
  };

  getItems = () => {
    let locale = language.getIso2();
    if (this.props.items) {
      //maaike: this needs to be replaced with proper pagination or something, for now as a quick fix I have changed the max length to 10000
      const length =
        this.props.items.length > 10000
          ? Math.round(this.props.items.length / 2)
          : this.props.items.length;

      return _.map(this.props.items, (item, key) =>
        key < length ? (
          <li key={item.id} className="document-listoption">
            <div className="doc-name-cont">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6">
                  <div
                    className="document-names"
                    onClick={() => this.goToLink(item, locale)}
                  >
                    <div className="btn btn-make-doc">
                      <i className="far fa-file-alt file-icon" />
                      <a className="title">{item.name}</a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xs-12 col-sm-12 col-md-6"
                  style={{ float: "right" }}
                >
                  <div className="actions">
                    <CustomDropModal
                      className="boron-modal form-modal infoModal"
                      ref="helperTextModal"
                    >
                      {/* <Modal
                      className="boron-modal form-modal infoModal"
                      ref={"helperTextModal"}
                    > */}
                      <div className="close-btn">
                        <span
                          className="ion-android-cancel clickable"
                          onClick={this.hideInfoModal}
                        />
                      </div>
                      <h4>{this.state.modalName}</h4>
                      <p className="text-left">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.modalDescription,
                          }}
                        />
                      </p>
                      {this.state.modalReadMoreLink ? (
                        <button
                          className="btn btn3"
                          onClick={() =>
                            this.handleHelperClick(this.state.modalReadMoreLink)
                          }
                        >
                          {strings.get(
                            "Client.documentsList.modalButtons.readMore"
                          )}
                        </button>
                      ) : null}
                      <button onClick={this.hideInfoModal} className="btn btn2">
                        {strings.get("Client.documentsList.modalButtons.gotIt")}
                      </button>
                      {/* </Modal> */}
                    </CustomDropModal>
                    <div className="row">
                      <div className="col-xs-3">
                        <div className="document-details-actions">
                          <div>
                            <i
                              className="fas fa-info-circle"
                              onClick={(event) =>
                                this.meerInformationIcon(
                                  event,
                                  item.name,
                                  item.description,
                                  item.read_more_link
                                )
                              }
                            />
                          </div>
                          <SocialShareButton
                            style={{ width: "50px" }}
                            url={this.processLink(item, locale)}
                            message={this.state.shareMsg}
                            sendEmails={this.props.sendEmails}
                            item={{ ...item, shareable: true }}
                            callbackFromList={(trigger, item) =>
                              this.handleShareCallback(trigger, item)
                            }
                            isOpen={
                              this.state.openShare &&
                              this.state.openShare.id === item.id
                            }
                          />
                        </div>
                      </div>
                      {!auth.isAuthenticated() ? null : (
                        <div className="col-xs-4 document-details-name">
                          <LoadSavedData
                            documentId={item && item.id ? item.id : 0}
                            catName={
                              this.props.catName ? this.props.catName : ""
                            }
                            stepWiseData={false}
                          />
                        </div>
                      )}
                      <div className="col-xs-5">
                        <div
                          className="btn btn-details-doc"
                          onClick={() => this.toggleDocDetails(item.id)}
                        >
                          <span className="details-text">
                            {strings.get("Client.documentsList.docdetails")}
                          </span>
                          <span className="details-icon">
                            <i className="fa fa-chevron-down" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix" />

            <div
              className="document-details1"
              ref={`doc-details-${item.id}`}
              style={{ display: "none" }}
            >
              <div
                className="close-btn"
                onClick={() => this.toggleDocDetails(item.id)}
              >
                <span className="ion-android-cancel clickable" />
              </div>
              <div className="document-details-left">
                <table>
                  <tbody>
                    <tr>
                      <td className="document-txt-left"> Versie </td>
                      <td className="spe2"> : </td>
                      <td> {item.version} </td>
                    </tr>
                    <tr>
                      <td className="document-txt-left"> Downloads </td>
                      <td className="spe2"> : </td>
                      <td> {_.size(item.downloads)} </td>
                    </tr>
                    <tr>
                      <td className="document-txt-left"> Laatst gewijzigd </td>
                      <td className="spe2"> : </td>
                      <td> {item.v_last_updated} </td>
                    </tr>
                    <tr>
                      <td className="document-txt-left"> Prijs </td>
                      <td className="spe2"> : </td>
                      <td> {this.getPrice(item)} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="document-details-right"></div>
            </div>
          </li>
        ) : null
      );
    }
  };

  handleShareCallback = (trigger, item = this.state.item) => {
    if (trigger) {
      if (this.state.openShare && this.state.openShare.id === item.id) {
        this.setState({ openShare: {} });
      } else {
        this.setState({ openShare: item });
      }
    } else {
      this.setState(
        {
          shareMsg: "",
          shareMsgLoaded: false,
          item: item,
        },
        () => {
          this.showShareMsgModal();
        }
      );
    }
  };

  showShareMsgModal = () => {
    if (
      !(
        this.props.exceptions.template &&
        this.props.exceptions.template.notDefined
      )
    ) {
      this.refs.shareMsgModal.show();
    }
  };

  handleShareMsgChange = (e) => {
    this.setState({
      shareMsg: e.target.value,
    });
  };

  shareMsgModalConfirm = () => {
    this.refs.shareMsgModal.hide();
    this.handleShareCallback(true);
  };

  shareMsgModalCancel = () => {
    this.refs.shareMsgModal.hide();
  };

  handleHelperClick = (link) => {
    if (validURL.isUri(link)) {
      window.open(link, "_blank");
    }
  };

  render() {
    let shareMsgModalContent = (
      <div className="jd-modal">
        <div className="row shareable-fields">
          <div className="col-xs-12 instruction-message text-left">
            <label>
              {strings.get("Client.documentsList.shareMsgModal.instructionMsg")}
            </label>
            <textarea
              className="form-control"
              name="share_msg"
              placeholder={strings.get(
                "Client.documentsList.shareMsgModal.instructionMsgPlaceholder"
              )}
              value={this.state.shareMsg}
              onChange={this.handleShareMsgChange}
            />
          </div>
        </div>
        <button className="btn btn2" onClick={this.shareMsgModalConfirm}>
          {strings.get("App.documents.documentForm.orderFieldsModal.save")}
        </button>
        <button className="btn btn3" onClick={this.shareMsgModalCancel}>
          {strings.get("App.documents.documentForm.orderFieldsModal.cancel")}
        </button>
      </div>
    );
    let shareMsgModal = (
      <CustomDropModal className="boron-modal" ref="shareMsgModal">
        <div className="close-btn">
          <span
            className="ion-android-cancel clickable"
            onClick={this.shareMsgModalCancel}
          />
        </div>
        {shareMsgModalContent}
      </CustomDropModal>
    );
    return (
      <div
        className="DocumentList col-md-10"
        style={{ display: _.size(this.props.items) ? "block" : "none" }}
      >
        {shareMsgModal}
        <ul>{this.getItems()}</ul>
      </div>
    );
  }
}

DocumentList.propTypes = {
  items: PropTypes.array,
  onMouseOver: PropTypes.func,
};

export default DocumentList;
