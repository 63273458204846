import React, { useEffect } from "react";

import PropTypes from "prop-types";
import strings from "../../services/strings";
import _ from "lodash";
import Select from "react-select";

function DraftTypeFilter(props) {
  useEffect(() => {
    return () => {
      props.setDraftsType();
    };
  }, []);

  // return true means draft, and false means roles
  const getDraftsType = () => {
    if (props.filters && props.filters.type && props.filters.type === "roles") {
      return false;
    }
    return true;
  };

  const handleDraftsTypeChange = async (option) => {
    if (option && option.value === "roles") {
      props.setDraftsType(option.value);
    } else {
      props.setDraftsType("drafts");
    }
    await props.fetchItems(true);
  };

  const draftTypeOptions = [
    {
      value: "drafts",
      label: strings.get("Client.profilePage.drafts"),
    },
    {
      value: "roles",
      label: strings.get("Client.profilePage.roles"),
    },
  ];

  return (
    <div className="DraftTypeFilter col-sm-3 col-md-3 col-xs-5 top-margin-10">
      <Select
        options={draftTypeOptions}
        defaultValue={
          getDraftsType() ? draftTypeOptions[0] : draftTypeOptions[1]
        }
        onChange={handleDraftsTypeChange}
      />
    </div>
  );
}

DraftTypeFilter.propTypes = {
  filters: PropTypes.object.isRequired,
  setDraftsType: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
};

export default DraftTypeFilter;
