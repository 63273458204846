import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";

import Breadcrumbs from "../../components/Breadcrumbs";
import NavigationTabs from "../../components/NavigationTabs";
import Topbar from "../../components/Topbar";
import Footer from "../../components/footer/Footer";
// import DraftCategoryFilter from "../../components/sharedDrafts/DraftCategoryFilter";
// import SearchBar from "../../components/sharedDrafts/SearchBar";
import SearchBar from "../../components/drafts/SearchBar";
import DraftCategoryFilter from "../../components/drafts/DraftCategoryFilter";
import Pagination from "../../components/Pagination";
import DraftsList from "../../components/sharedDrafts/DraftsList";

import * as authSelectors from "../../store/auth/selectors";
import * as sharedDraftsActions from "../../store/sharedDrafts/actions";
import * as draftsSelectors from "../../store/sharedDrafts/selectors";
import * as categoriesActions from "../../store/categories/actions";
import * as categoriesSelectors from "../../store/categories/selectors";
import * as glossariesActions from "../../store/glossaries/actions";
import * as glossariesSelectors from "../../store/glossaries/selectors";
import * as stepsActions from "../../store/steps/actions";
import * as stepsSelectors from "../../store/steps/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import language from "../../services/language";
import strings from "../../services/strings";
import auth from "../../services/auth";
import { browserHistory } from "../../helpers";

import "../drafts/DraftsPage.scss";
class SharedDraftsPage extends Component {
  componentDidMount() {
    if (!auth.isAuthenticated()) {
      browserHistory.push("/");
      window.location.reload();
    } else {
      this.props.fetchAllSharedDrafts();
      this.setState({ user: auth.getLocalUser() ? auth.getLocalUser() : null });
      if (!_.size(this.props.glossaries)) {
        this.props.fetchAllGlossaries();
      }
      if (!_.size(this.props.steps)) {
        this.props.fetchAllSteps();
      }
      this.props.fetchAllCategories();
    }
  }

  handleDeleteDraft = (id) => {
    this.props.deleteDraft(id);
  };

  handleValidateDoc = (id) => {
    this.props.validateDoc(id);
  };

  handleValidateDraft = (id) => {
    this.props.validateDraft(id);
  };

  render() {
    let locale = language.getIso2();
    return (
      <div className="UserProfilePage Profilepage DraftsPage">
        <Topbar
          title={strings.get("Client.profilePage.shareddrafts")}
          searchBox={true}
          currentView="draftsPage"
        />

        <div className="breadcrumb-cont">
          <div className="container">
            <Breadcrumbs>
              <Link to={`/${locale}/`}>
                {strings.get("Client.homePage.home")}
              </Link>
              <Link to={`/${locale}/profile`}>
                {strings.get("Client.homePage.profile")}
              </Link>
              <Link to={`/${locale}/shareddrafts`}>
                {strings.get("Client.profilePage.shareddrafts")}
              </Link>
            </Breadcrumbs>
          </div>
        </div>

        <div className="inner-page-cont">
          <div className="container">
            <NavigationTabs
              currentItemId={2}
              currentLang={this.props.currentLang}
            />
            <div className="row">
              <div className="col-sm-12 col-md-12 col-xs-12 draft-filters">
                <DraftCategoryFilter
                  filters={this.props.filters}
                  categories={this.props.categories}
                  fetchItems={this.props.fetchAllSharedDrafts}
                  setCategoryId={this.props.setCategoryId}
                />
                <SearchBar
                  placeholder={strings.get("Client.draftsPage.draftName")}
                  searchTerm={this.props.filters.searchTerm}
                  fetchItems={this.props.fetchAllSharedDrafts}
                  setSearchTerm={this.props.setSearchTerm}
                />
              </div>
            </div>
            <DraftsList
              {...this.props}
              categories={this.props.categories}
              glossaries={this.props.glossaries}
              steps={this.props.steps}
              fields={this.props.fields}
              fieldsOrder={this.props.fieldsOrder}
              selectors={this.props.selectors}
              clauses={this.props.clauses}
              stepsFromValidation={this.props.stepsFromValidation}
              updateItemDraftName={this.updateItemDraftName}
            />
            <div className="col-sm-12 text-right" style={{ display: "flex", justifyContent: "center" }}>
              <Pagination
                pagination={this.props.pagination}
                setCurrentPage={this.props.setCurrentPage}
                fetchItems={this.props.fetchAllSharedDrafts}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: authSelectors.getProfile(state),
    sharedDrafts: draftsSelectors.getItemsByPage(
      state,
      draftsSelectors.getPagination(state).currentPage
    ),
    categories: categoriesSelectors.getItems(state),
    steps: stepsSelectors.getItems(state),
    glossaries: glossariesSelectors.getItems(state),
    fields: draftsSelectors.getFields(state),
    fieldsOrder: draftsSelectors.getFieldsOrder(state),
    selectors: draftsSelectors.getSelectors(state),
    clauses: draftsSelectors.getClauses(state),
    stepsFromValidation: draftsSelectors.getSteps(state),
    filters: draftsSelectors.getFilters(state),
    pagination: draftsSelectors.getPagination(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllSharedDrafts: (deleteCache) => {
      return dispatch(sharedDraftsActions.fetchAllSharedDrafts(deleteCache));
    },
    fetchAllCategories: (deleteCache) => {
      dispatch(categoriesActions.fetchAllItems(deleteCache));
    },
    fetchAllSteps: (deleteCache) => {
      dispatch(stepsActions.fetchAllItems(deleteCache));
    },
    fetchAllGlossaries: () => {
      dispatch(glossariesActions.fetchAllItems());
    },
    setCategoryId: (id) => {
      dispatch(sharedDraftsActions.setCategoryId(id));
    },
    setSearchTerm: (searchTerm) => {
      dispatch(sharedDraftsActions.setSearchTerm(searchTerm));
    },
    setCurrentPage: (page) => {
      dispatch(sharedDraftsActions.setCurrentPage(page));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SharedDraftsPage);
