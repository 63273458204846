import React, { Component } from "react";
import strings from "../../services/strings";
import DropZone from "react-dropzone";
import Immutable from "seamless-immutable";
import _ from "lodash";
import ReactLoading from "react-loading";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import ActiveUrlLabel from "../ActiveUrlLabel";

import api from "../../services/api";
import { maximumFileSizeToUpload, acceptedFileFormats } from "../../helpers";

import "./UploadAttachments.scss";

export default class UploadAttachments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attachDocumentOnServer: [],
      upload: false,
    };
  }

  componentDidMount() {
    if (
      !_.size(this.state.attachDocumentOnServer) &&
      this.props.mileStoneId &&
      this.props.userCurrentWorkflow &&
      this.props.userCurrentWorkflow.milestone_attachments &&
      this.props.userCurrentWorkflow.milestone_attachments[
        this.props.mileStoneId
      ]
    ) {
      this.setAttachments(
        this.props.userCurrentWorkflow.milestone_attachments[
          this.props.mileStoneId
        ]
      );
    }
  }
  componentDidUpdate(prevProps) {
    if (
      !_.size(this.state.attachDocumentOnServer) &&
      this.props.mileStoneId &&
      this.props.userCurrentWorkflow &&
      this.props.userCurrentWorkflow.milestone_attachments &&
      this.props.userCurrentWorkflow.milestone_attachments[
        this.props.mileStoneId
      ]
    ) {
      this.setAttachments(
        this.props.userCurrentWorkflow.milestone_attachments[
          this.props.mileStoneId
        ]
      );
    }
    if (
      this.props.userCurrentWorkflow &&
      this.props.userCurrentWorkflow.milestone_attachments &&
      prevProps.userCurrentWorkflow.milestone_attachments !==
        this.props.userCurrentWorkflow.milestone_attachments
    ) {
      this.setAttachments(
        this.props.userCurrentWorkflow.milestone_attachments[
          this.props.mileStoneId
        ]
      );
    }
  }

  setAttachments = (milestone_attachments) => {
    let attachDocumentOnServer = milestone_attachments;
    if (
      attachDocumentOnServer &&
      attachDocumentOnServer.length &&
      Immutable.isImmutable(attachDocumentOnServer)
    ) {
      attachDocumentOnServer = Immutable.asMutable(attachDocumentOnServer, {
        deep: true,
      });
    }
    if (_.size(attachDocumentOnServer))
      this.setState(
        {
          attachDocumentOnServer,
        },
        () => {
          _.delay(() => {
            this.props.handleShowIconExpand();
          }, 100);
        }
      );
  };

  handleCategoryRemoveServer = async (data) => {
    let attachDocumentOnServer = this.state.attachDocumentOnServer;

    await api
      .delete(
        `/userWorkflows/milestoneAttachment/${attachDocumentOnServer[data].id}`
      )
      .then((response) => {
        attachDocumentOnServer.splice(data, 1);
        this.setState(
          {
            attachDocumentOnServer,
          },
          () => {
            NotificationManager.info(
              strings.get("Client.notifications.deleted")
            );
          }
        );
      })
      .catch((error) => {
        console.log(error);
        NotificationManager.error(strings.get("Client.notifications.error"));
      });
  };

  handleFile = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      for (const iterator of rejectedFiles) {
        if (iterator.size > maximumFileSizeToUpload) {
          NotificationManager.warning(
            strings.get("Client.notifications.fileUploadMaxSize"),
            iterator.name
          );
        } else {
          NotificationManager.warning(
            strings.get("Client.notifications.fileTypeNotSupported"),
            iterator.name
          );
        }
      }
    }
    for (const file of acceptedFiles) {
      let params = new Map();
      params.set("milestoneAttach", file);
      params.set("existingWorkflowId", this.props.id);
      params.set("milestoneId", this.props.mileStoneId);
      params.set("workflowId", this.props.workflowId);
      this.setState({
        upload: true,
      });
      await api
        .post("/userWorkflows/milestoneAttachment", params)
        .then((data) => {
          let attachDocumentOnServer = this.state.attachDocumentOnServer;
          attachDocumentOnServer.push(data);
          this.setState(
            {
              attachDocumentOnServer,
            },
            () => {
              NotificationManager.success(
                strings.get("Client.notifications.added"),
                file.name
              );
            }
          );
        })
        .catch((error) => {
          console.log(error);
          NotificationManager.error(strings.get("Client.notifications.error"));
        });
    }
    this.setState({
      upload: false,
    });
  };

  render() {
    let attachDocumentOnServer =
      this.state.attachDocumentOnServer &&
      !this.state.attachDocumentOnServer.length
        ? null
        : _.map(this.state.attachDocumentOnServer, (item) => {
            return (
              <ActiveUrlLabel
                name={item.url}
                value={this.state.attachDocumentOnServer.indexOf(item)}
                onRemove={this.handleCategoryRemoveServer}
                key={`upload-${_.uniqueId()}`}
                fetchItems={this.props.fetchCurrentWorkflow}
              />
            );
          });
    let fileFormats = acceptedFileFormats.split(" ");
    let tooltip = (
      <Tooltip id="uploadFileTypesTooltip">
        <ul>
          {_.map(fileFormats, (format) => {
            return <li key={_.uniqueId()}>{format}</li>;
          })}
        </ul>
      </Tooltip>
    );
    return (
      <div className="attachments-container">
        {this.state.upload ? (
          <ReactLoading
            className="react-loading"
            type="spinningBubbles"
            color="#36d7b7"
          />
        ) : (
          <div className="dropzone-container">
            <DropZone
              className="dropzone"
              onDrop={this.handleFile}
              multiple={true}
              maxSize={maximumFileSizeToUpload}
              accept={acceptedFileFormats}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <span>
                    {strings.get(
                      "Client.homePage.workflowForm.uploadMilestoneTitle"
                    )}
                  </span>
                </div>
              )}
            </DropZone>
            <OverlayTrigger
              placement="left"
              overlay={tooltip}
              trigger="click"
              rootClose
            >
              <i className="ion-help-circled" />
            </OverlayTrigger>
          </div>
        )}
        {_.size(attachDocumentOnServer) ? (
          <div className="attachments-list">{attachDocumentOnServer}</div>
        ) : (
          <div>{strings.get("Client.documentsList.noFiles")}</div>
        )}
      </div>
    );
  }
}
