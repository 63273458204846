import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

import Breadcrumbs from "../../components/Breadcrumbs";
import Topbar from "../../components/Topbar";
import Footer from "../../components/footer/Footer";

import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import api from "../../services/api";
import strings from "../../services/strings";
import language from "../../services/language";

import "./ContactsPage.scss";

const MyMapComponent = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={{ lat: 52.09476470000001, lng: 5.135750199999961 }}
    >
      {props.isMarkerShown && (
        <Marker position={{ lat: 52.09476470000001, lng: 5.135750199999961 }} />
      )}
    </GoogleMap>
  ))
);

class ContactsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSendSuccess: false,
    };
  }

  onSubmit = (data) => {
    let params = new Map();
    _.map(data, (value, key) => {
      params.set(key, value);
    });
    // const { email, name, number, subject, body } = values;
    // POST request to API
    api
      .post("/contact", params)
      .then((res) => console.log(res))
      .then(() => this.setState({ isSendSuccess: true }));
  };

  render() {
    const required = (value) =>
      value ? undefined : strings.get("Client.contactForm.required");
    const mustBeNumber = (value) =>
      isNaN(value) ? strings.get("Client.contactForm.mustNumber") : undefined;
    const regexForEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const mustBeEmail = (value) =>
      regexForEmail.test(value.toLowerCase())
        ? undefined
        : strings.get("Client.contactForm.emailInvalid");
    const minValue = (min) => (value) =>
      isNaN(value) || value >= min
        ? undefined
        : `${strings.get("Client.contactForm.shouldGreater")} ${min}`;
    const composeValidators = (...validators) => (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );
    const styleForError = { color: "red" };
    // let locale = JSON.parse(localStorage.getItem('language')).iso2;
    let locale = language.getIso2();

    return (
      <div className="ContactsPage">
        <Topbar
          title={strings.get("Client.contactForm.pageTitle")}
          subtitle={
            this.props.currentCategory ? this.props.currentCategory.name : null
          }
          description={
            this.props.currentCategory
              ? this.props.currentCategory.description
              : null
          }
          readMoreLink={
            this.props.currentCategory
              ? this.props.currentCategory.read_more_link
              : null
          }
          // handleLangChange={this.props.handleLangChange}
          // currentLang={this.props.currentLang}
          divider={true}
          menuItem={"contact"}
        />

        <div className="breadcrumb-cont">
          <div className="container">
            <Breadcrumbs>
              <Link to={`/${locale}/`}>
                {strings.get("Client.homePage.home")}
              </Link>
              <Link to={`/${locale}/contact`}>
                {strings.get("Client.contactForm.title")}
              </Link>
            </Breadcrumbs>
          </div>
        </div>
        <div className="inner-page-cont contacts contact-new">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {this.state.isSendSuccess ? (
                  <div className="alert alert-success">
                    {strings.get("Client.contactForm.successMsg")}
                  </div>
                ) : null}
                <h3>{strings.get("Client.contactForm.title")}</h3>
                <Form
                  onSubmit={this.onSubmit}
                  render={({
                    handleSubmit,
                    reset,
                    submitting,
                    pristine,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Field name="name" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group">
                            <input
                              className="form-control"
                              {...input}
                              type="text"
                              placeholder={strings.get(
                                "Client.contactForm.name"
                              )}
                            />
                            {meta.error && meta.touched && (
                              <span style={styleForError}>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>

                      <Field
                        name="email"
                        validate={composeValidators(required, mustBeEmail)}
                      >
                        {({ input, meta }) => (
                          <div className="form-group">
                            <input
                              className="form-control"
                              {...input}
                              type="email"
                              placeholder={strings.get(
                                "Client.contactForm.email"
                              )}
                            />
                            {meta.error && meta.touched && (
                              <span style={styleForError}>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>

                      <Field
                        name="number"
                        validate={composeValidators(required, mustBeNumber)}
                      >
                        {({ input, meta }) => (
                          <div className="form-group">
                            <input
                              className="form-control"
                              {...input}
                              type="text"
                              placeholder={strings.get(
                                "Client.contactForm.phone"
                              )}
                            />
                            {meta.error && meta.touched && (
                              <span style={styleForError}>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>

                      <Field name="subject" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group">
                            <input
                              className="form-control"
                              {...input}
                              type="text"
                              placeholder={strings.get(
                                "Client.contactForm.subject"
                              )}
                            />
                            {meta.error && meta.touched && (
                              <span style={styleForError}>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>

                      <Field
                        name="body"
                        validate={required}
                        component="textarea"
                      >
                        {({ input, meta }) => (
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              {...input}
                              type="textarea"
                              placeholder={strings.get(
                                "Client.contactForm.message"
                              )}
                            />
                            {meta.error && meta.touched && (
                              <span style={styleForError}>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="phone">
                        {({ input, meta }) => (
                          <div className="form-group chk-field1">
                            <label>
                              <input {...input} type="checkbox" />{" "}
                              {strings.get(
                                "Client.contactForm.checkboxes.callBack"
                              )}
                            </label>
                            {meta.error && meta.touched && (
                              <span style={styleForError}>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="terms" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group chk-field2">
                            <label>
                              <input {...input} type="checkbox" />{" "}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: strings.get(
                                    "Client.contactForm.checkboxes.agreements"
                                  ),
                                }}
                              ></span>
                            </label>
                            {meta.error && meta.touched && (
                              <span style={styleForError}>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="copyToSelf">
                        {({ input, meta }) => (
                          <div className="form-group chk-field2">
                            <label>
                              <input {...input} type="checkbox" />{" "}
                              {strings.get(
                                "Client.contactForm.checkboxes.sendCopyYourself"
                              )}
                            </label>
                            {meta.error && meta.touched && (
                              <span style={styleForError}>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>

                      <button
                        type="submit"
                        className="btn btn-primary btn1 mt-30"
                      >
                        {strings.get("Client.contactForm.btnSubmit")}
                      </button>
                    </form>
                  )}
                />
              </div>
              <div className="col-md-6 col-md-offset-1 contact-map">
                <MyMapComponent
                  isMarkerShown
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCBuoVBtQ_gkZzOezulQJzjrjekLDBclg8&v=3.exp&libraries=geometry,drawing,places"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={
                    <div className="map-cont" style={{ height: `410px` }} />
                  }
                  mapElement={
                    <div className="map-cont2" style={{ height: `100%` }} />
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { exceptions: exceptionsSelectors.getItems(state) };
}

function mapDispatchToProps(dispatch) {
  return {
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactsPage);
