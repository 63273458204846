import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactCollapsingTable from "react-collapsing-table";
import _ from "lodash";
import moment from "moment";
import "moment/locale/nl";
import DatePicker, { registerLocale } from "react-datepicker";
import { nl } from "date-fns/locale";
import { parseISO } from "date-fns";
import { Accordion, Card } from "react-bootstrap";

import CustomDropModal from "../PopUp";
import ButtonDelete from "./ButtonDelete";
import SocialShareDraftButton from "../social/SocialShareDraftButton";

import strings from "../../services/strings";
import { makeSEFUrl } from "../../helpers";
import auth from "../../services/auth";
import language from "../../services/language";

// import edit1 from "../../static/images/edit1.jpg";
// import alert1 from "../../static/images/alert1.jpg";

import "./DraftList.scss";
import DraftsDataReUse from "./DraftsDataReUse";

let componentInstance = null;

registerLocale("nl", nl);

const initialDraftReUseState = {
  showModal: false,
  draftId: null,
  documentId: null,
  name: null,
};

const TableDraftName = ({ row, accessor }) => {
  return (
    <div>
      <div className="edit-icon">
        <a
          className="edit"
          onClick={() => componentInstance.handleEditFieldsClick(row)}
        >
          <i className="fas fa-pencil-alt" />
        </a>
      </div>
      <div className="edit-text">
        <a href={componentInstance.processLink(row)}>{row.name}</a>
      </div>
    </div>
  );
};

const TableDraftRevisions = ({ row, accessor }) => {
  return (
    <span>
      <select
        className="form-control cate-select1"
        name="groups"
        value=""
        onChange={componentInstance.handleChange}
      >
        <option value="">{strings.get("Client.draftsPage.select")}</option>
        {componentInstance.getRevisions(row)}
        <option
          value={componentInstance.processRevisionLink(row, row.id)}
          key={row.id}
        >
          {strings.get("Client.draftsPage.revisions")} 1
        </option>
      </select>
    </span>
  );
};

const TableDraftDocName = ({ row, accessor }) => {
  return (
    <span className="template-name">
      <a href={componentInstance.processTemplateLink(row)} target="_blank">
        {row.document.name}
      </a>
      <br />
      <small>
        <a href={componentInstance.processCategoryLink(row)} target="_blank">
          {row.category.name}
        </a>
      </small>
    </span>
  );
};

const TableDraftReUse = ({ row, accessor }) => {
  return (
    <span className="draft-reuse-icon">
      <a
        className="set-reminder"
        title={strings.get("Client.draftsPage.reUse")}
        onClick={() => componentInstance.handleDraftReUseClick(row)}
      >
        <i className="fas fa-recycle"></i>
      </a>
    </span>
  );
};

const TableDraftShare = ({ row, accessor }) => {
  return (
    <SocialShareDraftButton
      url={componentInstance.processLink(row)}
      message={componentInstance.prepareMessage(row)}
      sendEmails={componentInstance.props.sendEmails}
      item={row}
      callbackFromList={(trigger, item) =>
        componentInstance.handleShareCallback(trigger, row.id, true, item)
      }
      // isOpen={componentInstance.state.openShare === row.id}
      isOpen={
        componentInstance.state.openSocialModal &&
        componentInstance.state.openShare === row.id
      }
      urlSuffix={componentInstance.state.selectedOption}
    />
  );
};

const TableDraftDelete = ({ row, accessor }) => {
  return (
    <ButtonDelete
      document={row.document}
      id={row.id}
      listDeleteDraft={componentInstance.handleDeleteDraft}
    />
  );
};

const TableDraftAlert = ({ row, accessor }) => {
  return (
    <span>
      <a
        className=" set-reminder"
        title="Set/Edit"
        onClick={() => componentInstance.handleSetReminderClick(row.id)}
      >
        <i className="far fa-clock" />
      </a>
    </span>
  );
};

class DraftsList extends Component {
  constructor(props) {
    super(props);
    componentInstance = this;
    this.state = {
      reboot: false,
      openShare: "0",
      draftId: "",
      fieldsLoaded: false,
      stepsLoaded: false,
      formShareFieldsLoaded: false,
      formShareFields: {},
      formShareFieldsMessage: "",
      formShareFieldsMessageLoaded: false,
      formSharedFieldsParents: {},
      // columnsLoaded: false,
      user: null,
      reminderDraftId: "",
      reminderFormFieldsLoaded: false,
      reminderForm: {
        datetime: "",
        email: "",
        message: "",
        related_documents: [],
      },
      selectedOption: "default",
      openSocialModal: false,
      expandGlossaries: {},
      glossariesCodeNameToId: {},
      glossariesCodeIdToName: {},
      draftReUse: {
        ...initialDraftReUseState,
      },
    };
  }

  getTitle = () => {
    if (_.size(this.props.items)) {
      return <h2>Drafts</h2>;
    }
  };

  processLink = (item) => {
    let locale = language.getIso2();
    // return `${window.location.protocol}//${window.location.host}/${locale}/documenten/${makeSEFUrl(item.category.name)}-${item.document.category_id}/${makeSEFUrl(item.document.name)}-${item.document.id}/${item.latestrevision_id}`
    // TODO REMOVE WHEN PUSH(For development)
    if (this.props.isRole) {
      return `${window.location.protocol}//${
        window.location.host
      }/${locale}/role/documenten/${makeSEFUrl(item.category.name)}-${
        item.document.category_id
      }/${makeSEFUrl(item.document.name)}-${item.document.id}/${
        item.latestrevision_id
      }${
        item.selectorType !== "" && item.selectorType !== undefined
          ? `/${item.selectorType}`
          : ""
      }`;
    } else {
      return `${window.location.protocol}//${
        window.location.host
      }/${locale}/documenten/${makeSEFUrl(item.category.name)}-${
        item.document.category_id
      }/${makeSEFUrl(item.document.name)}-${item.document.id}/${
        item.latestrevision_id
      }${
        item.selectorType !== "" && item.selectorType !== undefined
          ? `/${item.selectorType}`
          : ""
      }${
        item.user_workflow_id
          ? `?currentWorkflowId=${item.user_workflow_id}&milestoneId=${item.milestone_id}`
          : ""
      }${item.workflow_id ? `&workflowId=${item.workflow_id}` : ``}`;
    }
  };

  prepareMessage = (item) => {
    return this.state.formShareFieldsMessage;
  };

  processTemplateLink = (item) => {
    let locale = language.getIso2();
    return `${window.location.protocol}//${
      window.location.host
    }/${locale}/documenten/${makeSEFUrl(item.category.name)}-${
      item.document.category_id
    }/${makeSEFUrl(item.document.name)}-${item.document.id}`;
  };

  processCategoryLink = (item) => {
    let locale = language.getIso2();
    return `${window.location.protocol}//${
      window.location.host
    }/${locale}/documenten/${makeSEFUrl(item.category.name)}-${
      item.document.category_id
    }`;
  };

  handleDraftReUseClick = (draft) => {
    let draftReUse = this.state.draftReUse;
    draftReUse.showModal = true;
    draftReUse.draftId = draft.id;
    draftReUse.documentId = draft.document_id;
    draftReUse.name = draft.name;
    draftReUse.data = draft;
    this.setState({ draftReUse });
  };

  onDraftReUseModalHide = () => {
    this.setState({ draftReUse: { ...initialDraftReUseState } });
  };

  handleDeleteDraft = (id) => {
    this.props.formDeleteDraft(id);
  };

  showInfoModal = (name, description, readMoreLink = null) => {
    this.setState(
      {
        modalName: name,
        modalDescription: description,
        modalReadMoreLink: readMoreLink,
      },
      this.refs.helperTextModal.show()
    );
  };

  hideInfoModal = () => {
    this.refs.helperTextModal.hide();
  };

  meerInformationIcon = (event, desc, moreLink) => {
    event.preventDefault();

    this.showInfoModal(desc, moreLink);
  };

  handleShareCallback = (trigger, itemId, openShareFields, item) => {
    if (trigger) {
      this.setState({ openShare: 0, openSocialModal: false });
    } else {
      this.setState({ openShare: itemId });

      if (openShareFields) {
        if (!item) item = _.find(this.props.drafts, { id: itemId });
        this.handleShareFieldsClick(item);
      }
    }
  };

  processRevisionLink = (draft, revisionDraftId) => {
    let locale = language.getIso2();
    return `${window.location.protocol}//${
      window.location.host
    }/${locale}/documenten/${makeSEFUrl(draft.category.name)}-${
      draft.document.category_id
    }/${makeSEFUrl(draft.document.name)}-${
      draft.document.id
    }/${revisionDraftId}${
      draft.selectorType !== "" && draft.selectorType !== undefined
        ? `/${draft.selectorType}`
        : ""
    }${
      draft.user_workflow_id
        ? `?currentWorkflowId=${draft.user_workflow_id}&milestoneId=${draft.milestone_id}`
        : ""
    }${draft.workflow_id ? `&workflowId=${draft.workflow_id}` : ``}`;
  };

  getRevisions = (draft) => {
    if (draft) {
      return _.map(draft.revisions, (revisionDraft) => {
        return (
          <option
            value={this.processRevisionLink(draft, revisionDraft.id)}
            key={revisionDraft.id}
          >
            {strings.get("Client.draftsPage.revisions")}{" "}
            {revisionDraft.revision}
          </option>
        );
      });
    }
  };

  handleChange = (e) => {
    window.location = e.target.value;
  };

  /*Start */
  componentDidMount() {
    this.loadGlossaries();
    this.setState({
      user: auth.getLocalUser() ? auth.getLocalUser() : null,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.glossaries &&
      nextProps.glossaries !== this.props.glossaries &&
      !_.size(this.state.expandGlossaries)
    ) {
      this.loadGlossaries(nextProps);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      _.size(prevProps.stepsFromValidation) === 0 &&
      _.size(this.props.stepsFromValidation) > 0
    ) {
      this.tryLoadCurrentItem();
    }
    if (this.props.draftShareFields !== prevProps.draftShareFields) {
      if (!this.state.formShareFieldsLoaded) {
        let shareFields = this.props.draftShareFields
          ? JSON.parse(this.props.draftShareFields)
          : "";
        this.setState({
          formShareFieldsLoaded: true,
          formShareFields: shareFields,
        });
      }
    }
    if (_.size(this.props.currentItem) > 0) {
      // set message after current item set.
      if (!this.state.formShareFieldsMessageLoaded) {
        let message = this.props.currentItem.share_fields_message;
        if (this.props.profile.settings && !message) {
          let settingObject = JSON.parse(this.props.profile.settings);
          if (_.has(settingObject, "share_draft_instruction.message")) {
            message = settingObject.share_draft_instruction.message;
          }
        }
        this.setState({
          formShareFieldsMessage: message,
          formShareFieldsMessageLoaded: true,
        });
      }
      let reminder = this.props.currentItem.reminder;
      if (reminder && !this.state.reminderFormFieldsLoaded) {
        let reminderForm = _.extend({}, this.state.reminderForm);
        _.map(this.state.reminderForm, (value, key) => {
          reminderForm[key] = reminder[key]
            ? reminder[key]
            : this.state.reminderForm[key];
        });
        this.setState({
          reminderFormFieldsLoaded: true,
          reminderForm,
        });
      }
    }
  }

  orderFieldsByStep = (arr) => {
    let y = [];
    _.map(arr, (field) => {
      if (!y[field["stepId"] - 1]) y[field["stepId"] - 1] = [];
      y[field["stepId"] - 1].push(field);
    });

    let newArr = [];
    y = _.map(y, (item) => {
      if (typeof item !== "undefined") newArr.push(item);
    });

    return newArr;
  };

  loadGlossaries = (props = this.props) => {
    if (
      props.glossaries &&
      _.size(props.glossaries) &&
      !_.size(this.state.expandGlossaries)
    ) {
      let temp = {};
      let tempCodeNameToId = {};
      let tempCodeIdToName = {};
      let _glossaries = props.glossaries;
      _.map(_glossaries, (element) => {
        if (element.clauses.length) {
          element.clauses.forEach((clause) => {
            temp[`${clause.name}`] = clause;
            tempCodeNameToId[`${clause.name}`] = `clause_${clause.id}`;
            tempCodeIdToName[`clause_${clause.id}`] = `${clause.name}`;
          });
        }
        if (element.fields.length) {
          element.fields.forEach((field) => {
            temp[`${field.name}`] = field;
            tempCodeNameToId[`${field.name}`] = `field_${field.id}`;
            tempCodeIdToName[`field_${field.id}`] = `${field.name}`;
          });
        }
        if (element.selectors.length) {
          element.selectors.forEach((selector) => {
            temp[`${selector.name}`] = selector;
            tempCodeNameToId[`${selector.name}`] = `selector_${selector.id}`;
            tempCodeIdToName[`selector_${selector.id}`] = `${selector.name}`;
          });
        }
      });
      this.setState({
        expandGlossaries: temp,
        glossariesCodeNameToId: tempCodeNameToId,
        glossariesCodeIdToName: tempCodeIdToName,
      });
    }
  };

  tryLoadCurrentItem = () => {
    if (
      (_.size(this.props.fields) && !this.state.fieldsLoaded) ||
      (this.state.fieldsLoaded &&
        _.size(this.props.fields) !== this.state.fieldsCount)
    ) {
      let newFields = _.map(this.props.fields, (item) => {
        return item.asMutable ? item.asMutable() : item;
      });
      _.map(this.props.selectors, (item) => {
        newFields.push(item.asMutable ? item.asMutable() : item);
      });
      _.map(this.props.clauses, (item) => {
        newFields.push(item.asMutable ? item.asMutable() : item);
      });
      if (this.props.fieldsOrder) {
        _.map(newFields, (item) => {
          if (typeof this.props.fieldsOrder[item.name] !== "undefined") {
            item.index = this.props.fieldsOrder[item.name];
          }
        });
        newFields = _.sortBy(newFields, "index");
      }
      newFields = this.orderFieldsByStep(newFields);
      this.setState({
        fieldsLoaded: true,
        fields: newFields,
        formChanged: false,
        fieldsCount: _.size(this.props.fields),
      });
    }
    if (_.size(this.props.stepsFromValidation) && !this.state.stepsLoaded) {
      let items = _.map(this.props.stepsFromValidation, (step) => {
        return step;
      });
      let orderedItem = _.orderBy(items, ["order"], ["asc"]);
      let current_step_id = 0;
      if (orderedItem[0].id) {
        current_step_id = orderedItem[0].id;
      }
      this.setState({
        stepsLoaded: true,
        steps: items,
        currentStepId: current_step_id,
      });
    }
  };

  validateDocument = (documentId) => {
    this.props.formValidateDoc(documentId);
  };

  validateDraft = (draftId) => {
    this.props.formValidateDraft(draftId);
  };

  showShareFieldsModal = () => {
    let modal = this.refs.shareFieldsModal;
    if (
      !(
        this.props.exceptions &&
        this.props.exceptions.template &&
        this.props.exceptions.template.notDefined
      )
    ) {
      modal.show();
    }
  };

  handleShareFieldsClick = (draft) => {
    this.setState({
      fieldsLoaded: false,
      stepsLoaded: false,
    });
    if (draft.id !== this.state.draftId) {
      this.setState({
        formShareFieldsLoaded: false,
        formShareFields: {},
        formSharedFieldsParents: {},
        formShareFieldsMessage: "",
        formShareFieldsMessageLoaded: false,
      });
    }
    this.validateDocument(draft.document_id);
    this.validateDraft(draft.id);
    this.setState({
      fieldsLoaded: true,
      draftId: draft.id,
    });
    this.props.unsetCurrentItemId();
    _.delay(() => {
      this.props.setCurrentItemId(draft.id);
      this.showShareFieldsModal();
    }, 500);
  };

  handleEditFieldsClick = (draft) => {
    this.setState(
      {
        editDraftName: draft.name,
        draftId: draft.id,
      },
      () => {
        this.showEditFieldsModal();
      }
    );
  };

  showEditFieldsModal = () => {
    let modal = this.refs.editFieldsModal;
    modal.show();
  };

  handleDraftNameInputChange = (e) => {
    this.setState({
      editDraftName: e.target.value,
    });
  };

  editableFields = () => {
    return (
      <div className="editable-fields-box">
        <input
          type="text"
          className="form-control"
          name="editable_draft_name"
          value={this.state.editDraftName}
          onChange={this.handleDraftNameInputChange}
        />
      </div>
    );
  };

  editFieldsModalConfirm = () => {
    let newFields = {};
    if (this.state.editDraftName) {
      newFields["name"] = this.state.editDraftName;
    }
    this.props.updateItemDraftName(this.state.draftId, newFields);
    this.refs.editFieldsModal.hide();
  };

  editFieldsModalCancel = () => {
    this.refs.editFieldsModal.hide();
  };

  buildLabel = (Items, item) => {
    let data = [];
    let { expandGlossaries, glossariesCodeIdToName } = this.state;
    Items.forEach(function (Item) {
      let _label = Item.name;
      // separate out all selectors and loop through
      let result = strings.getFromBetween.get(_label, "[[", "]]");
      result.forEach((e) => {
        const reg = new RegExp(e);

        //if multiple codes are responsible for name of a step
        let stepNameOptions = e.split("||");
        if (stepNameOptions.length > 1) {
          stepNameOptions.forEach((element) => {
            element = element.trim();
            // work out pattern [[*.*_*]] for selects
            if (element.indexOf(".") > -1) {
              const field = element.split(".")[0];
              if (expandGlossaries && _.size(expandGlossaries)) {
                if (
                  expandGlossaries[field] &&
                  expandGlossaries[field].placeholderField
                ) {
                  _label = _label
                    .replace(element, expandGlossaries[field].placeholderField)
                    .replace(/\[\[|\]\]/g, "");
                } else if (
                  expandGlossaries[glossariesCodeIdToName[field]] &&
                  expandGlossaries[glossariesCodeIdToName[field]]
                    .placeholderField
                ) {
                  _label = _label
                    .replace(
                      element,
                      expandGlossaries[glossariesCodeIdToName[field]]
                        .placeholderField
                    )
                    .replace(/\[\[|\]\]/g, "");
                } else {
                  _label = _label
                    .replace(element, "< ## >")
                    .replace(/\[\[|\]\]/g, "");
                }
              }
            } else {
              if (expandGlossaries && _.size(expandGlossaries)) {
                if (
                  expandGlossaries[element] &&
                  expandGlossaries[element].placeholderField
                ) {
                  _label = _label
                    .replace(
                      element,
                      expandGlossaries[element].placeholderField
                    )
                    .replace(/\[\[|\]\]/g, "");
                } else if (
                  expandGlossaries[glossariesCodeIdToName[element]] &&
                  expandGlossaries[glossariesCodeIdToName[element]]
                    .placeholderField
                ) {
                  _label = _label
                    .replace(
                      element,
                      expandGlossaries[glossariesCodeIdToName[element]]
                        .placeholderField
                    )
                    .replace(/\[\[|\]\]/g, "");
                } else {
                  _label = _label
                    .replace(element, "< ## >")
                    .replace(/\[\[|\]\]/g, "");
                }
              }
            }
          });
        }
        //if single code is responsible for name of a step
        // work out pattern [[*.*_*]] for selects
        else if (e.indexOf(".") > -1) {
          const field = e.split(".")[0];
          if (expandGlossaries && _.size(expandGlossaries)) {
            if (
              expandGlossaries[field] &&
              expandGlossaries[field].placeholderField
            ) {
              _label = _label
                .replace(reg, expandGlossaries[field].placeholderField)
                .replace(/\[\[|\]\]/g, "");
            } else if (
              expandGlossaries[glossariesCodeIdToName[field]] &&
              expandGlossaries[glossariesCodeIdToName[field]].placeholderField
            ) {
              _label = _label
                .replace(
                  reg,
                  expandGlossaries[glossariesCodeIdToName[field]]
                    .placeholderField
                )
                .replace(/\[\[|\]\]/g, "");
            } else {
              _label = _label.replace(reg, "< ## >").replace(/\[\[|\]\]/g, "");
            }
          }
        } else {
          // if there's no placeholder there, then find placeholder in all glossaries
          if (expandGlossaries && _.size(expandGlossaries)) {
            if (expandGlossaries[e] && expandGlossaries[e].placeholderField) {
              _label = _label
                .replace(reg, expandGlossaries[e].placeholderField)
                .replace(/\[\[|\]\]/g, "");
            } else if (
              expandGlossaries[glossariesCodeIdToName[e]] &&
              expandGlossaries[glossariesCodeIdToName[e]].placeholderField
            ) {
              _label = _label
                .replace(
                  reg,
                  expandGlossaries[glossariesCodeIdToName[e]].placeholderField
                )
                .replace(/\[\[|\]\]/g, "");
            } else {
              _label = _label.replace(reg, "< ## >").replace(/\[\[|\]\]/g, "");
            }
          }
        }
      });
      data.push({
        id: Item.id,
        code: Item.name,
        name: _label,
      });
    });
    return data;
  };

  handleCheckboxChange = (e) => {
    let formShareFields = _.extend({}, this.state.formShareFields);
    formShareFields[e.target.name] = e.target.checked;
    this.setState({ formShareFields });
  };

  handleStepCheckboxChange = (e) => {
    let dataSet = e.target.dataset;
    let stepId = parseInt(dataSet.stepId);
    let stepFields = this.state.fields;
    let result = {};
    _.map(stepFields, function (item) {
      if (item.stepId === stepId) {
        result = item;
      }
    });
    let formShareFields = _.extend({}, this.state.formShareFields);
    _.forEach(result, function (value, key) {
      formShareFields[value.name] = e.target.checked;
    });
    let formSharedFieldsParents = _.extend(
      {},
      this.state.formSharedFieldsParents
    );
    formSharedFieldsParents[e.target.name] = e.target.checked;
    this.setState({ formSharedFieldsParents });
    this.setState({ formShareFields });
    return true;
  };

  checkSelectAllStatus = () => {
    let formSharedFieldsParents = this.state.formSharedFieldsParents;
    let steps = this.state.steps;
    if (!_.size(formSharedFieldsParents)) {
      return false;
    }
    if (_.size(formSharedFieldsParents) !== _.size(steps)) {
      return false;
    }
    for (const key in formSharedFieldsParents) {
      if (formSharedFieldsParents.hasOwnProperty(key)) {
        if (!formSharedFieldsParents[key]) {
          return false;
        }
      }
    }
    return true;
  };

  handleAllCheckboxsChange = (e) => {
    let formShareFields = _.extend({}, this.state.formShareFields);
    let stepFields = this.state.fields;
    _.forEach(stepFields, function (values, key) {
      _.forEach(values, function (value, key) {
        formShareFields[value.name] = e.target.checked;
      });
    });
    let formSharedFieldsParents = _.extend(
      {},
      this.state.formSharedFieldsParents
    );
    let orderedItem = _.orderBy(this.state.steps, ["order"], ["asc"]);
    _.forEach(orderedItem, function (value, key) {
      formSharedFieldsParents[value.name] = e.target.checked;
    });
    this.setState({ formSharedFieldsParents });
    this.setState({ formShareFields });
  };

  // steps = (orderedItem, codeName, stepFields) => {
  //   let items = [];
  //   items.push(
  //     <li key={_.uniqueId()}>
  //       <input
  //         type="checkbox"
  //         title="Select all"
  //         onChange={this.handleAllCheckboxsChange}
  //       />
  //       &nbsp;
  //       <b>Select all</b>
  //     </li>
  //   );
  //   items.push(
  //     _.map(orderedItem, (item, index) => {
  //       let placeholder = _.find(codeName, ["code", item.name]);
  //       let listItems = _.find(stepFields, ["stepId", item.id]);
  //       return (
  //         <li key={_.uniqueId()}>
  //           <input
  //             type="checkbox"
  //             title="Check All"
  //             data-step-id={item.id}
  //             name={item.name}
  //             checked={this.state.formSharedFieldsParents[item.name]}
  //             onChange={this.handleStepCheckboxChange}
  //           />
  //           &nbsp;
  //           <b>{placeholder.name}</b>
  //           <ul>{this.fields(listItems)}</ul>
  //           <hr />
  //         </li>
  //       );
  //     })
  //   );
  //   return items;
  // };

  stepsCards = (orderedItem, codeName, stepFields) => {
    let items = [];
    items.push(
      <Card key={_.uniqueId()}>
        <Card.Header>
          <input
            type="checkbox"
            title="Select all"
            onChange={this.handleAllCheckboxsChange}
            checked={this.checkSelectAllStatus()}
          />
          <Accordion.Toggle>
            <b>{strings.get("Client.draftsPage.selectAll")}</b>
          </Accordion.Toggle>
        </Card.Header>
      </Card>
    );
    items.push(
      _.map(orderedItem, (item, index) => {
        let placeholder = _.find(codeName, ["code", item.name]);
        let listItems = _.find(stepFields, ["stepId", item.id]);
        return (
          <Card key={_.uniqueId()}>
            <Card.Header>
              <input
                type="checkbox"
                title="Check All"
                data-step-id={item.id}
                name={item.name}
                checked={this.state.formSharedFieldsParents[item.name]}
                onChange={this.handleStepCheckboxChange}
              />
              <Accordion.Toggle eventKey={index + 1}>
                <b>{placeholder.name}</b>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={index + 1}>
              <Card.Body>
                <ul>{this.fields(listItems)}</ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        );
      })
    );
    return items;
  };

  shareFieldsExists = (name) => {
    let formShareFields = this.state.formShareFields;
    if (
      formShareFields &&
      formShareFields.hasOwnProperty(name) &&
      formShareFields[name]
    ) {
      return true;
    }
    return false;
  };

  fields = (items) => {
    if (items) {
      return _.map(items, (item) => {
        let fieldName = item.placeholderField
          ? item.placeholderField
          : item.name;
        return (
          <li key={_.uniqueId()}>
            <input
              type="checkbox"
              checked={this.shareFieldsExists(item.name)}
              name={item.name}
              onChange={this.handleCheckboxChange}
            />
            &nbsp;
            {fieldName}
          </li>
        );
      });
    }
  };

  getPlaceholderField = (field) => {
    let result = field;
    _.forEach(this.state.fields, function (value, key) {
      let filteredResult = _.filter(value, function (o) {
        return o.name === field;
      });

      if (filteredResult.length > 0) {
        result = filteredResult[0].placeholderField
          ? filteredResult[0].placeholderField
          : field;
        return false;
      }
    });
    return result;
  };

  lisfOfSharedFields = () => {
    let items = _.map(this.state.formShareFields, (item, index) => {
      if (item) {
        return (
          <span className="badge badge-secondary" key={_.uniqueId()}>
            {this.getPlaceholderField(index)}
          </span>
        );
      }
    });
    return items;
  };

  handleOptionChange = (event) => {
    event.preventDefault();
    this.setState({
      selectedOption: event.target.value,
    });
  };

  shareableFields = () => {
    let orderedItem = _.orderBy(this.state.steps, ["order"], ["asc"]);
    let codeName = this.buildLabel(orderedItem);
    let stepFields = this.state.fields;
    _.map(stepFields, (fields, ind) => {
      stepFields[ind].stepId = parseInt(fields[0].stepId);
    });

    return (
      <div className="row shareable-fields">
        <div className="col-xs-7">
          <h5>{strings.get("Client.draftsPage.shareFields")}</h5>
          <div className="share-fields-box">
            {/* <ul>{this.steps(orderedItem, codeName, stepFields)}</ul> */}
            <Accordion>
              {this.stepsCards(orderedItem, codeName, stepFields)}
            </Accordion>
          </div>
        </div>
        <div className="col-xs-5">
          <h5>{strings.get("Client.draftsPage.selectedFields")}</h5>
          <div className="share-fields-box">{this.lisfOfSharedFields()}</div>
        </div>
        <div className="col-xs-12 instruction-message tiny-mce">
          <label>
            {strings.get("Client.documentsList.shareMsgModal.instructionMsg")}:{" "}
          </label>
          <textarea
            className="form-control"
            name="share_fields_message"
            value={this.state.formShareFieldsMessage}
            onChange={this.handleShareFieldsMessageChange}
          />
        </div>
        <div
          className="col-xs-12"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <label>
            {strings.get(
              "Client.documentsList.shareMsgModal.receiverChoiceTitle"
            )}
          </label>
          <div>
            <label
              style={{ fontWeight: "200" }}
              key={`default-${this.state.selectedOption}`}
            >
              <input
                style={{ marginRight: "5px" }}
                name="clientType"
                type="radio"
                value="default"
                checked={this.state.selectedOption === "default"}
                onClick={this.handleOptionChange}
              />
              {strings.get("Client.documentsList.shareMsgModal.default")}
            </label>
            <label
              style={{ fontWeight: "200" }}
              key={`me-${this.state.selectedOption}`}
            >
              <input
                style={{ marginRight: "5px" }}
                name="clientType"
                type="radio"
                value="me"
                checked={this.state.selectedOption === "me"}
                onClick={this.handleOptionChange}
              />
              {strings.get("Client.documentsList.shareMsgModal.me")}
            </label>
            <label
              style={{ fontWeight: "200" }}
              key={`client-${this.state.selectedOption}`}
            >
              <input
                style={{ marginRight: "5px" }}
                name="clientType"
                type="radio"
                value="client"
                checked={this.state.selectedOption === "client"}
                onClick={this.handleOptionChange}
              />
              {strings.get("Client.documentsList.shareMsgModal.client")}
            </label>
          </div>
        </div>
      </div>
    );
  };

  handleShareFieldsMessageChange = (e) => {
    this.setState({
      formShareFieldsMessage: e.target.value,
    });
  };

  shareFieldsModalConfirm = () => {
    let newFields = {};
    _.map(this.state.formShareFields, (item, key) => {
      if (item) newFields[key] = item;
    });
    let data = {
      newFields: newFields,
      message: this.state.formShareFieldsMessage,
    };
    this.props.updateItemShareFields(this.state.draftId, data);
    // this.refs.shareFieldsModal.hide();
    this.shareFieldsModalCancel();
    this.handleShareCallback(false, this.state.draftId, false);
  };

  shareFieldsModalCancel = () => {
    this.refs.shareFieldsModal.hide();
    this.setState({
      openSocialModal: true,
    });
  };

  timeConverter = (updated) => {
    return moment(updated.date).format("DD-MM-YYYY HH:mm:ss");
  };
  /*End */

  /* Collapsing Responsive Table Start*/
  getDraftRows = () => {
    let rows = [];
    if (this.props.drafts) {
      _.forEach(this.props.drafts, function (item, key) {
        rows.push(item);
      });
    }
    // After rows loaded, then set columnsLoaded to True.
    // if (!this.state.columnsLoaded && rows.length > 0) {
    //   _.delay(() => {
    //     this.setState({
    //       columnsLoaded: true,
    //     });
    //   }, 500);
    // }
    return rows;
  };

  getDraftColumns = () => {
    const breakpoints = {
      desktop: 1024,
      ipad: 768,
      tablet: 640,
      mobile: 480,
    };
    let columnWidth;
    if (window.innerWidth >= breakpoints.desktop) {
      columnWidth = 200;
    } else if (window.innerWidth <= breakpoints.ipad) {
      columnWidth = 200;
    }

    let columns = [
      {
        accessor: "name",
        label: strings.get("Client.draftsPage.draftName"),
        priorityLevel: 1,
        position: 1,
        minWidth: columnWidth,
        sortable: false,
        CustomComponent: TableDraftName,
      },
      {
        accessor: "revisions",
        label: strings.get("Client.draftsPage.revisions"),
        priorityLevel: 4,
        position: 2,
        minWidth: columnWidth,
        sortable: false,
        CustomComponent: TableDraftRevisions,
      },
      {
        accessor: "documentName",
        label: strings.get("Client.draftsPage.docName"),
        priorityLevel: 3,
        position: 3,
        minWidth: columnWidth,
        sortable: false,
        CustomComponent: TableDraftDocName,
      },
      {
        accessor: "draftReUse",
        label: strings.get("Client.draftsPage.reUse"),
        priorityLevel: 7,
        position: 7,
        minWidth: columnWidth,
        sortable: false,
        CustomComponent: TableDraftReUse,
      },
      {
        accessor: "shareColumn",
        label: strings.get("Client.draftsPage.share"),
        priorityLevel: 2,
        position: 4,
        minWidth: columnWidth,
        sortable: false,
        CustomComponent: TableDraftShare,
      },
      {
        accessor: "alertColumn",
        label: strings.get("Client.draftsPage.actions"),
        priorityLevel: 5,
        position: 5,
        minWidth: columnWidth,
        sortable: false,
        CustomComponent: TableDraftAlert,
      },
      {
        accessor: "deleteColumn",
        label: strings.get("Client.draftsPage.delete"),
        priorityLevel: 6,
        position: 6,
        minWidth: columnWidth,
        sortable: false,
        CustomComponent: TableDraftDelete,
      },
    ];

    // let columnsForWorkflow = [
    //   {
    //     accessor: "name",
    //     label: strings.get("Client.draftsPage.draftName"),
    //     priorityLevel: 1,
    //     position: 1,
    //     minWidth: columnWidth,
    //     sortable: false,
    //     CustomComponent: TableDraftName,
    //   },
    //   {
    //     accessor: "revisions",
    //     label: strings.get("Client.draftsPage.revisions"),
    //     priorityLevel: 4,
    //     position: 2,
    //     minWidth: columnWidth,
    //     sortable: false,
    //     CustomComponent: TableDraftRevisions,
    //   },
    //   {
    //     accessor: "shareColumn",
    //     label: strings.get("Client.draftsPage.share"),
    //     priorityLevel: 2,
    //     position: 4,
    //     minWidth: columnWidth,
    //     sortable: false,
    //     CustomComponent: TableDraftShare,
    //   },
    //   {
    //     accessor: "deleteColumn",
    //     label: strings.get("Client.draftsPage.delete"),
    //     priorityLevel: 6,
    //     position: 6,
    //     minWidth: columnWidth,
    //     sortable: false,
    //     CustomComponent: TableDraftDelete,
    //   },
    // ];
    // if (this.props.isWorkflow) {
    //   return columnsForWorkflow;
    // }
    return columns;
  };

  handleSetReminderClick = (id) => {
    this.props.unsetCurrentItemId();
    this.handleReminderFormModalHide();
    _.delay(() => {
      this.props.setCurrentItemId(id);
      if (this.state.reminderForm.email === "") {
        let item = {};
        item["email"] = this.state.user.email;
        this.setState({
          reminderForm: _.extend(this.state.reminderForm, item),
        });
      }
      this.setState(
        {
          reminderDraftId: id,
        },
        () => {
          this.addRelatedDocToReminder();
        }
      );
      this.showReminderFormModal();
    }, 500);
  };

  addRelatedDocToReminder = () => {
    let { reminderForm } = this.state;
    reminderForm.related_documents = this.props.currentItem.related_documents
      ? this.props.currentItem.related_documents
      : [];
    this.setState({ reminderForm });
  };

  showReminderFormModal = () => {
    let modal = this.refs.reminderFormModal;
    if (
      !(
        this.props.exceptions.template &&
        this.props.exceptions.template.notDefined
      )
    ) {
      modal.show();
    }
  };

  handleInputChange = (e) => {
    let item = {};
    item[e.target.name] = e.target.value;
    this.setState({
      reminderForm: _.extend(this.state.reminderForm, item),
    });
  };

  handleDateInputChange = (date, name) => {
    let dateOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let item = {};
    item[name] = new Date(date).toLocaleDateString("nl-NL", dateOptions);
    this.setState({
      reminderForm: _.extend(this.state.reminderForm, item),
    });
  };

  handleReminderFormCancelClick = () => {
    this.refs.reminderFormModal.hide();
  };

  handleReminderFormSaveClick = () => {
    let updateItem = this.props.updateItemReminder(
      this.state.reminderDraftId,
      this.state.reminderForm
    );
    Promise.all([updateItem]).then(() => {
      _.delay(() => {
        if (_.size(this.props.exceptions) === 0) {
          this.handleReminderFormCancelClick();
        }
      }, 4000);
    });
  };

  handleReminderFormModalHide = () => {
    this.props.clearReminderException();
    this.setState({
      reminderDraftId: "",
      reminderFormFieldsLoaded: false,
      reminderForm: {
        datetime: "",
        email: "",
        message: "",
        related_documents: [],
      },
    });
  };

  hasError = (inputName) => {
    return !!this.props.exceptions[inputName];
  };

  getErrorClass = (inputName, defaultClasses = "") => {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  };

  getErrorMessage = (inputName) => {
    return this.props.exceptions[inputName];
  };

  render() {
    // const { drafts } = this.props;
    let datetimeError = this.hasError("datetime")
      ? `${strings.get(
          "Client.downloadsPage.reminderForm.reminder_date"
        )} ${this.getErrorMessage("datetime")}`
      : "";
    let emailError = this.hasError("email")
      ? `${strings.get(
          "Client.downloadsPage.reminderForm.email"
        )} ${this.getErrorMessage("email")}`
      : "";
    let messageError = this.hasError("message")
      ? `${strings.get(
          "Client.downloadsPage.reminderForm.message"
        )} ${this.getErrorMessage("message")}`
      : "";

    let shareFieldsModalContent = (
      <div className="jd-modal">
        {this.shareableFields()}
        <button className="btn btn1" onClick={this.shareFieldsModalCancel}>
          {strings.get("App.documents.documentForm.orderFieldsModal.cancel")}
        </button>
        <button className="btn btn2" onClick={this.shareFieldsModalConfirm}>
          {strings.get("App.documents.documentForm.orderFieldsModal.save")}
        </button>
      </div>
    );

    let editFieldsModalContent = (
      <div className="jd-modal">
        <label className="pull-left">
          {strings.get("Client.editModal.title")}
        </label>
        <br />
        {this.editableFields()}
        <button className="btn btn2" onClick={this.editFieldsModalConfirm}>
          {strings.get("App.documents.documentForm.orderFieldsModal.save")}
        </button>
        <button className="btn btn3" onClick={this.editFieldsModalCancel}>
          {strings.get("App.documents.documentForm.orderFieldsModal.cancel")}
        </button>
      </div>
    );

    let reminderFormModalContent = this.props.currentItem ? (
      <div className="reminder-form">
        <div className="form-group text-left">
          <label className="control-label">
            {strings.get("Client.downloadsPage.reminderForm.reminder_date")}
          </label>
          <DatePicker
            fixedHeight
            locale="nl"
            placeholderText={strings.get(
              "Client.downloadsPage.reminderForm.date_placeholder"
            )}
            dateFormat="d MMMM yyyy"
            minDate={parseISO(moment().toISOString())}
            className={this.getErrorClass("datetime", "form-control")}
            selected={
              this.state.reminderForm.datetime
                ? parseISO(
                    moment(this.state.reminderForm.datetime).toISOString()
                  )
                : null
            }
            onChange={(date) => this.handleDateInputChange(date, "datetime")}
          />
          <small className="text-danger">{datetimeError}</small>
        </div>
        <div className="form-group text-left">
          <label className="control-label">
            {strings.get("Client.downloadsPage.reminderForm.email")}
          </label>
          <input
            className={this.getErrorClass("email", "form-control")}
            type="text"
            name="email"
            value={this.state.reminderForm.email}
            onChange={this.handleInputChange}
          />
          <small className="text-danger">{emailError}</small>
        </div>
        <div className="form-group text-left">
          <label className="control-label">
            {strings.get("Client.downloadsPage.reminderForm.message")}
          </label>
          <textarea
            className={this.getErrorClass("message", "form-control")}
            name="message"
            placeholder={strings.get(
              "Client.downloadsPage.reminderForm.message_placeholder"
            )}
            value={this.state.reminderForm.message}
            onChange={this.handleInputChange}
          />
          <small className="text-danger">{messageError}</small>
        </div>
        <div className="form-actions">
          <button
            className="btn btn2 mr-20"
            onClick={this.handleReminderFormSaveClick}
          >
            <i className="ion-clock" />
            {strings.get("Client.documentContent.downloadModal.btnSave")}
          </button>
          <button
            className="btn btn4"
            onClick={this.handleReminderFormCancelClick}
          >
            <i className="ion-android-cancel" />
            {strings.get("Client.documentContent.downloadModal.btnCencel")}
          </button>
        </div>
      </div>
    ) : null;

    let rows = this.getDraftRows();
    let columns = this.getDraftColumns();

    return (
      <div className="DraftsList">
        <div className="row">
          <div className="col-sm-12">
            <DraftsDataReUse
              draft={this.state.draftReUse}
              draftId={this.state.draftReUse.draftId}
              origin={"drafts"}
              onHide={this.onDraftReUseModalHide}
            />
            <CustomDropModal className="boron-modal" ref="shareFieldsModal">
              {/* <Modal className="boron-modal" ref="shareFieldsModal"> */}
              <div className="close-btn">
                <span
                  className="ion-android-cancel clickable"
                  onClick={this.shareFieldsModalCancel}
                />
              </div>
              {shareFieldsModalContent}
              {/* </Modal> */}
            </CustomDropModal>
            <CustomDropModal className="boron-modal" ref="editFieldsModal">
              {/* <Modal className="boron-modal" ref="editFieldsModal"> */}
              <div className="close-btn">
                <span
                  className="ion-android-cancel clickable"
                  onClick={this.editFieldsModalCancel}
                />
              </div>
              {editFieldsModalContent}
              {/* </Modal> */}
            </CustomDropModal>
            <CustomDropModal
              className="boron-modal"
              ref="reminderFormModal"
              onHide={this.handleReminderFormModalHide}
            >
              {/* <Modal
              className="boron-modal"
              ref="reminderFormModal"
              onHide={this.handleReminderFormModalHide}
            > */}
              <div className="close-btn">
                <i
                  className="ion-android-cancel clickable"
                  onClick={this.handleReminderFormCancelClick}
                />
              </div>
              {reminderFormModalContent}
              {/* </Modal> */}
            </CustomDropModal>
            {/* {this.state.columnsLoaded ? ( */}
            {_.size(rows) ? (
              <ReactCollapsingTable
                theme="react-collapsible-custom-theme"
                rows={rows}
                columns={columns}
              />
            ) : (
              <div className="col-sm-12 defaultMsg">
                <div>{strings.get("Client.documentsList.noFiles")}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

DraftsList.defaultProps = {
  isRole: false,
};

DraftsList.propTypes = {
  items: PropTypes.array,
  onMouseOver: PropTypes.func,
  isRole: PropTypes.bool,
  // isWorkflow: PropTypes.bool,
};

export default DraftsList;
