import ReactGA from "react-ga";
import api from "./api";
import _ from "lodash";
import { checkAnalyticsCookieConsent } from "../helpers";

var trackingID = undefined;
var trackingStatus = false;

export const initGA = () => {
  api
    .get("/settings/analytics_settings")
    .then((data) => {
      let analyticsData = _.fromPairs(_.map(data, (i) => [i.key, i.value]));
      trackingStatus =
        analyticsData["google_analytics_status"] === "true" ? true : false;
      trackingID =
        analyticsData["google_analytics_id"] !== ""
          ? analyticsData["google_analytics_id"]
          : undefined;
      if (trackingID && checkAnalyticsCookieConsent()) {
        ReactGA.initialize(trackingID);
        PageView();
      }
    })
    .catch((error) => {
      console.trace("Error in tracker id", error);
    });
};

export const PageView = (pageLabel) => {
  if (trackingStatus && checkAnalyticsCookieConsent()) {
    if (pageLabel) {
      ReactGA.pageview(pageLabel);
    } else {
      let location = window.location.pathname;
      location = location.split("/");
      if (location.length === 3 && location[2] === "") {
        ReactGA.pageview(location[1] + "- Home Page");
      } else if (location.length > 2 && location[2] !== "") {
        ReactGA.pageview(location[1] + "-" + location[2]);
      } else if (location.length === 2) {
        ReactGA.pageview((location[1] ? location[1] : "NL") + "- Home Page");
      } else {
        ReactGA.pageview(window.location.pathname);
      }
    }
  }
};

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const Event = (category = "", action = "", label = "") => {
  if (trackingStatus && checkAnalyticsCookieConsent())
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
};
