import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import LazyLoad from "react-lazyload";

import language from "../../services/language";
import strings from "../../services/strings";

import "./TeamList.scss";

class TeamList extends Component {
  handleLeftArrowClick = () => {
    let arr = document.getElementsByClassName("teamMember");
    if (arr.length <= 4) return;

    let visibles = document.getElementsByClassName("teamMember visible");
    visibles = _.map(visibles, (item) => {
      return item;
    });

    for (let i = 0; i < arr.length; i++) {
      if (visibles.indexOf(arr[i]) >= 0) {
        let n = i;
        n++;
        if (n > arr.length - 1) return;

        arr[n].classList.add("visible");
      }
    }
    visibles[0].classList.remove("visible");
  };

  handleRightArrowClick = () => {
    let arr = document.getElementsByClassName("teamMember");
    if (arr.length <= 4) return;

    let visibles = document.getElementsByClassName("teamMember visible");
    visibles = _.map(visibles, (item) => {
      return item;
    });
    for (let i = 0; i < arr.length; i++) {
      if (visibles.indexOf(arr[i]) >= 0) {
        let n = i;
        n--;
        if (n < 0) return;

        arr[n].classList.add("visible");
      }
    }
    visibles[3].classList.remove("visible");
  };

  getImage = (item) => {
    if (item.imageURL) {
      return (
        <div
          style={{ backgroundColor: "#fff" }}
          className="image-container grey"
        >
          <img
            style={{ width: "100%", height: "100%" }}
            src={item.imageURL}
            alt="teamMate"
          />
        </div>
      );
    } else {
      return <div className="image-container grey" />;
    }
  };

  getItems = () => {
    let locale = language.getIso2();

    return _.map(this.props.items, (item, i) => {
      let className = item.visible
        ? "col-md-3 col-sm-3 col-xs-6 teamMember visible"
        : "col-md-3 teamMember";
      return (
        <LazyLoad height={50} offset={100} key={_.uniqueId()}>
          <div
            key={`teamMember-${i}`}
            id={`teamMember-${i}`}
            className={className}
          >
            {this.getImage(item)}
            <Link
              className="name"
              to={`${locale}`}
            >{`${item.first_name} ${item.last_name}`}</Link>
            <p className="position">{item.position}</p>
            <p className="description">{item.description}</p>
          </div>
        </LazyLoad>
      );
    });
  };

  render() {
    return (
      <div className="teamList">
        <div className="container-row">
          <div className="row">
            <h2>{strings.get("Client.homePage.ourTeam")}</h2>
            <div className="arrows">
              <div
                className="arrow ion-ios-arrow-left"
                onClick={this.handleLeftArrowClick}
              ></div>
              <div
                className="arrow ion-ios-arrow-right"
                onClick={this.handleRightArrowClick}
              ></div>
            </div>
            {this.getItems()}
          </div>
        </div>
      </div>
    );
  }
}

export default TeamList;
