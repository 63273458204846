import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Breadcrumbs from "../../components/Breadcrumbs";
import Topbar from "../../components/Topbar";
import CategoryList from "../../../src/components/category/CategoryList";
import Footer from "../../components/footer/Footer";

import * as categoriesSelectors from "../../store/categories/selectors";
import * as categoriesActions from "../../store/categories/actions";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import strings from "../../services/strings";
import language from "../../services/language";

// import "../Page.scss";
class CategoriesListPage extends Component {
  componentDidMount() {
    this.props.fetchAllCategories();
  }

  render() {
    let locale = language.getIso2();
    return (
      <div className="CategoriesListPage">
        <Topbar
          title={strings.get("Client.homePage.moreCategories")}
          subtitle={strings.get("Client.homePage.subTitle")}
          searchBox={true}
          menuItem={"categories"}
        />

        <div className="breadcrumb-cont">
          <div className="container">
            <Breadcrumbs>
              <Link to={`/${locale}/`}>
                {strings.get("Client.homePage.home")}
              </Link>
              <Link to={`/${locale}/documenten`}>
                {strings.get("Client.homePage.headerMenu.documents")}
              </Link>
            </Breadcrumbs>
          </div>
        </div>

        <div className="inner-page-cont">
          <div className="container">
            <div className="row">
              <CategoryList items={this.props.categories} />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: categoriesSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllCategories: () => {
      dispatch(categoriesActions.fetchAllItems());
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesListPage);
