import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import "./Pagination.scss";

class Pagination extends Component {
  getPages = () => {
    let pages = [];
    let totalPages = this.props.pagination
      ? this.props.pagination.totalPages
      : "";
    let currentPage = this.props.pagination
      ? this.props.pagination.currentPage
      : "";

    if (totalPages <= 1) {
      return pages;
    }
    if (currentPage > 1) {
      pages.push("<");
    }
    pages.push(1);
    if (currentPage > 2) {
      pages.push("...");
      if (currentPage === totalPages && totalPages > 3) {
        pages.push(currentPage - 2);
      }
      pages.push(currentPage - 1);
    }
    if (currentPage !== 1 && currentPage !== totalPages) {
      pages.push(currentPage);
    }
    if (currentPage < totalPages - 1) {
      pages.push(currentPage + 1);
      if (currentPage === 1 && totalPages > 3) {
        pages.push(currentPage + 2);
      }
      pages.push("...");
    }
    if (totalPages >= currentPage) {
      pages.push(totalPages);
    }
    if (currentPage < totalPages) {
      pages.push(">");
    }

    return pages;
  };

  handleLinkClick = (page) => {
    this.props.setCurrentPage(page);
    this.props.fetchItems();
  };

  render() {
    let pages = this.getPages();
    let links = _.map(pages, (page, i) => {
      if (page === "<") {
        return (
          <li key={i}>
            <a
              href="#"
              className="arrow-left"
              onClick={() =>
                this.handleLinkClick(this.props.pagination.currentPage - 1)
              }
            >
              <i className="fas fa-angle-left"></i>
            </a>
          </li>
        );
      } else if (page === ">") {
        return (
          <li key={i}>
            <a
              className="arrow-right"
              href="#"
              onClick={() =>
                this.handleLinkClick(this.props.pagination.currentPage + 1)
              }
            >
              <i className="fas fa-angle-right"></i>
            </a>
          </li>
        );
      } else if (page === "...") {
        return (
          <li key={i}>
            <a href="#">...</a>
          </li>
        );
      } else {
        if (page === this.props.pagination.currentPage) {
          return (
            <li key={i} className="active">
              <a href="#">{page}</a>
            </li>
          );
        } else {
          return (
            <li key={i}>
              <a href="#" onClick={() => this.handleLinkClick(page)}>
                {page}
              </a>
            </li>
          );
        }
      }
    });

    return this.props.fetchItems && pages.length ? (
      <div className="pagination-wrapper col-md-10">
        <ul className="pagination">{links}</ul>
      </div>
    ) : null;
  }
}

Pagination.propTypes = {
  pagination: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
};

export default Pagination;
