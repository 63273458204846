import React, { Component } from "react";
import { connect } from "react-redux";

import Topbar from "../../components/Topbar";
import Footer from "../../components/footer/Footer";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import strings from "../../services/strings";

// import "../Page.scss";

class DonationPage extends Component {
  render() {
    return (
      <div className="DonationPage">
        <Topbar
          // title={'Donate'}
          title={strings.get("Client.donationModal.submit")}
          // handleLangChange={this.props.handleLangChange}
          // currentLang={this.props.currentLang}
          divider={true}
        />
        <div className="content">
          <div className="container-row">
            <div className="row">
              <div className="col-md-12 text-center">
                <h3>{strings.get("Client.donationModal.thanksMsg")}</h3>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DonationPage);
