import React from 'react';
import Transition from 'react-transition-group/Transition';

const duration = 500;

const defaultStyle = {
  transition: `opacity, transform ${duration}ms ease-in-out`,
}

const transitionStyles = {
  entering: { opacity: 0, transform: 'translateX(30%)' },
  entered: { opacity: 1, transform: 'translateX(0)' },
  exiting: { opacity: 0, transform: 'translateX(30%)' }
};

const Fade = ({ children, in: inProp }) => (
  <Transition in={inProp} timeout={duration}>
    {(state) => (
      <div style={{
        ...defaultStyle,
        ...transitionStyles[state]
      }}>
        {children}
      </div>
    )}
  </Transition>
);

export default Fade;